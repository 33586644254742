import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/user";
import { actionCreators as notificationActions } from "redux/notification";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {} },
    notification: { badge }
  } = state;
  return {
    auth,
    badge
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signOut: () => {
      dispatch(userActions.signOut());
      // dispatch(notificationActions.clearNotification());
    },
    viewNotification: (userId) => {
      dispatch(notificationActions.viewNotification(userId));
    },
    deleteUserNotification: () => {
      dispatch(userActions.deleteUserNotification());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
