import React from "react";
import styles from "./styles.scss";
import Button from "components/lib/Button";
import PropTypes from "prop-types";

const SendMagicLink = (props, context) => {
  return (
    <div className={styles.contents}>
      <form className={styles.singInForm}>
        <input
          className={styles.textInput}
          type="email"
          name="email"
          placeholder={context.t("email")}
          onChange={props.onInputChange}
        />
        <Button className={styles.signInButton} onClick={props.onSignIn} text={props.action === "signup" ? context.t("signup") : context.t("login")}/>
      </form>
    </div>
  );
};

SendMagicLink.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SendMagicLink;
