import React, { Component } from "react";
import ContestEntryEdit from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    resource: "team",
    entryList: [],
    loading: false,
    confirmLoading: false
  };

  async componentDidMount() {
    const {
      contestInfo: { participantType, entryMaxCount },
      getContestTeams,
      getContestUsers,
      match: {
        params: { id }
      }
    } = this.props;

    const resource = participantType === "MULTI" ? "team" : "user";

    this.setState({
      loading: true,
      resource
    });

    let resultList = [];
    if (resource === "team") {
      // ContestTeam 取得
      resultList = await getContestTeams(id);
    } else if (resource === "user") {
      // 大会参加者一覧取得
      resultList = await getContestUsers(id);
    }
    const entryList = [];
    for (const val of resultList) {
      // チェック有無を設定
      const checked = val.status === "ALLOW" ? true : false;
      entryList.push({
        id: val.id,
        checked
      });
    }

    // 一件も選択されてない場合は entryMaxCount 分選択
    if (!entryList.find((el) => el.checked)) {
      for (const index in entryList) {
        if (index < entryMaxCount) {
          entryList[index].checked = true;
        }
      }
    }

    this.setState({
      loading: false,
      entryList
    });
  }

  render() {
    return (
      <ContestEntryEdit
        {...this.props}
        {...this.state}
        handleCheck={this._handleCheck}
        handleConfirm={this._handleConfirm}
        handleCancel={this._handleCancel}
        handleTotalCheck={this._handleTotalCheck}
      />
    );
  }

  // 全 Item 選択 / 解除
  _handleTotalCheck = (event) => {
    const {
      target: { checked }
    } = event;
    const { entryList } = this.state;

    for (const val of entryList) {
      val.checked = checked;
    }
    this.setState({ entryList });
  };

  // エントリー確定 Item 選択
  _handleCheck = (event) => {
    const {
      target: { value, checked }
    } = event;

    const { entryList } = this.state;
    // deleteList 検索
    const item = entryList.find((el) => el.id === value);
    if (item) {
      item.checked = checked;
    } else {
      entryList.push({
        id: value,
        checked
      });
    }
    this.setState({ entryList });
  };

  // エントリー確定
  _handleConfirm = async () => {
    const { t } = this.context;
    const {
      contestInfo: { id, entryMaxCount },
      updateContestTeamStatus,
      updateContestUserStatus,
      mode
    } = this.props;
    const { entryList = [], resource } = this.state;

    const activeList = entryList.filter((el) => el.checked);

    // 選択Itemがない場合
    if (mode !== "confirm" && !activeList.length) {
      alert(t("selectEntryItem"));
      return;
    } else if (activeList.length > entryMaxCount) {
      if (!window.confirm(t("overEntryMaxCount", { value: entryMaxCount }))) {
        return;
      }
    }

    this.setState({
      confirmLoading: true
    });

    for (const val of entryList) {
      const status = val.checked ? "ALLOW" : null;
      if (resource === "team") {
        await updateContestTeamStatus(id, val.id, status);
      } else if (resource === "user") {
        await updateContestUserStatus(id, val.id, status);
      }
    }

    this.setState({
      confirmLoading: false
    });

    const { nextStep } = this.props;
    // 親コンポネントから受け取った次の処理を実行
    if (nextStep) {
      nextStep();
    }
  };

  // 戻る
  _handleCancel = (event) => {
    const { history } = this.props;
    history.goBack();
  };

  static propTypes = {};

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
