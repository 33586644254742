import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";

const BackgroundImage = ({ src, alt }) => (
  <div className={styles.container}>
    <img
      src={src ? src : require("images/background_noimage.png")}
      className={styles.image}
      alt={alt}
      onError={(e) => (e.target.src = require("images/background_noimage.png"))}
    />
  </div>
);

BackgroundImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string
};

export default BackgroundImage;
