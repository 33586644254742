import { axios } from "lib/axios";
import {
  convertDuration,
  isWithinDuration,
  convertFirestoreTime,
  //getContestDefaultImage,
  //convertFileUrl
  getPublicImageUrl,
} from "lib/convert";
import { firestore } from "lib/firebase";
import {
  getDocs,
  getDoc,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  collection,
  collectionGroup,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  onSnapshot,
  serverTimestamp,
  arrayUnion,
  arrayRemove,
  runTransaction,
  writeBatch,
  increment
} from "firebase/firestore";
import moment from "moment";

let unsubscribeBattleRoyalInfo,
  unsubscribeBattleRoyalResult,
  unsubscribeBattleRoyalMatchList,
  unsubscribeTournamentMatchList,
  unsubscribeMatchChat,
  unsubscribeSwissDrawMatchList,
  unsubscribeRoomList,
  unsubscribeSwissDrawResult,
  unsubscribeSwissDrawInfo;

// actions
const CLEAR_CONTEST_INFO = "CLEAR_CONTEST_INFO";
const SET_CONTEST_LIST = "SET_CONTEST_LIST";
const SET_CONTEST_INFO = "SET_CONTEST_INFO";
const SET_CONTEST_TEAMS = "SET_CONTEST_TEAMS";
const SET_CONTEST_USERS = "SET_CONTEST_USERS";
const SET_CONTEST_MYTEAMS = "SET_CONTEST_MYTEAMS";
const SET_CONTEST_MYROLE = "SET_CONTEST_MYROLE";
const SET_TROPHY_LIST = "SET_TROPHY_LIST";
const SET_TOURNAMENT_INFO = "SET_TOURNAMENT_INFO";
const SET_TOURNAMENT_MATCH_LIST = "SET_TOURNAMENT_MATCH_LIST";
const SET_TOURNAMENT_MATCH_INFO = "SET_TOURNAMENT_MATCH_INFO";
const CLEAR_TOURNAMENT_MATCH_INFO = "CLEAR_TOURNAMENT_MATCH_INFO";
const SET_CONTEST_QUESTIONS = "SET_CONTEST_QUESTIONS";
const SET_CONTEST_QUESTION_SELECTIONS = "SET_CONTEST_QUESTION_SELECTIONS";
const SET_BATTLE_ROYAL_INFO = "SET_BATTLE_ROYAL_INFO";
const SET_BATTLE_ROYAL_RESULT = "SET_BATTLE_ROYAL_RESULT";
const SET_BATTLE_ROYAL_MATCH = "SET_BATTLE_ROYAL_MATCH";
const SET_MATCH_CHAT = "SET_MATCH_CHAT";
const CLEAR_MATCH_CHAT = "CLEAR_MATCH_CHAT";
const SET_OPERATOR_LIST = "SET_OPERATOR_LIST";
const SET_SWISSDRAW_INFO = "SET_SWISSDRAW_INFO";
const SET_SWISSDRAW_MATCH_LIST = "SET_SWISSDRAW_MATCH_LIST";
const SET_SWISSDRAW_MATCH_INFO = "SET_SWISSDRAW_MATCH_INFO";
const CLEAR_SWISSDRAW_MATCH_INFO = "CLEAR_SWISSDRAW_MATCH_INFO";
const SET_SWISSDRAW_RESULT = "SET_SWISSDRAW_RESULT";
const SET_CHAT_LIST = "SET_CHAT_LIST";

// action creator
// function setContestList (contestList) {
//     return {
//         type: SET_CONTEST_LIST,
//         contestList
//     };
// }

function setContestInfo(contestInfo) {
  return {
    type: SET_CONTEST_INFO,
    contestInfo
  };
}

function setContestTeams(contestTeams) {
  return {
    type: SET_CONTEST_TEAMS,
    contestTeams
  };
}

function setContestUsers(contestUsers) {
  return {
    type: SET_CONTEST_USERS,
    contestUsers
  };
}

function setContestMyTeams(myTeams) {
  return {
    type: SET_CONTEST_MYTEAMS,
    myTeams
  };
}

function setContestMyRole(myRole) {
  return {
    type: SET_CONTEST_MYROLE,
    myRole
  };
}

function setTrophyList(trophyList) {
  return {
    type: SET_TROPHY_LIST,
    trophyList
  };
}

function setTournamentInfo(tournamentInfo) {
  return {
    type: SET_TOURNAMENT_INFO,
    tournamentInfo
  };
}

function setTournamentMatchInfo(tournamentMatchInfo) {
  return {
    type: SET_TOURNAMENT_MATCH_INFO,
    tournamentMatchInfo
  };
}

function setTournamentMatchList(tournamentMatchList) {
  return {
    type: SET_TOURNAMENT_MATCH_LIST,
    tournamentMatchList
  };
}

function setMatchChat(messageList) {
  return {
    type: SET_MATCH_CHAT,
    messageList
  };
}

function setContestQuestions(contestQuestions) {
  return {
    type: SET_CONTEST_QUESTIONS,
    contestQuestions
  };
}

function setContestQuestionSelections(contestQuestionSelections) {
  return {
    type: SET_CONTEST_QUESTION_SELECTIONS,
    contestQuestionSelections
  };
}

function setBattleRoyalInfo(battleRoyalInfo) {
  return {
    type: SET_BATTLE_ROYAL_INFO,
    battleRoyalInfo
  };
}

function setBattleRoyalResult(battleRoyalResult) {
  return {
    type: SET_BATTLE_ROYAL_RESULT,
    battleRoyalResult
  };
}

function setBattleRoyalMatchList(battleRoyalMatchList) {
  return {
    type: SET_BATTLE_ROYAL_MATCH,
    battleRoyalMatchList
  };
}

function clearContestInfo() {
  return {
    type: CLEAR_CONTEST_INFO
  };
}
function clearTournamentMatchInfo() {
  return {
    type: CLEAR_TOURNAMENT_MATCH_INFO
  };
}

function clearMatchChat() {
  return {
    type: CLEAR_MATCH_CHAT
  };
}

function setOperatorList(operatorList) {
  return {
    type: SET_OPERATOR_LIST,
    operatorList
  };
}

function setSwissDrawInfo(swissDrawInfo) {
  return {
    type: SET_SWISSDRAW_INFO,
    swissDrawInfo
  };
}

function setSwissDrawMatchList(swissDrawMatchList) {
  return {
    type: SET_SWISSDRAW_MATCH_LIST,
    swissDrawMatchList
  };
}

function setSwissDrawMatchInfo(swissDrawMatchInfo) {
  return {
    type: SET_SWISSDRAW_MATCH_INFO,
    swissDrawMatchInfo
  };
}

function clearSwissDrawMatchInfo() {
  return {
    type: CLEAR_SWISSDRAW_MATCH_INFO
  };
}

function setSwissDrawResult(swissDrawResult) {
  return {
    type: SET_SWISSDRAW_RESULT,
    swissDrawResult
  };
}

function setChatList(chatList) {
  return {
    type: SET_CHAT_LIST,
    chatList
  };
}

// API actions
// contest 一覧取得
function getContestList(params = {}) {
  return async (dispatch, getState) => {
    let docRef = collection(firestore, "contests");
    const contestList = [];

    // ゲームフィルター
    if (params.gameId) {
      docRef = query(docRef, where("gameId", "==", params.gameId));
    }

    // sort
    docRef = query(docRef, orderBy("contestEnd", "desc"));

    // limit
    docRef = query(docRef, limit(params.limit));

    // loadmore
    if (params.lastData) {
      docRef = query(docRef, startAfter(params.lastData));
    }

    const querySnapshot = await getDocs(docRef);
    const { game: { gameList = [] } } = getState();
    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      // ゲーム名取得
      const game = gameList.find(el => el.id === data.gameId);
      const entryStart = data.entryStart ? data.entryStart.toDate() : null;
      const entryEnd = data.entryEnd ? data.entryEnd.toDate() : null;
      const contestStart = data.contestStart ? data.contestStart.toDate() : null;
      const contestEnd = data.contestEnd ? data.contestEnd.toDate() : null;
      // 日付の変換
      const format = "MM/DD HH:mm"; // year, second は省略
      const contestDurationText = convertDuration(
        contestStart,
        contestEnd,
        format
      );
      // 画像 URL 取得
      const imageUrl = getPublicImageUrl(data.image);

      // 大会のステータス設定
      let status;
      if (isWithinDuration(null, entryStart)) {
        status = "contestStatusPrepare";
      } else if (isWithinDuration(entryStart, entryEnd)) {
        status = "contestStatusEntryDuration";
      } else if (isWithinDuration(entryEnd, contestStart)) {
        status = "contestStatusEntryDone";
      } else if (isWithinDuration(contestStart, contestEnd)) {
        status = "contestStatusMatchDuration";
      } else if (isWithinDuration(contestEnd, null)) {
        status = "contestStatusMatchDone";
      }

      contestList.push({
        id: doc.id,
        ...doc.data(),
        imageUrl,
        gameName: game?.gameName,
        contestDurationText,
        status,
        entryStart,
        entryEnd,
        contestStart,
        contestEnd,
      });
    }

    const result = {
      contestList,
      lastData: querySnapshot.docs[querySnapshot.docs.length - 1]
    };
    return result;
  };
}

// 運営大会一覧取得
function getOperatedContestList(params = {}) {
  return async (dispatch, getState) => {
    const { user: { auth = {} }, game: { gameList = [] } } = getState();

    const contestList = [];
    let docRef = query(collectionGroup(firestore, "operators"), where("userId", "==", auth.id));

    // ゲームフィルター
    if (params.gameId) {
      docRef = query(docRef, where("gameId", "==", params.gameId));
    }

    // sort
    docRef = query(docRef, orderBy("createdAt", "desc"));
    // limit
    docRef = query(docRef, limit(params.limit));

    // loadmore
    if (params.lastData) {
      docRef = query(docRef, startAfter(params.lastData));
    }

    const querySnapshot = await getDocs(docRef);
    for (const doc of querySnapshot.docs) {
      const operatorRef = doc.ref;
      const contestRef = operatorRef.parent.parent;
      const contestDoc = await getDoc(contestRef);
      if (!contestDoc.exists()) return;

      const data = contestDoc.data();

      // ゲーム名取得
      const game = gameList.find(el => el.id === data.gameId);
      const entryStart = data.entryStart ? data.entryStart.toDate() : null;
      const entryEnd = data.entryEnd ? data.entryEnd.toDate() : null;
      const contestStart = data.contestStart ? data.contestStart.toDate() : null;
      const contestEnd = data.contestEnd ? data.contestEnd.toDate() : null;
      // 日付の変換
      const format = "MM/DD HH:mm"; // year, second は省略
      const contestDurationText = convertDuration(
        contestStart,
        contestEnd,
        format
      );
      // 画像 URL 取得
      const imageUrl = getPublicImageUrl(data.image);

      // 大会のステータス設定
      let status;
      if (isWithinDuration(null, entryStart)) {
        status = "contestStatusPrepare";
      } else if (isWithinDuration(entryStart, entryEnd)) {
        status = "contestStatusEntryDuration";
      } else if (isWithinDuration(entryEnd, contestStart)) {
        status = "contestStatusEntryDone";
      } else if (isWithinDuration(contestStart, contestEnd)) {
        status = "contestStatusMatchDuration";
      } else if (isWithinDuration(contestEnd, null)) {
        status = "contestStatusMatchDone";
      }

      contestList.push({
        id: contestDoc.id,
        ...contestDoc.data(),
        imageUrl,
        gameName: game?.gameName,
        contestDurationText,
        status,
        entryStart,
        entryEnd,
        contestStart,
        contestEnd,
      });
    }

    const result = {
      contestList,
      lastData: querySnapshot.docs[querySnapshot.docs.length - 1]
    };
    return result;
  };
}

// 参加大会一覧取得
function getEntryContestList(params = {}) {
  return async (dispatch, getState) => {
    const { user: { auth = {} }, game: { gameList = [] } } = getState();
    const contestList = [];
    let docRef = query(collectionGroup(firestore, "contestusers"), where("userId", "==", auth.id));

    // ゲームフィルター
    if (params.gameId) {
      docRef = query(docRef, where("gameId", "==", params.gameId));
    }

    // sort
    docRef = query(docRef, orderBy("createdAt", "desc"));
    // limit
    docRef = query(docRef, limit(params.limit));

    // loadmore
    if (params.lastData) {
      docRef = query(docRef, startAfter(params.lastData));
    }

    const querySnapshot = await getDocs(docRef);
    for (const doc of querySnapshot.docs) {
      const operatorRef = doc.ref;
      const contestRef = operatorRef.parent.parent;
      const contestDoc = await getDoc(contestRef);
      if (!contestDoc.exists()) return;

      const data = contestDoc.data();

      // ゲーム名取得
      const game = gameList.find(el => el.id === data.gameId);
      const entryStart = data.entryStart ? data.entryStart.toDate() : null;
      const entryEnd = data.entryEnd ? data.entryEnd.toDate() : null;
      const contestStart = data.contestStart ? data.contestStart.toDate() : null;
      const contestEnd = data.contestEnd ? data.contestEnd.toDate() : null;
      // 日付の変換
      const format = "MM/DD HH:mm"; // year, second は省略
      const contestDurationText = convertDuration(
        contestStart,
        contestEnd,
        format
      );
      // 画像 URL 取得
      const imageUrl = getPublicImageUrl(data.image);

      // 大会のステータス設定
      let status;
      if (isWithinDuration(null, entryStart)) {
        status = "contestStatusPrepare";
      } else if (isWithinDuration(entryStart, entryEnd)) {
        status = "contestStatusEntryDuration";
      } else if (isWithinDuration(entryEnd, contestStart)) {
        status = "contestStatusEntryDone";
      } else if (isWithinDuration(contestStart, contestEnd)) {
        status = "contestStatusMatchDuration";
      } else if (isWithinDuration(contestEnd, null)) {
        status = "contestStatusMatchDone";
      }

      contestList.push({
        id: contestDoc.id,
        ...contestDoc.data(),
        imageUrl,
        gameName: game?.gameName,
        contestDurationText,
        status,
        entryStart,
        entryEnd,
        contestStart,
        contestEnd,
      });
    }

    const result = {
      contestList,
      lastData: querySnapshot.docs[querySnapshot.docs.length - 1]
    };
    return result;
  };
}

// contest 参加チーム数取得
function getContestTeamCount(query) {
  return (dispatch) => {
    return axios
      .get("/api/v1/contests/teams/count/", { params: query })
      .then((result) => {
        return result.data;
      })
      .catch((err) => console.log(err));
  };
}

// contest 参加ユーザー数取得
function getContestUserCount(query) {
  return (dispatch) => {
    return axios
      .get("/api/v1/contests/users/count/", { params: query })
      .then((result) => {
        return result.data;
      })
      .catch((err) => console.log(err));
  };
}

const getContestDetailInfo = async (contestInfo = {}, auth = {}) => {
  if (contestInfo.participantType === "SOLO") {
    if (auth?.id) {
      let docRef = doc(firestore, "contests", contestInfo.id);
      docRef = query(collection(docRef, "contestusers"), where("userId", "==", auth.id));
      const querySnapshot = await getDocs(docRef);

      if (!querySnapshot.empty) {
        // ログインユーザが大会に参加している場合
        contestInfo.isEntry = true;
        let entryInfo = {};
        querySnapshot.forEach((doc) => {
          entryInfo = {
            id: doc.id,
            ...doc.data()
          };
        });
        contestInfo.entryInfo = entryInfo;
        contestInfo.isCheckin = entryInfo.checkin;
      }
    }
  }
  if (contestInfo.image) {
    // 画像 URL 取得
    contestInfo.imageUrl = getPublicImageUrl(contestInfo.image);

  }
  if (contestInfo.entryStart) {
    contestInfo.entryStart = contestInfo.entryStart.toDate();
  }
  if (contestInfo.entryEnd) {
    contestInfo.entryEnd = contestInfo.entryEnd.toDate();
  }
  if (contestInfo.contestStart) {
    contestInfo.contestStart = contestInfo.contestStart.toDate();
  }
  if (contestInfo.contestEnd) {
    contestInfo.contestEnd = contestInfo.contestEnd.toDate();
  }
  if (contestInfo.checkinStart) {
    contestInfo.checkinStart = contestInfo.checkinStart.toDate();
  }
  if (contestInfo.checkinEnd) {
    contestInfo.checkinEnd = contestInfo.checkinEnd.toDate();
  }
  const format = "MM/DD HH:mm"; // year, second は省略
  contestInfo.entryDurationText = convertDuration(
    contestInfo.entryStart,
    contestInfo.entryEnd,
    format
  );
  contestInfo.contestStartText = moment(
    contestInfo.contestStart
  ).format(format);
  contestInfo.canEntry = isWithinDuration(
    contestInfo.entryStart,
    contestInfo.entryEnd
  );
  contestInfo.canMatch = isWithinDuration(
    contestInfo.contestStart,
    contestInfo.contestEnd
  );
  contestInfo.canRanking = isWithinDuration(
    contestInfo.contestStart,
    null
  );
  contestInfo.canCreateTournament = isWithinDuration(
    contestInfo.entryEnd,
    null
  );
  contestInfo.canCreateBattleRoyalMatch = isWithinDuration(
    contestInfo.entryEnd,
    null
  );
  contestInfo.canCreateSwissDraw = isWithinDuration(
    contestInfo.entryEnd,
    null
  );
  return contestInfo;
};

// contest 詳細取得
function getContestDetail(id) {
  return async (dispatch, getState) => {
    const { user: { auth = {} } } = getState();
    if (!id) return;

    const docRef = doc(firestore, "contests", id);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const contestInfo = {
        id: docSnap.id,
        ...docSnap.data()
      };

      const converted = await getContestDetailInfo(contestInfo, auth);
      dispatch(setContestInfo(converted));
      return converted;
    }
  };
}

// contest 参加チーム詳細取得
function getContestUserInfo(contestId, contestuserId) {
  return async (dispatch) => {
    let docRef = doc(firestore, "contests", contestId);
    docRef = doc(docRef, "contestusers", contestuserId);
    const docSnap = await getDoc(docRef);

    const result = {
      id: docSnap.id,
      ...docSnap.data()
    };
    return result;
  };
}

// contest 参加チーム詳細取得
function getContestTeamInfo(contestId, teamId) {
  return (dispatch) => {
    return axios
      .get(`/api/v1/contests/${contestId}/teams/${teamId}/`)
      .then((result) => {
        return result.data;
      })
      .catch((err) => console.log(err));
  };
}

// contest 参加チーム一覧取得
function getContestTeams(id) {
  return async (dispatch, getState) => {

    let docRef = doc(firestore, "contests", id);
    docRef = collection(docRef, "contestteams");
    const querySnapshot = await getDocs(docRef);

    const contestTeamist = [];
    querySnapshot.forEach((doc) => {
      contestTeamist.push({
        id: doc.id,
        ...doc.data()
      });
    });
    dispatch(setContestTeams(contestTeamist));
    return contestTeamist;
  };
}

// contest 参加ユーザーー覧取得
function getContestUsers(id, status) {
  return async (dispatch, getState) => {

    let docRef = doc(firestore, "contests", id);
    docRef = collection(docRef, "contestusers");

    const querySnapshot = await getDocs(docRef);

    const contestUserList = [];
    for (const contestuserDoc of querySnapshot.docs) {
      const data = contestuserDoc.data();
      const userDoc = await getDoc(doc(firestore, "users", data.userId));
      if (userDoc.exists()) {
        const { nickname, imageUrl } = userDoc.data();
        data.nickName = nickname;
        if (imageUrl) {
          data.imageUrl = imageUrl;
        }
      }
      contestUserList.push({
        id: contestuserDoc.id,
        ...data
      });
    }
    dispatch(setContestUsers(contestUserList));
    return contestUserList;
  };
}

// contest 運営者ー覧取得
function getContestOperators(id) {
  return async (dispatch, getState) => {
    let docRef = doc(firestore, "contests", id);
    docRef = collection(docRef, "operators");

    const querySnapshot = await getDocs(docRef);

    const operatorList = [];
    for (const operatorDoc of querySnapshot.docs) {
      const data = operatorDoc.data();
      // TODO: 削除予定
      // 仮の userId 設定
      // const { userId } = doc.data();
      const userId = data.userId;
      if (userId) {
        const userDoc = await getDoc(doc(firestore, "users", userId));
        const { nickname } = userDoc.data();
        data.nickName = nickname;
      }
      operatorList.push({
        id: operatorDoc.id,
        ...data
      });
    }
    dispatch(setOperatorList(operatorList));
    // 自分のロール取得
    const { user: { auth = {} } } = getState();

    if (auth.id) {
      // TODO: 削除予定
      // 仮の authId 設定
      const operator = operatorList.find(el => el.userId === auth.id);
      if (operator?.roleId) {
        const roleDoc = await getDoc(doc(firestore, "roles", operator.roleId));
        const myRole = {
          id: roleDoc.id,
          ...roleDoc.data()
        };
        // myRole 設定
        dispatch(setContestMyRole(myRole));
      }
    }
    return operatorList;
  };
}

function addContestOperators(contestId, gameId, addList = []) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {} },
      contest: { operatorList = [] }
    } = getState();
    if (!contestId || !gameId || !addList.length) return;
    let batch = writeBatch(firestore);
    let docRef = doc(firestore, "contests", contestId);
    const operatorRef = collection(docRef, "operators");
    const newOperators = [];
    for (const item of addList) {
      const param = {
        contestId,
        userId: item.value,
        nickname: item.label,
        gameId,
        roleId: "ROLE_CONTEST_ADMIN",
        createdAt: new Date(),
        updatedAt: new Date(),
        createdById: auth.id,
        updatedById: auth.id,
      };
      const docId = doc(operatorRef).id;
      const operatorDoc = doc(docRef, "operators", docId);
      batch.set(operatorDoc, param);
      newOperators.push({
        id: docId,
        nickName: param.nickname,
        ...param
      });
    }
    //バッチ登録実行
    await batch.commit();
    dispatch(setOperatorList([...operatorList, ...newOperators]));
    return newOperators;
  };
}

function deleteContestOperators(contestId, operatorIds) {
  return async (dispatch, getState) => {
    try {
      const {
        contest: { operatorList = [] }
      } = getState();

      let batch = writeBatch(firestore);
      let docRef = doc(firestore, "contests", contestId);
      for (const operatorId of operatorIds) {
        const operatorDocRef = doc(docRef, "operators", operatorId);
        batch.delete(operatorDocRef);
      }
      await batch.commit();
      const changedOperatorList = operatorList.filter(el => operatorIds.every(id => id !== el.id));
      dispatch(setOperatorList(changedOperatorList));
      return;
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

// 質問の回答
function saveSurveyAnswer(contestId, questionId, param) {
  return (dispatch, getState) => {
    return axios
      .post(`/api/v1/contests/${contestId}/survey/${questionId}/answer/`, param)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// contest チーム参加申請
function entryContestTeam(contestId, param) {
  return (dispatch, getState) => {
    return axios
      .post(`/api/v1/contests/${contestId}/teams/`, param)
      .then((result) => {
        return 1;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// contest 個人参加申請
function entryContestUser(contestId, param) {
  return async (dispatch, getState) => {
    const contestDocRef = doc(firestore, "contests", contestId);
    const docRef = collection(contestDocRef, "contestusers");
    // contestusers 追加
    await addDoc(docRef, param);

    // contest の entryCount 増加
    await updateDoc(contestDocRef, {
      entryCount: increment(1)
    });
    return 1;
  };
}

// Multi battleRoyalMatch 作成
function createTeamBattleRoyalMatch(
  battleRoyalId,
  entryTeams = [],
  roundInfo = []
) {
  return async (dispatch, getState) => {
    const battleRoyalDocRef = doc(firestore, "battleRoyal", battleRoyalId);
    const teams = entryTeams.map((team, index) => {
      const {
        team: { id, name, imageURL, contact },
        gameuser
      } = team;
      return {
        index,
        id,
        name,
        imageURL,
        contact,
        gameuser,
        kill: 0,
        rank: null,
        killPoint: 0,
        rankPoint: 0
      };
    });

    // ラウンド数分のmatchを作成
    for (const val of roundInfo) {
      await addDoc(collection(battleRoyalDocRef, "match"), {
        round: val.round,
        startTime: val.startTime,
        endTime: val.endTime,
        teams,
        created: serverTimestamp(),
        updated: serverTimestamp()
      });
    }

    // result作成
    await addDoc(collection(battleRoyalDocRef, "result"), {
      teams: teams,
      created: serverTimestamp(),
      updated: serverTimestamp()
    });

    // battleRoyal doc 更新 (confirmed)
    const param = {
      matchCount: Number(roundInfo.length),
      roundInfo,
      currentRound: 0,
      roundStatus: "start",
      confirmed: serverTimestamp(),
      updated: serverTimestamp()
    };
    updateDoc(battleRoyalDocRef, param);
    const {
      contest: { battleRoyalInfo: beforeInfo = {} }
    } = getState();
    dispatch(
      setBattleRoyalInfo({
        ...beforeInfo,
        ...param
      })
    );

    return;
  };
}

// Solo battleRoyalMatch 作成
function createUserBattleRoyalMatch(
  battleRoyalId,
  entryUsers = [],
  roundInfo = []
) {
  return async (dispatch, getState) => {
    const battleRoyalDocRef = doc(firestore, "battleRoyal", battleRoyalId);
    const users = entryUsers.map((user, index) => {
      const {
        twitter,
        user: { id, nickname, profileImage, profileBackgroundImage },
        gameuser
      } = user;
      return {
        index,
        id,
        nickname,
        profileImage,
        profileBackgroundImage,
        twitter,
        gameuser,
        kill: 0,
        rank: null,
        killPoint: 0,
        rankPoint: 0
      };
    });
    // ラウンド数分のmatchを作成
    for (const val of roundInfo) {
      await addDoc(collection(battleRoyalDocRef, "match"), {
        round: val.round,
        startTime: val.startTime,
        endTime: val.endTime,
        users: users,
        created: serverTimestamp(),
        updated: serverTimestamp()
      });
    }
    // result作成
    await addDoc(collection(battleRoyalDocRef, "result"), {
      users: users,
      created: serverTimestamp(),
      updated: serverTimestamp()
    });

    // battleRoyal doc 更新 (confirmed)
    const param = {
      matchCount: Number(roundInfo.length),
      roundInfo,
      currentRound: 0,
      roundStatus: "start",
      confirmed: serverTimestamp(),
      updated: serverTimestamp()
    };
    updateDoc(battleRoyalDocRef, param);
    const {
      contest: { battleRoyalInfo: beforeInfo = {} }
    } = getState();
    dispatch(
      setBattleRoyalInfo({
        ...beforeInfo,
        ...param
      })
    );

    return;
  };
}

// contest 参加申請
function deleteContestTeam(contestId, teamId) {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/v1/contests/${contestId}/teams/${teamId}/`)
      .then((result) => {
        const {
          contest: { contestTeams }
        } = getState();
        const changedContestTeam = [];
        for (const val of contestTeams) {
          if (val.team.id !== teamId) {
            changedContestTeam.push(val);
          }
        }
        dispatch(setContestTeams(changedContestTeam));
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// contestUser 削除
function deleteContestUser(contestId, userId) {
  return async (dispatch, getState) => {
    try {
      const contestDocRef = doc(firestore, "contests", contestId);
      let docRef = collection(contestDocRef, "contestusers");
      docRef = query(docRef, where("userId", "==", userId));
      const docSnapshot = await getDocs(docRef);
      docSnapshot.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });

      // contest の entryCount を減らす
      await updateDoc(contestDocRef, {
        entryCount: increment(-1)
      });

      const {
        contest: { contestUsers }
      } = getState();
      const changedContestUser = [];
      for (const val of contestUsers) {
        if (val.userId !== userId) {
          changedContestUser.push(val);
        }
      }
      dispatch(setContestUsers(changedContestUser));
      return;
    }
    catch (err) {
      console.log(err);
      return err;
    }
  };
}

// 特定ユーザの contest 参加チーム取得
function getContestMyTeams(id, uid) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/contests/${id}/users/${uid}/teams/`)
      .then((result) => {
        dispatch(setContestMyTeams(result.data));
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return [];
      });
  };
}

// 特定contest のトロフィー取得
function getTrophies(id) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/contests/${id}/trophies/`)
      .then((result) => {
        dispatch(setTrophyList(result.data));
      })
      .catch((err) => {
        console.log(err);
        const faildResult = {
          data: err.response.data,
          status: err.response.status
        };

        return faildResult;
      });
  };
}

// brattleRoyal登録
function createBattleRoyal(param) {
  return async (dispatch, getState) => {
    const createBattleRoyalParam = {
      ...param,
      created: serverTimestamp(),
      updated: serverTimestamp()
    };
    // brattleRoyal 登録
    const docRef = collection(firestore, "battleRoyal");
    await addDoc(docRef, createBattleRoyalParam);
    return;
  };
}

// battleRoyal 取得
function getBattleRoyalInfo(id) {
  return async (dispatch) => {
    const battleRoyalQuerySnapShot = await getDocs(query(collection(firestore, "battleRoyal"), where("contest.id", "==", Number(id))));

    if (battleRoyalQuerySnapShot.empty) return {};

    let battleRoyalInfo = {};
    battleRoyalQuerySnapShot.forEach((doc) => {
      const data = doc.data();
      battleRoyalInfo = {
        id: doc.id,
        ...data
      };
    });

    dispatch(setBattleRoyalInfo(battleRoyalInfo));

    return battleRoyalInfo;
  };
}

// battleRoyalResult 取得
function getBattleRoyalResult(id) {
  return async (dispatch) => {
    const docRef = doc(firestore, "battleRoyal", id);
    const resultQuerySnapShot = await getDocs(docRef, "result");

    let battleRoyalResult = {};
    resultQuerySnapShot.forEach((doc) => {
      const data = doc.data();
      battleRoyalResult = {
        id: doc.id,
        ...data
      };
    });
    dispatch(setBattleRoyalResult(battleRoyalResult));
    return battleRoyalResult;
  };
}

// battleRoyalMatchList 取得
function getBattleRoyalMatchList(id) {
  return async (dispatch) => {
    const docRef = doc(firestore, "battleRoyal", id);
    const matchQuerySnapShot = await getDocs(query(collection(docRef, "match"), orderBy("round", "asc")));

    const battleRoyalMatchList = [];
    matchQuerySnapShot.forEach((doc) => {
      const data = doc.data();
      data.id = doc.id;
      if (data.startTime) {
        data.startTimeText = moment(data.startTime.toDate()).format(
          "MM/DD HH:mm"
        );
      }
      battleRoyalMatchList.push(data);
    });

    for (const val of battleRoyalMatchList) {
      const participants = val.teams ? val.teams : val.users;
      participants.sort((a, b) => {
        if (!a.rank) {
          return 1;
        }
        if (!b.rank) {
          return -1;
        }
        return a.rank - b.rank;
      });
    }

    dispatch(setBattleRoyalMatchList(battleRoyalMatchList));
    return battleRoyalMatchList;
  };
}

// battleRoyalInfo リアルタイム取得
function subscribeBattleRoyalInfo(id) {
  return (dispatch) => {
    // リスナーを解除
    if (unsubscribeBattleRoyalInfo) {
      unsubscribeBattleRoyalInfo();
    }
    // battleRoyal リスナー
    const docRef = doc(firestore, "battleRoyal", id);
    unsubscribeBattleRoyalInfo = onSnapshot(docRef, (doc) => {
      const data = doc.data();
      const battleRoyalInfo = {
        id: doc.id,
        ...data
      };

      dispatch(setBattleRoyalInfo(battleRoyalInfo));
    });
  };
}

// battleRoyalResult リアルタイム取得
function subscribeBattleRoyalResult(id) {
  return (dispatch) => {
    // リスナーを解除
    if (unsubscribeBattleRoyalResult) {
      unsubscribeBattleRoyalResult();
    }
    // result リスナー
    let docRef = doc(firestore, "battleRoyal", id);
    docRef = collection(docRef, "result");
    unsubscribeBattleRoyalResult = onSnapshot(docRef, (querySnapshot) => {
      let battleRoyalResult = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        battleRoyalResult = {
          id: doc.id,
          ...data
        };
      });
      if (battleRoyalResult.teams?.length) {
        battleRoyalResult.teams.sort((a, b) => {
          if (!a.rank) {
            return 1;
          }
          if (!b.rank) {
            return -1;
          }
          return a.rank - b.rank;
        });
      } else if (battleRoyalResult.users?.length) {
        battleRoyalResult.users.sort((a, b) => {
          if (!a.rank) {
            return 1;
          }
          if (!b.rank) {
            return -1;
          }
          return a.rank - b.rank;
        });
      }
      dispatch(setBattleRoyalResult(battleRoyalResult));
    });

  };
}

// battleRoyalMatchList リアルタイム取得
function subscribeBattleRoyalMatchList(id) {
  return (dispatch) => {
    // リスナーを解除
    if (unsubscribeBattleRoyalMatchList) {
      unsubscribeBattleRoyalMatchList();
    }
    // result リスナー
    let docRef = doc(firestore, "battleRoyal", id);
    docRef = query(collection(docRef, "match"), orderBy("round", "asc"));
    unsubscribeBattleRoyalMatchList = onSnapshot(docRef, (querySnapshot) => {
      const battleRoyalMatchList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        if (data.startTime) {
          data.startTimeText = moment(data.startTime.toDate()).format(
            "MM/DD HH:mm"
          );
        }
        battleRoyalMatchList.push(data);
      });
      for (const val of battleRoyalMatchList) {
        const participants = val.teams ? val.teams : val.users;
        participants.sort((a, b) => {
          if (!a.rank) {
            return 1;
          }
          if (!b.rank) {
            return -1;
          }
          return a.rank - b.rank;
        });
      }
      dispatch(setBattleRoyalMatchList(battleRoyalMatchList));
    });
  };
}

// battleRoyal 更新
function updateBattleRoyal(id, param = {}) {
  return async (dispatch, getState) => {
    if (!id || !param) return;

    const changeBattleRoyalParam = {
      ...param,
      updated: serverTimestamp()
    };
    // battleRoyal 登録
    const docRef = doc(firestore, "battleRoyal", id);
    await updateDoc(docRef, changeBattleRoyalParam);
    return;
  };
}

// battleRoyal 更新
function updateBattleRoyalMatchResult(id, result = {}, startTime) {
  return async (dispatch, getState) => {
    if (!id || !result.id) return;
    const param = {
      startTime: new Date(startTime),
      updated: serverTimestamp()
    };
    if (result.users) {
      param.users = result.users;
    } else if (result.teams) {
      param.teams = result.teams;
    }
    // battleRoyal 登録
    let docRef = doc(firestore, "battleRoyal", id);
    docRef = doc(docRef, "match", result.id);
    await updateDoc(docRef, param);
    return;
  };
}

/**
 * BattleRoyal 自己結果更新 (SOLO)
 * @param {Object} battleRoyalInfo
 * @param {Object} result
 * @param {number} userId
 *  */
function updateBattleRoyalSoloMatchSelfResult(
  battleRoyalInfo,
  result = {},
  userId
) {
  return async (dispatch, getState) => {
    let docRef = doc(firestore, "battleRoyal", battleRoyalInfo.id);
    docRef = doc(docRef, "match", result.id);

    runTransaction(firestore, async (transaction) => {
      const scoreDoc = await transaction.get(docRef);
      const data = scoreDoc.data();

      const myScore = data.users.find((el) => el.id === userId);

      // 変更チェックのための変換
      // TODO: Object内にObjectがある場合は再起ソートする改修が必要
      const beforeScore = JSON.stringify(Object.entries(myScore).sort());
      const afterScore = JSON.stringify(Object.entries(result.myScore).sort());

      // 変更があった場合のみ更新
      // データが重複しないよう旧データ削除してから最新データを挿入
      if (beforeScore !== afterScore) {
        await transaction.update(docRef, {
          users: arrayRemove(myScore),
          updated: serverTimestamp()
        });
        await transaction.update(docRef, {
          users: arrayUnion(result.myScore),
          updated: serverTimestamp()
        });
      }
    });
  };
}

/**
 * BattleRoyal 自己結果更新 (MULTI)
 * @param {Object} battleRoyalInfo
 * @param {Object} result
 * @param {Array} teamList
 *  */
function updateBattleRoyalMultiMatchSelfResult(
  battleRoyalInfo,
  result = {},
  teamList
) {
  return async (dispatch, getState) => {
    let docRef = doc(firestore, "battleRoyal", battleRoyalInfo.id);
    docRef = doc(docRef, "match", result.id);

    runTransaction(firestore, async (transaction) => {
      const scoreDoc = await transaction.get(docRef);
      const data = scoreDoc.data();

      const myScore = data.teams.find((participant) => {
        return teamList.some((team) => participant.id === team.id);
      });

      // 変更チェックのための変換
      // TODO: Object内にObjectがある場合は再起ソートする改修が必要
      const beforeScore = JSON.stringify(Object.entries(myScore).sort());
      const afterScore = JSON.stringify(Object.entries(result.myScore).sort());

      // 変更があった場合のみ更新
      // データが重複しないよう旧データ削除してから最新データを挿入
      if (beforeScore !== afterScore) {
        await transaction.update(docRef, {
          teams: arrayRemove(myScore),
          updated: serverTimestamp()
        });
        await transaction.update(docRef, {
          teams: arrayUnion(result.myScore),
          updated: serverTimestamp()
        });
      }
    });
  };
}

function updateContestTeamStatus(contestId, teamId, status) {
  return (dispatch, getState) => {
    const {
      contest: { contestTeams = [] }
    } = getState();
    const params = {
      status
    };
    return axios
      .put(`/api/v1/contests/${contestId}/teams/${teamId}/`, params)
      .then((result) => {
        const contestTeam = contestTeams.find((el) => el.id === result.data.id);
        if (contestTeam) {
          contestTeam.status = status;
        }
        dispatch(setContestTeams(contestTeams));
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// 大会ユーザーの status 変更
function updateContestUserStatus(contestId, contestuserId, status) {
  return async (dispatch, getState) => {
    const {
      contest: { contestUsers = [] }
    } = getState();

    let docRef = doc(firestore, "contests", contestId);
    docRef = doc(docRef, "contestusers", contestuserId);
    await updateDoc(docRef, {
      status,
      updatedAt: new Date()
    });

    const contestUser = contestUsers.find((el) => el.id === contestuserId);
    if (contestUser) {
      contestUser.status = status;
    }
    return dispatch(setContestUsers(contestUsers));
  };
}

// tournament 作成
function createTeamTournament(contest = {}, game = {}, tournament = []) {
  return async (dispatch, getState) => {
    // tournament 登録
    const docRef = collection(firestore, "tournament");
    const doc = await addDoc(docRef, {
      contest: contest,
      game: game,
      created: serverTimestamp(),
      updated: serverTimestamp()
    });

    // match 登録
    for (const val of tournament) {
      const startTime = new Date(moment(val.startTime));
      const matchCount = val.matchCount;
      const matchTime = val.matchTime;
      const endTime = new Date(moment(startTime).add(matchTime, "minutes"));

      for (const match of val.match) {
        const teams = [];
        for (const index in match) {
          const teamInfo = match[index];
          if (teamInfo.team.id) {
            teams.push({
              ...teamInfo.team,
              index: Number(index)
            });
          }
        }
        // match データ設定
        const param = {
          round: val.round,
          order: match[0].order,
          teams: teams,
          startTime: startTime,
          endTime: endTime,
          matchCount: matchCount,
          matchTime: matchTime,
          created: serverTimestamp(),
          updated: serverTimestamp()
        };
        // typeが存在する場合
        if (match[0].type) {
          param.type = match[0].type;
        }
        // 三位決定戦の場合
        if (match[0].type === "thirdPlaceMatch") {
          param.startTime = new Date(moment(val.thirdPlaceMatchStartTime));
        }
        // match 登録
        let docRef = doc(firestore, "tournament", doc.id);
        docRef = collection(docRef, "match");
        await addDoc(docRef, param);
      }
    }
    return;
  };
}

// tournament 作成
function createUserTournament(contest = {}, gameId, tournament = []) {
  return async (dispatch, getState) => {
    // tournament 登録
    const docRef = collection(firestore, "tournament");
    const tournamentDoc = await addDoc(docRef, {
      contest,
      gameId,
      created: serverTimestamp(),
      updated: serverTimestamp()
    });

    // match 登録
    for (const val of tournament) {
      const startTime = new Date(val.startTime);
      const matchCount = val.matchCount;
      const matchTime = val.matchTime;
      const endTime = new Date(moment(startTime).add(matchTime, "minutes"));

      for (const match of val.match) {
        const users = [];
        for (const index in match) {
          const userInfo = match[index];
          if (userInfo.user.id) {
            users.push({
              ...userInfo.user,
              index: Number(index)
            });
          }
        }
        // match データ設定
        const param = {
          round: val.round,
          order: match[0].order,
          users: users,
          startTime: startTime,
          endTime: endTime,
          matchCount: matchCount,
          matchTime: matchTime,
          created: serverTimestamp(),
          updated: serverTimestamp()
        };
        // typeが存在する場合
        if (match[0].type) {
          param.type = match[0].type;
        }
        // 三位決定戦の場合
        if (match[0].type === "thirdPlaceMatch") {
          param.startTime = new Date(val.thirdPlaceMatchStartTime);
        }
        // match 登録
        let docRef = doc(firestore, "tournament", tournamentDoc.id);
        docRef = collection(docRef, "match");
        await addDoc(docRef, param);
      }
    }
    return;
  };
}

//tournament 削除
function deleteTournament(tournamentId) {
  return async (dispatch, getState) => {
    //サブコレクション削除
    // TODO: マッチ数が500以上になった場合パッチの上限対応が必要(現在は256がマックスなので問題なし)
    let batch = writeBatch(firestore);
    let docRef = doc(firestore, "tournament", tournamentId);
    docRef = collection(docRef, "match");

    const matchQuerySnapShot = await getDocs(docRef);
    if (matchQuerySnapShot.empty) return {};

    matchQuerySnapShot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    //バッチ削除実行
    await batch.commit();
    //トーナメント削除
    deleteDoc(doc(firestore, "tournament", tournamentId));
  };
}

// image アップロード
function uploadImage(formData) {
  return (dispatch, getState) => {
    return axios
      .post("/api/v1/contests/images/", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// tournament 更新
function updateTournament(id, matchId, params = {}) {
  return async (dispatch, getState) => {
    if (!id || !matchId) return;
    // tournament 登録
    params.updated = serverTimestamp();
    if (params.startTime) {
      params.startTime = new Date(params.startTime);
    }
    let docRef = doc(firestore, "tournament", id);
    docRef = doc(docRef, "match", matchId);
    await updateDoc(docRef, params);
    return;
  };
}

// tounamentInfo 取得
function getTournamentInfo(id) {
  return async (dispatch, getState) => {
    if (!id) return;
    let docRef = collection(firestore, "tournament");
    docRef = query(docRef, where("contest.id", "==", id));
    const tournamentQuerySnapShot = await getDocs(docRef);
    if (tournamentQuerySnapShot.empty) return {};

    let tournamentInfo = {};
    tournamentQuerySnapShot.forEach((doc) => {
      const data = doc.data();
      tournamentInfo = {
        id: doc.id,
        ...data
      };
    });
    dispatch(setTournamentInfo(tournamentInfo));
    return tournamentInfo;
  };
}

// tounament match 詳細取得
function getTournamentMatchInfo(tournamentId, matchId) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {}, myTeamList = [] }
    } = getState();
    if (!tournamentId || !matchId) return;
    let docRef = doc(firestore, "tournament", tournamentId);
    docRef = doc(docRef, "match", matchId);
    const matchDoc = await getDoc(docRef);

    if (!matchDoc.exists()) return {};

    const data = matchDoc.data();
    if (data.startTime) {
      data.startTimeText = moment(data.startTime.toDate()).format(
        "MM-DD HH:mm"
      );
    }
    // teams, users を加工してまとめる
    data.participantList = [];
    // TODO: 表示側で制御できそうなら、初期化しない方向で修正
    if (data.teams) {
      // 自分の対戦を設定
      for (const val of data.teams) {
        const param = {
          index: val.index,
          id: val.id,
          name: val.name,
          imageUrl: val.imageURL,
          backgroundImageUrl: val.backgroundImageURL,
          twitter: val.contact,
          friendCode: val.friendCode,
          gameuser: val.gameuser
        };
        if (myTeamList.some((el) => el.id === val.id)) {
          param.myItem = true;
        }
        data.participantList.push(param);
      }
      delete data.teams;
    } else if (data.users) {
      // 自分の対戦を設定
      for (const val of data.users) {
        const param = {
          index: val.index,
          id: val.id,
          userId: val.userId,
          name: val.nickName,
          imageUrl: val.profileImage,
          backgroundImageUrl: val.profileBackgroundImage,
          twitter: val.twitterId,
          friendCode: val.friendCode,
          gameId: val.gameId,
          gameuser: val.gameUsername
        };
        if (auth.id === val.userId) {
          param.myItem = true;
        }
        data.participantList.push(param);
      }
      delete data.users;
    }
    if (data.reports) {
      for (const report of data.reports) {
        for (const val of report.images) {
          val.imageUrl = getPublicImageUrl(val.image);
        }
      }
    }
    const matchInfo = {
      id: matchDoc.id,
      ...data
    };

    dispatch(setTournamentMatchInfo(matchInfo));
    return matchInfo;
  };
}

// TournamentMatchList 取得
function getTournamentMatchList(id) {
  return async (dispatch) => {
    let docRef = doc(firestore, "tournament", id);
    docRef = collection(docRef, "match");
    docRef = query(docRef, orderBy("round", "asc"), orderBy("order", "asc"));
    const matchQuerySnapShot = await getDocs(docRef);

    const TournamentMatchList = [];
    matchQuerySnapShot.forEach((doc) => {
      const data = doc.data();
      // コレクションのドキュメントID追加
      data.id = doc.id;
      TournamentMatchList.push(data);
    });
    return TournamentMatchList;
  };
}

// TournamentInfo リアルタイム取得
function subscribeTournamentMatchList(id) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {}, myTeamList = [] }
    } = getState();
    // リスナーを解除
    if (unsubscribeTournamentMatchList) {
      unsubscribeTournamentMatchList();
    }

    let docRef = doc(firestore, "tournament", id);
    unsubscribeTournamentMatchList = onSnapshot(query(collection(docRef, "match"), orderBy("round", "asc"), orderBy("order", "asc")), (querySnapshot) => {
      const rawMatch = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        // teams, users を加工してまとめる
        data.participantList = [];
        // TODO: 表示側で制御できそうなら、初期化しない方向で修正
        if (data.teams) {
          // 自分の対戦を設定
          for (const val of data.teams) {
            const param = {
              index: val.index,
              id: val.id,
              name: val.name,
              imageUrl: val.imageURL,
              twitter: val.contact,
              friendCode: val.friendCode,
              gameuser: val.gameuser
            };
            if (myTeamList.some((el) => el.id === val.id)) {
              param.myItem = true;
            }
            data.participantList.push(param);
          }
          // 空き slotに空 team初期化
          const count = 2 - data.teams.length;
          for (let i = 0; i < count; i++) {
            data.participantList.push({});
          }
          delete data.teams;
        } else if (data.users) {
          // 自分の対戦を設定
          for (const val of data.users) {
            const param = {
              index: val.index,
              id: val.id,
              name: val.nickName,
              imageUrl: val.profileImage,
              twitter: val.twitter,
              friendCode: val.friendCode,
              gameuser: val.gameuser
            };
            if (auth.id === val.userId) {
              param.myItem = true;
            }
            data.participantList.push(param);
          }
          // 空き slotに空 user初期化
          const count = 2 - data.users.length;
          for (let i = 0; i < count; i++) {
            data.participantList.push({});
          }
          delete data.users;
        }
        if (data.startTime) {
          data.startTimeText = moment(data.startTime.toDate()).format(
            "MM-DD HH:mm"
          );
        }
        rawMatch.push(data);
      });
      const groupedMatch = _groupBy(rawMatch, "round");
      const matchList = [];
      for (const i in groupedMatch) {
        const startTimetoDate = groupedMatch[i][0].startTime.toDate();
        const startTime = moment(startTimetoDate).format("YYYY-MM-DDTHH:mm");
        const startTimeText = moment(startTimetoDate).format("MM-DD HH:mm");
        const params = {
          round: Number(i),
          startTime: startTime,
          startTimeText: startTimeText,
          orderList: []
        };
        matchList.push(params);
        const grouped = _groupBy(groupedMatch[i], "order");
        for (const index in grouped) {
          if (grouped[index][0].type === "thirdPlaceMatch") {
            params.thirdPlaceMatchStartTime = moment(
              grouped[index][0].startTime.toDate()
            ).format("YYYY-MM-DD HH:mm");
          }
          if (matchList[i]) {
            matchList[i].orderList.push(grouped[index][0]);
          }
        }
      }
      dispatch(setTournamentMatchList(matchList));
    });
  };
}

// MatchChat アーカイブ取得
function getMatchChat(chatId, params = {}) {
  return async (dispatch, getState) => {
    try {
      if (!chatId) {
        throw new Error("chatId is required");
      }
      // メッセージ取得
      let docRef = doc(firestore, "chat", chatId);
      docRef = collection(docRef, "message");
      docRef = query(docRef, orderBy("created", "desc"), limit(params.limit));

      // 古いメッセージを読み込む用の開始点設定
      if (params.lastData) {
        docRef = query(docRef, startAfter(params.lastData));
      }
      return getDocs(docRef).then((snapShots) => {
        if (snapShots.empty) {
          const result = {
            length: 0,
            lastData: null
          };
          return result;
        } else {
          const {
            contest: { messageList = [] }
          } = getState();
          // データ加工
          const chatMessages = [...messageList];
          snapShots.forEach(
            (doc) => {
              if (chatMessages.some(el => el.id === doc.id)) return;
              const data = doc.data();
              const displayTime = convertFirestoreTime(
                data.created,
                "MM/DD HH:mm"
              );

              // 書き込み日時が新しい順で取得しているので、表示用にunshiftで古い順で追加
              chatMessages.unshift({
                id: doc.id,
                ...data,
                displayTime
              });
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            }
          );

          const result = {
            length: snapShots.size,
            lastData: snapShots.docs[snapShots.docs.length - 1]
          };
          dispatch(setMatchChat(chatMessages));
          return result;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
}

// TournamentMatchChat リアルタイム取得
function subscribeMatchChat(chatId) {
  return async (dispatch, getState) => {
    // リスナーを解除
    if (unsubscribeMatchChat) {
      unsubscribeMatchChat();
    }

    let docRef = doc(firestore, "chat", chatId);
    docRef = collection(docRef, "message");
    docRef = query(docRef, orderBy("created", "desc"), limit(1));

    unsubscribeMatchChat = onSnapshot(docRef, (querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          if (querySnapshot.size) {
            const {
              contest: { messageList = [] }
            } = getState();
            const data = querySnapshot.docs[change.newIndex].data();
            data.id = querySnapshot.docs[change.newIndex].id;
            if (data.created) {
              data.displayTime = convertFirestoreTime(
                data.created,
                "MM/DD HH:mm"
              );
            }
            if (!messageList.some((el) => el.id === data.id)) {
              dispatch(setMatchChat([...messageList, data]));
            }
          }
        }
      });
    });
  };
}

// チャットメッセージ送信
function sendMessage(chatId, param = {}) {
  return async (dispatch) => {
    if (chatId) {
      let docRef = doc(firestore, "chat", chatId);
      docRef = collection(docRef, "message");
      addDoc(docRef, {
        ...param,
        created: new Date()
      });
    }
  };
}

// 対戦チャットバッジークリア
function clearChatBadge(contestId) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {} }
    } = getState();
    if (!auth.id) return;

    // バッジーがあるチャットルーム一覧取得
    let docRef = collection(firestore, "room");
    docRef = query(docRef, where("userId", "==", auth.id), where("contest.id", "==", contestId), where("unread", ">", 0));
    const querySnapshot = await getDocs(docRef);

    if (querySnapshot.empty) return;
    let batch = writeBatch(firestore);
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.unread) {
        batch.update(doc.ref, { unread: 0 });
      }
    });
    // 未読データを既読に一括更新
    await batch.commit();
  };
}

// グルーピング
function _groupBy(array, property) {
  return array.reduce((group, item) => {
    return Object.assign(group, {
      [item[property]]: (group[item[property]] || []).concat(item)
    });
  }, {});
}

// 大会登録
function contestRegistration(params) {
  return async (dispatch, getState) => {
    const { user: { auth = {} }, contest: { operatorList = [] } } = getState();
    const contestRef = await addDoc(collection(firestore, "contests"), {
      ...params,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdById: auth.id,
      createdBy: auth.nickname,
      updatedById: auth.id,
      updatedBy: auth.nickname,
    });

    const contestInfo = {
      id: contestRef.id,
      ...params
    };

    dispatch(setContestInfo(contestInfo));

    // operation 追加
    // 大会オーナー
    const roleId = "ROLE_CONTEST_OWNER";
    const operatorParams = {
      contestId: contestInfo.id,
      userId: auth.id,
      roleId,
      gameId: contestInfo.gameId,
      createdAt: new Date(),
      updatedAt: new Date(),
      createdById: auth.id,
      updatedById: auth.id,
    };
    const operatorRef = await addDoc(collection(doc(firestore, "contests", contestInfo.id), "operators"), operatorParams);

    const newOperator = {
      id: operatorRef.id,
      ...operatorParams,
    };

    dispatch(setOperatorList([...operatorList, newOperator]));

    const result = {
      status: 200,
      data: contestInfo
    };
    return result;
  };
}

// 大会編集
function contestEdit(contestId, params = {}) {
  return async (dispatch, getState) => {
    if (!contestId) return;
    const { user: { auth = {} } } = getState();
    const data = {
      ...params,
      updatedAt: new Date(),
      updatedById: auth.id,
      updatedBy: auth.nickname,
    };
    const docRef = doc(firestore, "contests", contestId);
    await updateDoc(docRef, data);

    // 更新後データ取得 
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const contestInfo = {
        id: docSnap.id,
        ...docSnap.data()
      };

      // 詳細データ加工
      const converted = await getContestDetailInfo(contestInfo, auth);
      dispatch(setContestInfo(converted));

      const result = {
        status: 200,
        data: converted
      };
      return result;
    }
    else {
      return {
        status: 400
      };
    }
  };
}

// 大会のアンケート取得(代表者向け)
function getContestQuestions(id) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/contests/${id}/questions/`)
      .then((result) => {
        const contestQuestions = [];
        for (const val of result.data) {
          contestQuestions.push(val);
        }
        dispatch(setContestQuestions(contestQuestions));
        return contestQuestions;
      })
      .catch((err) => {
        console.log(err);
        return {};
      });
  };
}

function getContestQuestionSelections(id) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/contests/${id}/questions/selections/`)
      .then((result) => {
        const contestQuestionSelections = [];
        for (const val of result.data) {
          contestQuestionSelections.push(val);
        }
        dispatch(setContestQuestionSelections(contestQuestionSelections));
        return contestQuestionSelections;
      })
      .catch((err) => console.log(err));
  };
}

// swissDrawInfo 取得
function getSwissDrawInfo(id) {
  return async (dispatch, getState) => {
    const docRef = collection(firestore, "swissDraw");
    const q = query(docRef, where("contest.id", "==", Number(id)));

    const swissDrawQuerySnapShot = await getDocs(q);

    if (swissDrawQuerySnapShot.empty) return {};

    let swissDrawInfo = {};
    swissDrawQuerySnapShot.forEach((doc) => {
      const data = doc.data();
      swissDrawInfo = {
        id: doc.id,
        ...data
      };
    });
    dispatch(setSwissDrawInfo(swissDrawInfo));
    return swissDrawInfo;
  };
}

// SwissDrawInfo リアルタイム取得
function subscribeSwissDrawInfo(id) {
  return async (dispatch, getState) => {
    //リスナーを解除
    if (unsubscribeSwissDrawInfo) {
      unsubscribeSwissDrawInfo();
    }
    let docRef = collection(firestore, "swissDraw");
    docRef = query(docRef, where("contest.id", "==", Number(id)));
    unsubscribeSwissDrawInfo = onSnapshot(docRef, (querySnapshot) => {
      let swissDrawInfo = {};
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        swissDrawInfo = {
          id: doc.id,
          ...data
        };
      });
      dispatch(setSwissDrawInfo(swissDrawInfo));
    });
  };
}

// swissdraw 作成
function createTeamSwissDraw(
  contest = {},
  game = {},
  roundInfo = [],
  swissdraw = []
) {
  return async (dispatch, getState) => {
    // swissdraw 登録
    const docRef = collection(firestore, "swissDraw");
    const doc = await addDoc(docRef, {
      contest: contest,
      game: game,
      roundInfo: roundInfo,
      currentRound: 0,
      roundStatus: "start",
      created: serverTimestamp(),
      updated: serverTimestamp()
    });

    // match 登録
    for (const val of swissdraw) {
      const startTime = new Date(moment(val.startTime));
      const endTime = new Date(moment(val.endTime));
      const matchCount = val.matchCount;
      for (const match of val.match) {
        const teams = [];
        for (const index in match) {
          const teamInfo = match[index];
          if (teamInfo.team.id) {
            teams.push({
              ...teamInfo.team,
              index: Number(index)
            });
          }
        }
        // match データ設定
        const param = {
          round: val.round,
          order: match[0].order,
          matchCount: matchCount,
          teams: teams,
          startTime: startTime,
          endTime: endTime,
          created: serverTimestamp(),
          updated: serverTimestamp()
        };
        // match 登録
        let docRef = doc(firestore, "swissDraw", doc.id);
        docRef = collection(docRef, "match");
        await addDoc(docRef, param);
      }
    }
    return;
  };
}

// swissdraw 作成
function createUserSwissDraw(
  contest = {},
  game = {},
  roundInfo = [],
  swissdraw = []
) {
  return async (dispatch, getState) => {
    // swissdraw 登録
    const docRef = collection(firestore, "swissDraw");
    const doc = await addDoc(docRef, {
      contest: contest,
      game: game,
      roundInfo: roundInfo,
      currentRound: 0,
      roundStatus: "start",
      created: serverTimestamp(),
      updated: serverTimestamp()
    });

    // match 登録
    for (const val of swissdraw) {
      const startTime = new Date(moment(val.startTime));
      const endTime = new Date(moment(val.endTime));
      const matchCount = val.matchCount;
      for (const match of val.match) {
        const users = [];
        for (const index in match) {
          const userInfo = match[index];
          if (userInfo.user.id) {
            users.push({
              ...userInfo.user,
              index: Number(index)
            });
          }
        }
        // match データ設定
        const param = {
          round: val.round,
          order: match[0].order,
          matchCount: matchCount,
          users: users,
          startTime: startTime,
          endTime: endTime,
          created: serverTimestamp(),
          updated: serverTimestamp()
        };
        // match 登録
        let docRef = doc(firestore, "swissDraw", doc.id);
        docRef = collection(docRef, "match");
        await addDoc(docRef, param);
      }
    }
    return;
  };
}

// SwissDrawInfo リアルタイム取得
function subscribeSwissDrawMatchList(id) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {}, myTeamList = [] }
    } = getState();
    // リスナーを解除
    if (unsubscribeSwissDrawMatchList) {
      unsubscribeSwissDrawMatchList();
    }
    let docRef = doc(firestore, "swissDraw", id);
    docRef = collection(docRef, "match");
    docRef = query(docRef, orderBy("round", "asc"), orderBy("order", "asc"));
    unsubscribeSwissDrawMatchList = onSnapshot(docRef, (querySnapshot) => {
      const rawMatch = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        data.id = doc.id;
        // teams, users を加工してまとめる
        data.participantList = [];
        // TODO: 表示側で制御できそうなら、初期化しない方向で修正
        if (data.teams) {
          // 自分の対戦を設定
          for (const val of data.teams) {
            const param = {
              index: val.index,
              id: val.id,
              name: val.name,
              imageUrl: val.imageURL,
              backgroundImageUrl: val.backgroundImageURL,
              twitter: val.contact,
              friendCode: val.friendCode,
              gameuser: val.gameuser
            };
            if (myTeamList.some((el) => el.id === val.id)) {
              param.myItem = true;
            }
            data.participantList.push(param);
          }
          // 空き slotに空 team初期化
          const count = 2 - data.teams.length;
          for (let i = 0; i < count; i++) {
            data.participantList.push({});
          }
          delete data.teams;
        } else if (data.users) {
          // 自分の対戦を設定
          for (const val of data.users) {
            const param = {
              index: val.index,
              id: val.id,
              name: val.nickname,
              imageUrl: val.profileImage,
              backgroundImageUrl: val.profileBackgroundImage,
              twitter: val.twitter,
              friendCode: val.friendCode,
              gameuser: val.gameuser
            };
            if (auth.id === val.id) {
              param.myItem = true;
            }
            data.participantList.push(param);
          }
          // 空き slotに空 user初期化
          const count = 2 - data.users.length;
          for (let i = 0; i < count; i++) {
            data.participantList.push({});
          }
          delete data.users;
        }
        if (data.startTime) {
          data.startTimeText = moment(data.startTime.toDate()).format(
            "MM/DD HH:mm"
          );
        }
        rawMatch.push(data);
      });
      const groupedMatch = _groupBy(rawMatch, "round");
      const matchList = [];
      for (const i in groupedMatch) {
        const startTimetoDate = groupedMatch[i][0].startTime.toDate();
        const startTime = moment(startTimetoDate).format("YYYY-MM/DDTHH:mm");
        const startTimeText = moment(startTimetoDate).format("MM/DD HH:mm");
        const params = {
          round: Number(i),
          startTime: startTime,
          startTimeText: startTimeText,
          orderList: []
        };
        matchList.push(params);
        const grouped = _groupBy(groupedMatch[i], "order");
        for (const index in grouped) {
          if (matchList[i]) {
            matchList[i].orderList.push(grouped[index][0]);
          }
        }
      }

      dispatch(setSwissDrawMatchList(matchList));
    });
  };
}

// swissdraw match 詳細取得
function getSwissDrawMatchInfo(swissDrawId, matchId) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {}, myTeamList = [] }
    } = getState();
    let docRef = doc(firestore, "swissDraw", swissDrawId);
    docRef = doc(docRef, "match", matchId);
    const matchDoc = await getDoc(docRef);

    if (!matchDoc.exists()) return {};

    const data = matchDoc.data();
    if (data.startTime) {
      data.startTimeText = moment(data.startTime.toDate()).format(
        "MM-DD HH:mm"
      );
    }
    // teams, users を加工してまとめる
    data.participantList = [];
    // TODO: 表示側で制御できそうなら、初期化しない方向で修正
    if (data.teams) {
      // 自分の対戦を設定
      for (const val of data.teams) {
        const param = {
          index: val.index,
          id: val.id,
          name: val.name,
          imageUrl: val.imageURL,
          backgroundImageUrl: val.backgroundImageURL,
          twitter: val.contact,
          friendCode: val.friendCode,
          gameuser: val.gameuser
        };
        if (myTeamList.some((el) => el.id === val.id)) {
          param.myItem = true;
        }
        data.participantList.push(param);
      }
      delete data.teams;
    } else if (data.users) {
      // 自分の対戦を設定
      for (const val of data.users) {
        const param = {
          index: val.index,
          id: val.id,
          name: val.nickname,
          imageUrl: val.profileImage,
          backgroundImageUrl: val.profileBackgroundImage,
          twitter: val.twitter,
          friendCode: val.friendCode,
          gameuser: val.gameuser
        };
        if (auth.id === val.id) {
          param.myItem = true;
        }
        data.participantList.push(param);
      }
      delete data.users;
    }
    const matchInfo = {
      id: matchDoc.id,
      ...data
    };

    dispatch(setSwissDrawMatchInfo(matchInfo));
    return matchInfo;
  };
}

// swissdraw 更新
function updateSwissDraw(id, matchId, params = {}) {
  return async (dispatch, getState) => {
    if (!id || !matchId) return;
    // swissdraw 登録
    params.updated = serverTimestamp();
    if (params.startTime) {
      params.startTime = new Date(params.startTime);
    }
    let docRef = doc(firestore, "swissDraw", id);
    docRef = doc(docRef, "match", matchId);
    await updateDoc(docRef, params);
    return;
  };
}

// SwissDrawInfo ランキングリアルタイム取得
function subscribeSwissDrawRanking(id) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {}, myTeamList = [] }
    } = getState();
    //リスナーを解除
    if (unsubscribeSwissDrawResult) {
      unsubscribeSwissDrawResult();
    }
    let docRef = doc(firestore, "swissDraw", id);
    docRef = collection(docRef, "result");
    docRef = query(docRef, orderBy("rank", "asc"), orderBy("point", "desc"));

    unsubscribeSwissDrawResult = onSnapshot(docRef, (querySnapshot) => {
      const resultList = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();

        // user, team のデータを統一
        let resourceObj = {};
        let isMyObj = false;
        if (data.team) {
          resourceObj = {
            id: data.team.id,
            name: data.team.name,
            imageUrl: data.team.imageURL,
            twitter: data.team.contact,
            friendCode: data.team.friendCode,
            gameuser: data.team.gameuser
          };
          if (myTeamList.some((el) => el.id === data.team.id)) {
            isMyObj = true;
          }
        } else {
          resourceObj = {
            id: data.user.id,
            name: data.user.nickname,
            imageUrl: data.user.profileImage,
            twitter: data.user.twitter,
            friendCode: data.user.friendCode,
            gameuser: data.user.gameuser
          };
          if (auth.id === data.user.id) {
            isMyObj = true;
          }
        }

        resultList.push({
          id: resourceObj.id,
          imageURL: resourceObj.imageUrl,
          name: resourceObj.name,
          twitter: resourceObj.twitter,
          friendCode: resourceObj.friendCode,
          gameuser: resourceObj.gameuser,
          isMyObj: isMyObj,
          rank: data.rank,
          win: data.win,
          draw: data.draw,
          lose: data.lose,
          point: data.point
        });
      });
      dispatch(setSwissDrawResult(resultList));
    });
  };
}

// バトロワのラウンド終了
function doneBattleRoyalRound(battleRoyalId) {
  return async (dispatch, getState) => {
    const docRef = doc(firestore, "battleRoyal", battleRoyalId);
    await updateDoc(docRef, {
      roundStatus: "end",
      updated: serverTimestamp()
    });
  };
}

// スイスドローのラウンド終了
function doneSwissDrawRound(swissDrawId) {
  return async (dispatch, getState) => {
    const docRef = doc(firestore, "swissDraw", swissDrawId);
    await updateDoc(docRef, {
      roundStatus: "end",
      updated: serverTimestamp()
    });
  };
}

// チャットルームID取得
function getChatInfo(contestType, contestId, contestTypeId, matchId) {
  return async () => {
    let docRef = collection(firestore, "chat");
    docRef = query(docRef, where("contest.id", "==", contestId), where(`${contestType}Id`, "==", contestTypeId), where("matchId", "==", matchId));
    let chat = await getDocs(docRef);
    let chatInfo = {};
    chat.forEach((doc) => {
      chatInfo = {
        id: doc.id,
        ...doc.data()
      };
    });
    return chatInfo;
  };
}

// chat document 取得
function getChatDetail(chatId) {
  return async () => {
    const docRef = doc(firestore, "chat", chatId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return {};

    const chatInfo = {
      id: docSnap.id,
      ...docSnap.data()
    };
    return chatInfo;
  };
}

// matchId でチャット情報取得
function getContestMatchChatInfo (contestId, matchId) {
  return async () => {
    let docRef = collection(firestore, "chat");
    docRef = query(docRef, where("contest.id", "==", contestId), where("matchId", "==", matchId));
    let chat = await getDocs(docRef);
    let chatInfo = {};
    chat.forEach((doc) => {
      chatInfo = {
        id: doc.id,
        ...doc.data()
      };
    });
    return chatInfo;
  };
}

// 質問チャットルームID取得
function getContestQuestionChatInfo(contestId) {
  return async () => {
    let docRef = collection(firestore, "chat");
    docRef = query(docRef, where("contest.id", "==", contestId), where("isQuestion", "==", true));
    const chat = await getDocs(docRef);
    let chatInfo = {};
    chat.forEach((doc) => {
      chatInfo = {
        id: doc.id,
        ...doc.data()
      };
    });
    return chatInfo;
  };
}

// 告知チャットルームID取得
function getContestNoticeChatInfo(contestId) {
  return async () => {
    let docRef = collection(firestore, "chat");
    docRef = query(docRef, where("contest.id", "==", contestId), where("isNotice", "==", true));

    const chat = await getDocs(docRef);
    let chatInfo = {};
    chat.forEach((doc) => {
      chatInfo = {
        id: doc.id,
        ...doc.data()
      };
    });
    return chatInfo;
  };
}

// 公開チャット作成
function createPublicChat(name, option = {}) {
  return async (dispatch, getState) => {
    const {
      user: { auth },
      contest: { contestInfo = {} }
    } = getState();
    let info = {
      name: `${name} ${contestInfo.name}`,
      //image: contestInfo.imageUrl,
      contest: {
        id: contestInfo.id
      },
      isPublic: true,
      users: [auth.id],
      admin: [auth.id],
      updated: serverTimestamp(),
      created: serverTimestamp()
    };
    // オプションがあれば既存の情報と結合
    if (Object.keys(option).length !== 0) {
      info = { ...info, ...option };
    }

    await addDoc(collection(firestore, "chat"), info);
  };
}

// エントリー確定部屋チャットルーム更新
function updateEntryConfirmChat(
  contestInfo = {},
  roomName,
  users = [],
  admin = []
) {
  return async (dispatch, getState) => {
    let docRef = collection(firestore, "chat");
    docRef = query(docRef, where("contest.id", "==", contestInfo.id), where("isConfirmed", "==", true));

    const querySnapshot = await getDocs(docRef);
    if (querySnapshot.empty) {
      // チャットルーム新規作成
      const param = {
        name: roomName,
        //image: contestInfo.imageUrl,
        contest: {
          id: contestInfo.id
        },
        users,
        admin,
        isConfirmed: true,
        updated: serverTimestamp(),
        created: serverTimestamp()
      };
      await addDoc(collection(firestore, "chat"), param);
    } else {
      // チャットルーム更新
      querySnapshot.forEach(async (doc) => {
        await updateDoc(doc.ref, { users });
      });
    }
  };
}

// チャット作成
function createChat(users = [], admin = []) {
  return async (dispatch, getState) => {
    const {
      contest: { contestInfo = {} }
    } = getState();

    let imageUrl = contestInfo.contestImage;
    if (!imageUrl) {
      if (contestInfo.game.key) {
        imageUrl =
          process.env.REACT_APP_GCS_PATH +
          "/game/" +
          `${contestInfo.game.key}.jpg`;
      } else {
        imageUrl = require("images/background_noimage.png");
      }
    }
    const current = Math.round(new Date().getTime() / 1000);

    let info = {
      name: `${contestInfo.name} ${current}`,
      image: imageUrl,
      contest: {
        id: contestInfo.id
      },
      users,
      admin,
      updated: serverTimestamp(),
      created: serverTimestamp()
    };

    return await addDoc(collection(firestore, "chat"), info)
      .then(async (docRef) => {
        const doc = await getDoc(docRef);
        const data = doc.data();
        return { id: doc.id, ...data };
      });
  };
}

// エントリーユーザーを公開チャットに追加
function addChatUser(contestId, userIdList = []) {
  return async (dispatch) => {
    if (userIdList.length) {
      let batch = writeBatch(firestore);
      const querySnapshot = await getDocs(query(collection(firestore, "chat"), where("contest.id", "==", contestId), where("isPublic", "==", true)));

      querySnapshot.forEach((doc) => {
        const param = {
          users: arrayUnion(...userIdList),
          updated: serverTimestamp()
        };
        batch.update(doc.ref, param);
      });
      //バッチ更新実行
      await batch.commit();
    }
  };
}

// 運営ユーザーを大会チャットに追加
function addChatAdmin(contestId, userIdList = []) {
  return async (dispatch) => {
    if (userIdList.length) {
      let batch = writeBatch(firestore);
      // 大会の全てのチャットを取得
      let docRef = collection(firestore, "chat");
      docRef = query(docRef, where("contest.id", "==", contestId));
      const querySnapshot = await getDocs(docRef);

      querySnapshot.forEach((doc) => {
        const { isNotice } = doc.data();
        const param = {
          users: arrayUnion(...userIdList),
          admin: arrayUnion(...userIdList),
          updated: serverTimestamp()
        };
        if (isNotice) {
          // 告知部屋の場合 senders に追加
          param.senders = arrayUnion(...userIdList);
        }
        batch.update(doc.ref, param);
      });
      //バッチ更新実行
      await batch.commit();
    }
  };
}

// チャットから除外
function removeChatUser(contestId, userIdList = []) {
  return async (dispatch) => {
    if (userIdList.length) {
      let batch = writeBatch(firestore);
      let docRef = collection(firestore, "chat");
      docRef = query(docRef, where("contest.id", "==", contestId), where("isPublic", "==", true));
      const querySnapshot = await getDocs(docRef);

      querySnapshot.forEach((doc) => {
        const param = {
          users: arrayRemove(...userIdList),
          updated: serverTimestamp()
        };
        batch.update(doc.ref, param);
      });
      //バッチ更新実行
      await batch.commit();
    }
  };
}

// チャットから除外
function removeChatAdmin(contestId, userIdList = []) {
  return async (dispatch) => {
    if (userIdList.length) {
      let batch = writeBatch(firestore);
      let docRef = collection(firestore, "chat");
      docRef = query(docRef, where("contest.id", "==", contestId));
      const querySnapshot = await getDocs(docRef);

      querySnapshot.forEach((doc) => {
        const { isNotice } = doc.data();
        const param = {
          users: arrayRemove(...userIdList),
          admin: arrayRemove(...userIdList),
          updated: serverTimestamp()
        };
        if (isNotice) {
          // 告知部屋の場合 senders から削除
          param.senders = arrayRemove(...userIdList);
        }
        batch.update(doc.ref, param);
      });
      //バッチ更新実行
      await batch.commit();
    }
  };
}

function subscribeRoomList(contestId) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {} }
    } = getState();
    // リスナーを解除
    if (unsubscribeRoomList) {
      unsubscribeRoomList();
    }
    let docRef = collection(firestore, "room");
    docRef = query(docRef,
      where("userId", "==", auth.id),
      where("contest.id", "==", contestId),
      orderBy("lastMessageUpdated", "desc")
    );

    // チャット一覧取得
    unsubscribeRoomList = onSnapshot(docRef, (querySnapshot) => {
      const chatList = [];
      querySnapshot.forEach(async (doc) => {
        const data = doc.data();
        chatList.push({
          id: doc.id,
          ...data
        });
      });
      dispatch(setChatList(chatList));
    });
  };
}

// 未読バッジーを 0に更新
function changeRoomStatus(chatId) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {} }
    } = getState();
    let docRef = collection(firestore, "room");
    docRef = query(docRef, where("chatId", "==", chatId), where("userId", "==", auth.id));
    const querySnapshot = await getDocs(docRef);

    querySnapshot.forEach((doc) => {
      updateDoc(doc.ref, {
        unread: 0,
        updated: new Date()
      });
    });
  };
}

// 個人大会のチェックイン
function contestUserCheckin(contestId, contestuserId, params) {
  return async (dispatch, getState) => {
    try {
      const {
        contest: { contestInfo = {} }
      } = getState();
      let docRef = doc(firestore, "contests", contestId);
      docRef = doc(docRef, "contestusers", contestuserId);
      await updateDoc(docRef, params);

      dispatch(
        setContestInfo({
          ...contestInfo,
          isCheckin: true
        })
      );
      return;
    }
    catch (err) {
      return Promise.reject(err);
    }
  };
}

/*
// 個人大会のチェックイン
function contestUserCheckin(contestId, userId, params) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/contests/${contestId}/users/${userId}/checkin/`, params)
        .then((result) => {
          const {
            contest: { contestInfo = {} }
          } = getState();
          const { checkin } = result.data;
          dispatch(
            setContestInfo({
              ...contestInfo,
              isCheckin: checkin
            })
          );
          resolve(result.data);
        })
        .catch(reject);
    });
  };
}
*/

// チーム大会のチェックイン
function contestTeamCheckin(contestId, teamId, params) {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/api/v1/contests/${contestId}/teams/${teamId}/checkin/`, params)
        .then((result) => {
          const {
            contest: { contestInfo = {} }
          } = getState();
          const { checkin } = result.data;
          dispatch(
            setContestInfo({
              ...contestInfo,
              isCheckin: checkin
            })
          );
          resolve(result.data);
        })
        .catch(reject);
    });
  };
}

// TODO: エントリーAPIに統合
// エントリー用パスワードチェック
function checkContestEntryPassword(contestId, entryPassword) {
  return (dispatch, getState) => {
    const params = {
      entryPassword
    };
    return axios
      .post(`/api/v1/contests/${contestId}/password/`, params)
      .then((result) => {
        return result?.data;
      })
      .catch((err) => {
        return err.response?.data;
      });
  };
}

// 大会削除
function deleteContest(id) {
  return (dispatch, getState) => {
    return axios
      .delete(`/api/v1/contests/${id}/`)
      .then((result) => {
        return result.data;
      })
      .catch((err) => console.log(err));
  };
}

// フォロー＆リツイートチェック
function checkTwitterEngagement(id, tweetId = "", tweetProtected = false) {
  return (dispatch, getState) => {
    const {
      user: { token }
    } = getState();

    const param = {
      tweetId,
      tweetProtected
    };

    return axios
      .post(`/api/v1/contests/${id}/twitter/engagement/`, param, {
        headers: {
          Authorization: `JWT ${token}`
        }
      })
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
}

const initialState = {
  contestList: [],
  contestInfo: {},
  contestTeams: [],
  contestUsers: [],
  trophyList: [],
  myTeams: [],
  myRole: {},
  tournamentInfo: {},
  tournamentMatchList: [],
  chatList: [],
  messageList: [],
  tournamentMatchInfo: {},
  contestQuestions: [],
  contestQuestionSelections: [],
  battleRoyalInfo: {},
  battleRoyalResult: { teams: [] },
  battleRoyalMatchList: [],
  operatorList: [],
  swissDrawInfo: {},
  swissDrawMatchList: [],
  swissDrawMatchInfo: {}
};

// reducer
function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_CONTEST_INFO:
      return applyClearContestInfo(state, action);
    case SET_CONTEST_LIST:
      return applySetContestList(state, action);
    case SET_CONTEST_INFO:
      return applySetContestInfo(state, action);
    case SET_CONTEST_TEAMS:
      return applySetContestTeams(state, action);
    case SET_CONTEST_USERS:
      return applySetContestUsers(state, action);
    case SET_CONTEST_MYTEAMS:
      return applySetContestMyTeams(state, action);
    case SET_CONTEST_MYROLE:
      return applySetContestMyRole(state, action);
    case SET_TROPHY_LIST:
      return applySetTrophyList(state, action);
    case SET_TOURNAMENT_INFO:
      return applySetTournamentInfo(state, action);
    case SET_TOURNAMENT_MATCH_LIST:
      return applySetTournamentMatchList(state, action);
    case SET_MATCH_CHAT:
      return applySetMatchChat(state, action);
    case CLEAR_MATCH_CHAT:
      return applyClearMatchChat(state, action);
    case SET_TOURNAMENT_MATCH_INFO:
      return applySetTournamentMatchInfo(state, action);
    case CLEAR_TOURNAMENT_MATCH_INFO:
      return applyClearTournamentMatchInfo(state, action);
    case SET_CONTEST_QUESTIONS:
      return applySetContestQuestions(state, action);
    case SET_CONTEST_QUESTION_SELECTIONS:
      return applySetContestQuestionSelections(state, action);
    case SET_BATTLE_ROYAL_INFO:
      return applySetBattleRoyalInfo(state, action);
    case SET_BATTLE_ROYAL_RESULT:
      return applySetBattleRoyalResult(state, action);
    case SET_BATTLE_ROYAL_MATCH:
      return applySetBattleRoyalMatchList(state, action);
    case SET_OPERATOR_LIST:
      return applySetOperatorList(state, action);
    case SET_SWISSDRAW_INFO:
      return applySetSwissDrawInfo(state, action);
    case SET_SWISSDRAW_MATCH_LIST:
      return applySetSwissDrawMatchList(state, action);
    case SET_SWISSDRAW_MATCH_INFO:
      return applySetSwissDrawMatchInfo(state, action);
    case CLEAR_SWISSDRAW_MATCH_INFO:
      return applyClearSwissDrawMatchInfo(state, action);
    case SET_SWISSDRAW_RESULT:
      return applySetSwissDrawResult(state, action);
    case SET_CHAT_LIST:
      return applySetChatList(state, action);
    default:
      return state;
  }
}

// reducer functions
function applyClearContestInfo(state, action) {
  // リスナーを解除
  if (unsubscribeBattleRoyalInfo) {
    unsubscribeBattleRoyalInfo();
  }
  if (unsubscribeBattleRoyalResult) {
    unsubscribeBattleRoyalResult();
  }
  if (unsubscribeBattleRoyalMatchList) {
    unsubscribeBattleRoyalMatchList();
  }
  if (unsubscribeTournamentMatchList) {
    unsubscribeTournamentMatchList();
  }
  if (unsubscribeSwissDrawMatchList) {
    unsubscribeSwissDrawMatchList();
  }
  if (unsubscribeSwissDrawResult) {
    unsubscribeSwissDrawResult();
  }
  if (unsubscribeSwissDrawInfo) {
    unsubscribeSwissDrawInfo();
  }
  if (unsubscribeRoomList) {
    unsubscribeRoomList();
  }
  if (unsubscribeMatchChat) {
    unsubscribeMatchChat();
  }
  return initialState;
}

function applySetContestList(state, action) {
  const { contestList } = action;
  return {
    ...state,
    contestList
  };
}

function applySetContestInfo(state, action) {
  const { contestInfo } = action;
  return {
    ...state,
    contestInfo
  };
}

function applySetContestTeams(state, action) {
  const { contestTeams } = action;
  return {
    ...state,
    contestTeams
  };
}

function applySetContestUsers(state, action) {
  const { contestUsers } = action;
  return {
    ...state,
    contestUsers
  };
}

function applySetContestMyTeams(state, action) {
  const { myTeams } = action;
  return {
    ...state,
    myTeams
  };
}

function applySetContestMyRole(state, action) {
  const { myRole } = action;
  return {
    ...state,
    myRole
  };
}
function applySetTrophyList(state, action) {
  const { trophyList } = action;
  return {
    ...state,
    trophyList
  };
}

function applySetTournamentInfo(state, action) {
  const { tournamentInfo } = action;
  return {
    ...state,
    tournamentInfo
  };
}

function applySetTournamentMatchList(state, action) {
  const { tournamentMatchList } = action;
  return {
    ...state,
    tournamentMatchList
  };
}

function applySetMatchChat(state, action) {
  const { messageList } = action;
  return {
    ...state,
    messageList
  };
}

function applyClearMatchChat(state, action) {
  // リスナーを解除
  if (unsubscribeMatchChat) {
    unsubscribeMatchChat();
  }
  return {
    ...state,
    messageList: []
  };
}

function applySetTournamentMatchInfo(state, action) {
  const { tournamentMatchInfo } = action;
  return {
    ...state,
    tournamentMatchInfo
  };
}

function applyClearTournamentMatchInfo(state, action) {
  return {
    ...state,
    tournamentMatchInfo: {}
  };
}

function applySetContestQuestions(state, action) {
  const { contestQuestions } = action;
  return {
    ...state,
    contestQuestions
  };
}

function applySetContestQuestionSelections(state, action) {
  const { contestQuestionSelections } = action;
  return {
    ...state,
    contestQuestionSelections
  };
}

function applySetBattleRoyalInfo(state, action) {
  const { battleRoyalInfo } = action;
  return {
    ...state,
    battleRoyalInfo
  };
}

function applySetBattleRoyalResult(state, action) {
  const { battleRoyalResult } = action;
  return {
    ...state,
    battleRoyalResult
  };
}

function applySetBattleRoyalMatchList(state, action) {
  const { battleRoyalMatchList } = action;
  return {
    ...state,
    battleRoyalMatchList
  };
}

function applySetOperatorList(state, action) {
  const { operatorList } = action;
  return {
    ...state,
    operatorList
  };
}

function applySetSwissDrawInfo(state, action) {
  const { swissDrawInfo } = action;
  return {
    ...state,
    swissDrawInfo
  };
}

function applySetSwissDrawMatchList(state, action) {
  const { swissDrawMatchList } = action;
  return {
    ...state,
    swissDrawMatchList
  };
}

function applySetSwissDrawMatchInfo(state, action) {
  const { swissDrawMatchInfo } = action;
  return {
    ...state,
    swissDrawMatchInfo
  };
}

function applyClearSwissDrawMatchInfo(state, action) {
  return {
    ...state,
    swissDrawMatchInfo: {}
  };
}

function applySetSwissDrawResult(state, action) {
  const { swissDrawResult } = action;
  return {
    ...state,
    swissDrawResult
  };
}

function applySetChatList(state, action) {
  const { chatList } = action;
  return {
    ...state,
    chatList
  };
}

// exports
const actionCreators = {
  clearContestInfo,
  getContestList,
  getOperatedContestList,
  getEntryContestList,
  getContestTeamCount,
  getContestUserCount,
  getContestDetail,
  getContestUserInfo,
  getContestTeamInfo,
  getContestTeams,
  getContestUsers,
  getContestOperators,
  addContestOperators,
  deleteContestOperators,
  entryContestTeam,
  entryContestUser,
  deleteContestTeam,
  deleteContestUser,
  deleteTournament,
  getContestMyTeams,
  getTrophies,
  uploadImage,
  createTeamTournament,
  createUserTournament,
  updateTournament,
  getTournamentInfo,
  getTournamentMatchInfo,
  getTournamentMatchList,
  saveSurveyAnswer,
  contestRegistration,
  contestEdit,
  getContestQuestions,
  getContestQuestionSelections,
  createBattleRoyal,
  getBattleRoyalInfo,
  getBattleRoyalResult,
  getBattleRoyalMatchList,
  subscribeBattleRoyalInfo,
  subscribeBattleRoyalResult,
  subscribeBattleRoyalMatchList,
  subscribeTournamentMatchList,
  subscribeMatchChat,
  getMatchChat,
  sendMessage,
  getChatInfo,
  getChatDetail,
  getContestMatchChatInfo,
  getContestQuestionChatInfo,
  getContestNoticeChatInfo,
  addChatUser,
  addChatAdmin,
  removeChatUser,
  removeChatAdmin,
  subscribeRoomList,
  createPublicChat,
  updateEntryConfirmChat,
  createChat,
  updateBattleRoyal,
  createTeamBattleRoyalMatch,
  createUserBattleRoyalMatch,
  updateBattleRoyalMatchResult,
  updateBattleRoyalSoloMatchSelfResult,
  updateBattleRoyalMultiMatchSelfResult,
  clearTournamentMatchInfo,
  clearMatchChat,
  clearChatBadge,
  updateContestTeamStatus,
  updateContestUserStatus,
  contestUserCheckin,
  contestTeamCheckin,
  getSwissDrawInfo,
  subscribeSwissDrawInfo,
  createTeamSwissDraw,
  createUserSwissDraw,
  subscribeSwissDrawMatchList,
  getSwissDrawMatchInfo,
  clearSwissDrawMatchInfo,
  updateSwissDraw,
  subscribeSwissDrawRanking,
  doneBattleRoyalRound,
  doneSwissDrawRound,
  deleteContest,
  checkTwitterEngagement,
  changeRoomStatus,
  checkContestEntryPassword
};

export { actionCreators };

export default reducer;
