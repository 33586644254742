import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import TextArea from "react-validation/build/textarea";
import { required, length } from "components/lib/Validator";
import { TiAttachment } from "react-icons/ti";
import Dropzone from "react-dropzone";
import Button from "components/lib/Button";

// ゲーム名取得
const getGameName = (gameId, gameList = []) => {
  const game = gameList.find(el => el.id === gameId);
  if(game?.gameName) {
    return game.gameName;
  }
    return "";
};

const ContestEdit = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.info}>
      <Form className={styles.form} method="post" onSubmit={props.handleSubmit}>
        <div className={styles.profileImageContainer}>
          <label className={styles.imageLabel}>
            {props.imageUrl ? (
              <img
                className={styles.image}
                src={props.imageUrl}
                alt={context.t("preview")}
              />
            ) : (
              <div className={styles.chooseCoverImage}>
                {context.t("ChooseCoverImage")}
              </div>
            )}
            <input
              type="file"
              className={styles.imageInput}
              accept="image/png, image/jpeg"
              onChange={props.handleFileChange}
              onClick={(event) => (event.target.value = "")}
            />
          </label>
          <div className={styles.inputContents}>
            <Button style={{ width: "100%", marginTop: 10 }} className={styles.contestImageResetButton} text={context.t("resetImageButton")} onClick={props.handleImageReset}/>
          </div>
        </div>
        <div className={styles.inputContents}>
          {/* チームにゲームが割り当てられているためゲームは変更出来ないこととする */}
          <span className={styles.label}>{context.t("chooseGameTitle")}</span>
          <input
            type="text"
            name="gameTitle"
            value={getGameName(props.gameId, props.gameList)}
            className={styles.textInput}
            autoComplete="off"
            readOnly
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("decideContestName")}</span>
          <Input
            type="text"
            name="contestName"
            value={props.name}
            placeholder={context.t("contest")}
            className={styles.textInput}
            onChange={props.handleInputChange}
            autoComplete="off"
            maxLength="256"
            validations={[required, length]}
          />
        </div>
        <div className={styles.dateAndTimeContentsWrap}>
          <span className={styles.label}>{context.t("entryPeriod")}</span>
          <div className={styles.dateAndTimeContents}>
            <div className={styles.inputContents}>
              <Input
                type="datetime-local"
                name="entryStart"
                value={props.entryStart}
                className={styles.textInput}
                onChange={props.handleInputChange}
                autoComplete="off"
                validations={[required]}
              />
            </div>
            <span className={styles.wavyLine}>〜</span>
            <div className={styles.inputContents}>
              <Input
                type="datetime-local"
                name="entryEnd"
                value={props.entryEnd}
                className={styles.textInput}
                onChange={props.handleInputChange}
                autoComplete="off"
                validations={[required]}
              />
            </div>
          </div>
        </div>
        <div className={styles.dateAndTimeContentsWrap}>
          <span className={styles.label}>{context.t("contestDuration")}</span>
          <div className={styles.dateAndTimeContents}>
            <div className={styles.inputContents}>
              <Input
                type="datetime-local"
                name="contestStart"
                value={props.contestStart}
                className={styles.textInput}
                onChange={props.handleInputChange}
                autoComplete="off"
                validations={[required]}
              />
            </div>
            <span className={styles.wavyLine}>〜</span>
            <div className={styles.inputContents}>
              <Input
                type="datetime-local"
                name="contestEnd"
                value={props.contestEnd}
                className={styles.textInput}
                onChange={props.handleInputChange}
                autoComplete="off"
                validations={[required]}
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContents}>
          <div className={styles.checkBoxInput}>
            <input
              id="checkinCheckbox"
              name="isCheckin"
              type="checkbox"
              onChange={props.handleCheck}
              checked={props.isCheckin}
            />
            <label className={styles.checkBoxText} htmlFor={"checkinCheckbox"}>
              {context.t("enableContestCheckin")}
            </label>
          </div>
        </div>
        {props.isCheckin ? (
          <div className={styles.dateAndTimeContentsWrap}>
            <span className={styles.label}>{context.t("checkinDuration")}</span>
            <div className={styles.dateAndTimeContents}>
              <div className={styles.inputContents}>
                <input
                  type="datetime-local"
                  name="checkinStart"
                  className={styles.textInput}
                  value={props.checkinStart}
                  onChange={props.handleInputChange}
                  autoComplete="off"
                />
              </div>
              <span className={styles.wavyLine}>〜</span>
              <div className={styles.inputContents}>
                <input
                  type="datetime-local"
                  name="checkinEnd"
                  className={styles.textInput}
                  value={props.checkinEnd}
                  onChange={props.handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.inputContents}>
          <div className={styles.checkBoxInput}>
            <input
              id="passwordCheckbox"
              name="isEntryPassword"
              type="checkbox"
              onChange={props.handleCheck}
              checked={props.isEntryPassword}
            />
            <label className={styles.checkBoxText} htmlFor={"passwordCheckbox"}>
              {context.t("enableEntryPassword")}
            </label>
          </div>
          {props.isEntryPassword ? (
            <Input
              name="entryPassword"
              value={props.entryPassword}
              className={styles.textInput}
              onChange={props.handleInputChange}
              autoComplete="off"
              maxLength="16"
            />
          ) : null}
        </div>
        {props.participantType === "MULTI" ? (
          <div className={styles.inputContents}>
            <span className={styles.label}>
              {context.t("MinimumNumberOfTeams")}
            </span>
            <Input
              type="number"
              name="teamSize"
              value={props.teamSize}
              min="2"
              max="99"
              pattern="\d*"
              className={styles.textInput}
              onChange={props.handleInputChange}
              autoComplete="off"
              maxLength="256"
              validations={[required]}
            />
          </div>
        ) : null}
        <div className={styles.inputContents}>
          <span className={styles.label}>
            {props.participantType === "SOLO"
              ? context.t("chooseMaximumNumberOfParticipants")
              : context.t("chooseMaximumNumberOfTeams")}
          </span>
          <Input
            type="number"
            name="entryMaxCount"
            value={props.entryMaxCount}
            min="2"
            max="256"
            pattern="\d*"
            className={styles.textInput}
            onChange={props.handleInputentryMaxCountChange}
            autoComplete="off"
            maxLength="256"
            validations={[required]}
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("inputDiscordUrl")}</span>
          <Input
            type="url"
            name="discordUrl"
            value={props.discordUrl}
            placeholder={context.t("discordUrl")}
            className={styles.textInput}
            onChange={props.handleInputChange}
            autoComplete="off"
            maxLength="256"
            validations={[length]}
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("inputIframeTag")}</span>
          <TextArea
            type="text"
            row="5"
            name="iframeSrc"
            value={props.iframeSrc}
            placeholder={context.t("contestIframeVideo")}
            className={styles.iframeTag}
            onChange={props.handleInputChange}
            autoComplete="off"
            maxLength="512"
            validations={[length]}
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("prizeIfAny")}</span>
          <TextArea
            name="award"
            value={props.award}
            placeholder={context.t("award")}
            className={styles.award}
            onChange={props.handleInputChange}
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("inputContestRules")}</span>
          <Dropzone onDrop={props.handleDragDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <textarea
                    name="rule"
                    ref={props.myRef}
                    value={props.rule}
                    placeholder={context.t("contestRule")}
                    className={styles.rule}
                    onChange={props.handleInputChange}
                    autoComplete="off"
                    maxLength="4096"
                    validations={[length]}
                  />
                </div>
              </section>
            )}
          </Dropzone>
          <div className={styles.attach}>
            <label className={styles.imageLabel}>
              <span
                className={styles.attachButton}
                onClick={props.handleChangeInputFile}
              >
                <TiAttachment className={styles.icon} size="20" />
                <span>{context.t("attachImage")}</span>
              </span>
              <input
                type="file"
                name="image"
                className={styles.imageInput}
                accept="image/png, image/jpeg, image/gif"
                onChange={props.handleChangeInputFile}
                onClick={props.handleClickInputFile}
              />
            </label>
          </div>
        </div>
        {props.contestType === "BATTLEROYAL" ? (
          <div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputMatchCount")}
              </span>
              <select
                name="matchCount"
                className={styles.textInput}
                onChange={props.handleInputChange}
                value={props.matchCount}
              >
                {props.matchCountList.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputRankPoint")}
              </span>
              <div
                className={styles.rankPointTable}
                style={{ height: props.getRankPointTableHeight() + "px" }}
              >
                {props.rankPoint.length
                  ? props.rankPoint.map((item, index) => (
                      <div className={styles.item} key={index}>
                        <span className={styles.rank}>
                          {context.t("rankText", { value: item.rank })}
                        </span>
                        <span className={styles.point}>
                          <input
                            type="number"
                            name="rankPoint"
                            className={styles.inputPoint}
                            value={item.point}
                            onChange={props.handleInputRankPointChange.bind(
                              this,
                              index
                            )}
                            autoComplete="off"
                          />
                        </span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputKillPoint")}
              </span>
              <Input
                type="number"
                name="killPoint"
                value={props.killPoint.point}
                className={styles.textInput}
                onChange={props.handleInputKillPointChange}
                autoComplete="off"
              />
            </div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputKillPointMax")}
              </span>
              <Input
                type="number"
                name="killPointMax"
                value={props.killPoint.max}
                className={styles.textInput}
                onChange={props.handleInputKillPointChange}
                autoComplete="off"
              />
            </div>
          </div>
        ) : null}
        <div className={styles.inputContents}>
          <div>
            <Button
              style={{ width: "100%" }}
              className={styles.contestEditButton}
              text={context.t("saveChange")}
              onClick={props.handleSubmit}
              disabled={
                !props.gameId ||
                !props.name ||
                !props.entryStart ||
                !props.entryEnd ||
                !props.contestStart ||
                !props.contestEnd ||
                (props.participantType !== "SOLO" && !props.teamSize) ||
                !props.entryMaxCount ||
                props.isLoading
              }
            />
            <div className={styles.deleteButtonContainer}>
              <Button
                style={{
                  width: "100%",
                  background: "initial",
                  color: "#707070",
                  border: "1px solid #707070",
                }}
                className={styles.contestDeleteButton}
                text={context.t("contestDelete")}
                onClick={props.handleContestDelete}
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
);

ContestEdit.propTypes = {
  gameList: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired
};

ContestEdit.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestEdit;
