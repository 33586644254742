import React from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import { PropTypes } from "prop-types";

const Footer = (props, context) => (
  <div className={styles.contents}>
    <span>
      <select
        name="lang"
        className={styles.language}
        onChange={props.handleSelectChange}
        value={props.lang}
      >
        {props.langList.map((item) => (
          <option key={item} value={item}>
            {context.t(item)}
          </option>
        ))}
      </select>
    </span>
    <span>
      <Link to="/terms-of-service">
        <span className={styles.contract}>{context.t("contract")}</span>
      </Link>
      <Link to="/privacy-policy">
        <span className={styles.privacy}>{context.t("privacy")}</span>
      </Link>
    </span>
  </div>
);

Footer.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Footer;
