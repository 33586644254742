import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Button from "react-validation/build/button";
import { required, length } from "components/lib/Validator";

const PasswordReset = (props, context) => (
  <div className={styles.contentsWrap}>
    <div className={styles.contents}>
      <div className={styles.passwordResetContainer}>
        <Form
          className={styles.passwordResetForm}
          method="post"
          onSubmit={props.handleSubmit}
        >
          <div className={styles.label}> {context.t("newPassword")} </div>
          <Input
            type="password"
            name="newPassword"
            placeholder={context.t("newPassword")}
            className={styles.textInput}
            value={props.newPassword}
            onChange={props.handleInputChange}
            minLength="8"
            validations={[required, length]}
            autoComplete="off"
          />
          <div className={styles.label}> {context.t("confirmPassword")} </div>
          <Input
            type="password"
            name="confirmPassword"
            placeholder={context.t("confirmPassword")}
            className={styles.textInput}
            value={props.confirmPassword}
            onChange={props.handleInputChange}
            minLength="8"
            validations={[required, length]}
            autoComplete="off"
          />
          <Button
            type="submit"
            className={styles.passwordResetButton}
            disabled={props.isChecked || props.loading}
          >
            {context.t("setting")}
          </Button>
        </Form>
      </div>
    </div>
  </div>
);

PasswordReset.propTypes = {
  newPassword: PropTypes.string.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

PasswordReset.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PasswordReset;
