import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import classNames from "classnames/bind";
import { Link } from "react-router-dom";

const cx = classNames.bind(styles);

const CommonMatchResult = (props, context) => (
  <div className={styles.contents}>
    {props.matchInfo.id ? (
      <div className={styles.matchInfo}>
        <div className={styles.infoItem}>
          <div className={styles.label}>{context.t("startTime")}</div>
          <div className={styles.value}>{props.matchInfo.startTimeText}</div>
        </div>
        <div className={styles.infoItem}>
          <div className={styles.label}>{context.t("matchResult")}</div>
          <div className={styles.value}>
            {props.winnerInfo.id ? (
              <span className={styles.winnerInfo}>
                <span className={styles.win}>WIN</span>
                <span className={styles.participantInfo}>
                  <Link
                    to={`/users/${props.winnerInfo.userId}`}
                  >
                    <img
                      src={
                        props.winnerInfo.imageUrl
                          ? props.winnerInfo.imageUrl
                          : require("images/noimage.png")
                      }
                      alt="proflie"
                      className={styles.image}
                      onError={(e) =>
                        (e.target.src = require("images/noimage.png"))
                      }
                    />
                    <span>{props.winnerInfo.name || "-"}</span>
                  </Link>
                </span>
                {props.matchInfo.unearned ? (
                  <span className={styles.unearnedWin}>
                    ({context.t("unearnedWin")})
                  </span>
                ) : null}
              </span>
            ) : (
              <div className={styles.empty}>{context.t("emptyData")}</div>
            )}
          </div>
        </div>
        {props.matchInfo.winner ? (
          <div className={styles.infoItem}>
            <div className={styles.label}>{context.t("matchDetail")}</div>
            <div className={cx(styles.value, styles.reportList)}>
              {props.matchList.map((item, index) => (
                <div key={index}>
                  <span className={styles.label}>
                    {context.t("numberth", { val: index + 1 })}
                  </span>
                  {item.images ? (
                    <div>
                      {item.winner ? (
                        <span className={styles.winnerInfo}>
                          <span className={styles.win}>WIN</span>
                          <span className={styles.participantInfo}>
                            <Link
                              to={`/users/${item.winnerInfo.userId}`}
                            >
                              <img
                                src={
                                  item.winnerInfo.imageUrl
                                    ? item.winnerInfo.imageUrl
                                    : require("images/noimage.png")
                                }
                                alt="proflie"
                                className={styles.image}
                                onError={(e) =>
                                  (e.target.src = require("images/noimage.png"))
                                }
                              />
                              <span>{item.winnerInfo.name || "-"}</span>
                            </Link>
                          </span>
                        </span>
                      ) : null}
                      <div className={styles.imageContainer}>
                        {item.images ? (
                          item.images.length > 1 ? (
                            <Carousel className={styles.slide} showThumbs={false}>
                              {item.images.map((img, i) => (
                                <img
                                  key={i}
                                  className={styles.image}
                                  src={img.imageUrl}
                                  alt={context.t("preview")}
                                />
                              ))}
                            </Carousel>
                          ) : (
                            item.images.map((img, i) => (
                              <img
                                key={i}
                                className={styles.image}
                                src={img.imageUrl}
                                alt={context.t("preview")}
                              />
                            ))
                          )
                        ) : null}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.empty}>{context.t("emptyData")}</div>
                  )}
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    ) : null}
  </div>
);

CommonMatchResult.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CommonMatchResult;
