import React from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "./styles.scss";
import PropTypes from "prop-types";

const CommonMenu = ({ items = [], anchorEl, onClose, onClick }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
      className={styles.container}
    >
      {items.map((item, index) => (
        <MenuItem
          key={index}
          className={styles.menuItem}
          onClick={() => onClick(item.value)}
        >
          {item.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

CommonMenu.propTypes = {
  items: PropTypes.array,
  anchorEl: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CommonMenu;
