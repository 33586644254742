import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loading from "components/lib/Loading";
import classNames from "classnames/bind";
import { TiSocialTwitter } from "react-icons/ti";
//import { IoCheckmarkCircle, IoCheckmark } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "components/lib/Button";

const cx = classNames.bind(styles);

const ContestUserList = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.entryList}>
      <div className={styles.listHeader}>
        <div className={styles.entryStatus}>
          {props.contestUsers.length ? (
            <span>{context.t("attendedUser", { value: props.userCount })}</span>
          ) : null}
        </div>
        <div className={styles.buttonList}>
          {props.isSignedIn &&
            props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
            !props.deleteMode ? (
              <React.Fragment>
                <Button className={styles.confirmButton} text={context.t("entryConfirm")} onClick={props.handleEntryConfirm} />
                <Button className={styles.deleteButton} text={context.t("deleteSelect")} onClick={props.handleSwitchDelete} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button className={styles.deleteButton} text={context.t("delete")} onClick={props.handleUserDelete} />
                <Button className={styles.deleteButton} text={context.t("cancel")} onClick={props.handleSwitchDelete} />
              </React.Fragment>
            )
          ) : props.contestInfo.isEntry ? (
            <Button className={styles.deleteButton} text={context.t("deleteSelect")} onClick={props.handleDeleteMe} />
          ) : null}
        </div>
      </div>
      <div className={styles.list}>
        {props.loading ? (
          <Loading />
        ) : props.contestUsers.length ? (
          <div className={styles.listContainer}>
            <InfiniteScroll
              className={styles.infiniteScroll}
              dataLength={props.contestUsers.length}
              next={props.loadMore}
              hasMore={props.hasMore}
              loader={<Loading />}
            >
              {props.contestUsers.map((contestUser, index) => (
                <label
                  className={styles.contestLabel}
                  htmlFor={`checkbox_${contestUser.id}`}
                  key={index}
                >
                  <div
                    className={cx(styles.contestUser, {
                      deleteMode: props.deleteMode
                    })}
                  >
                    <div className={styles.participantContainer}>
                      <input
                        type="checkbox"
                        id={`checkbox_${contestUser.id}`}
                        className={styles.checkbox}
                        value={contestUser.userId}
                        onChange={props.handleCheck}
                        checked={
                          props.deleteList.find(
                            (el) => el.id === contestUser.userId && el.isDelete
                          )
                            ? true
                            : false
                        }
                      />
                      <div className={styles.user}>
                        <Link
                          to={`/users/${contestUser.userId}`}
                          className={
                            contestUser.trophyInfo
                              ? cx(
                                  contestUser.trophyInfo.trophyType.toLowerCase()
                                )
                              : null
                          }
                        >
                          <div className={styles.item}>
                            <div className={styles.imageContainer}>
                                <img
                                  src={
                                    contestUser?.profileImage
                                      ? contestUser?.profileImage
                                      : require("images/noimage.png")
                                  }
                                  alt="proflie"
                                  className={styles.image}
                                  onError={(e) =>
                                    (e.target.src = require("images/noimage.png"))
                                  }
                                />
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className={styles.itemInfo}>
                      <div
                        className={cx(styles.nickname, {
                          me: props.loginUserId === contestUser.userId
                        })}
                      >
                        {contestUser.nickName}
                      </div>
                      <div className={styles.twitter}>
                        {contestUser.twitterId ? (
                          <a
                            href={`https://twitter.com/${contestUser.twitterId}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={styles.twitterURL}
                          >
                            <div className={styles.twitterId}>
                              <TiSocialTwitter
                                className={styles.twitterBird}
                              />
                              <span className={styles.twitterText}>
                                {contestUser.twitterId}
                              </span>
                            </div>
                          </a>
                        ) : null}
                      </div>
                      {/*
                      <div className={styles.statusContainer}>
                        <div className={styles.statusIconContainer}>
                          <div className={styles.iconItem}>
                            <IoCheckmarkCircle
                              className={cx(styles.checkinIcon, {
                                active: contestUser.checkin
                              })}
                            />
                          </div>
                          <div className={styles.iconItem}>
                            <IoCheckmark
                              className={cx(styles.confirmIcon, {
                                active: contestUser.status === "ALLOW"
                              })}
                            />
                          </div>
                        </div>
                      </div>
                      */}
                    </div>
                  </div>
                </label>
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className={styles.empty}>
            <span>{context.t("emptyData")}</span>
          </div>
        )}
      </div>
    </div>
  </div>
);

ContestUserList.propTypes = {
  isSignedIn: PropTypes.bool.isRequired,
  deleteMode: PropTypes.bool.isRequired,
  deleteList: PropTypes.array.isRequired
};

ContestUserList.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestUserList;
