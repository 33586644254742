import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { TiAttachment } from "react-icons/ti";
import Dropzone from "react-dropzone";
import Button from "components/lib/Button";

const ContestRegister = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.info}>
      <form className={styles.form} method="post" onSubmit={props.handleSubmit}>
        <div className={styles.title}>{context.t("createContest")}</div>
        <div className={styles.inputContents}>
          <span className={styles.label}>
            {context.t("selectContestTemplate")}
          </span>
          <select
            name="template"
            defaultValue={""}
            onChange={props.handleInputChange}
            className={styles.select}
          >
            {props.templateSelectList.map((item, index) => (
              <option key={index} value={item.value}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.profileImageContainer}>
          <label className={styles.imageLabel}>
            {props.imageUrl ? (
              <img
                className={styles.image}
                src={props.imageUrl}
                alt={context.t("preview")}
              />
            ) : (
              <div className={styles.chooseCoverImage}>
                {context.t("ChooseCoverImage")}
              </div>
            )}
            <input
              type="file"
              className={styles.imageInput}
              accept="image/png, image/jpeg"
              onChange={props.handleFileChange}
              onClick={(event) => (event.target.value = "")}
            />
          </label>
          <div className={styles.inputContents}>
            <Button
              style={{ width: "100%", marginTop: 10 }}
              className={styles.contestImageResetButton}
              text={context.t("resetImageButton")}
              onClick={props.handleImageReset}
            />
          </div>
        </div>
        <div className={styles.inputContents}>
          {props.gameList.length ? (
            <div>
              <span className={styles.label}>
                {context.t("chooseGameTitle")}
              </span>
              <select
                name="gameTitle"
                value={props.gameTitle}
                onChange={props.handleInputChange}
                className={styles.select}
              >
                {props.gameList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.gameName}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("decideContestName")}</span>
          <input
            type="text"
            name="contestName"
            placeholder={context.t("contest")}
            className={styles.textInput}
            value={props.name}
            onChange={props.handleInputChange}
            autoComplete="off"
            maxLength="256"
          />
        </div>
        <div className={styles.dateAndTimeContentsWrap}>
          <span className={styles.label}>{context.t("entryPeriod")}</span>
          <div className={styles.dateAndTimeContents}>
            <div className={styles.inputContents}>
              <input
                type="datetime-local"
                name="entryStart"
                className={styles.textInput}
                value={props.entryStart}
                onChange={props.handleInputChange}
                autoComplete="off"
              />
            </div>
            <span className={styles.wavyLine}>〜</span>
            <div className={styles.inputContents}>
              <input
                type="datetime-local"
                name="entryEnd"
                className={styles.textInput}
                value={props.entryEnd}
                onChange={props.handleInputChange}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className={styles.dateAndTimeContentsWrap}>
          <span className={styles.label}>{context.t("contestDuration")}</span>
          <div className={styles.dateAndTimeContents}>
            <div className={styles.inputContents}>
              <input
                type="datetime-local"
                name="contestStart"
                className={styles.textInput}
                value={props.contestStart}
                onChange={props.handleInputChange}
                autoComplete="off"
              />
            </div>
            <span className={styles.wavyLine}>〜</span>
            <div className={styles.inputContents}>
              <input
                type="datetime-local"
                name="contestEnd"
                className={styles.textInput}
                value={props.contestEnd}
                onChange={props.handleInputChange}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
        <div className={styles.inputContents}>
          <div className={styles.checkBoxInput}>
            <input
              id="checkinCheckbox"
              name="isCheckin"
              type="checkbox"
              onChange={props.handleCheck}
              checked={props.isCheckin}
            />
            <label className={styles.checkBoxText} htmlFor={"checkinCheckbox"}>
              {context.t("enableContestCheckin")}
            </label>
          </div>
        </div>
        {props.isCheckin ? (
          <div className={styles.dateAndTimeContentsWrap}>
            <span className={styles.label}>{context.t("checkinDuration")}</span>
            <div className={styles.dateAndTimeContents}>
              <div className={styles.inputContents}>
                <input
                  type="datetime-local"
                  name="checkinStart"
                  className={styles.textInput}
                  value={props.checkinStart}
                  onChange={props.handleInputChange}
                  autoComplete="off"
                />
              </div>
              <span className={styles.wavyLine}>〜</span>
              <div className={styles.inputContents}>
                <input
                  type="datetime-local"
                  name="checkinEnd"
                  className={styles.textInput}
                  value={props.checkinEnd}
                  onChange={props.handleInputChange}
                  autoComplete="off"
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={styles.inputContents}>
          <div className={styles.checkBoxInput}>
            <input
              id="passwordCheckbox"
              name="isEntryPassword"
              type="checkbox"
              onChange={props.handleCheck}
              checked={props.isEntryPassword}
            />
            <label className={styles.checkBoxText} htmlFor={"passwordCheckbox"}>
              {context.t("enableEntryPassword")}
            </label>
          </div>
          {props.isEntryPassword ? (
            <input
              name="entryPassword"
              value={props.entryPassword}
              className={styles.textInput}
              onChange={props.handleInputChange}
              autoComplete="off"
              maxLength="16"
            />
          ) : null}
        </div>
        <div className={styles.inputContents}>
          {props.contestTypeList.length ? (
            <div>
              <span className={styles.label}>
                {context.t("chooseContestType")}
              </span>
              <select
                name="contestType"
                value={props.contestType}
                onChange={props.handleInputChange}
                className={styles.select}
              >
                {props.contestTypeList.map((item, index) => (
                  <option key={index} value={item.value}>
                    {context.t(item.text)}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className={styles.inputContents}>
          {props.participantTypeList.length ? (
            <div>
              <span className={styles.label}>
                {context.t("chooseParticipantType")}
              </span>
              <select
                name="participantType"
                value={props.participantType}
                onChange={props.handleInputChange}
                className={styles.select}
              >
                {props.participantTypeList.map((item, index) =>
                  item.contestType.includes(props.contestType) ? (
                    <option key={index} value={item.value}>
                      {context.t(item.text)}
                    </option>
                  ) : null
                )}
              </select>
            </div>
          ) : (
            <div></div>
          )}
        </div>
        {props.participantType === "MULTI" ? (
          <div className={styles.inputContents}>
            <span className={styles.label}>
              {context.t("MinimumNumberOfTeams")}
            </span>
            <input
              type="number"
              name="teamSize"
              min="2"
              max="99"
              pattern="\d*"
              className={styles.textInput}
              value={props.teamSize}
              onChange={props.handleInputChange}
              autoComplete="off"
            />
          </div>
        ) : null}
        <div className={styles.inputContents}>
          <span className={styles.label}>
            {props.participantType === "SOLO"
              ? context.t("chooseMaximumNumberOfParticipants")
              : context.t("chooseMaximumNumberOfTeams")}
          </span>
          <input
            type="number"
            name="entryMaxCount"
            min="2"
            max="256"
            pattern="\d*"
            className={styles.textInput}
            value={props.entryMaxCount}
            onChange={props.handleInputentryMaxCountChange}
            autoComplete="off"
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("inputDiscordUrl")}</span>
          <input
            type="url"
            name="discordUrl"
            className={styles.textInput}
            value={props.discordUrl}
            onChange={props.handleInputChange}
            autoComplete="off"
            maxLength="256"
            placeholder={context.t("discordUrl")}
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("inputIframeTag")}</span>
          <textarea
            type="text"
            row="5"
            name="iframeSrc"
            value={props.iframeSrc}
            placeholder={context.t("contestIframeVideo")}
            className={styles.iframeTag}
            onChange={props.handleInputChange}
            autoComplete="off"
            maxLength="512"
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("prizeIfAny")}</span>
          <textarea
            name="award"
            placeholder={context.t("award")}
            className={styles.award}
            value={props.award}
            onChange={props.handleInputChange}
            autoComplete="off"
            maxLength="256"
          />
        </div>
        <div className={styles.inputContents}>
          <span className={styles.label}>{context.t("inputContestRules")}</span>
          <Dropzone onDrop={props.handleDragDrop}>
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <textarea
                    name="rule"
                    ref={props.myRef}
                    value={props.rule}
                    placeholder={context.t("contestRule")}
                    className={styles.rule}
                    onChange={props.handleInputChange}
                    autoComplete="off"
                    maxLength="4096"
                  />
                </div>
              </section>
            )}
          </Dropzone>
          <div className={styles.attach}>
            <label className={styles.imageLabel}>
              <span
                className={styles.attachButton}
                onClick={props.handleChangeInputFile}
              >
                <TiAttachment className={styles.icon} size="20" />
                <span>{context.t("attachImage")}</span>
              </span>
              <input
                type="file"
                name="image"
                className={styles.imageInput}
                accept="image/png, image/jpeg, image/gif"
                onChange={props.handleChangeInputFile}
                onClick={props.handleClickInputFile}
              />
            </label>
          </div>
        </div>
        {props.contestType === "BATTLEROYAL" ? (
          <div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputMatchCount")}
              </span>
              <select
                name="matchCount"
                className={styles.textInput}
                onChange={props.handleInputChange}
                value={props.matchCount}
              >
                {props.matchCountList.map((val) => (
                  <option key={val} value={val}>
                    {val}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputRankPoint")}
              </span>
              <div
                className={styles.rankPointTable}
                style={{ height: props.getRankPointTableHeight() + "px" }}
              >
                {props.rankPoint.length
                  ? props.rankPoint.map((item, index) => (
                      <div className={styles.item} key={index}>
                        <span className={styles.rank}>
                          {context.t("rankText", { value: item.rank })}
                        </span>
                        <span className={styles.point}>
                          <input
                            type="number"
                            name="rankPoint"
                            className={styles.inputPoint}
                            value={item.point}
                            onChange={props.handleInputRankPointChange.bind(
                              this,
                              index
                            )}
                            autoComplete="off"
                          />
                        </span>
                      </div>
                    ))
                  : null}
              </div>
            </div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputKillPoint")}
              </span>
              <input
                type="number"
                name="killPoint"
                value={props.killPoint.point}
                className={styles.textInput}
                onChange={props.handleInputKillPointChange}
                autoComplete="off"
              />
            </div>
            <div className={styles.inputContents}>
              <span className={styles.label}>
                {context.t("inputKillPointMax")}
              </span>
              <input
                type="number"
                name="killPointMax"
                value={props.killPoint.max}
                className={styles.textInput}
                onChange={props.handleInputKillPointChange}
                autoComplete="off"
              />
            </div>
          </div>
        ) : null}
        <div className={styles.inputContents}>
          <div className={styles.recommendNotification}>
            <input
              id="recommendCheckbox"
              name="isRecommend"
              type="checkbox"
              onChange={props.handleCheck}
              checked={props.isRecommend}
            />
            <label
              className={styles.checkBoxText}
              htmlFor={"recommendCheckbox"}
            >
              {context.t("contestRecommend")}
            </label>
          </div>
          <div className={styles.recommendNotificationTip}>
            {context.t("contestRecommendTip", {
              game: props.selectedGameName
            })}
          </div>
        </div>
        <div className={styles.inputContents}>
          <div>
            <Button
              style={{ width: "100%", marginTop: 10 }}
              className={styles.contestRegistrationButton}
              text={context.t("createContest")}
              onClick={props.handleSubmit}
              disabled={
                !props.gameTitle ||
                !props.name ||
                !props.entryStart ||
                !props.entryEnd ||
                !props.contestStart ||
                !props.contestEnd ||
                (props.participantType !== "SOLO" && !props.teamSize) ||
                !props.entryMaxCount ||
                props.isLoading
              }
            />
          </div>
        </div>
      </form>
    </div>
  </div>
);

ContestRegister.propTypes = {
  gameList: PropTypes.array.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleFileChange: PropTypes.func.isRequired,
  discordUrl: PropTypes.string.isRequired
};

ContestRegister.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestRegister;
