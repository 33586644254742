import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { isSignedIn },
    contest: { contestList = [] },
    game: { gameList = [] }
  } = state;

  return {
    isSignedIn,
    contestList,
    gameList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getContestList: (query) => {
      return dispatch(contestActions.getContestList(query));
    },
    getOperatedContestList: (query) => {
      return dispatch(contestActions.getOperatedContestList(query));
    },
    getEntryContestList: (query) => {
      return dispatch(contestActions.getEntryContestList(query));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
