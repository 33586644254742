import React from "react";
import { Box } from "@material-ui/core";
import IconButton from "components/lib/IconButton";
import { ImTwitter, ImFacebook, ImLink } from "react-icons/im";
import { MdClose } from "react-icons/md";
import Modal from "components/lib/Modal";
import styled from "styled-components";
import styles from "./styles.scss";

const StyledBox = styled(Box)`
  min-width: 100px;
  min-height: 100px;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const ShareModal = ({
  title,
  isOpen,
  onClose,
  onShareTwitter,
  onShareFacebook,
  onShareLink
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} >
      <StyledBox>
        <div className={styles.header}>
          <span className={styles.title}>{title}</span>
          <span className={styles.closeButton} onClick={onClose} >
            <MdClose color="#fff" size={22} />
          </span>
        </div>
        <div className={styles.contents}>
          <span className={styles.item}>
            <IconButton color="#38a1f1" onClick={onShareTwitter}>
              <ImTwitter color="#fff" />
            </IconButton>
            <div className={styles.text}>twitter</div>
          </span>
          <span className={styles.item}>
            <IconButton color="#2c77f2" onClick={onShareFacebook}>
              <ImFacebook color="#fff" />
            </IconButton>
            <div className={styles.text}>facebook</div>
          </span>
          <span className={styles.item}>
            <IconButton color="#f3f4f5" onClick={onShareLink}>
              <ImLink color="#000" />
            </IconButton>
            <div className={styles.text}>link</div>
          </span>
        </div>
      </StyledBox>
    </Modal>
  );
};

export default ShareModal;