import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import Loading from "components/lib/Loading";
import classNames from "classnames/bind";
import { TiSocialTwitter } from "react-icons/ti";
import { FaUserCheck, FaRetweet } from "react-icons/fa";
import { IoCheckmarkCircle, IoCheckmark } from "react-icons/io5";
import Button from "components/lib/Button";

const cx = classNames.bind(styles);

const ContestEntryEdit = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.list}>
      <div className={styles.entryStatus}>
        <span>
          {props.resource === "team"
            ? context.t("attendedTeam", { value: props.contestTeams.length })
            : null}
          {props.resource === "user"
            ? context.t("attendedUser", { value: props.contestUsers.length })
            : null}
        </span>
      </div>
      {props.loading ? (
        <Loading />
      ) : (
        <div className={styles.listContainer}>
          {props.resource === "team" ? (
            props.contestTeams.length ? (
              <React.Fragment>
                <div className={styles.listHeader}>
                  <input
                    type="checkbox"
                    id="checkbox_total"
                    className={styles.checkbox}
                    onChange={props.handleTotalCheck}
                  />
                  <div className={styles.teamHeader}>{context.t("team")}</div>
                  <div className={styles.twitterHeader}>
                    {context.t("teamTwitterID")}
                  </div>
                </div>
                {props.contestTeams.map((contestTeam, index) => (
                  <label
                    className={styles.contestLabel}
                    htmlFor={`checkbox_${contestTeam.id}`}
                    key={index}
                  >
                    <div className={styles.contestTeam}>
                      <div className={styles.participantContainer}>
                        <input
                          type="checkbox"
                          id={`checkbox_${contestTeam.id}`}
                          className={styles.checkbox}
                          value={contestTeam.team.id}
                          onChange={props.handleCheck}
                          checked={
                            props.entryList.find(
                              (el) =>
                                el.id === contestTeam.team.id && el.checked
                            )
                              ? true
                              : false
                          }
                        />
                        <div className={styles.team}>
                          <div className={styles.item}>
                            <div className={styles.imageContainer}>
                              <img
                                src={
                                  contestTeam.team.imageURL
                                    ? contestTeam.team.imageURL
                                    : require("images/noimage.png")
                                }
                                alt="proflie"
                                className={styles.image}
                                onError={(e) =>
                                  (e.target.src = require("images/noimage.png"))
                                }
                              />
                            </div>
                            <div className={styles.teamName}>
                              {contestTeam.team.name} (
                              {contestTeam.teamUserCount})
                            </div>
                          </div>
                        </div>
                        <div className={styles.twitter}>
                          {contestTeam.twitter ? (
                            <div className={styles.twitterId}>
                              <TiSocialTwitter className={styles.twitterBird} />
                              <span className={styles.twitterText}>
                                {contestTeam.twitter}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className={styles.statusContainer}>
                        <div className={styles.statusIconContainer}>
                          <div className={styles.iconItem}>
                            <IoCheckmarkCircle
                              className={cx(styles.checkinIcon, {
                                active: contestTeam.checkin
                              })}
                            />
                          </div>
                          <div className={styles.iconItem}>
                            <FaUserCheck
                              className={cx(styles.followingIcon, {
                                active: contestTeam.following
                              })}
                            />
                          </div>
                          <div className={styles.iconItem}>
                            <FaRetweet
                              className={cx(styles.retweetIcon, {
                                active: contestTeam.retweet
                              })}
                            />
                          </div>
                          <div className={styles.iconItem}>
                            <IoCheckmark
                              className={cx(styles.confirmIcon, {
                                active: contestTeam.status === "ALLOW"
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                ))}
              </React.Fragment>
            ) : (
              <div className={styles.empty}>
                <span>{context.t("emptyData")}</span>
              </div>
            )
          ) : null}
          {props.resource === "user" ? (
            props.contestUsers.length ? (
              <React.Fragment>
                <div className={styles.listHeader}>
                  <input
                    type="checkbox"
                    id="checkbox_total"
                    className={styles.checkbox}
                    onChange={props.handleTotalCheck}
                  />
                  <div className={styles.userHeader}>
                    {context.t("nickname")}
                  </div>
                  <div className={styles.userTwitterHeader}>
                    {context.t("twitterID")}
                  </div>
                </div>
                {props.contestUsers.map((contestUser, index) => (
                  <label
                    className={styles.contestLabel}
                    htmlFor={`checkbox_${contestUser.id}`}
                    key={index}
                  >
                    <div className={styles.contestUser}>
                      <div className={styles.participantContainer}>
                        <input
                          type="checkbox"
                          id={`checkbox_${contestUser.id}`}
                          className={styles.checkbox}
                          value={contestUser.id}
                          onChange={props.handleCheck}
                          checked={
                            props.entryList.find(
                              (el) =>
                                el.id === contestUser.id && el.checked
                            )
                              ? true
                              : false
                          }
                        />
                        <div className={styles.team}>
                          <div className={styles.item}>
                            <div className={styles.imageContainer}>
                              <img
                                src={
                                  contestUser.profileImage
                                    ? contestUser.profileImage
                                    : require("images/noimage.png")
                                }
                                alt="proflie"
                                className={styles.image}
                                onError={(e) =>
                                  (e.target.src = require("images/noimage.png"))
                                }
                              />
                            </div>
                            <div className={styles.teamname}>
                              {contestUser.nickName}
                            </div>
                          </div>
                        </div>
                        <div className={styles.twitter}>
                          {contestUser.twitterId ? (
                            <div className={styles.twitterId}>
                              <TiSocialTwitter className={styles.twitterBird} />
                              <span className={styles.twitterText}>
                                {contestUser.twitterId}
                              </span>
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className={styles.statusContainer}>
                        <div className={styles.statusIconContainer}>
                          <div className={styles.iconItem}>
                            <IoCheckmarkCircle
                              className={cx(styles.checkinIcon, {
                                active: contestUser.checkin
                              })}
                            />
                          </div>
                          <div className={styles.iconItem}>
                            <FaUserCheck
                              className={cx(styles.followingIcon, {
                                active: contestUser.following
                              })}
                            />
                          </div>
                          <div className={styles.iconItem}>
                            <FaRetweet
                              className={cx(styles.retweetIcon, {
                                active: contestUser.retweet
                              })}
                            />
                          </div>
                          <div className={styles.iconItem}>
                            <IoCheckmark
                              className={cx(styles.confirmIcon, {
                                active: contestUser.status === "ALLOW"
                              })}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                ))}
              </React.Fragment>
            ) : (
              <div className={styles.empty}>
                <span>{context.t("emptyData")}</span>
              </div>
            )
          ) : null}
        </div>
      )}
    </div>
    <div
      className={cx(styles.selectedMessage, {
        alert:
          props.entryList.filter((el) => el.checked).length >
          props.contestInfo.entryMaxCount
      })}
    >
      {context.t("selectedEntryItem", {
        value: props.entryList.filter((el) => el.checked).length
      })}
    </div>
    <div className={styles.buttonLayout}>
      <Button
        style={{ margin: 5 }}
        className={styles.cancelButton}
        text={context.t("back")}
        onClick={props.handleCancel}
      />
      <Button
        style={{ margin: 5 }}
        className={styles.confirmButton}
        text={context.t("entryConfirm")}
        onClick={props.handleConfirm}
        disabled={props.confirmLoading}
      />
    </div>
  </div>
);

ContestEntryEdit.propTypes = {};

ContestEntryEdit.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestEntryEdit;
