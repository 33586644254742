import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { FaCamera } from "react-icons/fa";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SelectWinner from "components/SelectWinner";
import Button from "components/lib/Button";

const cx = classNames.bind(styles);

const CommonUploadMatchResult = (props, context) => (
  <div className={styles.contents}>
    {props.matchInfo ? (
      <div>
        <div className={styles.inputContents}>
          <div className={styles.label}>
            {props.isUnearned
              ? props.contestInfo.participantType === "SOLO"
                ? context.t("chooseUnearnedRoundWinUser")
                : context.t("chooseUnearnedRoundWinTeam")
              : props.contestInfo.participantType === "SOLO"
              ? context.t("chooseRoundWinUser")
              : context.t("chooseRoundWinTeam")}
          </div>
          <SelectWinner winType="round" matchIndex={props.matchInfo?.round} {...props} />
        </div>
        <div className={cx(styles.inputContents, { inactive: !props.winner })}>
          {!props.isUnearned &&
            props.matchList.map((item, index) => (
              <div key={index}>
                <p className={styles.label}>
                  {context.t("numberth", { val: index + 1 })}
                </p>
                <p className={styles.labelDetail}>
                  {props.isUnearned
                    ? props.contestInfo.participantType === "SOLO"
                      ? context.t("chooseUnearnedWinUser")
                      : context.t("chooseUnearnedWinTeam")
                    : props.contestInfo.participantType === "SOLO"
                    ? context.t("chooseWinUser")
                    : context.t("chooseWinTeam")}
                </p>
                <SelectWinner
                  key={index}
                  matchIndex={index}
                  winType="match"
                  {...props}
                />
                <div className={styles.imageContainer}>
                  <label
                    htmlFor={"uploadImage_" + index}
                    className={styles.imageLabel}
                  >
                    {item.images.length ? (
                      item.images.length > 1 ? (
                        <Carousel className={styles.slide} showThumbs={false}>
                          {item.images.map((img, i) => (
                            <img
                              key={i}
                              className={styles.image}
                              src={img.imageUrl}
                              alt={context.t("preview")}
                            />
                          ))}
                        </Carousel>
                      ) : (
                        item.images.map((img, i) => (
                          <img
                            key={i}
                            className={styles.image}
                            src={img.imageUrl}
                            alt={context.t("preview")}
                          />
                        ))
                      )
                    ) : (
                      <div className={styles.chooseResultImage}>
                        <FaCamera fontSize="22px" className={styles.icon} />
                        <div>{context.t("chooseResultImage")}</div>
                      </div>
                    )}
                    <input
                      type="file"
                      id={"uploadImage_" + index}
                      name="contestImage"
                      className={styles.imageInput}
                      accept="image/png, image/jpeg"
                      onChange={props.handleFileChange.bind(this, index)}
                      onClick={(event) => (event.target.value = "")}
                    />
                  </label>
                </div>
              </div>
            ))}
          <div className={styles.buttonLayout}>
            <Button
              style={{ width: "100%" }}
              className={styles.saveButton}
              text={context.t("save")}
              onClick={props.handleSubmit}
              disabled={props.isLoading}
            />
          </div>
        </div>
        <div className={styles.isUnearned} onClick={props.handleToggleType}>
          {props.isUnearned ? (
            <span>{context.t("reportWinInfo")}</span>
          ) : (
            <span>{context.t("reportUnearnedWinInfo")}</span>
          )}
        </div>
      </div>
    ) : null}
  </div>
);

CommonUploadMatchResult.propTypes = {
  handleSelectWinner: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

CommonUploadMatchResult.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CommonUploadMatchResult;
