import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Loading from "components/lib/Loading";
import BackgroundImage from "components/lib/BackgroundImage";
import classNames from "classnames/bind";
import { MdPerson, MdGroup } from "react-icons/md";
import {
  IoTrophy,
  IoStar,
  IoCheckmark,
  IoTrophyOutline,
  IoStarOutline,
  IoCheckmarkOutline
} from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";

const cx = classNames.bind(styles);

const ContestList = (props, context) => (
  <div className={styles.contents}>
    <div
      className={cx(styles.filter, {
        disabled: props.loading
      })}
    >
      <div className={styles.statusFilter}>
        <div
          className={cx(styles.statusAll, {
            active: props.query.status === "all",
            disabled: props.loading
          })}
          onClick={() =>
            !props.filterDisabled ? props.switchStatusFilter("all") : null
          }
        >
          {props.query.status === "all" ? (
            <IoTrophy fontSize="20px" className={styles.icon} />
          ) : (
            <IoTrophyOutline fontSize="20px" className={styles.icon} />
          )}
          <span>{context.t("contestList")}</span>
        </div>
        {props.isSignedIn ? (
          <>
            <div
              className={cx(styles.statusOperated, {
                active: props.query.status === "operated",
                disabled: props.loading
              })}
              onClick={() =>
                !props.filterDisabled ? props.switchStatusFilter("operated") : null
              }
            >
              {props.query.status === "operated" ? (
                <IoStar fontSize="20px" className={styles.icon} />
              ) : (
                <IoStarOutline fontSize="20px" className={styles.icon} />
              )}
              <span>{context.t("operatedContest")}</span>
            </div>
            <div
              className={cx(styles.statusParticipated, {
                active: props.query.status === "participated",
                disabled: props.loading
              })}
              onClick={() =>
                !props.filterDisabled ? props.switchStatusFilter("participated") : null
              }
            >
              {props.query.status === "participated" ? (
                <IoCheckmark fontSize="20px" className={styles.icon} />
              ) : (
                <IoCheckmarkOutline fontSize="20px" className={styles.icon} />
              )}
              <span>{context.t("participatedContest")}</span>
            </div>
          </>
        ) : null}
      </div>
      <div
        className={cx(styles.gameFilter, {
          disabled: props.loading
        })}
      >
        <select
          name="gameId"
          value={props.game}
          onChange={props.handleSelectChange}
        >
          <option value={""}>{context.t("selectGame")}</option>
          {props.gameList.map((item, index) => (
            <option key={index} value={item.id}>
              {item.gameName}
            </option>
          ))}
        </select>
      </div>
    </div>
    <div className={styles.list}>
      {props.loading ? (
        <Loading />
      ) : props.contestList?.length ? (
        <InfiniteScroll
          dataLength={props.contestList.length}
          next={props.loadMore}
          hasMore={props.hasMore}
          loader={<Loading />}
        >
          {props.contestList.map((contest) => (
            <Link to={`/contests/${contest.id}`} key={contest.id}>
              <div className={styles.item}>
                <div className={styles.hero}>
                    <BackgroundImage
                      src={contest.imageUrl}
                      alt="hero"
                      className={styles.image}
                    />
                </div>
                <div className={styles.body}>
                  <div className={styles.title}>{contest.name}</div>
                  <div className={styles.contestInfo}>
                    <div className={styles.baseline}>
                      {contest.contestDurationText}
                    </div>
                    <div className={styles.baseline}>
                      {contest.gameName ? contest.gameName : "-"}
                    </div>
                    {/* TODO:取得可能になったら正しく表示 */}
                    <div className={styles.baseline}>
                      {context.t("organizer")}:{" "}
                      {contest.createdBy ? contest.createdBy : "Asobi Club"}
                    </div>
                  </div>
                </div>
                <div className={styles.footer}>
                  <span className={styles.entryInfo}>
                    {contest.participantType === "SOLO" ? (
                      <MdPerson fontSize="16px" className={styles.icon} />
                    ) : (
                      <MdGroup fontSize="16px" className={styles.icon} />
                    )}
                    <div className={styles.participants}>
                      <span>
                        {contest.participantType === "SOLO"
                          ? context.t("userLength", {
                              value: contest.entryCount || 0
                            })
                          : context.t("teamLength", {
                              value: contest.entryCount || 0
                            })}
                      </span>
                    </div>
                  </span>
                  <span className={styles.statusInfo}>
                    <div
                      className={cx(
                        styles.participantType,
                        contest.participantType
                      )}
                    >
                      <span>
                        {contest.participantType === "SOLO"
                          ? context.t("singleMatch")
                          : null}
                        {contest.participantType === "MULTI"
                          ? context.t("teamMatch")
                          : null}
                      </span>
                    </div>
                    <div className={cx(styles.status, contest.status)}>
                      <span>{context.t(contest.status)}</span>
                    </div>
                  </span>
                </div>
              </div>
            </Link>
          ))}
        </InfiniteScroll>
      ) : (
        <div className={styles.empty}>{context.t("emptyData")}</div>
      )}
    </div>
  </div>
);

ContestList.propTypes = {
  contestList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

ContestList.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestList;
