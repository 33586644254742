import React, { Component } from "react";
import ContestTournament from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    loading: true,
    isOpen: false,
    selected: {}
  };

  componentDidMount() {
    const {
      tournamentInfo: { id },
      subscribeTournamentMatchList
    } = this.props;
    
    // トーナメント対戦をリアルタイムリスナーで取得
    subscribeTournamentMatchList(id);

    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <ContestTournament
        {...this.props}
        {...this.state}
        handleMatchInfo={this._handleMatchInfo}
        handleClose={this._handleClose}
      />
    );
  }

  // 対戦詳細表示
  _handleMatchInfo = (matchInfo = {}) => {
    // 対戦詳細 Modal 表示
    const {
      contestInfo: { id: contestId },
      tournamentInfo: { id: tournamentId },
      //history
    } = this.props;
    
    if (contestId && tournamentId && matchInfo.id) {
      this.setState({
        isOpen: true,
        selected: {
          contestId,
          tournamentId,
          matchId: matchInfo.id
        }
      });
      //history.push(
      //  `/contests/${contestId}/tournament/${tournamentId}/match/${matchInfo.id}`
      //);
    }
  };

  _handleClose = () => {
    this.setState({
      isOpen: false,
      selected: {},
    });
  }

  static propTypes = {
    subscribeTournamentMatchList: PropTypes.func.isRequired
  };
}

export default Container;
