import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as notificationActions } from "redux/notification";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {} },
    notification: { notificationList = [], hasMore }
  } = state;

  return {
    auth,
    notificationList,
    hasMore
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getNotificationList: (userId) => {
      return dispatch(notificationActions.getNotificationList(userId));
    },
    readNotification: (id) => {
      dispatch(notificationActions.readNotification(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
