import React from "react";
import { Dialog, Slide } from "@material-ui/core";
import styled from "styled-components";


const StyledModal = styled(Dialog)`
  & .MuiPaper-root {
    background-color: #1c1c1c;
    color: #fff;
    border-radius: 10px;
    max-width: initial;
  }
`;

// アニメーション
const Transition = React.forwardRef(function Transition(props, ref){
  return <Slide direction="down" ref={ref} {...props} />;
});

const CommonModal = ({isOpen, onClose, children}) => (
  <StyledModal
    open={isOpen}
    TransitionComponent={Transition}
    onClose={onClose}
  >
    {children} 
  </StyledModal>
);

export default CommonModal;