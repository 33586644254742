import React from "react";
import styles from "./styles.scss";
import HeaderMenu from "components/lib/HeaderMenu";
import Logo from "components/Logo";
import PropTypes from "prop-types";

const Header = (props) => (
  <header>
    <div className={styles.headerContainer}>
      <div className={styles.headerWrapper}>
        <Logo />
      </div>
      <HeaderMenu {...props} />
    </div>
  </header>
);

Header.propTypes = {
  isSignedIn: PropTypes.bool.isRequired
};

export default Header;
