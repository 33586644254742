import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Textarea from "react-validation/build/textarea";
//import Button from "react-validation/build/button";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { required, length } from "components/lib/Validator";
import { MdAddAPhoto, MdClose } from "react-icons/md";
import Button from "components/lib/Button";

const UserProfileEdit = (props, context) => (
  <React.Fragment>
    <Form className={styles.main} method="put" onSubmit={props.handleSubmit}>
      <div className={styles.header}>
        <div className={styles.backgroundImageContainer}>
          {props.cropCompleted ? (
            <img
              className={styles.backgroundImage}
              src={props.croppedImageUrl}
              alt="crop.img"
              onError={(e) =>
                (e.target.src = require("images/background_noimage.png"))
              }
            />
          ) : (
            <img
              className={styles.backgroundImage}
              src={
                props.userInfo.backgroundImageUrl ||
                require("images/background_noimage.png")
              }
              alt={context.t("preview")}
              onError={(e) =>
                (e.target.src = require("images/background_noimage.png"))
              }
            />
          )}
          <div className={styles.icon}>
            <label className={styles.MdAddAPhotoIcon}>
              <MdAddAPhoto />
              <input
                type="file"
                id="backgroundSelect"
                className={styles.imageInput}
                accept="image/png, image/jpeg"
                onChange={props.onSelectBackgroundImage}
              />
            </label>
            <MdClose
              className={styles.MdCloseIcon}
              onClick={props.onbackgroundImageUrlDelete}
            />
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.imageContainer}>
          <label className={styles.imageLabel}>
            <img
              className={styles.image}
              src={props.userInfo.imageUrl || require("images/noimage.png")}
              alt={context.t("preview")}
              onError={(e) => (e.target.src = require("images/noimage.png"))}
            />
            <input
              type="file"
              className={styles.imageInput}
              name="imageUrl"
              accept="image/png, image/jpeg"
              onChange={props.handleFileChange}
              onClick={(event) => (event.target.value = "")} // valueの初期化
            />
          </label>
        </div>

        <div className={styles.menu}>
          <Button className={styles.closeButton} text={context.t("closeEditor")} onClick={props.handleCloseButton} />
        </div>

        <ReactModal
          isOpen={props.modal.isOpen}
          ariaHideApp={false}
          style={props.modalStyle}
          className={styles.cropModal}
        >
          <React.Fragment>
            <ReactCrop
              src={props.backgroundImageSrc}
              crop={props.crop}
              ruleOfThirds
              onChange={props.onCropChange}
              onImageLoaded={props.onImageLoaded}
              onComplete={props.onCropComplete}
            />
            <p className={styles.cutOut} onClick={props.onCropCompleted}>
              {context.t("imageCut")}
            </p>
            <p className={styles.back} onClick={props.onModalClose}>
              {context.t("back")}
            </p>
          </React.Fragment>
        </ReactModal>

        <div className={styles.basicInfo}>
          <p>{context.t("nickname")}</p>
          <Input
            type="text"
            name="nickname"
            value={props.userInfo.nickname || ""}
            onChange={props.handleInputChange}
            className={styles.inputText}
            maxLength="30"
            validations={[required, length]}
          />

          <p>{context.t("bio")}</p>
          <Textarea
            name="bio"
            value={props.userInfo.bio || ""}
            onChange={props.handleInputChange}
            className={styles.bioText}
            maxLength="512"
            validations={[length]}
          ></Textarea>

          <Button style={{width: "100%"}} text={context.t("saveChange")} onClick={props.handleSubmit} />
        </div>
      </div>
    </Form>

    {/* <UserEditPassword {...props} /> */}
    {/* <GameUserEdit {...props} /> */}

    {/* <ServiceIntegration {...props} /> */}
  </React.Fragment>
);

UserProfileEdit.contextTypes = {
  t: PropTypes.func.isRequired
};

UserProfileEdit.propTypes = {
  userInfo: PropTypes.shape({
    nickname: PropTypes.string,
    bio: PropTypes.string
  }),
  onSelectBackgroundImage: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onCropChange: PropTypes.func.isRequired,
  onbackgroundImageUrlDelete: PropTypes.func.isRequired,
  onImageLoaded: PropTypes.func.isRequired,
  onCropComplete: PropTypes.func.isRequired,
  onCropCompleted: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

export default UserProfileEdit;
