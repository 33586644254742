import React, { Component } from "react";
import Header from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  render() {
    return <Header {...this.props} />;
  }

  static propTypes = {
    isSignedIn: PropTypes.bool.isRequired
  };
}

export default Container;
