import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as socialActions } from "redux/social";
import { actionCreators as userActions } from "redux/user";

const mapStateToProps = (state, ownProps) => {
  const { action } = ownProps;
  return {
    action: action
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    socialOauth: () => {
      return dispatch(socialActions.socialOauth());
    },
    signInWithTwitter: () => {
      return dispatch(userActions.signInWithTwitter());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
