import React from "react";
import Contest from "components/Contest";
import styles from "./styles.scss";

const Home = (props) => (
  <div className={styles.contents}>
    <Contest {...props} />
  </div>
);

export default Home;
