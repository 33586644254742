import { axios } from "lib/axios";
import { firestore, FieldValue } from "lib/firebase";
import moment from "moment";
import { convertFirestoreTime } from "lib/convert";
import { getDocs, collection, query, where } from "firebase/firestore";

let unsubscribeCurrentList,
  unsubscribeMatch,
  unsubscribeChat,
  unsubscribePlayers;

// actions
const SET_GAME_LIST = "SET_GAME_LIST";
const SET_GAME_RANK_LIST = "SET_GAME_RANK_LIST";
const SET_MATCH_INFO = "SET_MATCH_INFO";
const SET_MATCH_PLAYERS = "SET_MATCH_PLAYERS";
const SET_MATCH_CHAT = "SET_MATCH_CHAT";
const CLEAR_MATCH_INFO = "CLAER_MATCH_INFO";
const CLEAR_MATCH_LIST = "CLAER_MATCH_LIST";
const SET_CURRENT_LIST = "SET_CURRENT_LIST";
const SET_HISTORY_LIST = "SET_HISTORY_LIST";
const SET_RECORD_LIST = "SET_RECORD_LIST";

// action creator
function setGameList(gameList) {
  return {
    type: SET_GAME_LIST,
    gameList
  };
}

function setGameRankList(gameRankList) {
  return {
    type: SET_GAME_RANK_LIST,
    gameRankList
  };
}

function setMatchInfo(matchInfo) {
  return {
    type: SET_MATCH_INFO,
    matchInfo
  };
}

function setMatchPlayers(players) {
  return {
    type: SET_MATCH_PLAYERS,
    players
  };
}

function setMatchChat(chat) {
  return {
    type: SET_MATCH_CHAT,
    chat
  };
}

function clearMatchInfo() {
  return {
    type: CLEAR_MATCH_INFO
  };
}

function clearMatchList() {
  return {
    type: CLEAR_MATCH_LIST
  };
}

function setMatchCurrentList(currentList) {
  return {
    type: SET_CURRENT_LIST,
    currentList
  };
}

function setMatchHistoryList(historyList) {
  return {
    type: SET_HISTORY_LIST,
    historyList
  };
}

function setRecordList(recordList) {
  return {
    type: SET_RECORD_LIST,
    recordList
  };
}

// API actions
function getGameList() {
  return async (dispatch, getState) => {
    const q = query(collection(firestore, "games"), where("isEnable", "==", true));
    const querySnapshot = await getDocs(q);
    const gameList = [];
    querySnapshot.forEach((doc) => {
      gameList.push({
        id: doc.id,
        ...doc.data()
      });
    });
    dispatch(setGameList(gameList));
    return gameList;
  };
}

function getGameRankList() {
  return (dispatch, getState) => {
    return axios
      .get("/api/v1/games/ranks/")
      .then((result) => {
        dispatch(setGameRankList(result.data));
        return result.data;
      })
      .catch((err) => console.log(err));
  };
}

// match info 取得
function getMatchInfo(id) {
  return (dispatch, getState) => {
    return firestore
      .collection("match")
      .doc(id)
      .get()
      .then((doc) => {
        const matchInfo = {
          id: doc.id,
          ...doc.data()
        };
        dispatch(setMatchInfo(matchInfo));
        return matchInfo;
      })
      .catch((err) => console.log(err));
  };
}

// match players 取得
function getMatchPlayers(id) {
  return (dispatch, getState) => {
    return firestore
      .collection("match")
      .doc(id)
      .collection("players")
      .get()
      .then((querySnapshot) => {
        const players = [];
        querySnapshot.forEach((doc) => {
          players.push({
            id: doc.id,
            ...doc.data()
          });
        });
        return players;
      })
      .catch((err) => console.log(err));
  };
}

// match room 作成
function createMatch(param) {
  return (dispatch, getState) => {
    return firestore
      .collection("match")
      .add({
        ...param,
        deleteFlag: false,
        created: FieldValue.serverTimestamp(),
        updated: FieldValue.serverTimestamp()
      })
      .then((docRef) => {
        const matchInfo = {
          id: docRef.id,
          ...param
        };
        dispatch(setMatchInfo(matchInfo));
        return docRef;
      })
      .catch((err) => console.log(err));
  };
}

// 参加可能な対戦ルーム取得
function getMatchCurrent(game, contest = 0) {
  return (dispatch, getState) => {
    // リスナーを解除
    if (unsubscribeCurrentList) {
      unsubscribeCurrentList();
    }

    // match リスナー
    unsubscribeCurrentList = firestore
      .collection("match")
      .where("gid", "==", parseInt(game, 10))
      .where("cid", "==", parseInt(contest, 10))
      .where("deleteFlag", "==", false)
      // 7日以内の対戦を取得
      .where("created", ">", new Date(moment().subtract(24 * 7, "hour")))
      .orderBy("created", "desc")
      .onSnapshot((querySnapshot) => {
        const currentList = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const createdText = convertFirestoreTime(data.created);
          // statusが完了以外のデータを取得
          if (data.status < 99) {
            currentList.push({
              id: doc.id,
              ...data,
              createdText
            });
          }
        });
        dispatch(setMatchCurrentList(currentList));
      });
    return;
  };
}

// 対戦履歴一覧取得
function getMatchHistory(game, contest = 0) {
  return async (dispatch, getState) => {
    const querySnapShot = await firestore
      .collection("match")
      .where("gid", "==", parseInt(game, 10))
      .where("cid", "==", parseInt(contest, 10))
      .where("deleteFlag", "==", false)
      .where("status", "==", 99)
      .orderBy("created", "desc")
      .get();

    const historyList = [];
    querySnapShot.forEach((doc) => {
      const data = doc.data();
      const createdText = convertFirestoreTime(data.created);
      historyList.push({
        id: doc.id,
        ...data,
        createdText
      });
    });

    dispatch(setMatchHistoryList(historyList));

    return;
  };
}

// 対戦ルームに参加
function enterMatch(id, param) {
  return async (dispatch, getState) => {
    const match = firestore.collection("match").doc(id);
    const doc = await match.get();
    const matchInfo = {
      id: doc.id,
      ...doc.data()
    };
    const { teams, players, teamInfo } = matchInfo;
    const { tid, uid } = param;

    if (!teams[tid] || !players[uid]) {
      let changedMatchInfo = matchInfo;
      const current = new Date().getTime();
      // ルームに参加Teamが存在しない場合
      if (!teams[tid]) {
        changedMatchInfo = {
          ...changedMatchInfo,
          teams: {
            ...teams,
            [tid]: current
          },
          teamInfo: {
            ...teamInfo,
            [tid]: {
              name: param.teamname,
              image: param.teamImage
            }
          },
          updated: FieldValue.serverTimestamp()
        };
      }
      // ルームに参加Playerが存在しない場合
      if (!players[uid]) {
        changedMatchInfo = {
          ...changedMatchInfo,
          players: {
            ...players,
            [uid]: current
          },
          updated: FieldValue.serverTimestamp()
        };
      }

      // match.players 情報更新
      await match.update(changedMatchInfo);

      dispatch(setMatchInfo(changedMatchInfo));

      //const querySnapshot = await match.collection('players').get()

      // players コレクションに追加
      await match.collection("players").add({
        ...param,
        created: FieldValue.serverTimestamp(),
        updated: FieldValue.serverTimestamp()
      });
    }

    // matchInfo リスナー
    unsubscribeMatch = match.onSnapshot((doc) => {
      const matchInfo = {
        id: doc.id,
        ...doc.data()
      };
      dispatch(setMatchInfo(matchInfo));
    });

    // chat リスナー
    unsubscribeChat = match
      .collection("chat")
      .orderBy("created", "desc")
      .onSnapshot((querySnapshot) => {
        const chat = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          const displayTime = convertFirestoreTime(data.created);
          chat.push({
            id: doc.id,
            ...data,
            displayTime
          });
        });
        dispatch(setMatchChat(chat));
      });

    // players リスナー
    unsubscribePlayers = match
      .collection("players")
      .orderBy("created", "desc")
      .onSnapshot((querySnapshot) => {
        const matchPlayers = [];
        querySnapshot.forEach((doc) => {
          matchPlayers.push({
            id: doc.id,
            ...doc.data()
          });
        });
        dispatch(setMatchPlayers(matchPlayers));
      });
    return;
  };
}

// 対戦ルームから退出
function exitMatch(matchInfo, player) {
  return async (dispatch, getState) => {
    // statusが Readyではない playerの場合
    if (!player.status) {
      const { players, teams, teamInfo } = matchInfo;
      const match = firestore.collection("match").doc(matchInfo.id);

      const querySnapshot = await match.collection("players").get();
      let memberCount = 0;
      querySnapshot.forEach((doc) => {
        if (doc.tid === player.tid) {
          memberCount++;
        }
      });

      // player 削除
      delete players[player.uid];
      await match
        .collection("players")
        .doc(player.id)
        .delete();

      // ルームから誰もいなくなった場合
      if (!Object.keys(players).length) {
        const changedMatchInfo = {
          ...matchInfo,
          deleteFlag: true,
          updated: FieldValue.serverTimestamp()
        };

        // 対戦ルームの論理削除
        await match.update(changedMatchInfo);
      } else {
        // チームメンバーが自分しかいない場合
        if (memberCount < 2) {
          delete teams[player.tid];
          delete teamInfo[player.tid];
        }

        const changedMatchInfo = {
          ...matchInfo,
          players,
          teams,
          teamInfo,
          updated: FieldValue.serverTimestamp()
        };

        // match.players 情報更新
        await match.update(changedMatchInfo);
      }
    }
    // リスナーを解除
    if (unsubscribeMatch) {
      unsubscribeMatch();
    }
    if (unsubscribeChat) {
      unsubscribeChat();
    }
    if (unsubscribePlayers) {
      unsubscribePlayers();
    }
    dispatch(clearMatchInfo());
    return;
  };
}

function exitMatchList() {
  return (dispatch, getState) => {
    // リスナーを解除
    if (unsubscribeCurrentList) {
      unsubscribeCurrentList();
    }

    dispatch(clearMatchList());
    return;
  };
}

function exitMatchResult() {
  return (dispatch, getState) => {
    dispatch(clearMatchList());
    return;
  };
}

// メンバーを招待
function inviteMember(id, tid, members) {
  return (dispatch, getState) => {
    const param = {
      team: tid,
      members: members
    };
    return axios
      .put(`/api/v1/match/${id}/invite/`, param)
      .then((result) => {
        return result.status === 200;
      })
      .catch((err) => console.log(err));
  };
}

// チャットを送信
function sendMessage(matchID, param) {
  return async (dispatch, getState) => {
    await firestore
      .collection("match")
      .doc(matchID)
      .collection("chat")
      .add({
        ...param,
        created: FieldValue.serverTimestamp(),
        updated: FieldValue.serverTimestamp()
      });
    return;
  };
}

// Player status 更新
function updatePlayerStatus(matchID, playerID, param) {
  return async (dispatch, getState) => {
    const match = firestore.collection("match").doc(matchID);
    await match
      .collection("players")
      .doc(playerID)
      .update({
        ...param,
        updated: FieldValue.serverTimestamp()
      });
    return;
  };
}

// Match 情報更新
function updateMatchInfo(matchID, param) {
  return async (dispatch, getState) => {
    const match = firestore.collection("match").doc(matchID);
    await match.update({
      ...param,
      updated: FieldValue.serverTimestamp()
    });
    return;
  };
}

// 対戦結果を取得
function getMatchResult(matchID) {
  return async (dispatch, getState) => {
    const match = firestore.collection("match").doc(matchID);
    const querySnapshot = await match.collection("results").get();

    return querySnapshot;
  };
}

// ランキングを取得
function getRecords(contestID, gameID) {
  return (dispatch, getState) => {
    const query = {
      contestID,
      gameID
    };
    return axios
      .get("/api/v1/records/", {
        params: query
      })
      .then((result) => {
        dispatch(setRecordList(result.data));
        return;
      })
      .catch((err) => console.log(err));
  };
}

const initialState = {
  gameList: [],
  matchInfo: {},
  players: [],
  chat: [],
  currentList: [],
  historyList: [],
  recordList: []
};

// reducer
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GAME_LIST:
      return applySetGameList(state, action);
    case SET_GAME_RANK_LIST:
      return applySetGameRankList(state, action);
    case SET_MATCH_INFO:
      return applySetMatchInfo(state, action);
    case SET_MATCH_PLAYERS:
      return applySetMatchPlayers(state, action);
    case SET_MATCH_CHAT:
      return applySetMatchChat(state, action);
    case CLEAR_MATCH_INFO:
      return applyClearMatchInfo(state, action);
    case CLEAR_MATCH_LIST:
      return applyClearMatchList(state, action);
    case SET_CURRENT_LIST:
      return applySetMatchCurrentList(state, action);
    case SET_HISTORY_LIST:
      return applySetMatchHistoryList(state, action);
    case SET_RECORD_LIST:
      return applySetRecordList(state, action);
    default:
      return state;
  }
}

// reducer functions
function applySetGameList(state, action) {
  const { gameList } = action;
  return {
    ...state,
    gameList
  };
}

function applySetGameRankList(state, action) {
  const { gameRankList } = action;
  return {
    ...state,
    gameRankList
  };
}

function applySetMatchInfo(state, action) {
  const { matchInfo } = action;
  return {
    ...state,
    matchInfo
  };
}

function applySetMatchPlayers(state, action) {
  const { players } = action;
  return {
    ...state,
    players
  };
}

function applySetMatchChat(state, action) {
  const { chat } = action;
  return {
    ...state,
    chat
  };
}

function applyClearMatchInfo(state, action) {
  return {
    ...state,
    matchInfo: {},
    players: [],
    chat: []
  };
}

function applyClearMatchList(state, action) {
  return {
    ...state,
    currentList: [],
    historyList: []
  };
}

function applySetMatchCurrentList(state, action) {
  const { currentList } = action;
  return {
    ...state,
    currentList
  };
}

function applySetMatchHistoryList(state, action) {
  const { historyList } = action;
  return {
    ...state,
    historyList
  };
}

function applySetRecordList(state, action) {
  const { recordList } = action;
  return {
    ...state,
    recordList
  };
}

// exports
const actionCreators = {
  getGameList,
  getGameRankList,
  getMatchInfo,
  getMatchPlayers,
  createMatch,
  enterMatch,
  exitMatch,
  inviteMember,
  sendMessage,
  getRecords,
  updatePlayerStatus,
  getMatchCurrent,
  updateMatchInfo,
  getMatchResult,
  getMatchHistory,
  exitMatchList,
  exitMatchResult
};

export { actionCreators };

export default reducer;
