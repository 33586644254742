import React, { Component } from "react";
import ContestOperatorEdit from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    loading: false,
    addList: [],
    deleteList: [],
    buttonLoading: false
  };

  render() {
    return (
      <ContestOperatorEdit
        {...this.state}
        {...this.props}
        handleCheck={this._handleCheck}
        handleSelectChange={this._handleSelectChange}
        handleAddOperator={this._handleAddOperator}
        searchUser={this._searchUser}
        handleDeleteOperator={this._handleDeleteOperator}
        handleCancel={this._handleCancel}
      />
    );
  }

  // Selectbox の選択値更新
  _handleSelectChange = (value) => {
    this.setState({
      addList: value || []
    });
  };

  // ユーザ検索
  _searchUser = async (keyword) => {
    const { searchUser, operatorList = [] } = this.props;

    const query = {
      keyword,
      limit: 10
    };

    const results = await searchUser(query);
    const result = [];
    for (const val of results.userList) {
      // 既に登録済みの運営ユーザは選択不可にする
      const isDisabled = operatorList.some((el) => el.userId === val.id);
      result.push({
        value: val.id,
        label: val.nickname,
        isDisabled: isDisabled
      });
    }
    return result;
  };

  // 大会運営者検索一覧を選択
  _handleCheck = (item = {}, event) => {
    const {
      target: { checked }
    } = event;
    const deleteList = [ ...this.state.deleteList ];
    if (checked) {
      deleteList.push({
        id: item.id,
        userId: item.userId
      });
    } else {
      const index = deleteList.findIndex((el) => el.id === item.id);
      if (index || index === 0) {
        deleteList.splice(index, 1);
      }
    }
    this.setState({
      deleteList
    });
  };

  // 運営ユーザ追加
  _handleAddOperator = async () => {
    const { addList = [] } = this.state;
    const {
      contestInfo: { id: contestId, gameId },
      addContestOperators,
      addChatAdmin
    } = this.props;

    if (!addList.length) {
      return;
    }
    this.setState({
      buttonLoading: true
    });
    try {
      // 運営者登録
      await addContestOperators(contestId, gameId, addList);

      // 公開チャットに追加
      const userIds = addList.map((item) => item.value);
      await addChatAdmin(contestId, userIds);
      
      alert(this.context.t("completeAddOperator"));
      this.setState({
        addList: [],
        buttonLoading: false
      });
    } catch (e) {
      alert(this.context.t("failedAddOperator"));
    }
    this.setState({
      buttonLoading: false
    });
  };

  // 運営ユーザ削除
  _handleDeleteOperator = async () => {
    const {
      contestInfo: { id: contestId },
      deleteContestOperators,
      removeChatAdmin
    } = this.props;

    const { deleteList = [] } = this.state;

    if (!contestId || !deleteList.length) {
      return;
    }
    this.setState({
      buttonLoading: true
    });
    if (window.confirm(this.context.t("deleteOperatorConfirm"))) {
      try {
        // 運営ユーザ削除
        const deleteIds = deleteList.map(el => el.id);
        await deleteContestOperators(contestId, deleteIds);

        // 公開チャットから除外
        const deleteUserIds = deleteList.map(el => el.userId);
        await removeChatAdmin(contestId, deleteUserIds);

        alert(this.context.t("completeDeleteOperator"));
        this.setState({
          deleteList: []
        });
      } catch (e) {
        alert(this.context.t("failedDeleteOperator"));
      }
    }
    this.setState({
      buttonLoading: false
    });
  };

  _handleCancel = () => {
    const {
      history,
    } = this.props;
    // 戻る
    history.goBack();
  }

  static contextTypes = {
    t: PropTypes.func.isRequired
  };

  static propTypes = {
    searchUser: PropTypes.func.isRequired
  };
}

export default Container;
