import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: { contestInfo = {}, contestTeams = [], contestUsers = [] }
  } = state;
  let activeList = [];
  if (contestInfo.participantType === "MULTI") {
    activeList = contestTeams.filter((el) => el.status === "ALLOW");
  } else if (contestInfo.participantType === "SOLO") {
    activeList = contestUsers.filter((el) => el.status === "ALLOW");
  }


  return {
    contestInfo,
    activeList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getContestTeams: (id, query) => {
      return dispatch(contestActions.getContestTeams(id, query));
    },
    getContestUsers: (id, status) => {
      return dispatch(contestActions.getContestUsers(id, status));
    },
    createTeamTournament: (contest, game, tournament) => {
      return dispatch(
        contestActions.createTeamTournament(contest, game, tournament)
      );
    },
    createUserTournament: (contest, game, tournament) => {
      return dispatch(
        contestActions.createUserTournament(contest, game, tournament)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
