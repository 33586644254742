import React, { Component } from "react";
import App from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  componentDidMount() {
    const {
      isSignedIn,
      getAuthUser,
      getGameList,
      getGameRankList,
      getRedirectTwitterResult
    } = this.props;

    if (isSignedIn) {
      getAuthUser();
    } else {
      getRedirectTwitterResult();
    }
    // ゲーム一覧取得
    // 非ユーザーも閲覧可能になるためここでgameList取得
    getGameList();
    getGameRankList();
  }

  componentDidUpdate = (prevProps, prevState) => {
    const {
      auth = {},
      subscribeNewNotification,
      getNotificationList,
      getNotificationBadge
      // getMyTeamList
    } = this.props;

    if (prevProps.auth.id !== auth.id && auth.id) {
      // 通知リスナーを設定
      subscribeNewNotification(auth.id);
      // 通知一覧取得
      getNotificationList(auth.id);
      // 通知のバッジー取得
      getNotificationBadge(auth.id);

      // TODO: リプレイスのためコメントアウト
      // 所属しているチームを取得
      // getMyTeamList();
    }
  };

  render() {
    return <App {...this.props} />;
  }

  static propTypes = {
    isSignedIn: PropTypes.bool.isRequired,
    getAuthUser: PropTypes.func.isRequired,
    getGameList: PropTypes.func.isRequired,
    getGameRankList: PropTypes.func.isRequired,
    subscribeNewNotification: PropTypes.func.isRequired,
    getNotificationList: PropTypes.func.isRequired,
    getNotificationBadge: PropTypes.func.isRequired,
    getMyTeamList: PropTypes.func.isRequired
  };
}

export default Container;
