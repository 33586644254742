import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";
import { actionCreators as utilActions } from "redux/util";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: { contestInfo = {}, chatList = [], messageList = [] }
  } = state;

  return {
    contestInfo,
    chatList,
    messageList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    subscribeRoomList: (contestId) => {
      return dispatch(contestActions.subscribeRoomList(contestId));
    },
    getChatDetail: (chatId) => {
      return dispatch(contestActions.getChatDetail(chatId));
    },
    clearChatBadge: (contestId) => {
      return dispatch(contestActions.clearChatBadge(contestId));
    },
    uploadFile: (formData) => {
      return dispatch(utilActions.uploadFile(formData));
    },
    subscribeMatchChat: (chatId) => {
      return dispatch(contestActions.subscribeMatchChat(chatId));
    },
    changeRoomStatus: (chatId) => {
      return dispatch(contestActions.changeRoomStatus(chatId));
    },
    getMatchChat: (chatId, query) => {
      return dispatch(contestActions.getMatchChat(chatId, query));
    },
    clearMatchChat: () => {
      return dispatch(contestActions.clearMatchChat());
    },
    sendMessage: (chatId, param) => {
      return dispatch(contestActions.sendMessage(chatId, param));
    },
    getContestOperators: (contestId) => {
      return dispatch(contestActions.getContestOperators(contestId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
