import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledIconButton = styled(IconButton)`
  && {
    background-color: ${(props) => props.color ? props.color : "initial"};
  }
  &&:hover {
    background-color: ${(props) => props.color ? props.color : "initial"};
  }
`;

const CommonIconButton = ({ color, onClick, children }) => {
  return (
    <StyledIconButton color={color} onClick={onClick}>
      {children}
    </StyledIconButton>
  );
};

CommonIconButton.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default CommonIconButton;
