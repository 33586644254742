import React from "react";
import styles from "./styles.scss";
import Loading from "components/lib/Loading";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Button from "components/lib/Button";
import classNames from "classnames/bind";
import PropTypes from "prop-types";

const cx = classNames.bind(styles);

const SearchUser = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.filter}>
      <form method="post" onSubmit={props.onSubmit}>
        <div className={styles.filterContents}>
          <input
            type="text"
            name="keyword"
            placeholder={context.t("inputNickname")}
            className={styles.searchInput}
            value={props.keyword}
            onChange={props.onInputChange}
            onKeyPress={props.handleKeyPress}
          />
          <Button
            type="button"
            className={styles.searchButton}
            text={context.t("search")}
            onClick={props.onSubmit}
            style={{
              marginLeft: 10,
              minWidth: 100
            }}
          />
        </div>
        {props.isDetail ? (
          <div>
            <div className={styles.filterContents}>
              <span className={styles.label}>{context.t("game")}</span>
              <select
                name="gameId"
                value={props.gameId}
                onChange={props.onSelectChange}
                className={styles.select}
              >
                <option key="default" value=""></option>
                {props.gameList.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styles.filterContents}>
              <input
                type="text"
                name="gameAccount"
                placeholder={context.t("searchGameAccount")}
                className={styles.gameAccountInput}
                value={props.gameAccount}
                onChange={props.onInputChange}
                onKeyPress={props.handleKeyPress}
              />
            </div>
          </div>
        ) : null}
      </form>
    </div>

    <div className={styles.list}>
      <InfiniteScroll
        dataLength={props.userList.length}
        next={props.loadMore}
        hasMore={props.hasMore}
        loader={<Loading />}
      >
        {props.userList.length ? (
          props.userList.map((user, index) => (
            <div
              key={index}
              className={styles.item}
            >
              <div className={styles.user}>
                <Link
                  to={`/users/${user.id}`}
                >
                  <div className={styles.imageContainer}>
                      <img
                        src={
                          user?.profileImage
                            ? user?.profileImage
                            : require("images/noimage.png")
                        }
                        alt="proflie"
                        className={styles.image}
                        onError={(e) =>
                          (e.target.src = require("images/noimage.png"))
                        }
                      />
                  </div>
                  <div className={styles.infoContainer}>
                    <div
                      className={cx(styles.nickname, {
                        me: props.auth.id === user.id
                      })}
                    >
                      {user.nickname}
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          ))) : (
            !props.loading ?
            <div className={styles.empty}>{context.t("emptyData")}</div>
            : null
          )}
      </InfiniteScroll>
    </div>
  </div>
);

SearchUser.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  handleKeyPress: PropTypes.func.isRequired,
  handleToggle: PropTypes.func.isRequired,
  keyword: PropTypes.string.isRequired,
  gameAccount: PropTypes.string.isRequired,
  userList: PropTypes.array.isRequired,
  gameId: PropTypes.string.isRequired,
  isDetail: PropTypes.bool.isRequired
};

SearchUser.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SearchUser;
