import { connect } from "react-redux";
import Container from "./container";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {} },
  } = state;
  
  return {
    auth,
  };
};

export default connect(mapStateToProps)(Container);
