import { connect } from "react-redux";
import Container from "./container";
import { withRouter } from "react-router-dom";
import { actionCreators as userActions } from "redux/user";
import { actionCreators as gameActions } from "redux/game";
import { actionCreators as notificationActions } from "redux/notification";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { isSignedIn, auth = {}, myTeamList = [] } = {},
    game: { gameList = [] }
  } = state;

  return {
    isSignedIn,
    auth,
    myTeamList,
    gameList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getAuthUser: () => {
      dispatch(userActions.getAuthUser());
    },
    getMyTeamList: () => {
      dispatch(userActions.getMyTeamList());
    },
    getRedirectTwitterResult: () => {
      dispatch(userActions.getRedirectTwitterResult());
    },
    subscribeNewNotification: (userId) => {
      dispatch(notificationActions.subscribeNewNotification(userId));
    },
    getNotificationList: (userId) => {
      dispatch(notificationActions.getNotificationList(userId));
    },
    getNotificationBadge: (userId) => {
      dispatch(notificationActions.getNotificationBadge(userId));
    },
    getGameList: () => {
      dispatch(gameActions.getGameList());
    },
    getGameRankList: () => {
      dispatch(gameActions.getGameRankList());
    }
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Container)
);
