import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: { contestInfo = {}, contestTeams = [], contestUsers = [] }
  } = state;

  // エントリー確定リスト
  let activeList = [];
  if (contestInfo.participantType === "MULTI") {
    activeList = contestTeams.filter((el) => el.status === "ALLOW");
  } else if (contestInfo.participantType === "SOLO") {
    activeList = contestUsers.filter((el) => el.status === "ALLOW");
  }
  return {
    contestInfo,
    activeList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getContestOperators: (contestId) => {
      return dispatch(contestActions.getContestOperators(contestId));
    },
    updateEntryConfirmChat: (contestInfo, roomName, users, admin) => {
      return dispatch(
        contestActions.updateEntryConfirmChat(
          contestInfo,
          roomName,
          users,
          admin
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
