import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/user";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth, teamList }
  } = state;
  return {
    auth,
    teamList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // getTeamList: (id) => {
    //   return dispatch(userActions.getTeamList(id));
    // },
    updateUserInfo: (id, userInfo) => {
      return dispatch(userActions.updateUserInfo(id, userInfo));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
