import { setGTMDataLayer } from "lib/analytics";
import moment from "moment";
import { convertFirestoreTime, generateRandomCode } from "lib/convert";
import { axios } from "lib/axios";
import FirebaseApp, { firestore } from "lib/firebase";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut as signOutFromFirebaseAuth,
  TwitterAuthProvider,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
  signInWithRedirect,
  getRedirectResult
} from "firebase/auth";
import {
  doc,
  collection,
  setDoc,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  serverTimestamp,
  Timestamp,
  query,
  collectionGroup,
  where,
  orderBy,
  startAt,
  endAt,
  limit,
  startAfter
} from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";

const auth = getAuth(FirebaseApp);
auth.useDeviceLanguage();

const AUTH_EXPIRATION_MINUTES = 30;

// actions
const SET_AUTH_USER = "SET_AUTH_USER";
const CLEAR_AUTH_USER = "CLEAR_AUTH_USER";

// old actions
const SAVE_TOKEN = "SAVE_TOKEN";
const LOGOUT = "LOGOUT";
const SET_PROFILE = "SET_PROFILE";
const SET_USER_INFO = "SET_USER_INFO";
const SET_TEAM_LIST = "SET_TEAM_LIST";
const SET_FRIEND_LIST = "SET_FRIEND_LIST";
const SET_MYFRIEND_LIST = "SET_MYFRIEND_LIST";
const SET_MYTEAM_LIST = "SET_MYTEAM_LIST";
const SET_REQUEST_LIST = "SET_REQUEST_LIST";
const CLEAR_USER_INFO = "CLEAR_USER_INFO";
const SET_CONNECTION_LIST = "SET_CONNECTION_LIST";

// action creator
function setAuthUser(auth) {
  return {
    type: SET_AUTH_USER,
    auth
  };
}

function clearAuthUser() {
  return {
    type: CLEAR_AUTH_USER
  };
}

// old action creator
function saveToken(token) {
  return {
    type: SAVE_TOKEN,
    token
  };
}

function logout() {
  return {
    type: LOGOUT
  };
}

function setProfile(auth) {
  return {
    type: SET_PROFILE,
    auth
  };
}

function setUserInfo(userInfo) {
  return {
    type: SET_USER_INFO,
    userInfo
  };
}

function setTeamList(teamList) {
  return {
    type: SET_TEAM_LIST,
    teamList
  };
}

function setFriendList(friendList) {
  return {
    type: SET_FRIEND_LIST,
    friendList
  };
}

function setMyFriendList(myFriendList) {
  return {
    type: SET_MYFRIEND_LIST,
    myFriendList
  };
}

function setMyTeamList(myTeamList) {
  return {
    type: SET_MYTEAM_LIST,
    myTeamList
  };
}

function setRequestList(requestList) {
  return {
    type: SET_REQUEST_LIST,
    requestList
  };
}

function clearUserInfo() {
  return {
    type: CLEAR_USER_INFO
  };
}

function setConnectionList(connectionList) {
  return {
    type: SET_CONNECTION_LIST,
    connectionList
  };
}

function signUpWithEmail(email, password, nickname) {
  return (dispatch) => {
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const {
          user: { uid = "" }
        } = userCredential;

        // TODO: ユーザデータは別Functionに切り出すことも検討
        const userInfo = {
          id: uid,
          nickname: nickname,
          bio: null,
          imageUrl: null,
          backgroundImageUrl: null,
          activatedAt: serverTimestamp(),
          lastSignInAt: serverTimestamp(),
          signInFailed: 0,
          pwModifiedAt: null,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          createdById: uid,
          updatedById: uid
        };

        try {
          await setDoc(doc(firestore, "users", uid), userInfo);
          dispatch(setAuthUser(userInfo));
        } catch (e) {
          console.error("Error adding document: ", e);
        }
      })
      .catch((error) => {
        const { code, message } = error;
        switch (code) {
          case "auth/email-already-in-use":
            // TODO: 教えるのはセキュリティ的に良くないかも？
            console.log(
              "このメールアドレスは、すでに使われています。サインインをお試しください。"
            );
            break;
          case "auth/invalid-email":
            console.log("メールアドレスが不正です！！！！！");
            break;
          case "auth/operation-not-allowed":
            console.log("アカウントが無効のため、操作できません！！！！！");
            break;
          case "auth/week-password":
            console.log("パスワードが脆弱です！！！！！");
            break;
          default:
            console.log("%s, %s", code, message);
            break;
        }
      });
  };
}

function signInWithEmail(email, password) {
  return (dispatch) => {
    signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        const {
          user: { uid }
        } = userCredential;

        const docRef = doc(firestore, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userInfo = docSnap.data();
          dispatch(setAuthUser(userInfo));
        } else {
          console.log("No such document!");
        }
      })
      .catch((error) => {
        const { code, message } = error;
        switch (code) {
          case "auth/user-not-found":
            console.log("ユーザが存在しません！！！！！");
            break;
          case "auth/wrong-password":
            console.log(
              "メールアドレスもしくはパスワードが異なります！！！！！"
            );
            break;
          case "auth/invalid-email":
            console.log("メールアドレスが不正です！！！！！");
            break;
          case "auth/internal-error":
            console.log("内部エラー！！！！！");
            break;
          default:
            console.log("%s, %s", code, message);
            break;
        }
      });
  };
}

function getAuthUser() {
  return (dispatch) => {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { uid } = user;

        const docRef = doc(firestore, "users", uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const userInfo = docSnap.data();
          dispatch(setAuthUser(userInfo));
        } else {
          console.log("No such document!");
        }
      }
    });
  };
}

function signOut() {
  return (dispatch) => {
    signOutFromFirebaseAuth(auth)
      .then(() => {
        // Sign-out successful.
        dispatch(clearAuthUser());
      })
      .catch((error) => {
        // An error happened.
      });
  };
}

function signInWithTwitter() {
  return (dispatch) => {
    const provider = new TwitterAuthProvider();
    signInWithRedirect(auth, provider);
  };
}

function getRedirectTwitterResult() {
  return (dispatch) => {
    getRedirectResult(auth)
      .then(async (result) => {
        const { uid, displayName } = result.user;

        // TODO: プロフィール画像を登録する
        let userInfo = {
          id: uid,
          nickname: displayName,
          bio: null,
          imageUrl: null,
          backgroundImageUrl: null,
          lastSignInAt: serverTimestamp(),
          signInFailed: 0,
          pwModifiedAt: null,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          createdById: uid,
          updatedById: uid
        };

        try {
          const docRef = doc(firestore, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            userInfo = docSnap.data();
          } else {
            await setDoc(docRef, userInfo);
          }
          dispatch(setAuthUser(userInfo));
        } catch (e) {
          console.error("Error adding document: ", e);
        }

        return { result: userInfo, error: undefined };
      })
      .catch((error) => {
        const { code, message } = error;
        console.error("%s, %s", code, message);

        return { result: undefined, error: error };
      });
  };
}

function sendMagicLink(email) {
  return async (dispatch) => {
    const pinCode = uuidv4();
    let authId;

    // マジックリンク遷移後に照合する認証情報を保存する
    try {
      const { id } = await addDoc(collection(firestore, "magicLinks"), {
        email: email,
        expireAt: Timestamp.fromDate(
          moment()
            .add(AUTH_EXPIRATION_MINUTES, "minutes")
            .toDate()
        ),
        pinCode: pinCode,
        activatedAt: serverTimestamp(),
        lastSignInAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp()
      });

      authId = id;
    } catch (e) {
      return console.error("Error adding document: ", e);
    }

    const actionCodeSettings = {
      // 認証済みドメインのみ設定可能
      url:
        process.env.REACT_APP_WEB_HOST +
        `/magic-link?pinCode=${pinCode}&authId=${authId}`,
      handleCodeInApp: true
      // TODO: アプリを追加する場合は要設定
      // iOS: {
      //   bundleId: "com.example.ios"
      // },
      // android: {
      //   packageName: "com.example.android",
      //   installApp: true,
      //   minimumVersion: "12"
      // },
      // dynamicLinkDomain: "example.page.link"
    };

    // マジックリンク入りメールを送信
    sendSignInLinkToEmail(auth, email, actionCodeSettings).catch((error) => {
      const { code, message } = error;
      console.log("%s, %s", code, message);
    });
  };
}

function signInWithMagicLink(email, magicLink) {
  return async (dispatch) => {
    if (!isSignInWithEmailLink(auth, magicLink)) return;

    const { pinCode, authId } = new URLSearchParams(magicLink);

    try {
      const docRef = doc(firestore, "magicLinks", authId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const { expireAt, pinCode: originPinCode } = docSnap.data();

        if (
          moment().diff(expireAt.toData(), "minutes") < AUTH_EXPIRATION_MINUTES
        )
          return;

        if (pinCode !== originPinCode) return;
      }
    } catch {
      console.log("No such document!");
    }

    return signInWithEmailLink(auth, email, magicLink)
      .then(async (userCredential) => {
        const {
          user: { uid = "" }
        } = userCredential;

        let userInfo = {
          id: uid,
          nickname: `user${generateRandomCode(3)}`, // ランダムな3桁の整数を生成
          bio: null,
          imageUrl: null,
          backgroundImageUrl: null,
          lastSignInAt: serverTimestamp(),
          signInFailed: 0,
          pwModifiedAt: null,
          createdAt: serverTimestamp(),
          updatedAt: serverTimestamp(),
          createdById: uid,
          updatedById: uid
        };

        try {
          const docRef = doc(firestore, "users", uid);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            userInfo = docSnap.data();
          } else {
            await setDoc(docRef, userInfo);
          }
          dispatch(setAuthUser(userInfo));
        } catch (e) {
          console.error("Error adding document: ", e);
        }

        return { result: userInfo, error: undefined };
      })
      .catch((error) => {
        const { code, message } = error;
        console.log("%s, %s", code, message);
        return { result: undefined, error: error };
      })
      .finally(() => {
        localStorage.removeItem("emailForSignIn");
      });
  };
}

function getProfile() {
  return (dispatch, getState) => {
    axios
      .get("/api/v1/users/profile/")
      .then((result) => {
        dispatch(setProfile(result.data));
      })
      .catch((err) => console.log(err));
  };
}

function getUserInfo(id) {
  return async (dispatch, getState) => {
    try {
      const docRef = doc(firestore, "users", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const {
          user: {
            auth: { id: authId }
          }
        } = getState();

        const userInfo = {
          ...docSnap.data(),
          isSelf: authId === id
        };
        dispatch(setUserInfo(userInfo));
        return { result: userInfo, error: undefined };
      } else {
        console.log("No such document!");
        return { result: undefined, error: "No such document!" };
      }
    } catch (error) {
      console.error("Error adding document: ", error);
      return { result: undefined, error: error };
    }
  };
}

function updateUserInfo(id, userInfo) {
  return async (dispatch, getState) => {
    const {
      user: { auth = {} }
    } = getState();

    const data = {
      ...userInfo,
      updatedAt: new Date(),
      updatedById: auth.id
    };

    const docRef = doc(firestore, "users", id);
    await updateDoc(docRef, data);

    // 更新後データ取得
    const docSnap = await getDoc(docRef);

    if (docSnap.exists) {
      const {
        user: {
          auth: { id: authId }
        }
      } = getState();

      const newUserInfo = {
        id: docSnap.id,
        ...docSnap.data(),
        isSelf: authId === id
      };
      dispatch(setAuthUser(newUserInfo));
      dispatch(setUserInfo(newUserInfo));
      return [newUserInfo, undefined];
    } else {
      return [undefined, new Error("error")];
    }

    // return axios
    //   .put(`/api/v1/users/${id}/`, formData)
    //   .then((result) => {
    //     const {
    //       user: { auth, userInfo }
    //     } = getState();

    //     const changedAuth = {
    //       ...auth,
    //       ...result.data
    //     };
    //     const changedUserInfo = {
    //       ...userInfo,
    //       ...result.data
    //     };
    //     dispatch(setProfile(changedAuth));
    //     dispatch(setUserInfo(changedUserInfo));
    //   })
    //   .catch((err) => {
    //     console.log(err);

    //     if (err.response.status === 400) {
    //       return err.response.data;
    //     }
    //   });
  };
}

function updateUserPassword(id, password, newPassword, passwordConfirm) {
  const params = {
    id,
    password,
    newPassword,
    passwordConfirm
  };

  // TODO: user id を取得する
  return (dispatch, getState) => {
    return axios
      .put(`/api/v1/users/${id}/password/`, params)
      .then((result) => {
        if (result.data > 0) {
          return result.data;
        } else {
          return -7;
        }
        //dispatch(setProfile(result.data));
        //dispatch(setUserInfo(result.data));
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

function getUserList(ids = []) {
  return async (dispatch, getState) => {
    const userList = [];
    for (const id of ids) {
      const docRef = doc(firestore, "users", id);
      const userDoc = await getDoc(docRef);
      if (userDoc.exists) {
        userList.push({
          id: userDoc.id,
          ...userDoc.data()
        });
      }
    }
    return userList;
  };
}

// ユーザ検索
function searchUser(params = {}) {
  return async (dispatch, getState) => {
    let docRef = collection(firestore, "users");

    if (params.keyword) {
      // キーワードがある場合、前方一致検索
      // TODO: 全文検索は algolia と連携
      docRef = query(docRef, orderBy("nickname"),
        startAt(params.keyword),
        endAt(params.keyword + "\uf8ff")
      );
    } else {
      // キーワードがない場合、最新登録順に表示
      docRef = query(docRef, orderBy("createdAt", "desc"));
    }

    // limit
    docRef = query(docRef, limit(params.limit));

    // loadmore
    if (params.lastData) {
      docRef = query(docRef, startAfter(params.lastData));
    }

    // ユーザ検索
    const querySnapshot = await getDocs(docRef);
    const userList = [];
    for (const doc of querySnapshot.docs) {
      const userInfo = {
        id: doc.id,
        ...doc.data(),
      };
      userList.push(userInfo);
    }

    dispatch(setUserInfo(userList));
    return {
      userList,
      lastData: querySnapshot.docs[querySnapshot.docs.length - 1]
    };
  };
}

// チーム一覧取得
function getTeamList(id) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/users/${id}/teams/`)
      .then((result) => {
        const teamList = [];
        for (const val of result.data) {
          teamList.push(val.team);
        }
        dispatch(setTeamList(teamList));
        return;
      })
      .catch((err) => console.log(err));
  };
}

// ゲームユーザ一覧取得
function getGameUserList(id, query) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/users/${id}/gameusers/`, {
        params: query
      })
      .then((result) => {
        return result.data;
      })
      .catch((err) => console.log(err));
  };
}

// 友達一覧取得
function getFriendList(id) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/users/${id}/friends/`)
      .then((result) => {
        const friendList = [];
        for (const val of result.data) {
          if (val.requestUser.id !== id) {
            friendList.push(val.requestUser);
          } else {
            friendList.push(val.responseUser);
          }
        }
        dispatch(setFriendList(friendList));
        return;
      })
      .catch((err) => console.log(err));
  };
}

// ログインユーザの友達一覧取得
function getMyFriendList() {
  return (dispatch, getState) => {
    const {
      user: {
        auth: { id }
      }
    } = getState();
    return axios
      .get(`/api/v1/users/${id}/friends/`)
      .then((result) => {
        const friendList = [];
        for (const val of result.data) {
          if (val.requestUser.id !== id) {
            friendList.push(val.requestUser);
          } else {
            friendList.push(val.responseUser);
          }
        }
        dispatch(setMyFriendList(friendList));
        return;
      })
      .catch((err) => console.log(err));
  };
}

// ログインユーザのチーム一覧取得
function getMyTeamList() {
  return (dispatch, getState) => {
    const {
      user: {
        auth: { id }
      }
    } = getState();
    return axios
      .get(`/api/v1/users/${id}/teams/`)
      .then((result) => {
        const teamList = [];
        for (const val of result.data) {
          teamList.push(val.team);
        }
        dispatch(setMyTeamList(teamList));
        return;
      })
      .catch((err) => console.log(err));
  };
}

// 友達申請中のユーザー一覧取得
function getRequestList(id) {
  return (dispatch, getState) => {
    return axios
      .get(`/api/v1/users/${id}/request/`)
      .then((result) => {
        dispatch(setRequestList(result.data));
        return;
      })
      .catch((err) => console.log(err));
  };
}

// 友達申請
function friendRequest(id) {
  return (dispatch, getState) => {
    return axios
      .post(`/api/v1/users/${id}/request/`)
      .then((result) => {
        const {
          user: { userInfo }
        } = getState();
        const changedUserInfo = {
          ...userInfo,
          friendID: result.data.id,
          myStatus: result.data.status,
          isRequestMe: false
        };
        dispatch(setUserInfo(changedUserInfo));
        return;
      })
      .catch((err) => console.log(err));
  };
}

// 友達申請に対して返信
function setRequestReply(uid, rid, type, from) {
  return (dispatch, getState) => {
    const param = { status: type };
    axios
      .put(`/api/v1/users/${uid}/request/${rid}/reply/`, param)
      .then((result) => {
        if (from === "userOverview") {
          // userOverviewから実行された場合
          const {
            user: { userInfo }
          } = getState();
          const changedUserInfo = {
            ...userInfo,
            myStatus: result.data.status
          };
          dispatch(setUserInfo(changedUserInfo));
        } else {
          // friendListから実行された場合
          const {
            user: { friendList, requestList }
          } = getState();
          if (result.data.status === "ALLOW") {
            const friend = result.data.requestUser;
            const changedFriends = [...friendList, friend];
            dispatch(setFriendList(changedFriends));
          }
          const changedRequest = [];
          for (const val of requestList) {
            if (val.id !== rid) {
              changedRequest.push(val);
            }
          }
          dispatch(setRequestList(changedRequest));
        }
      })
      .catch((err) => console.log(err));
  };
}

// game_users の追加
function createGameUser(gameUser) {
  return (dispatch, getState) => {
    const {
      user: {
        auth,
        auth: { id, gameUsers = [] },
        userInfo = {}
      }
    } = getState();
    const params = gameUser;
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/v1/users/${id}/gameusers/`, params)
        .then((result) => {
          const newGameUsers = gameUsers;

          newGameUsers.push(result.data);
          const newAuth = {
            ...auth,
            gameUsers: newGameUsers
          };
          const newUserInfo = {
            ...userInfo,
            gameUsers: newGameUsers
          };

          dispatch(setProfile(newAuth));
          dispatch(setUserInfo(newUserInfo));

          resolve(newGameUsers);
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  };
}

// game_users の更新
function updateGameUser(gameUser) {
  return (dispatch, getState) => {
    const {
      user: {
        auth,
        auth: { id, gameUsers = [] },
        userInfo = {}
      }
    } = getState();

    axios
      .put(`/api/v1/users/${id}/gameusers/${gameUser.id}/`, gameUser)
      .then((result) => {
        const { data: newGameUser } = result;

        // 更新するユーザをリストから一度削除
        const deleteIndex = gameUsers.findIndex((v) => v.id === gameUser.id);
        gameUsers.splice(deleteIndex, 1);

        // 更新されたユーザを追加（実質、上書き）
        gameUsers.push(newGameUser);

        // delete -> push による表示順変化を修正
        gameUsers.sort((a, b) => {
          return a.id < b.id ? -1 : 1;
        });

        const newAuth = {
          ...auth,
          gameUsers: gameUsers
        };
        const newUserInfo = {
          ...userInfo,
          gameUsers: gameUsers
        };

        dispatch(setProfile(newAuth));
        dispatch(setUserInfo(newUserInfo));
      })
      .catch((err) => {
        console.log(err);
        return gameUser.username;
      });
  };
}

function deleteGameUser(gameUser) {
  return (dispatch, getState) => {
    const {
      user: {
        auth: { id }
      }
    } = getState();
    return axios
      .delete(`/api/v1/users/${id}/gameusers/${gameUser.id}/`)
      .then((result) => {
        const {
          user: {
            auth,
            userInfo,
            userInfo: { gameUsers }
          }
        } = getState();

        // ゲームユーザ削除
        const deleteIndex = gameUsers.findIndex((v) => v.id === gameUser.id);
        gameUsers.splice(deleteIndex, 1);

        const newAuth = {
          ...auth,
          gameUsers: gameUsers
        };
        const newUserInfo = {
          ...userInfo,
          gameUsers: gameUsers
        };

        dispatch(setProfile(newAuth));
        dispatch(setUserInfo(newUserInfo));

        return result.status === 204 ? true : false;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });
  };
}
//get summoner DTO
function getSummonerDto(summonerName) {
  return (dispatch, getState) => {
    const param = { summonerName: summonerName };
    //requesting riot games summoner api
    return axios
      .post("/api/v1/extern/riotgames/summoner/", param)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
}

function getFriendConnection() {
  return (dispatch, getState) => {
    const {
      user: { myFriendList = [] }
    } = getState();

    for (const val of myFriendList) {
      const connectionFirestoreRef = firestore
        .collection("connection")
        .doc(String(val.id));
      connectionFirestoreRef.onSnapshot((doc) => {
        if (doc.exists) {
          const connectionList = {};
          const data = doc.data();
          connectionList[doc.id] = {
            isOnline: data.state === "online",
            lastChanged: convertFirestoreTime(data.last_changed)
          };

          dispatch(setConnectionList(connectionList));
        }
      });
    }
  };
}

// push notification用token登録
function registUserNotification() {
  return (dispatch, getState) => {
    const {
      user: {
        auth: { id }
      }
    } = getState();
    const notification_token = localStorage.getItem("fcm_token");
    const param = { notification_token: notification_token };
    axios
      .post(`/api/v1/users/${id}/notification/`, param)
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
// push notification用token削除
function deleteUserNotification() {
  return (dispatch, getState) => {
    const {
      user: {
        auth: { id }
      }
    } = getState();
    const notification_token = localStorage.getItem("fcm_token");
    // TODO: deleteメソッドだとbodyにパラメータセットできない？のでURLに含ませる
    axios
      .delete(`/api/v1/users/${id}/notification/${notification_token}/`)
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

// パスワードリセットメール送信
function sendPasswordResetMail(email) {
  return (dispatch) => {
    const param = { email: email };
    return axios
      .post("/api/v1/users/password/forget/", param)
      .then((result) => {
        const successResult = {
          status: result.status,
          data: result.data
        };
        return successResult;
      })
      .catch((err) => {
        console.log(err.data);
        const failedResult = {
          status: err.response.status,
          data: err.response.data
        };
        return failedResult;
      });
  };
}

// keyPhraseからパスワードリセット情報取得
function getPasswordResetInfo(keyPhrase) {
  return (dispatch) => {
    const query = {
      keyPhrase
    };
    return axios
      .get("/api/v1/users/password/reset/", {
        params: query
      })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// パスワードリセット
function resetUserPassword(params) {
  return (dispatch, getState) => {
    return axios
      .post("/api/v1/users/password/reset/", params)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// keyPhraseからアクティベート情報取得
function getActivateInfo(keyPhrase) {
  return (dispatch) => {
    const query = {
      keyPhrase
    };
    return axios
      .get("/api/v1/users/activate/", {
        params: query
      })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}
// パスワードリセット
function userActivate(params) {
  return (dispatch, getState) => {
    return axios
      .post("/api/v1/users/activate/", params)
      .then((result) => {
        if (result.data) {
          const { token, userInfo } = result.data;

          dispatch(saveToken(token));
          dispatch(setProfile(userInfo));
        }
        return;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

// 運営大会履歴一覧取得
function getContestTemplateList(userId) {
  return async (dispatch, getState) => {
    if (!userId) return;

    const querysnapShot = await getDocs(
      query(
        collectionGroup(firestore, "operators"),
        where("userId", "==", userId)
      )
    );
    const templateList = [];
    for (const doc of querysnapShot.docs) {
      const contestDoc = await getDoc(doc.ref.parent.parent);
      templateList.push({
        id: contestDoc.id,
        ...contestDoc.data()
      });
    }
    return templateList;
  };
}

// 大会履歴のサマリ情報取得
function getRelatedContestCount(userId) {
  return (dispatch, getState) => {
    if (!userId) return;
    return axios
      .get(`/api/v1/users/${userId}/contests/count/`)
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

const initialState = {
  isSignedIn: localStorage.getItem("isSignedIn") === "true" ? true : false,
  token: localStorage.getItem("jwt"),
  auth: {},
  userInfo: {},
  teamList: [],
  friendList: [],
  myFriendList: [],
  myTeamList: [],
  requestList: [],
  connectionList: {}
};

// reducer
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_AUTH_USER:
      return applySetAuthUser(state, action);
    case CLEAR_AUTH_USER:
      return applyClearAuthUser(state, action);
    case SAVE_TOKEN:
      return applySetToken(state, action);
    // case LOGOUT:
    //   return applyLogout(state, action);
    case SET_PROFILE:
      return applySetProfile(state, action);
    case SET_USER_INFO:
      return applySetUserInfo(state, action);
    case SET_TEAM_LIST:
      return applySetTeamList(state, action);
    case SET_FRIEND_LIST:
      return applySetFriendList(state, action);
    case SET_MYFRIEND_LIST:
      return applySetMyFriendList(state, action);
    case SET_MYTEAM_LIST:
      return applySetMyTeamList(state, action);
    case SET_REQUEST_LIST:
      return applySetRequestList(state, action);
    case CLEAR_USER_INFO:
      return applyClearUserInfo(state, action);
    case SET_CONNECTION_LIST:
      return applySetConnectionList(state, action);
    default:
      return state;
  }
}

// reducer functions
function applySetAuthUser(state, action) {
  const { auth } = action;
  localStorage.setItem("isSignedIn", true);
  return {
    ...state,
    auth,
    isSignedIn: true
  };
}

function applyClearAuthUser(state, action) {
  const { auth } = initialState;
  localStorage.setItem("isSignedIn", false);
  return {
    auth,
    isSignedIn: false
  };
}

// old reducer functions
function applySetToken(state, action) {
  const { token } = action;
  localStorage.setItem("jwt", token);
  return {
    ...state,
    isSignedIn: true,
    token: token
  };
}

// function applyLogout(state, action) {
//   localStorage.removeItem("jwt");
//   return {
//     isSignedIn: false
//   };
// }

function applySetProfile(state, action) {
  const {
    auth,
    auth: { id }
  } = action;
  setGTMDataLayer("uid", id);
  return {
    ...state,
    auth
  };
}

function applySetUserInfo(state, action) {
  const { userInfo } = action;
  return {
    ...state,
    userInfo
  };
}

function applySetTeamList(state, action) {
  const { teamList } = action;
  return {
    ...state,
    teamList
  };
}

function applySetFriendList(state, action) {
  const { friendList } = action;
  return {
    ...state,
    friendList
  };
}

function applySetMyFriendList(state, action) {
  const { myFriendList } = action;
  return {
    ...state,
    myFriendList
  };
}

function applySetMyTeamList(state, action) {
  const { myTeamList } = action;
  return {
    ...state,
    myTeamList
  };
}

function applySetRequestList(state, action) {
  const { requestList } = action;
  return {
    ...state,
    requestList
  };
}

function applyClearUserInfo(state, action) {
  const { userInfo, teamList, friendList, requestList } = initialState;
  return {
    ...state,
    userInfo,
    teamList,
    friendList,
    requestList
  };
}

function applySetConnectionList(state, action) {
  const { connectionList } = action;
  return {
    ...state,
    connectionList
  };
}

// exports
const actionCreators = {
  saveToken,
  logout,
  getProfile,
  getUserInfo,
  updateUserInfo,
  searchUser,
  getTeamList,
  getGameUserList,
  getFriendList,
  getMyFriendList,
  getMyTeamList,
  getRequestList,
  friendRequest,
  setRequestReply,
  clearUserInfo,
  getSummonerDto,
  updateUserPassword,
  createGameUser,
  deleteGameUser,
  setProfile,
  getFriendConnection,
  sendPasswordResetMail,
  getPasswordResetInfo,
  resetUserPassword,
  getActivateInfo,
  userActivate,
  registUserNotification,
  deleteUserNotification,
  updateGameUser,
  getContestTemplateList,
  getUserList,
  getRelatedContestCount,
  signUpWithEmail,
  signInWithEmail,
  getAuthUser,
  signOut,
  signInWithTwitter,
  sendMagicLink,
  signInWithMagicLink,
  getRedirectTwitterResult
};

export { actionCreators };

export default reducer;
