import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {} },
    contest: {
      contestInfo = {},
      contestQuestions = [],
      contestQuestionSelections = []
    }
  } = state;

  return {
    auth,
    contestInfo,
    contestQuestions,
    contestQuestionSelections
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    entryContestUser: (contestId, param) => {
      return dispatch(contestActions.entryContestUser(contestId, param));
    },
    saveSurveyAnswer: (contestId, questionId, param) => {
      return dispatch(
        contestActions.saveSurveyAnswer(contestId, questionId, param)
      );
    },
    getContestQuestions: (id) => {
      return dispatch(contestActions.getContestQuestions(id));
    },
    getContestQuestionSelections: (id) => {
      return dispatch(contestActions.getContestQuestionSelections(id));
    },
    addChatUser: (contestId, userIdList) => {
      return dispatch(contestActions.addChatUser(contestId, userIdList));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
