import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { routerReducer, routerMiddleware } from "react-router-redux";
import { createBrowserHistory as createHistory } from "history";
import { i18nState } from "redux-i18n";
import { composeWithDevTools } from "redux-devtools-extension";
import user from "redux/user";
import game from "redux/game";
import notification from "redux/notification";
import contest from "redux/contest";
import chat from "redux/chat";
import util from "redux/util";

const { NODE_ENV } = process.env;

const history = createHistory();

const middlewares = [thunk, routerMiddleware(history)];

if (NODE_ENV === "development") {
  // dev環境のみ redux-logger設定
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

const reducer = combineReducers({
  user,
  game,
  notification,
  contest,
  route: routerReducer,
  i18nState,
  chat,
  util
});

let store;

if (NODE_ENV === "development") {
  // dev環境のみ redux-devtools設定
  store = (initialState) =>
    createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
} else {
  store = (initialState) =>
    createStore(reducer, applyMiddleware(...middlewares));
}

export { history };

export default store();
