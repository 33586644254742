import React, { Component } from "react";
import HeaderMenu from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    notification: false,
    userNavigation: false,
    isAuth: false
  };

  render() {
    return (
      <HeaderMenu
        {...this.state}
        {...this.props}
        toggleNotification={this._toggleNotification}
        toggleUserNavigation={this._toggleUserNavigation}
        handleLogout={this._handleLogout}
        handleLogin={this._handleLogin}
        handleClose={this._handleClose}
      />
    );
  }

  closeNotification = () => {
    const { notification } = this.state;
    if (notification) {
      this.setState({
        notification: false
      });
    }
  };

  closeUserNavigation = () => {
    const { userNavigation } = this.state;
    if (userNavigation) {
      this.setState({
        userNavigation: false
      });
    }
  };

  _toggleNotification = () => {
    const { notification } = this.state;
    const { auth = {}, viewNotification } = this.props;

    if (!notification) {
      viewNotification(auth.id);
    }

    this.closeUserNavigation();
    this.setState({
      notification: !notification
    });
  };

  _toggleUserNavigation = () => {
    const { userNavigation } = this.state;

    this.closeNotification();
    this.setState({
      userNavigation: !userNavigation
    });
  };

  _handleLogout = () => {
    const { signOut } = this.props;
    const { history } = this.props;

    // deleteUserNotification();
    signOut();
    this.closeUserNavigation();
    history.push("/");
  };

  _handleLogin = () => {
    this.setState({
      isAuth: true
    });
  };

  _handleClose = () => {
    this.setState({
      isAuth: false
    });
  }

  static propTypes = {
    auth: PropTypes.object,
    signOut: PropTypes.func.isRequired,
  };
}

export default Container;
