import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators as userActions } from "redux/user";
import SendMagicLink from "./presenter";

class Container extends Component {
  state = {
    email: "",
    password: ""
  };

  render() {
    return (
      <SendMagicLink
        {...this.props}
        {...this.state}
        onInputChange={this._onInputChange}
        onSignIn={this._onSignIn}
      />
    );
  }

  _onInputChange = (event) => {
    event.preventDefault();
    const {
      target: { name, value }
    } = event;

    this.setState({
      [name]: value
    });
  };

  _onSignIn = async (event) => {
    event.preventDefault();
    const { email = "" } = this.state;
    const { sendMagicLink, handleClose } = this.props;

    if (!email || !this._validateEmail(email)) {
      alert("正しいメールアドレスを入力してください");
      return;
    }
    // デバイス一致確認用にメールアドレスを保存
    window.localStorage.setItem("emailForSignIn", email);
    await sendMagicLink(email);
    handleClose();
    alert(
      `${email} へサインインURLを送信しました。メールボックスを確認してください。`
    );
  };

  // バリデーションチェック処理追加
  _validateEmail = (email) => {
    const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailFormat.test(email);
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    sendMagicLink: (email) => {
      return dispatch(userActions.sendMagicLink(email));
    }
  };
};

export default connect(null, mapDispatchToProps)(Container);
