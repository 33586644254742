import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";

import Loading from "components/lib/Loading";


const SignInWithMagicLink = (props, context) => {
  return (
    props.loading ? (
      <Loading />
    ): (
      <div className={styles.contents}>
        {props.isAUthSuccess ? (
          <div className={styles.text}>{"サインインに成功しました"}</div>
        ) : (
          <div className={styles.text}>{"ログインに失敗しました"}</div>
        )
        }
      </div>
    )
  );
};

SignInWithMagicLink.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SignInWithMagicLink;
