import React, { Component } from "react";
import Notification from "./presenter";

class Container extends Component {
  state = {
    loading: false
  };

  render() {
    return (
      <Notification
        {...this.props}
        {...this.state}
        readNotification={this._readNotification}
        loadMore={this._loadMore}
      />
    );
  }

  // 次のデータ取得
  _loadMore = async () => {
    const { auth, getNotificationList } = this.props;

    await getNotificationList(auth.id);
  };

  // 通知 Item クリック
  _readNotification = (item = {}) => {
    const { id, readed } = item;
    const { readNotification, history } = this.props;

    if (!item.id) return;
    switch (item.notificationType) {
      case "tournament":
        if (item.status === "created") {
          // 大会詳細ページに遷移
          history.push(`/contests/${item.payload.contestId}/tournament`);
        } else if (item.status === "emptyResult") {
          // 対戦詳細ページに遷移
          history.push(`
            /contests/${item.payload.contestId}/tournament/${item.payload.tournamentId}/match/${item.payload.matchId}
          `);
        }
        break;
      case "swissDraw":
        if (["created", "startRound"].includes(item.status)) {
          // 大会詳細ページに遷移
          history.push(`/contests/${item.payload.contestId}/`);
        } else if (item.status === "emptyResult") {
          // 対戦詳細ページに遷移
          history.push(`
            /contests/${item.payload.contestId}/swissdraw/${item.payload.swissDrawId}/match/${item.payload.matchId}
            `);
        }
        break;
      case "battleRoyal":
      case "contest":
        // 大会詳細ページに遷移
        history.push(`/contests/${item.payload.contestId}/`);
        break;
      case "teamMember":
        // チームプロフィールページに遷移
        history.push(`/teams/${item.payload.teamId}/`);
        break;
      case "admin":
        if (item.status === "custom") {
          const { internalLink, externalLink } = item.payload;
          if (internalLink) {
            // 内部URLに遷移
            history.push(internalLink);
          }
          if (externalLink) {
            // 新しいタブを開き、外部URLに遷移
            window.open(externalLink, "_blank");
          }
        }
        break;
      default:
        break;
    }
    if (!readed) {
      // 既読処理
      readNotification(id);
    }
  };
}

export default Container;
