import React, { Component } from "react";
import PasswordReset from "./presenter";
import URLSearchParams from "url-search-params";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    loading: false,
    newPassword: "",
    confirmPassword: "",
    id: "",
    username: "",
    email: "",
    pincode: ""
  };

  async componentDidMount() {
    const {
      location: { search },
      getPasswordResetInfo
    } = this.props;

    // Query string を parsing
    const params = new URLSearchParams(search);
    const keyPhrase = params.get("key_phrase");
    // keyPhrase チェック
    const result = await getPasswordResetInfo(keyPhrase);
    if (result < 0) {
      const { t } = this.context;
      switch (result) {
        case -2:
          alert(t("expiredPasswordResetLink"));
          break;
        default:
          alert(t("invalidPasswordResetLink"));
          break;
      }
      // ホーム画面リダイレクト
      window.location.href = "/";
      return;
    }
    const { id, username, email, pincode } = result;

    this.setState({
      id,
      username,
      email,
      pincode
    });
  }

  render() {
    return (
      <PasswordReset
        {...this.state}
        handleInputChange={this._handleInputChange}
        handleSubmit={this._handleSubmit}
      />
    );
  }
  // input 変更
  _handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;

    this.setState({
      [name]: value
    });
  };

  // 設定ボタンクリック
  _handleSubmit = async (event) => {
    event.preventDefault();

    const { resetUserPassword } = this.props;
    const {
      id,
      username,
      email,
      pincode,
      newPassword,
      confirmPassword
    } = this.state;
    const { t } = this.context;

    // パスワードの正規表現
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,32}$/;
    if (newPassword !== confirmPassword) {
      // パスワードとパスワード確認が一致しない場合
      alert(t("failedPasswordConfirm"));
      return;
    } else if (!regex.test(newPassword)) {
      //  // 新パスワードが 半角英字および数字を含まない場合
      return alert(t("invalidPasswordFormat"));
    }

    this.setState({
      loading: true
    });

    const param = {
      id,
      username,
      email,
      pincode,
      newPassword,
      passwordConfirm: confirmPassword
    };

    const result = await resetUserPassword(param);
    if (result < 0) {
      switch (result) {
        case -1:
          // パスワード必須チェック
          alert(t("requiredNewPassword"));
          break;
        case -2:
          // パスワード確認必須チェック
          alert(t("requiredPasswordConfirm"));
          break;
        case -3:
          // パスワード、パスワード確認が不一致
          alert(t("failedPasswordConfirm"));
          break;
        case -5:
          // パスワード formatが一致しない場合
          alert(t("invalidPasswordFormat"));
          break;
        default:
          // 設定失敗
          alert(t("failedPasswordReset"));
      }
      return;
    }
    // 設定成功
    alert(t("completeUpdatePassword"));
    // ホーム画面リダイレクト
    window.location.href = "/";

    this.setState({
      loading: false
    });
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };

  static propsTypes = {
    resetUserPassword: PropTypes.func.isRequired,
    getPasswordResetInfo: PropTypes.func.isRequired
  };
}

export default Container;
