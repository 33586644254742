import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: {
      contestInfo = {},
      myRole = {},
      tournamentInfo = {},
      battleRoyalInfo = {},
      swissDrawInfo = {},
      operatorList = []
    },
    user: { isSignedIn, auth = {} },
    game: { gameList = [] }
  } = state;

  return {
    auth,
    myRole,
    contestInfo,
    isSignedIn,
    gameList,
    tournamentInfo,
    battleRoyalInfo,
    swissDrawInfo,
    operatorList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearContestInfo: () => {
      dispatch(contestActions.clearContestInfo());
    },
    getContestDetail: (id) => {
      return dispatch(contestActions.getContestDetail(id));
    },
    getBattleRoyalInfo: (id) => {
      return dispatch(contestActions.getBattleRoyalInfo(id));
    },
    getTournamentInfo: (id) => {
      return dispatch(contestActions.getTournamentInfo(id));
    },
    getSwissDrawInfo: (id) => {
      return dispatch(contestActions.getSwissDrawInfo(id));
    },
    createBattleRoyalMatch: (battleRoyalInfo, entryteams) => {
      return dispatch(
        contestActions.createBattleRoyalMatch(battleRoyalInfo, entryteams)
      );
    },
    getContestOperators: (contestId) => {
      return dispatch(contestActions.getContestOperators(contestId));
    },
    getContestQuestionChatInfo: (contestId) => {
      return dispatch(contestActions.getContestQuestionChatInfo(contestId));
    },
    getContestNoticeChatInfo: (contestId) => {
      return dispatch(contestActions.getContestNoticeChatInfo(contestId));
    },
    contestUserCheckin: (contestId, contestUserId, params) => {
      return dispatch(
        contestActions.contestUserCheckin(contestId, contestUserId, params)
      );
    },
    contestTeamCheckin: (contestId, teamId, params) => {
      return dispatch(
        contestActions.contestTeamCheckin(contestId, teamId, params)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
