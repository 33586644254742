import React, { Component } from "react";
import SocialLogin from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  render() {
    return (
      <SocialLogin
        {...this.state}
        handleTwitterLogin={this._handleTwitterLogin}
        action={this.props.action}
      />
    );
  }

  _handleTwitterLogin = (event) => {
    event.preventDefault();
    this._twitterOAuth();
  };

  _twitterOAuth = async () => {
    const { signInWithTwitter } = this.props;
    signInWithTwitter();
  };

  static propTypes = {
    socialOauth: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
