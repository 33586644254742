import React, { Component } from "react";
import Footer from "./presenter";

class Container extends Component {
  state = {
    langList: ["ja", "en", "ko"],
    lang: ""
  };

  componentDidMount() {
    const { lang } = this.props;
    this.setState({ lang });
  }

  render() {
    return (
      <Footer {...this.state} handleSelectChange={this._handleSelectChange} />
    );
  }

  // 言語選択
  _handleSelectChange = (event) => {
    const {
      target: { name, value }
    } = event;
    const { setLanguage } = this.props;

    this.setState({
      [name]: value
    });
    // i18n 言語更新
    setLanguage(value);

    // localStorage 言語更新
    localStorage.setItem(String([name]), value);
  };
}

export default Container;
