import Moment from "moment";
import { extendMoment } from "moment-range";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";

const {
  REACT_APP_FIREBASE_STORAGE_BUCKET,
} = process.env;

const moment = extendMoment(Moment);

/**
 * Firestore Timestampを 表示用Timeに変換
 * @param {Firestore.Timestamp} ts 変換する値
 * @param {String} format 変換する形式
 * @param {Date} defaultTs デフォルト値
 * @return {String} displayTime 表示用時間
 */
function convertFirestoreTime(
  ts,
  format = "YYYY/MM/DD HH:mm:ss",
  defaultTs = Date.now()
) {
  const displayTime = ts
    ? moment(ts.toDate()).format(format)
    : moment(defaultTs).format(format);

  return displayTime;
}

/**
 * クライアント時間を指定のフォーマットに変換
 * @param {String} dateTime クライアント時間
 * @param {String} format 変換する形式
 * @return {String} local時間
 */
function convertClientDateTime(dateTime, format = "YYYY-MM-DDTHH:mm") {
  if (!dateTime) {
    return;
  }
  return moment(dateTime).format(format);
}

/**
 * 開始/終了時刻で期間を生成
 * @param {String} start 開始時刻
 * @param {String} end 終了時刻
 * @param {String} format 変換する形式
 * @return {String} duration 期間
 */
function convertDuration(start, end, format = "YYYY/MM/DD HH:mm:ss") {
  const startText = start ? moment(new Date(start)).format(format) : null;
  const endText = end ? moment(new Date(end)).format(format) : null;

  const duration = startText && endText ? `${startText} ~ ${endText}` : "-";

  return duration;
}

/**
 * 期間内に含まれてるのかチェック
 * @param {DateTime} start 開始時刻
 * @param {DateTime} end 終了時刻
 * @param {DateTime} value 比較時刻
 * @return {Boolean} result 結果
 */
function isWithinDuration(start, end, value = new Date()) {
  const startDate = start ? new Date(start) : null;
  const endDate = end ? new Date(end) : null;
  const range = moment.range(startDate, endDate);

  const result = range.contains(value);

  return result;
}

/**
 * オブジェクトの配列を指定keyでグルーピングして、カウントを取る
 * @param {Array} array 配列データ
 * @param {String} key グルーピングキー
 * @return {Object} result 結果
 */
function convertGroupByCount(array, key) {
  return array.reduce((result, current) => {
    const name = current[key];
    if (!Object.prototype.hasOwnProperty.call(result, "name")) {
      result[name] = 0;
    }
    result[name]++;
    return result;
  }, {});
}

/**
 * ゲーム別ゲームユーザ名の Keyを返す
 * @param {String} gameKey ゲームキー
 * @return {String} key ゲームユーザ名のKey
 */
function convertGameUserNameKey(gameKey) {
  let key;
  switch (gameKey) {
    // League of Legends
    case "dbGameMasterValue00001":
      // サモナーネーム
      key = "gameAccount00001";
      break;
    // Rainbow Six Siege
    case "dbGameMasterValue00004":
      // Uplay アカウント
      key = "gameAccount00004";
      break;
    // 荒野行動
    case "dbGameMasterValue00006":
      // ユーザ名（荒野名）
      key = "gameAccount00006";
      break;
    // Call of Duty: Mobile
    case "dbGameMasterValue00014":
      // IGN
      key = "gameAccount00014";
      break;
    default:
      // ゲームアカウント
      key = "gameAccount";
      break;
  }
  return key;
}

const convertComfortableDateTime = (datetime) => {
  if (moment(new Date()).isSame(datetime, "day")) {
    // 更新日が本日の場合
    return moment(datetime).format("HH:mm");
  } else if (
    moment(new Date())
      .subtract(1, "days")
      .isSame(datetime, "day")
  ) {
    return "昨日";
  } else {
    return moment(datetime).format("MM/DD");
  }
};

/**
 * 文字列を省略
 * @param {String} text 文字列
 * @param {Number} limit 表示する文字数
 * @return {String} result 結果
 */
const trimText = (text = "", limit) => {
  if (!text) {
    return "";
  }
  return text.length > limit ? `${text.slice(0, limit)}...` : text;
};

/**
 * 先頭の半角@と全角＠を取り除く
 * @param {String} text 加工前のテキスト
 */
const removeAtSign = (text) => {
  if (!text) return "";
  return text.replace(/^[@＠]/g, "");
};

/**
 * 大会のデフォルト画像取得
 * @param {String} gameKey ゲームキー
 * @returns
 */
const getContestDefaultImage = (gameKey) => {
  if (gameKey) {
    return `${process.env.REACT_APP_GCS_PATH}/game/${gameKey}.jpg`;
  } else {
    return require("images/background_noimage.png");
  }
};

/**
 * ファイル取得 URLに API のbaseURLを設定
 * @param {String} text 加工前のファイル取得URL
 * @return {String} fileUrl ファイル取得URL
 */
const convertFileUrl = (text) => {
  if (!text) return;
  // /api/v1/files/ で始まる URLは API_BASEURL を先頭に付ける
  const fileUrl = text.replace(
    /\/api\/v1\/files\//g,
    process.env.REACT_APP_API_HOST + "/api/v1/files/"
  );
  return fileUrl;
};

/**
 * ランダムコード取得
 * @param {Integer} length コードの長さ
 * @return {String} code 生成したコード
 */
const generateRandomCode = (length = 6) => {
  // デフォルトで 6文字ランダムコード設定
  //元になる文字
  const source = "0123456789";
  let code = "";
  for (let i = 0; i < length; i++) {
    code += source[Math.floor(Math.random() * source.length)];
  }
  return code;
};

/**
 * ファイルアップロード
 * @param {Object} file ファイルオブジェクト
 * @param {String} prefix ディレクトリ
 * @return {String} image イメージ path
 */
const uploadFile = async (file, prefix) => {
  if(!file) return null;
  const storage = getStorage();
  // TODO: UUID でランダム発行
  const fileName = uuidv4().replace(/-/g, "");
  let filePath = "";
  if (prefix) {
    filePath += `${prefix}/`;
  }
  filePath += fileName;
  const storageRef = ref(storage, filePath);

  // 'file' comes from the Blob or File API
  const snapshot = await uploadBytes(storageRef, file);
  const { metadata : { fullPath } } = snapshot;
  return fullPath;
};

// ファイルダウンロード
const downloadFile = async (filePath) => {
  try {
    if(!filePath) return;
    const storage = getStorage();
    const url = await getDownloadURL(ref(storage, filePath));
    return url;
  } catch (err) {
    return;
  }
};

// 公開ファイルの取得用 URL 取得
const getPublicImageUrl = (filePath) => {
  try {
    if(!filePath) return;
    const url = `https://storage.googleapis.com/${REACT_APP_FIREBASE_STORAGE_BUCKET}/${filePath}`
    return url;
  } catch (err) {
    return;
  }
}

export {
  convertFirestoreTime,
  convertClientDateTime,
  convertDuration,
  isWithinDuration,
  convertGroupByCount,
  convertGameUserNameKey,
  convertComfortableDateTime,
  trimText,
  removeAtSign,
  getContestDefaultImage,
  convertFileUrl,
  generateRandomCode,
  uploadFile,
  downloadFile,
  getPublicImageUrl
};
