import React, { Component } from "react";
import CommonUploadMatchResult from "./presenter";
import { uploadFile } from "lib/convert";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    isUnearned: false,
    winner: "",
    matchList: [],
    isLoading: false
  };

  async componentDidMount() {
    const {
      matchInfo: { matchCount = 1, reports = [], winner, unearned = false }
    } = this.props;

    const matchList = reports;
    if (!matchList.length) {
      // matchCountで枠作成
      for (let i = 0; i < matchCount; i++) {
        matchList.push({
          index: i,
          images: [],
          winner: ""
        });
      }
    }

    // 初期値設定
    this.setState({
      matchList,
      winner: winner || "",
      isUnearned: unearned
    });
  }

  render() {
    return (
      <CommonUploadMatchResult
        {...this.state}
        {...this.props}
        handleToggleType={this._handleToggleType}
        handleSelectWinner={this._handleSelectWinner}
        handleFileChange={this._handleFileChange}
        handleSubmit={this._handleSubmit}
      />
    );
  }

  // 不戦勝フラグ toggle
  _handleToggleType = () => {
    const { isUnearned } = this.state;
    this.setState({
      isUnearned: !isUnearned,
      winner: ""
    });
  };

  // 勝利者入力イベント
  _handleSelectWinner = (participantId, matchIndex, winType) => {
    if (winType === "round") {
      this.setState({
        winner: participantId
      });
    } else if (winType === "match") {
      const { matchList = [] } = this.state;
      const newMatchList = [...matchList];
      newMatchList[matchIndex].winner = participantId || "";
      this.setState({
        matchList: newMatchList
      });
    }
  };

  // 画像選択
  _handleFileChange = async (index, event) => {
    const {
      target: { files }
    } = event;
    const { auth: { id } } = this.props;
    const { matchList } = this.state;
    // キャンセルボタンのとき、Stateを更新しない
    if (files.length === 0) {
      return;
    }
    this.setState({
      isLoading: true
    });

    const image = await uploadFile(files[0], "matches");

    const createObjectURL =
    (window.URL || window.webkitURL).createObjectURL ||
    window.createObjectURL;
    const imageUrl = files.length ? createObjectURL(files[0]) : null;

    if (image) {
      if (!matchList[index].images) {
        matchList[index].images = [];
      }
      matchList[index].images.push({
        image,
        imageUrl, // TODO: 削除予定
        editBy: id,
        updated: new Date()
      });
      this.setState({
        matchList
      });
    }

    this.setState({
      isLoading: false
    });
  };

  // 保存ボタンクリック
  _handleSubmit = (event) => {
    event.preventDefault();
    this._saveResult();
  };

  _saveResult = async () => {
    const {
      contestInfo: { contestType },
      resourceInfo: { id: resourceId, currentRound, roundInfo, roundStatus },
      matchInfo: { id: matchId, unearned = false, round },
      updateResource,
      handleClose,
    } = this.props;
    const { t } = this.context;
    const { isUnearned, winner, matchList } = this.state;

    // スイスドローはcurrentRoundかつ全てのラウンドが終了していない場合は編集可能
    if (
      contestType === "SWISSDRAW" &&
      (round !== currentRound ||
        (currentRound === roundInfo.length - 1 && roundStatus === "end"))
    ) {
      const { t } = this.context;
      alert(t("finishedRound"));
      return;
    }

    this.setState({
      isLoading: true
    });
    
    const reports = matchList.map(el => {
      el.images.map(image => {
        delete image.imageUrl;
        return image;
      });
      return el;
    });

    const param = {
      winner,
      reports
    };
    if (isUnearned !== unearned) {
      // 不戦勝フラグを付ける
      param.unearned = isUnearned;
    }
    // 結果更新
    await updateResource(resourceId, matchId, param);

    alert(t("completeUpdateMatchResult"));
    this.setState({
      isLoading: false
    });

    // モーダルを閉じる
    handleClose();
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
