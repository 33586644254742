import React, { Component } from "react";
import UserProfile from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    loading: true,
    openMenu: false,
    isChecked: false
  };

  componentDidMount() {
    this._getUserInfo();
  }

  componentDidUpdate = (prevProps, prevState) => {
    // 同一Component内で更新
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this._getUserInfo();
    }
  };

  componentWillUnmount() {
    const { clearUserInfo } = this.props;
    // ユーザ関連storeをクリア
    clearUserInfo();
  }

  render() {
    return <UserProfile {...this.state} {...this.props} />;
  }

  // ユーザ詳細情報取得
  _getUserInfo = async () => {
    const {
      getUserInfo,
      match: {
        params: { id }
      }
    } = this.props;

    await getUserInfo(id);
    this.setState({
      loading: false
    });
  };

  static propTypes = {
    userInfo: PropTypes.object.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    clearUserInfo: PropTypes.func.isRequired
  };
}

export default Container;
