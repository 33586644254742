import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/user";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {}, userInfo = {} },
    game: { gameList = [], gameRankList = [] }
  } = state;
  return {
    gameList,
    gameRankList,
    auth,
    userInfo
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getUserInfo: (id) => {
      return dispatch(userActions.getUserInfo(id));
    },
    clearUserInfo: () => {
      dispatch(userActions.clearUserInfo());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
