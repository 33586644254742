import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: {
      contestInfo = {},
      tournamentInfo = {},
      tournamentMatchList = [],
      myTeams = [],
      myRole = {}
    }
  } = state;

  return {
    contestInfo,
    tournamentInfo,
    tournamentMatchList,
    myTeams,
    myRole
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    subscribeTournamentMatchList: (id) => {
      dispatch(contestActions.subscribeTournamentMatchList(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
