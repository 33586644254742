import React, { Component } from "react";
import UserProfileDetail from "./presenter";

class Container extends Component {
  // state = {
  //   relatedContestInfo: {
  //     user: 0,
  //     team: 0,
  //     operator: 0
  //   }
  // };

  async componentDidMount() {
    const {
      userInfo: { id }
      // getRelatedContestCount
    } = this.props;
    if (!id) return;
    // const relatedContestInfo = await getRelatedContestCount(id);
    // this.setState({ relatedContestInfo });
  }

  render() {
    return (
      <UserProfileDetail
        {...this.props}
        // {...this.state}
        handleEditButton={this._handleEditButton}
      />
    );
  }

  // 編集画面の表示
  _handleEditButton = (event) => {
    const {
      history,
      userInfo: { id }
    } = this.props;

    event.preventDefault();
    history.push(`/users/${id}/edit`);
  };
}

export default Container;
