import React from "react";
import styles from "./styles.scss";
import Loading from "components/lib/Loading";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Button from "components/lib/Button";

const cx = classNames.bind(styles);

const EditTournamentMatch = (props, context) => (
  <div>
    {props.loading ? (
      <Loading />
    ) : (
      <div className={styles.contents}>
        <div className={styles.title}>{context.t("editTournament")}</div>
        <div className={styles.form}>
          <div className={styles.input}>
            <p>{context.t("slot")}</p>
            <select name="slotCount" value={props.slotCount} disabled>
              {props.slotCountList &&
                props.slotCountList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.input}>
            <p>{context.t("countOfMatch")}</p>
            <select name="matchCount" value={props.matchCount} disabled>
              {props.matchCountList &&
                props.matchCountList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.input}>
            <p>{context.t("timeOfMatch")}</p>
            <select
              name="matchTime"
              value={props.matchTime || props.matchTimeList[0]}
              disabled
            >
              {props.matchTimeList &&
                props.matchTimeList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.tournament}>
            {props.tournament.map((roundItem, roundIndex) => (
              <div
                className={cx(styles.round, {
                  finalRound: roundIndex === props.tournament.length - 1
                })}
                key={roundIndex}
              >
                <div className={styles.roundInfo}>
                  <div className={styles.roundTitle}>
                    {roundIndex === props.tournament.length - 1
                      ? context.t("final")
                      : context.t("roundValue", { value: roundIndex + 1 })}
                  </div>
                  <div>
                    <input
                      type="datetime-local"
                      className={styles.startTime}
                      placeholder={context.t("startTime")}
                      onChange={(e) => props.handleTimeChange(roundIndex, e)}
                      value={roundItem.startTime}
                    />
                  </div>
                </div>
                {roundItem.match.map((matchItem, matchIndex) => (
                  <React.Fragment key={matchIndex}>
                    {matchItem.type === "thirdPlaceMatch" ? (
                      <div
                        className={cx(styles.roundInfo, {
                          thirdPlaceMatch: matchItem.type === "thirdPlaceMatch"
                        })}
                      >
                        <div className={styles.roundTitle}>
                          {context.t("thirdPlaceMatch")}
                        </div>
                        <div>
                          <input
                            type="datetime-local"
                            className={styles.startTime}
                            placeholder={context.t("startTime")}
                            onChange={(e) =>
                              props.handleTimeChange(roundIndex, e)
                            }
                            id="thirdPlaceMatch"
                            value={roundItem.thirdPlaceMatchStartTime}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={cx(styles.match, {
                        thirdPlaceMatch: matchItem.type === "thirdPlaceMatch"
                      })}
                      key={matchIndex}
                    >
                      {props.resource === "teams"
                        ? matchItem.teams.map((teamItem, index) => (
                          <select
                            key={index}
                            className={styles.slot}
                            value={teamItem.id || ""}
                            onChange={(e) =>
                              props.handleSlotChange(
                                { index, matchIndex, ...teamItem },
                                e
                              )
                            }
                            disabled={
                              !(roundItem.round === 0) ||
                                "reports" in matchItem
                                ? true
                                : false
                            }
                          >
                            <option key="" value="" />
                            {matchItem.activeList.map((active, activeIndex) =>
                              "name" in active ? (
                                <option key={activeIndex} value={active.id}>
                                  {active.name}
                                </option>
                              ) : null
                            )}
                          </select>
                        ))
                        : matchItem.users.map((userItem, index) => (
                          <select
                            key={index}
                            className={styles.slot}
                            value={userItem.id || ""}
                            onChange={(e) =>
                              props.handleSlotChange(
                                { index, matchIndex, ...userItem },
                                e
                              )
                            }
                            disabled={
                              !(roundItem.round === 0) ||
                                "reports" in matchItem
                                ? true
                                : false
                            }
                          >
                            <option key="" value="" />
                            {matchItem.activeList.map((active, activeIndex) =>
                              "nickName" in active ? (
                                <option key={activeIndex} value={active.id}>
                                  {active.nickName}
                                </option>
                              ) : null
                            )}
                          </select>
                        ))}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.buttonList}>
            <Button
              style={{ margin: 5 }}
              className={styles.button}
              text={context.t("back")}
              onClick={props.handleCancel}
              disabled={props.editing}
            />
            <Button
              style={{ margin: 5 }}
              className={styles.button}
              text={context.t("save")}
              onClick={props.handleEditTournament}
              disabled={props.editing}
            />
            <Button
              style={{ margin: 5 }}
              className={styles.button}
              text={context.t("delete")}
              onClick={props.handleDeleteTournament}
              disabled={props.editing}
            />
          </div>
          {props.editing ? (
            <div>
              <div className={styles.editing}>
                {context.t("editingTournament")}
              </div>
              <Loading />
            </div>
          ) : null}
        </div>
      </div>
    )}
  </div>
);

EditTournamentMatch.propTypes = {
  slotCountList: PropTypes.array.isRequired,
  slotCount: PropTypes.number.isRequired,
  tournament: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

EditTournamentMatch.contextTypes = {
  t: PropTypes.func.isRequired
};

export default EditTournamentMatch;
