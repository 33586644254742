import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import Loading from "components/lib/Loading";
import Button from "components/lib/Button";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

// AsyncSelect カスタム style
const customStyles = {
  // selectbox
  control: (provided) => ({
    ...provided,
    backgroundColor: "#030C1A",
    color: "#fff",
    border: "solid 1px #9A9A9A",
    borderRadius: "3px",
    boxSizing: "border-box",
    padding: "0 10px",
    WebkitAppearance: "none",
    appearance: "none",
    fontSize: "14px"
  }),
  // 入力フォームのテキスト
  input: (provided) => ({
    ...provided,
    color: "#fff"
  }),
  // 選択した値のテキスト
  multiValueLabel: (provided) => ({
    ...provided,
    backgroundColor: "#FE8D1E",
    color: "#fff",
    borderTopRightRadius: "initial",
    borderBottomRightRadius: "initial"
  }),
  // 選択した値のxボタン
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#FE8D1E",
    color: "#fff",
    borderTopLeftRadius: "initial",
    borderBottomLeftRadius: "initial"
  }),
  // select option
  menu: (provided) => ({
    ...provided,
    color: "#000"
  })
};

const ContestOperatorEdit = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.buttonList}>
      <Button text={context.t("delete")} onClick={props.handleDeleteOperator} disabled={!props.deleteList.length} />
      <Button text={context.t("cancel")} onClick={props.handleCancel} />
    </div>
    <div className={styles.addOperatorLayout}>
      <div className={styles.filter}>
        <AsyncSelect
          className={styles.select}
          isMulti={true}
          defaultOptions={false}
          loadOptions={props.searchUser}
          onChange={props.handleSelectChange}
          value={props.addList}
          styles={customStyles}
        />
        <div className={styles.buttonLayout}>
          <Button
            disabled={!props.addList.length || props.buttonLoading}
            onClick={props.handleAddOperator}
          >
            {context.t("add")}
          </Button>
        </div>
      </div>
    </div>
    <div className={styles.operatorList}>
      {props.loading ? (
        <Loading />
      ) : (
        props.operatorList.length
          ? props.operatorList.map((val, index) => (
              <label
                key={index}
                className={styles.item}
                htmlFor={`checkbox_${val.id}`}
              >
                { val.userId !== props.auth.id ?
                  <input
                    type="checkbox"
                    id={`checkbox_${val.id}`}
                    className={styles.checkbox}
                    value={val.id}
                    onChange={(event) => props.handleCheck(val, event)}
                    checked={props.deleteList.some((el) => el.id === val.id)}
                  />
                  : null
                }
                <div
                  className={styles.userInfo}
                >
                  <span className={styles.imageLayout}>
                    <img
                      src={require("images/noimage.png")}
                      alt="profile"
                      className={styles.image}
                      onError={(e) =>
                        (e.target.src = require("images/noimage.png"))
                      }
                    />
                  </span>
                  <div className={styles.nameContainer}>
                    <span
                      className={cx(styles.nickname, {
                        me: props.auth.id === val.userId
                      })}
                    >
                      {val.nickName}
                    </span>
                  </div>
                  <div className={styles.roleContainer}>
                    <span className={styles.roleId}>
                      {context.t(val.roleId)}
                    </span>
                  </div>
                </div>
              </label>
            ))
          : null
      )}
    </div>
  </div>
);

ContestOperatorEdit.propTypes = {
  addList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

ContestOperatorEdit.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestOperatorEdit;
