import React, { Component } from "react";
import SearchUser from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    gameId: "",
    keyword: "",
    gameAccount: "",
    userList: [],
    isDetail: false,
    query: {
      keyword: "",
      lastData: null,
      limit: 50
    },
    hasMore: false,
    searchStatus: "close",
    loading: false
  };
  async componentDidMount() {
    this.setState({
      hasMore: true
    });
    // TODO: setStateが同期で実行できれば、loadMoreへ統合
    this._searchUser();
  }
  render() {
    return (
      <SearchUser
        {...this.state}
        {...this.props}
        onSubmit={this._onSubmit}
        onInputChange={this._onInputChange}
        onSelectChange={this._onSelectChange}
        handleKeyPress={this._handleKeyPress}
        handleToggle={this._handleToggle}
        loadMore={this._loadMore}
        switchStatusFilter={this._switchStatusFilter}
        handleSelectChange={this._handleSelectChange}
      />
    );
  }
  // status filter
  _switchStatusFilter = async (searchStatus) => {
    if (!searchStatus) {
      return;
    }
    this.setState({
      searchStatus
    });
  };

  // Inputbox の入力値更新
  _onInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    this.setState({
      [name]: value
    });
  };

  // Selectbox の選択値更新
  _onSelectChange = (event) => {
    const {
      target: { name, value }
    } = event;
    this.setState({
      [name]: value
    });
  };

  // game filter
  _handleSelectChange = async (event) => {
    const {
      target: { name, value }
    } = event;

    this.setState(
      {
        [name]: value
      },
      () => {
        this._searchUser();
      }
    );
  };

  // Inputbox の Enterキー対応
  _handleKeyPress = (event) => {
    const { key } = event;

    if (key === "Enter") {
      this._onSubmit(event);
    }
  };

  // 詳細フィルター Toggle
  _handleToggle = () => {
    const { isDetail } = this.state;

    // 詳細フィルター初期化
    if (isDetail) {
      this.setState({
        gameId: "",
        gameAccount: ""
      });
    }

    this.setState({
      isDetail: !isDetail
    });
  };

  // Submit
  _onSubmit = (event) => {
    // default event 無効
    event.preventDefault();

    this.setState({
      hasMore: true
    });

    // TODO: setStateが同期で実行できれば、loadMoreへ統合
    this._searchUser();
  };

  // 検索実行
  _searchUser = async () => {
    const { searchUser } = this.props;
    const { keyword = "" } = this.state;
    const query = {
      keyword,
      lastData: null,
      limit: 50
    };

    this.setState({
      loading: true
    });

    const { userList = [], lastData } = await searchUser(query);

    const length = userList?.length || 0;
    query.lastData = lastData;
    const hasMore = query.limit > length ? false : true;

    this.setState({
      userList,
      query,
      hasMore,
      loading: false
    });
  };

  // Loadmore
  _loadMore = async () => {
    const { searchUser } = this.props;
    const { query = {}, userList } = this.state;
    const { userList : newUserList = [], lastData } = await searchUser(query);

    const length = newUserList?.length || 0;
    query.lastData = lastData;
    const hasMore = query.limit > length ? false : true;

    this.setState({
      userList: userList.concat(newUserList),
      query,
      hasMore
    });
  };

  static propTypes = {
    searchUser: PropTypes.func.isRequired
  };
}

export default Container;
