import React from "react";
import styles from "./styles.scss";
import { Route, Switch } from "react-router-dom";
import ContestList from "components/ContestList";
import ContestDetail from "components/ContestDetail";
import ContestRegister from "components/ContestRegister";
import PropTypes from "prop-types";

const Contest = (props) => (
  <div>
    <div className={styles.contents}>
      <Routes {...props} />
    </div>
  </div>
);

const Routes = (props) => (
  <Switch>
    <Route exact path="/contests" render={() => <ContestList {...props} />} />
    <Route
      exact
      path="/contests/registration"
      render={() => <ContestRegister {...props} />}
    />
    <Route path="/contests/:id(\w+)" render={({ match }) => <ContestDetail {...props} match={match} />} />
    <Route render={() => <ContestList {...props} />} />
  </Switch>
);

Contest.propTypes = {
  auth: PropTypes.object.isRequired
};

export default Contest;
