import React from "react";
import styles from "./styles.scss";
import UserContract from "components/UserContract";

const Contract = (props) => (
  <div className={styles.contents}>
    <div className={styles.contractLayout}>
      <UserContract {...props} />
    </div>
  </div>
);

export default Contract;
