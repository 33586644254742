import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {} },
    contest: {
      contestInfo = {},
      contestTeams = [],
      tournamentInfo = {},
      tournamentMatchInfo = {}
    }
  } = state;

  return {
    auth,
    contestInfo,
    contestTeams,
    tournamentInfo,
    tournamentMatchInfo
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentInfo: (contestId) => {
      return dispatch(contestActions.getTournamentInfo(contestId));
    },
    getTournamentMatchInfo: (tournamentId, matchId) => {
      return dispatch(
        contestActions.getTournamentMatchInfo(tournamentId, matchId)
      );
    },
    clearTournamentMatchInfo: () => {
      dispatch(contestActions.clearTournamentMatchInfo());
    },
    updateTournament: (tournamentId, matchId, params) => {
      return dispatch(
        contestActions.updateTournament(tournamentId, matchId, params)
      );
    },
    getContestUserInfo: (contestId, userId) => {
      return dispatch(contestActions.getContestUserInfo(contestId, userId));
    },
    getContestTeamInfo: (contestId, teamId) => {
      return dispatch(contestActions.getContestTeamInfo(contestId, teamId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
