import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { FaTwitter } from "react-icons/fa";
import Button from "components/lib/Button";

const SocialLogin = (props, context) => (
  <React.Fragment>
    <div className={styles.contents}>
      <div className={styles.socialLogin}>
        <Button
          style={{
            width: "100%",
            display: "flex",
            border: "none",
            borderRadius: 5,
            textAlign: "center",
            alignItems: "center",
            color: "#ffffff",
            background: "initial",
            backgroundColor: "#1da1f2",
          }}
          className={styles.twitterLoginButton}
          onClick={props.handleTwitterLogin}
        >
          <div className={styles.socialIcon}>
            <FaTwitter />
          </div>
          {props.action === "login" ? (
            <div className={styles.buttonText}>
              {context.t("loginWithTwitter")}
            </div>
          ) : null}
          {props.action === "signup" ? (
            <div className={styles.buttonText}>
              {context.t("signupWithTwitter")}
            </div>
          ) : null}
        </Button>
      </div>
      <div className={styles.border}> {context.t("OR")} </div>
    </div>
  </React.Fragment>
);

SocialLogin.propTypes = {
  handleTwitterLogin: PropTypes.func.isRequired
};

SocialLogin.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SocialLogin;
