import { axios } from "lib/axios";

// image アップロード
function uploadFile(formData) {
  return (dispatch, getState) => {
    return axios
      .post("/api/v1/files/", formData, {
        headers: {
          "content-type": "multipart/form-data"
        }
      })
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err.response.data;
      });
  };
}

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

// exports
const actionCreators = {
  uploadFile
};

export { actionCreators };

export default reducer;
