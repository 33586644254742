import React, { Component } from "react";
import ContestRegister from "./presenter";
import PropTypes from "prop-types";
import moment from "moment";
import { downloadFile, generateRandomCode, uploadFile } from "lib/convert";

class Container extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    isLoading: false,
    templateSelectList: [],
    templateContestList: [],
    image: "",
    imageUrl: "",
    uploadedFile: null,
    isChangedImage: false,
    contestType: "TOURNAMENT",
    participantType: "SOLO",
    gameTitle: 0,
    name: "",
    entryStart: "",
    entryEnd: "",
    contestStart: "",
    contestEnd: "",
    checkinStart: "",
    checkinEnd: "",
    isEntryPassword: false,
    entryPassword: "",
    teamSize: 2,
    entryMaxCount: 16,
    discordUrl: "",
    iframeSrc: "",
    matchCount: 1,
    rankPoint: [
      { rank: 1, point: 0 },
      { rank: 2, point: 0 }
    ],
    killPoint: { point: 0, max: "" },
    award: "",
    rule: "",
    contestTypeList: [
      { text: "tournament", value: "TOURNAMENT" },
    ],
    participantTypeList: [
      {
        text: "singleMatch",
        value: "SOLO",
        contestType: ["TOURNAMENT"]
      }
    ],
    matchCountList: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20
    ],
    selectedGameName: "",
    isCheckin: false,
    isRecommend: false
  };

  async componentDidMount() {
    const { gameList } = this.props;

    // 期間のデフォルト設定
    const entryStart = moment().format("YYYY-MM-DDTHH:00");
    const entryEnd = moment(new Date(entryStart))
      .add(2, "hours")
      .format("YYYY-MM-DDTHH:00");
    const contestEnd = moment(new Date(entryEnd))
      .add(3, "hours")
      .format("YYYY-MM-DDTHH:00");
    const gameTitle = gameList.length ? gameList[0].id : 0;
    this.setState({
      gameTitle,
      templateSelectList: [
        {
          label: this.context.t("doNotUseContestTemplate"),
          value: ""
        }
      ],
      entryStart,
      entryEnd,
      contestStart: entryEnd,
      contestEnd
    });

    this._setContestTemplateList();
    this._setSelectGameName(gameTitle);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isCheckin && this.state.isCheckin) {
      // チェックインが無効から有効になった場合
      this._setCheckinDuration();
    }
    if (!prevState.isEntryPassword && this.state.isEntryPassword) {
      // パスワードが有効になった場合
      if (!this.state.entryPassword) {
        // パスワードが設定されてない場合新規発行
        const entryPassword = generateRandomCode(6);
        this.setState({
          entryPassword
        });
      }
    }
  }

  render() {
    return (
      <ContestRegister
        {...this.props}
        {...this.state}
        myRef={this.myRef}
        handleInputChange={this._handleInputChange}
        handleInputentryMaxCountChange={this._handleInputentryMaxCountChange}
        handleInputRankPointChange={this._handleInputRankPointChange}
        handleInputKillPointChange={this._handleInputKillPointChange}
        handleFileChange={this._handleFileChange}
        handleImageReset={this._handleImageReset}
        handleSubmit={this._handleSubmit}
        getRankPointTableHeight={this._getRankPointTableHeight}
        handleClickInputFile={this._handleClickInputFile}
        handleChangeInputFile={this._handleChangeInputFile}
        handleDragDrop={this._handleDragDrop}
        handleCheck={this._handleCheck}
      />
    );
  }

  // 大会テンプレート一覧設定
  _setContestTemplateList = async () => {
    const { auth, getContestTemplateList } = this.props;
    // 運営大会一覧取得
    const templateList = await getContestTemplateList(auth.id) || [];
    const templateSelectList = [
      {
        label: this.context.t("doNotUseContestTemplate"),
        value: ""
      }
    ];
    for (const val of templateList) {
      templateSelectList.push({
        label: val.name,
        value: `${val.id}`
      });
    }
    this.setState({
      templateSelectList,
      templateContestList: templateList
    });
  };

  // テンプレートの内容を設定
  _setTemplateContents = async (selected = "") => {
    const { templateContestList = [] } = this.state;
    const { gameList = [] } = this.props;
    const templateInfo =
      templateContestList.find((el) => el.id === selected) ?? {};
    const defaultGame = gameList.length ? gameList[0].id : 0;
    const {
      name = "",
      game = defaultGame,
      image = "",
      contestType = "TOURNAMENT",
      participantType = "MULTI",
      teamSize = "",
      entryMaxCount = "",
      discordUrl = "",
      iframeSrc = "",
      award = "",
      rule = "",
      checkin = false,
      recommend = false,
      isEntryPassword = false,
      entryPassword = ""
    } = templateInfo;

    // imageから取得用URL取得
    const imageUrl = await downloadFile(image);

    this.setState({
      contestType,
      participantType,
      gameTitle: game,
      name,
      teamSize,
      entryMaxCount,
      entryCount: 0, 
      discordUrl: discordUrl || "",
      iframeSrc: iframeSrc || "",
      image,
      imageUrl,
      award: award || "",
      rule: rule || "",
      isCheckin: checkin || false,
      isRecommend: recommend || false,
      isEntryPassword: isEntryPassword || false,
      entryPassword: entryPassword || "",
    });

    if (contestType === "BATTLEROYAL") {
      const { getBattleRoyalInfo } = this.props;
      const battleRoyalInfo = await getBattleRoyalInfo(templateInfo.id);
      const {
        matchCount = "",
        rankPoint = [],
        killPoint: { point = 0, max = "" }
      } = battleRoyalInfo;
      this.setState({
        matchCount,
        rankPoint,
        killPoint: { point, max }
      });
    }
    // 選択されたゲーム名を設定
    this._setSelectGameName(game);
  };

  _handleFileChange = (event) => {
    const {
      target: { files }
    } = event;

    // キャンセルボタンのとき、Stateを更新しない
    if (files.length === 0) {
      return;
    }

    const uploadedFile = files.length ? files[0] : null;

    const createObjectURL =
      (window.URL || window.webkitURL).createObjectURL ||
      window.createObjectURL;
    const imageUrl = uploadedFile ? createObjectURL(uploadedFile) : null;
    this.setState({
      uploadedFile,
      imageUrl,
      isChangedImage: true,
    });
  };

  _handleImageReset = () => {
    this.setState({
      uploadedFile: null,
      imageUrl: "",
      isChangedImage: true,
    });
  };

  // ルールのTextAreaにドラッグ&ドロップ
  _handleDragDrop = async (files) => {
    // TextAreaに focus
    this.myRef.current.focus();

    // キャンセルボタンのとき、Stateを更新しない
    if (!files || files.length === 0) {
      return;
    }

    const [file] = files;
    const supportType = ["image/png", "image/jpeg", "image/gif"];
    // ファイル形式チェック
    if (!supportType.includes(file.type)) {
      alert(this.context.t("unsupportedFileType"));
      return;
    }

    this._handleAttachImage(files);
  };

  // 添付ボタンクリック
  _handleClickInputFile = (event) => {
    event.target.value = "";
    // TextAreaに focus
    this.myRef.current.focus();
  };

  // 添付ボタンで画像選択
  _handleChangeInputFile = (event) => {
    // 画像選択
    const {
      target: { files }
    } = event;

    // キャンセルボタンのとき、Stateを更新しない
    if (!files || files.length === 0) {
      return;
    }

    this._handleAttachImage(files);
  };

  // 画像添付処理
  _handleAttachImage = async (files) => {
    const { rule = "" } = this.state;

    this.setState({
      isLoading: true
    });

    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("prefix", "image");

    // ファイルアップロード
    const imageUrl = await uploadFile(formData, "images");

    if (imageUrl) {
      // 現在時刻
      const currentTime = moment().format("YYMMDDHHmmss");
      // イメージ名
      const image = `\n\n![image_${currentTime}](${imageUrl})\n\n`;
      // TextAreaの cursor位置取得
      const cursorIndex = this.myRef.current.selectionEnd;
      // cursor位置にイメージ挿入
      const changedRule =
        rule.slice(0, cursorIndex) + image + rule.slice(cursorIndex);
      this.setState({
        rule: changedRule,
        isLoading: false
      });
      // cursorの位置設定
      this.myRef.current.selectionEnd = cursorIndex + image.length;
    }
  };

  // ゲーム選択時に selectedGameName を設定
  _setSelectGameName = (value) => {
    const { gameList = [] } = this.props;
    const selectedGame = gameList.find((el) => el.id === Number(value));
    if (!selectedGame) {
      return this.setState({
        selectedGameName: ""
      });
    }
    const selectedGameName = selectedGame.name;
    if (selectedGameName) {
      this.setState({
        selectedGameName
      });
    }
  };

  // デフォルトチェックイン期間設定
  _setCheckinDuration = () => {
    const { contestStart } = this.state;
    // 期間のデフォルト設定
    const checkinEnd = moment(new Date(contestStart)).format(
      "YYYY-MM-DDTHH:mm"
    );
    const checkinStart = moment(checkinEnd)
      .subtract(1, "hours")
      .format("YYYY-MM-DDTHH:mm");
    this.setState({
      checkinStart,
      checkinEnd
    });
  };

  // input 入力イベント
  _handleInputChange = (event) => {
    const {
      target,
      target: { value }
    } = event;
    const name = target.name === "contestName" ? "name" : target.name;
    this.setState({
      [name]: value
    });

    if (name === "contestType") {
      // contestType 更新時に participantType も初期化
      const { participantTypeList } = this.state;
      const defaultItem = participantTypeList.find((el) =>
        el.contestType.includes(value)
      );
      if (defaultItem) {
        this.setState({
          participantType: defaultItem.value
        });
      }
    } else if (name === "template") {
      this._setTemplateContents(value);
    } else if (name === "gameTitle") {
      this._setSelectGameName(value);
    }
  };

  // checkBox 選択
  _handleCheck = (event) => {
    const {
      target: { name, checked }
    } = event;
    this.setState({
      [name]: checked
    });
  };

  _handleInputentryMaxCountChange = (event) => {
    const {
      target: { name, value }
    } = event;

    // チームの上限数が変わる度に配列の初期化(延々配列にPushされるのを避けるため)
    const rankPoint = [];
    // チームの上限数分の要素を追加
    for (let i = 0; i < value; i++) {
      rankPoint.push({ rank: i + 1, point: "0" });
      // 400-500チームをいっぺんにチーム数を増やすとフリーズするため入力制限
      // GGmates側でポイントのテンプレルールを決めるようなので一時的に最低限動作する実装にとどめる
      // 現実的に256チームを耐えられるようにしておけば当面運用可能と思っていて256チームで処理を止める
      if (i > 256) {
        alert(this.context.t("entryMaxCountIsTooLarge"));
        return;
      }
    }
    this.setState({
      [name]: value,
      rankPoint
    });
  };

  _handleInputRankPointChange = (index, event) => {
    const {
      target: { value }
    } = event;
    const { rankPoint } = this.state;

    rankPoint[index].point = value;

    this.setState({
      rankPoint
    });
  };

  _handleInputKillPointChange = (event) => {
    const {
      target: { name, value }
    } = event;
    const { killPoint } = this.state;

    if (name === "killPoint") {
      killPoint.point = value;
    } else if (name === "killPointMax") {
      killPoint.max = value;
    }

    this.setState({
      killPoint
    });
  };

  // 大会作成ボタンクリック
  _handleSubmit = (event) => {
    event.preventDefault();
    this._contestRegister();
  };

  _contestRegister = async (target) => {
    const {
      contestType,
      participantType,
      gameTitle,
      name,
      entryStart,
      entryEnd,
      contestStart,
      contestEnd,
      isCheckin,
      checkinStart,
      checkinEnd,
      isEntryPassword,
      entryPassword,
      teamSize,
      entryMaxCount,
      discordUrl,
      iframeSrc,
      matchCount,
      rankPoint,
      killPoint,
      award,
      rule,
      image : templateImage,
      uploadedFile,
      isChangedImage,
      isRecommend
    } = this.state;
    const {
      auth,
      createBattleRoyal,
      contestRegistration,
      createPublicChat,
      history
    } = this.props;
    const { t } = this.context;

    // 画像アップロード
    const image = isChangedImage ? await uploadFile(uploadedFile, "contests") : templateImage;

    this.setState({
      isLoading: true
    });

    if (name.length >= 256) {
      alert(t("toLongContestName"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (
      (entryStart && entryEnd && entryStart >= entryEnd) ||
      (contestStart && contestEnd && contestStart >= contestEnd) ||
      (checkinStart && checkinEnd && checkinStart >= checkinEnd) ||
      entryEnd > contestStart
    ) {
      alert(t("reversedDate"));
      this.setState({
        isLoading: false
      });
      return;
    }

    // エントリー用パスワードバリデーション
    if (isEntryPassword) {
      const pattern = new RegExp("^[a-zA-Z0-9]{4,16}$");
      if (!entryPassword) {
        alert(t("inputEntryPassword"));
        return this.setState({
          isLoading: false
        });
      } else if (!pattern.test(entryPassword)) {
        alert(t("invalidEntryPasswordFormat"));
        return this.setState({
          isLoading: false
        });
      }
    }

    if ((participantType !== "SOLO" && teamSize < 2) || entryMaxCount < 2) {
      alert(t("SetAValueOfAtLeast2"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (participantType !== "SOLO" && teamSize > 99) {
      alert(t("TeamSizeIsTooLarge"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (entryMaxCount > 8192) {
      alert(t("entryMaxCountIsTooLarge"));
      this.setState({
        isLoading: false
      });
      return;
    }

    // 必須ではないが文字が入力されている場合はURLを正規表現でチェック
    if (
      typeof discordUrl === "string" &&
      discordUrl.length &&
      !discordUrl.match(
        /(http(s)?:\/\/[a-zA-Z0-9-.!'()*;/?:@&=+$,%#]+\.[a-z]+)/
      )
    ) {
      alert(t("inputDiscordUrl"));
      this.setState({
        isLoading: false
      });
      return;
    }

    let iframeTagSrc;

    if (iframeSrc.match(/^<iframe/)) {
      iframeTagSrc = iframeSrc.match(/src="(.*?)"/)[1];
    } else {
      iframeTagSrc = iframeSrc;
    }
    // 必須ではないが文字が入力されている場合はURLを正規表現でチェック
    if (
      iframeTagSrc.length &&
      !iframeTagSrc.match(
        /(http(s)?:\/\/[a-zA-Z0-9-.!'()*;/?:@&=+$,%#]+\.[a-z]+)/
      )
    ) {
      alert(t("inputIframeTag"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (isRecommend && isEntryPassword) {
      // レコメンド通知とエントリー用パスワードが両方有効な場合エラー
      alert(t("invalidContestRecommend"));
      return this.setState({
        isLoading: false
      });
    }

    if (contestType === "BATTLEROYAL") {
      // 1回あたりのkillのpointがmaxを上回っている場合
      if (killPoint.max && Number(killPoint.point) > Number(killPoint.max)) {
        alert(t("overMaxKillPoint"));
        this.setState({
          isLoading: false
        });
        return;
      }

      // 試合数が0以下で登録された場合
      if (matchCount < 1) {
        alert(t("setMatchCount"));
        this.setState({
          isLoading: false
        });
        return;
      }
    }

    const params = {
      contestType,
      participantType,
      gameId: gameTitle,
      name,
      image,
      entryStart:  entryStart ? new Date(entryStart) : null,
      entryEnd: entryEnd ? new Date(entryEnd) : null,
      contestStart:contestStart ? new Date(contestStart) : null,
      contestEnd: contestEnd ? new Date(contestEnd) : null,
      checkinStart: checkinStart ? new Date(checkinStart) : null,
      checkinEnd: checkinEnd ? new Date(checkinEnd) : null,
      checkin: isCheckin,
      entryMaxCount,
      teamSize,
      award,
      rule,
      iframeSrc,
      discordUrl,
      isRecommend,
      isEntryPassword,
      entryPassword,
    };

    const result = await contestRegistration(params);
    if (result.status !== 200) {
      switch (result.data) {
        case -1:
          alert(t("thisGameDoesNotExists"));
          return;
        case -2:
          alert(t("toLongContestName"));
          return;
        case -3:
          alert(t("reversedDate"));
          return;
        // case -4:
        //     alert(t("pastDate"));
        //     return;
        case -5:
          alert(t("SetAValueOfAtLeast2"));
          return;
        case -6:
          alert(t("TeamSizeIsTooLarge"));
          return;
        case -7:
          alert(t("entryMaxCountIsTooLarge"));
          return;
        case -8:
          alert(t("inputDiscordUrl"));
          return;
        default:
          alert(t("errorMsg"));
          break;
      }
      this.setState({
        isLoading: false
      });
    } else {
      // 公開チャット作成
      // 告知ルーム
      await createPublicChat(t("contestNotice"), {
        isNotice: true,
        isReadOnly: true,
        senders: [auth.id]
      });
      // 質問ルーム
      await createPublicChat(t("contestQuestionRoom"), {
        isQuestion: true
      });

      if (contestType === "BATTLEROYAL") {
        const { id, image } = result.data;
        const contest = {
          id,
          name,
          image,
          participantType,
          contestStart,
          contestEnd
        };
        const rankPointList = rankPoint.map((item) => {
          return { rank: item.rank, point: Number(item.point) };
        });

        const killPointInfo = {
          point: Number(killPoint.point)
        };
        killPoint.point = Number(killPoint.point);
        if (killPoint.max) {
          killPointInfo.max = Number(killPoint.max);
        }

        const param = {
          contest: contest,
          game: result.data.game,
          matchCount: Number(matchCount),
          rankPoint: rankPointList,
          killPoint: killPointInfo
        };
        await createBattleRoyal(param);
      }
      this.setState({
        isLoading: false
      });
      alert(t("contestRegistrationed"));
      history.push(`/contests/${result.data.id}/`);
    }
  };
  // テーブルの height 取得
  // TODO: style定義だけてできるように修正
  _getRankPointTableHeight = () => {
    const { rankPoint = [] } = this.state;
    const height = Math.round(rankPoint.length / 2) * 37;
    return height;
  };

  static propTypes = {
    contestRegistration: PropTypes.func.isRequired,
    createPublicChat: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
