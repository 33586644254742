import React, { useState, useEffect } from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";
import Loading from "components/lib/Loading";
import ContestTournament from "components/ContestTournament";
import CreateTournament from "components/CreateTournament";
import EditTournamentMatch from "components/EditTournamentMatch";
import TournamentMatchDetail from "components/TournamentMatchDetail";
import ContestEdit from "components/ContestEdit";
import ContestOperatorEdit from "components/ContestOperatorEdit";
import ContestEntryConfirm from "components/ContestEntryConfirm";
import classNames from "classnames/bind";
import ContestUserList from "components/ContestUserList";
import BackgroundImage from "components/lib/BackgroundImage";
import Markdown from "react-markdown";
import { FaExternalLinkAlt } from "react-icons/fa";
//import { TiSocialTwitter } from "react-icons/ti";
import { MdQuestionAnswer } from "react-icons/md";
import { AiFillNotification } from "react-icons/ai";
import { BsShare } from "react-icons/bs";
import { isWithinDuration, downloadFile } from "lib/convert";
import moment from "moment";
import Button from "components/lib/Button";
import { Box, Tabs, Tab } from "@material-ui/core";
import IconButton from "components/lib/IconButton";
import ShareModal from "components/lib/ShareModal";
import ContestChat from "components/ContestChat";
import Modal from "components/lib/Modal";
import EntryContestUser from "components/EntryContestUser";
import Auth from "components/Auth";

const cx = classNames.bind(styles);

const getDateTimeText = (datetime) => {
  if (!datetime) return;
  return moment(new Date(datetime)).format("MM/DD HH:mm");
};

const ContestDetail = (props, context) => {
  return (
    <div className={styles.contents}>
      {props.loading ? (
        <Loading />
      ) : (
        <span>
          <Routes {...props} />
        </span>
      )}
    </div>
  );
};

const Detail = (props, context) => {
  const [value, setValue] = useState("overview");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    // url から タブを設定
    const {
      location: { pathname }
    } = props;
    if (pathname.indexOf("operator") > -1) {
      setValue("operator");
    } else if (pathname.indexOf("participant") > -1) {
      setValue("participant");
    } else if (pathname.indexOf("tournament") > -1) {
      setValue("tournament");
    } else if (pathname.indexOf("chats") > -1) {
      setValue("chats");
    } else {
      setValue("overview");
    }
  }, [props.location]);

  return (
    <div className={styles.detail}>
      <div className={styles.header}>
        <div className={styles.hero}>
          <BackgroundImage
            src={props.contestInfo.imageUrl}
            alt="hero"
            className={styles.image}
          />
        </div>
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          <span className={styles.text}>{props.contestInfo.name}</span>
          <div className={styles.twitterButtonList}>
            <IconButton
              color="rgba(255, 255, 255, 0.1)"
              onClick={props.handleOpenShareModal}
            >
              <BsShare className={styles.shareIcon} />
            </IconButton>
            <ShareModal
              title={context.t("share")}
              isOpen={props.isShareModal}
              onClose={props.handleCloseShareModal}
              onShareTwitter={props.handleShareTwitter}
              onShareFacebook={props.handleShareFacebook}
              onShareLink={props.handleShareLink}
            />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.tabs}>
          <Box sx={{ width: "100%", typography: "body1" }}>
            {props.isSignedIn &&
            (props.myRole?.abilityList?.includes("ABILITY_CONTEST_CHAT_EDIT") ||
              props.contestInfo.isEntry) ? (
              <Tabs
                className={styles.tabList}
                value={value}
                onChange={handleChange}
              >
                <Tab
                  className={styles.tab}
                  label={context.t("overview")}
                  value="overview"
                  component={Link}
                  to={`/contests/${props.match.params.id}`}
                />
                <Tab
                  className={styles.tab}
                  label={context.t("operator")}
                  value="operator"
                  component={Link}
                  to={`/contests/${props.match.params.id}/operator`}
                />
                <Tab
                  className={styles.tab}
                  label={context.t("participant")}
                  value="participant"
                  component={Link}
                  to={`/contests/${props.match.params.id}/participant`}
                />
                <Tab
                  className={styles.tab}
                  label={context.t("tournament")}
                  value="tournament"
                  component={Link}
                  to={`/contests/${props.match.params.id}/tournament`}
                />
                <Tab
                  className={styles.tab}
                  label={context.t("chats")}
                  value="chats"
                  component={Link}
                  to={`/contests/${props.match.params.id}/chats`}
                />
              </Tabs>
            ) : (
              <Tabs
                className={styles.tabList}
                value={value}
                onChange={handleChange}
              >
                <Tab
                  className={styles.tab}
                  label={context.t("overview")}
                  value="overview"
                  component={Link}
                  to={`/contests/${props.match.params.id}`}
                />
                <Tab
                  className={styles.tab}
                  label={context.t("operator")}
                  value="operator"
                  component={Link}
                  to={`/contests/${props.match.params.id}/operator`}
                />
                <Tab
                  className={styles.tab}
                  label={context.t("participant")}
                  value="participant"
                  component={Link}
                  to={`/contests/${props.match.params.id}/participant`}
                />
                <Tab
                  className={styles.tab}
                  label={context.t("tournament")}
                  value="tournament"
                  component={Link}
                  to={`/contests/${props.match.params.id}/tournament`}
                />
              </Tabs>
            )}
            <div className={styles.tabContent}>
              <Switch>
                <Route
                  exact
                  path="/contests/:id/"
                  component={() => <Overview {...props} />}
                />
                <Route
                  path="/contests/:id/operator"
                  component={() => <Operator {...props} />}
                />
                <Route
                  path="/contests/:id/participant"
                  component={() => <Participant {...props} />}
                />
                <Route
                  path="/contests/:id/tournament"
                  component={() => <Tournament {...props} />}
                />
                <Route
                  path="/contests/:id/chats/:chatId"
                  render={({ match }) => <ContestChat {...props} chatId={match.params.chatId} />
                }
                />
                <Route
                  path="/contests/:id/chats"
                  component={() => <ContestChat {...props} />}
                />
              </Switch>
            </div>
          </Box>
        </div>
      </div>
      <div className={styles.footerButtonContainer}>
        {isWithinDuration(null, props.contestInfo.entryEnd) &&
        !props.contestInfo.isEntry ? (
          props.contestInfo.canEntry ? (
            <Button onClick={props.handleOpenEntryModal}>
              <div className={styles.entryButton}>
                <div>{context.t("entryContest")}</div>
                <div className={styles.supplement}>
                  {"~ " + getDateTimeText(props.contestInfo.entryEnd)}
                </div>
              </div>
            </Button>
          ) : (
            <Button onClick={props.handleOpenEntryModal} disabled={true}>
              <div className={styles.entryButton}>
                <div>{context.t("entryContest")}</div>
                <div className={styles.supplement}>
                  {getDateTimeText(props.contestInfo.entryStart) + " ~"}
                </div>
              </div>
            </Button>
          )
        ) : null}
        {props.contestInfo.isEntry &&
        props.contestInfo.checkin &&
        isWithinDuration(null, props.contestInfo.checkinEnd) ? (
          isWithinDuration(null, props.contestInfo.checkinStart) ? (
            <Button disabled={true}>
              <div className={styles.checkinButton}>
                <div>{context.t("checkinContest")}</div>
                <div className={styles.supplement}>
                  {getDateTimeText(props.contestInfo.checkinStart) + " ~"}
                </div>
              </div>
            </Button>
          ) : (
            <Button onClick={props.handleCheckin}>
              <div
                className={
                  props.contestInfo.isCheckin
                    ? cx(styles.checkinButton, styles.checked)
                    : styles.checkinButton
                }
              >
                <div>
                  {props.contestInfo.isCheckin
                    ? context.t("checkinContestDone")
                    : context.t("checkinContest")}
                </div>
                <div className={styles.supplement}>
                  {"~ " + getDateTimeText(props.contestInfo.checkinEnd)}
                </div>
              </div>
            </Button>
          )
        ) : null}
      </div>
      <Modal isOpen={props.isEntryModal} onClose={props.handleCloseEntryModal}>
        {props.isSignedIn ? <EntryContestUser {...props} /> : <Auth {...props} />}
      </Modal>
    </div>
  );
};

const Routes = (props) => (
  <Switch>
    <Route exact path="/contests/:id" render={() => <Detail {...props} />} />
    <Route
      exact
      path="/contests/:id/edit"
      render={({ match }) =>
        props.isSignedIn &&
        props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
          <ContestEdit {...props} match={match} gameList={props.gameList} />
        ) : (
          <Detail {...props} />
        )
      }
    />
    <Route render={() => <Detail {...props} />} />
  </Switch>
);

const Overview = (props, context) => {
  const [convertedRule, setConvertedRule] = useState("");

  // ルールのイメージ変換
  const convertImageRule = async (rule = "") => {
    if (!rule) return;
    const imagePaths = rule.match(/images\/\w+/gi) || [];
    for(const path of imagePaths) {
      const imageUrl = await downloadFile(path);
      rule = rule.replace(new RegExp(path), imageUrl); 
    }
    setConvertedRule(rule);
  };

  useEffect(() => {
    if(props.contestInfo.rule) {
      convertImageRule(props.contestInfo.rule);
    }
  }, [props.contestInfo]);

  return (
    <div>
      <div className={styles.buttonContainer}>
        {props.isSignedIn &&
        props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
          <Link to={`/contests/${props.contestInfo.id}/edit`}>
            <Button style={{ margin: 5 }} className={styles.editButton} text={context.t("edit")} />
          </Link>
        ) : null}
      </div>
      <div className={styles.detailTitle}>{context.t("overview")}</div>
      <div className={styles.info}>
        <div className={styles.column}>
          <div className={styles.columnName}>
            {context.t("contestDuration")}
          </div>
          <div className={styles.columnContent}>
            {props.contestInfo.contestStartText} {context.t("start")}
          </div>
        </div>
        <div className={styles.column}>
          <div>
            <div className={styles.columnName}>
              {context.t("contestUserMaxSize")}
            </div>
            <div className={styles.columnContent}>
              {context.t("userLength", {
                value: props.contestInfo.entryMaxCount
              })}
            </div>
          </div>
        </div>
        {props.contestInfo.discordUrl ? (
          <div className={styles.column}>
            <div className={styles.columnName}>
              {context.t("discordForContest")}
            </div>
            <div className={styles.columnContent}>
              <a
                href={props.contestInfo.discordUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.url}
              >
                {props.contestInfo.discordUrl} <FaExternalLinkAlt />
              </a>
            </div>
          </div>
        ) : null}
        {props.contestInfo.videoUrl ? (
          <div className={styles.column}>
            <div className={styles.columnName}>{context.t("videoUrl")}</div>
            <div className={styles.columnContent}>
              <a
                href={props.contestInfo.videoUrl}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.url}
              >
                {props.contestInfo.videoUrl} <FaExternalLinkAlt />
              </a>
            </div>
          </div>
        ) : null}
        {props.contestInfo.iframeSrc ? (
          <div className={cx(styles.column, styles.iframeTagContents)}>
            <div className={styles.columnContent}>
              <iframe
                className={styles.iframe}
                title={context.t("contestIframeVideo")}
                src={props.contestInfo.iframeSrc}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ) : null}
        {props.contestInfo.award ? (
          <div className={cx(styles.column, { award: true })}>
            <div className={styles.columnName}>{context.t("award")}</div>
            <div className={styles.columnContent}>{props.contestInfo.award}</div>
          </div>
        ) : null}
      </div>
      <div className={styles.detailTitle}>{context.t("contestRule")}</div>
      <div className={styles.info}>
        <div className={styles.rule}>
          <Markdown
            className={styles.markdown}
            source={convertedRule}
            linkTarget="_blank"
          />
        </div>
      </div>
      {props.isSignedIn &&
      (props.myRole?.abilityList?.includes("ABILITY_CONTEST_CHAT_EDIT") ||
        props.contestInfo.isEntry) ? (
        <div className={styles.chatButtonList}>
          <div className={styles.chatButton} onClick={props.handleNotice}>
            <AiFillNotification className={styles.chatButtonIcon} />
            <div className={styles.chatButtonText}>
              {context.t("contestNoticeLink")}
            </div>
          </div>
          <div className={styles.chatButton} onClick={props.handleQuestion}>
            <MdQuestionAnswer className={styles.chatButtonIcon} />
            <div className={styles.chatButtonText}>
              {context.t("contestQuestionRoomLink")}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const Operator = (props, context) => (
  <Switch>
    <Route
      path="/contests/:id/operator/edit"
      render={({ match }) =>
        props.isSignedIn &&
        props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
          <ContestOperatorEdit {...props} match={match} />
        ) : (
          <Detail {...props} />
        )
      }
    />
    <Route
      render={() => (
        <div>
          <div className={styles.buttonContainer}>
            {props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") && (
              <Link to={`/contests/${props.contestInfo.id}/operator/edit`}>
                <Button
                  style={{ margin: 5 }}
                  className={styles.editOperatorButton}
                  text={context.t("editOperator")}
                />
              </Link>
            )}
          </div>
          <div className={styles.operatorList}>
            {props.operatorList.length
              ? props.operatorList.map((val, index) => (
                  <div key={index} className={styles.item}>
                    <Link
                      className={styles.userInfo}
                      to={`/users/${val.userId}`}
                    >
                      <span className={styles.imageLayout}>
                        <img
                          src={require("images/noimage.png")}
                          alt="profile"
                          className={styles.image}
                          onError={(e) =>
                            (e.target.src = require("images/noimage.png"))
                          }
                        />
                      </span>
                      <div className={styles.nameContainer}>
                        <span
                          className={cx(styles.nickname, {
                            me: props.auth.id === val.userId
                          })}
                        >
                          {val.nickName}
                        </span>
                      </div>
                      <div className={styles.roleContainer}>
                        <span className={styles.roleId}>
                          {context.t(val.roleId)}
                        </span>
                      </div>
                    </Link>
                  </div>
                ))
              : null}
          </div>
        </div>
      )}
    />
  </Switch>
);

const Participant = (props, context) => (
  <Switch>
    <Route
      path="/contests/:id/participant/entryconfirm"
      render={({ match }) =>
        props.isSignedIn &&
        props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
          <ContestEntryConfirm {...props} match={match} />
        ) : (
          <Detail {...props} />
        )
      }
    />
    <Route
      render={() => (
        <div>
          <ContestUserList {...props} />
        </div>
      )}
    />
  </Switch>
);

const Tournament = (props, context) => (
  <Switch>
    <Route
      path="/contests/:id/tournament/add"
      render={({ match }) =>
        props.isSignedIn &&
        props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
          <CreateTournament {...props} match={match} />
        ) : (
          <Detail {...props} />
        )
      }
    />
    <Route
      exec
      path="/contests/:id/tournament/:tournamentId/edit"
      render={({ match }) =>
        props.isSignedIn &&
        props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
          <EditTournamentMatch {...props} match={match} />
        ) : (
          <Detail {...props} />
        )
      }
    />
    <Route
      path="/contests/:id/tournament/:tournamentId/match/:matchId"
      render={({ match }) => <TournamentMatchDetail {...props} match={match} />}
    />
    <Route
      render={() => (
        <div>
          <div className={styles.buttonContainer}>
            {props.tournamentInfo.id ? (
              <Button
                style={{ margin: 5 }}
                text={context.t("screenShot")}
                onClick={props.handleScreenShot}
              />
            ) : null}
            {props.tournamentInfo.id &&
            props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ? (
              <Link
                to={`/contests/${props.contestInfo.id}/tournament/${props.tournamentInfo.id}/edit`}
              >
                <Button style={{ margin: 5 }} text={context.t("edit")} />
              </Link>
            ) : null}
            {props.contestInfo.canCreateTournament &&
            props.contestInfo.contestType === "TOURNAMENT" &&
            !props.tournamentInfo.id &&
            props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") &&
            props.contestInfo.contestType === "TOURNAMENT" ? (
              <Link to={`/contests/${props.contestInfo.id}/tournament/add`}>
                <Button
                  style={{ margin: 5 }}
                  className={styles.tournamentButton}
                  text={context.t("createtournament")}
                />
              </Link>
            ) : null}
          </div>
          {props.tournamentInfo.id ? (
            <ContestTournament {...props} />
          ) : (
            <div className={styles.empty}>
              <span>{context.t("emptyData")}</span>
            </div>
          )}
        </div>
      )}
    />
  </Switch>
);

ContestDetail.propTypes = {
  contestInfo: PropTypes.object.isRequired,
  gameList: PropTypes.array.isRequired,
  handleOpenEntryModal: PropTypes.func.isRequired,
  isSignedIn: PropTypes.bool.isRequired,
  tournamentInfo: PropTypes.object.isRequired,
};

ContestDetail.contextTypes = {
  t: PropTypes.func.isRequired
};

Detail.contextTypes = {
  t: PropTypes.func.isRequired
};

Overview.contextTypes = {
  t: PropTypes.func.isRequired
};

Operator.contextTypes = {
  t: PropTypes.func.isRequired
};

Participant.contextTypes = {
  t: PropTypes.func.isRequired
};

Tournament.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestDetail;
