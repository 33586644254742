import React, { Component } from "react";
import ContestList from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    loading: false,
    gameId: "",
    status: "all",
    query: {
      gameId: "",
      status: "all",
      lastData: null,
      limit: 50
    },
    hasMore: true,
    contestList: []
  };

  async componentDidMount() {
    const { query } = this.state;

    this.setState({
      loading: true
    });

    //大会一覧取得
    await this._getContestList(query);

    this.setState({
      loading: false
    });
  }

  render() {
    return (
      <ContestList
        {...this.props}
        {...this.state}
        switchStatusFilter={this._switchStatusFilter}
        handleSelectChange={this._handleSelectChange}
        loadMore={this._loadMore}
      />
    );
  }

  // 大会一覧取得（リロード）
  _getContestList = async (query = {}) => {
    const { getContestList, getOperatedContestList, getEntryContestList } = this.props;

    let result;
    // 大会一覧取得
    if(query.status === "operated") {
      result = await getOperatedContestList(query);
    } else if(query.status === "participated") {
      result = await getEntryContestList(query);
    } else {
      result = await getContestList(query);
    }
    const contestList = result.contestList;
    const lastData = result.lastData;
    const length = contestList?.length || 0;
    query.lastData = lastData;
    const hasMore = query.limit > length ? false : true;

    this.setState({
      contestList,
      query,
      hasMore
    });
    return this.state.contestList;
  };

  // Loadmore
  _loadMore = async () => {
    const { contestList = [], query = {} } = this.state;
    const { getContestList, getOperatedContestList, getEntryContestList } = this.props;

    let results;
    // 大会一覧取得
    if(query.status === "operated") {
      results = await getOperatedContestList(query);
    } else if(query.status === "participated") {
      results = await getEntryContestList(query);
    } else {
      results = await getContestList(query);
    }

    query.lastData = results.lastData;
    const hasMore = query.limit > results.contestList.length ? false : true;

    const concatContestList = contestList.concat(results.contestList);
    this.setState({
      contestList: concatContestList,
      query,
      hasMore
    });
  };

  // status filter
  _switchStatusFilter = async (status) => {
    const { query } = this.state;

    if (!status) {
      return;
    }
    this.setState({
      loading: true
    });

    query.status = status;
    query.lastData = null;

    await this._getContestList(query);

    this.setState({
      loading: false
    });
  };

  // game filter
  _handleSelectChange = async (event) => {
    this.setState({
      loading: true
    });

    const {
      target: { value }
    } = event;
    const { query } = this.state;

    query.gameId = value;
    query.lastData = null;

    await this._getContestList(query);

    this.setState({
      loading: false
    });
  };

  static propTypes = {
    contestList: PropTypes.array.isRequired,
    getContestList: PropTypes.func.isRequired,
  };
}

export default Container;
