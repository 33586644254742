import React, { Component } from "react";
import ContestEntryConfirm from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    createLoading: false
  };

  render() {
    return (
      <ContestEntryConfirm
        {...this.props}
        {...this.state}
        handleEntryConfirm={this._handleEntryConfirm}
      />
    );
  }

  // 確定枠チャットルームのユーザー取得
  _getConfirmedChatUsers = async (contestInfo = {}, activeList = []) => {
    const { getTeamMembers } = this.props;
    const users = [];
    if (contestInfo.participantType === "SOLO") {
      for (const val of activeList) {
        if (users.some((el) => el === val.userId)) {
          continue;
        }
        users.push(val.userId);
      }
    } else {
      for (const val of activeList) {
        // チーム戦の場合、メンバー取得
        const members = await getTeamMembers(val.team.id);
        for (const val of members) {
          if (users.some((el) => el === val.userId)) {
            continue;
          }
          users.push(val.userId);
        }
      }
    }
    return users;
  };

  // 運営ユーザー取得
  _getOperators = async (contestInfo = {}) => {
    const { getContestOperators } = this.props;
    const operatorList = await getContestOperators(contestInfo.id);
    const operators = operatorList.map((el) => el.userId);
    return operators;
  };

  // エントリー確定
  _handleEntryConfirm = async () => {
    const {
      contestInfo,
      history,
      activeList,
      updateEntryConfirmChat
    } = this.props;
    const { t } = this.context;

    // 連打防止
    this.setState({
      createLoading: true
    });

    // チャットルーム参加ユーザー取得
    const users = await this._getConfirmedChatUsers(contestInfo, activeList);
    const admin = await this._getOperators(contestInfo);

    // 運営ユーザー追加
    for (const userId of admin) {
      if (users.some((el) => el === userId)) {
        continue;
      }
      users.push(userId);
    }
    const roomName = `${t("contestEntryConfirmRoom")} ${contestInfo.name}`;
    await updateEntryConfirmChat(contestInfo, roomName, users, admin);

    alert(t("completeEntryConfirm"));
    this.setState({
      createLoading: false
    });
    // 大会詳細ページに遷移
    history.push(`/contests/${contestInfo.id}/`);
  };

  static propTypes = {
    getTeamMembers: PropTypes.func.isRequired,
    getContestOperators: PropTypes.func.isRequired,
    updateEntryConfirmChat: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
