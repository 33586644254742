import React from "react";
import CommonMatchDetail from "components/CommonMatchDetail";

const TournamentMatchDetail = (props) => (
  <CommonMatchDetail
    {...props}
    resourceInfo={props.tournamentInfo}
    getResourceInfo={props.getTournamentInfo}
    getResourceMatchInfo={props.getTournamentMatchInfo}
    clearResourceMatchInfo={props.clearTournamentMatchInfo}
    updateResource={props.updateTournament}
  />
);

export default TournamentMatchDetail;
