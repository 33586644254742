import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: { contestInfo = {}, contestTeams = [], contestUsers = [] }
  } = state;

  return {
    contestInfo,
    contestTeams,
    contestUsers
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentInfo: (contestId) => {
      return dispatch(contestActions.getTournamentInfo(contestId));
    },
    getTournamentMatchList: (tournamentId) => {
      return dispatch(contestActions.getTournamentMatchList(tournamentId));
    },
    updateTournament: (id, matchId, params) => {
      return dispatch(contestActions.updateTournament(id, matchId, params));
    },
    deleteTournament: (tournamentId) => {
      return dispatch(contestActions.deleteTournament(tournamentId));
    },
    updateContestTeamStatus: (contestId, teamId, status) => {
      return dispatch(
        contestActions.updateContestTeamStatus(contestId, teamId, status)
      );
    },
    updateContestUserStatus: (contestId, userId, status) => {
      return dispatch(
        contestActions.updateContestUserStatus(contestId, userId, status)
      );
    },
    getContestTeams: (contestId) => {
      return dispatch(contestActions.getContestTeams(contestId));
    },
    getContestUsers: (contestId) => {
      return dispatch(contestActions.getContestUsers(contestId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
