import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.scss";
import { Route, Switch } from "react-router-dom";
import Header from "components/lib/Header";
import Home from "components/Home";
import Contest from "components/Contest";
import SearchUser from "components/SearchUser";
import UserProfile from "components/UserProfile";
import Contract from "components/Contract";
import Footer from "components/lib/Footer";
import Loading from "components/lib/Loading";
import PasswordReset from "components/PasswordReset";
import SocialCallback from "components/SocialCallback";
import SignInWithMagicLink from "components/SignInWithMagicLink";
import TermsOfService from "components/TermsOfService";
import PrivacyPolicy from "components/PrivacyPolicy";

const App = (props) => (
  <div className={styles.App}>
    <Header {...props} />
    <div className={styles.contents}>
      {props.isSignedIn ? (
        props.auth.id ? (
          <PrivateRoutes {...props} />
        ) : (
          <Loading />
        )
      ) : (
        <PublicRoutes {...props} />
      )}
    </div>
    <Footer />
  </div>
);
const PrivateRoutes = (props) => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/contests" component={Contest} />
    <Route path="/search-user" component={SearchUser} />
    <Route path="/users/:id" component={UserProfile} />
    <Route path="/contract" component={Contract} />
    <Route path="/oauth/callback" component={SocialCallback} />
    <Route path="/magic-link" component={SignInWithMagicLink} />
    <Route path="/terms-of-service" component={TermsOfService} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route component={Home} />
  </Switch>
);

const PublicRoutes = (props) => (
  <Switch>
    <Route exact path="/" component={Home} />
    <Route path="/contests" component={Contest} />
    <Route path="/search-user" component={SearchUser} />
    <Route path="/users/:id" component={UserProfile} />
    <Route path="/contract" component={Contract} />
    <Route path="/password-reset" component={PasswordReset} />
    <Route path="/oauth/callback" component={SocialCallback} />
    <Route path="/magic-link" component={SignInWithMagicLink} />
    <Route path="/terms-of-service" component={TermsOfService} />
    <Route path="/privacy-policy" component={PrivacyPolicy} />
    <Route component={Home} />
  </Switch>
);

App.propTypes = {
  isSignedIn: PropTypes.bool.isRequired
};

export default App;
