import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import Linkify from "react-linkify";
import Button from "components/lib/Button";

const UserProfileDetail = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.header}>
      <div className={styles.backgroudImageContainer}>
        <img
          className={styles.backgroudImage}
          src={
            props.userInfo.profileBackgroundImage ||
            require("images/background_noimage.png")
          }
          alt={context.t("preview")}
          onError={(e) =>
            (e.target.src = require("images/background_noimage.png"))
          }
        />
      </div>
    </div>

    <div className={styles.body}>
      <div className={styles.imageContainer}>
        <img
          src={props.userInfo.profileImage || require("images/noimage.png")}
          alt="profile"
          className={styles.image}
          onError={(e) => (e.target.src = require("images/noimage.png"))}
        />
      </div>
      <div className={styles.menu}>
        {props.userInfo.isSelf ? (
          <Button className={styles.editButton} text={context.t("editProfile")} onClick={props.handleEditButton} />
        ) : null}
      </div>

      <div className={styles.basicInfo}>
        <div className={styles.nickname}>{props.userInfo.nickname}</div>
        <div className={styles.bio}>
          <Linkify
            className={styles.link}
            properties={{ target: "_blank", rel: "noopener noreferrer" }}
          >
            {props.userInfo.bio}
          </Linkify>
        </div>

        {/* <div className={styles.data}>{props.userInfo.country} </div>
                <div className={styles.data}>{props.userInfo.activityStartTime} ~ {props.userInfo.activityEndTime}</div> */}
      </div>

      {/* {props.userInfo.twitter ? (
        <div className={styles.sns}>
          <div className={styles.snsHeader}>
            <div className={styles.title}>{context.t("snsOfUser")}</div>
          </div>
          <div className={styles.snsContents}>
            <div className={styles.contentsItem}>
              <a
                href={`https://twitter.com/${props.userInfo.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter size={24} />
              </a>
            </div>
          </div>
        </div>
      ) : null} */}
    </div>

    {/* <RelatedContestInfo
      title={context.t("contestHistory")}
      user={props.relatedContestInfo.user}
      team={props.relatedContestInfo.team}
      operator={props.relatedContestInfo.operator}
    /> */}

    {/* <GameUser {...props} /> */}

    {/* <TeamList
      userInfo={props.userInfo}
      match={props.match}
      history={props.history}
    /> */}
  </div>
);

UserProfileDetail.propTypes = {
  userInfo: PropTypes.shape({
    nickname: PropTypes.string,
    gender: PropTypes.string,
    email: PropTypes.string,
    game_users: PropTypes.array,
    country: PropTypes.string,
    comment: PropTypes.string,
    bio: PropTypes.string,
    activityStarttime: PropTypes.string,
    activityEndTime: PropTypes.string
  })
};

UserProfileDetail.contextTypes = {
  t: PropTypes.func.isRequired
};

export default UserProfileDetail;
