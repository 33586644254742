import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/user";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    resetUserPassword: (param) => {
      return dispatch(userActions.resetUserPassword(param));
    },
    getPasswordResetInfo: (keyPhrase) => {
      return dispatch(userActions.getPasswordResetInfo(keyPhrase));
    }
  };
};

export default connect(null, mapDispatchToProps)(Container);
