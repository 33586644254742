import React from "react";
import { Button } from "@material-ui/core";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledButton = styled(Button)`
  && {
    min-width: 100px;
    padding: 5px;
    background: ${props => props.color ? props.color : "#f40058"};
  }
  &&:hover {
    background: ${props => props.color ? props.color : "#f40058"};
  }
  &&:disabled {
    background: #999999;
  }
`;

const CommonButton = ({
  text,
  color,
  style,
  disabled,
  children,
  onClick,
}) => {
  return (
    <StyledButton
      color={color}
      variant="contained"
      style={style}
      onClick={onClick}
      disabled={disabled}
    >
      <div className={styles.text}>{text}</div>
      {children}
    </StyledButton>
  );
};

CommonButton.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.node,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default CommonButton;
