import React from "react";
import styles from "./styles.scss";
import classNames from "classnames/bind";
import Loading from "components/lib/Loading";
import PropTypes from "prop-types";
import { convertComfortableDateTime, trimText } from "lib/convert";
import { IoEllipsisHorizontal } from "react-icons/io5";
import IconButton from "components/lib/IconButton";
import Menu from "components/lib/Menu";
import {
  ThemeProvider,
  Message,
  MessageList,
  MessageText,
  MessageMedia,
  Bubble,
  Avatar,
  Row,
  TextComposer,
  TextInput,
  SendButton,
  IconButton as IconButton2
} from "@livechat/ui-kit";
import InfiniteScroll from "react-infinite-scroller";
import { BsImage } from "react-icons/bs";
import { convertFileUrl } from "lib/convert";


// システムログ文字列変換
const convertSystemLogText = (context, type, payload = {}) => {
  let users = "";
  if (typeof payload.users === "object") {
    const userLength = payload.users.length;
    if (userLength) {
      users += `${payload.users[0].nickname}`;
    }
    if (userLength > 1) {
      const count = userLength - 1;
      users += ` ${context.t("andOthors", { count })}`;
    }
  }
  let text = "";
  switch (type) {
    case "userEnter":
      text = context.t("chatRoomEnter", { value: users });
      break;
    case "userLeave":
      text = context.t("chatRoomLeave", { value: users });
      break;
    default:
      break;
  }
  return text;
};


const cx = classNames.bind(styles);

const convertChatMessage = (text = "", context) => {
  let message = text;
  if (!message) {
    return "";
  }
  if (message === "chatMessageSentImage") {
    // メッセージが画像の場合、「写真を送信しました」で表示
    message = context.t("chatMessageSentImage");
  }
  message = message.replace(/\r?\n/g, " ");
  return trimText(message, 32);
};

const ContestChat = (props, context) => (
  <div className={styles.chats}>
    <div className={cx("chatList", { active: !props.isHide })}>
      <div>
        <div className={cx("list", { active: !props.isHide })}>
          <div className={styles.chatListContainer}>
            <div className={styles.listHeader}>
              <IconButton onClick={props.handleOpenMenu}>
                <IoEllipsisHorizontal size={20} color={"#fff"} />
              </IconButton>
              <Menu
                anchorEl={props.anchorEl}
                items={[{ label: context.t("clearChatBadge"), value: 1 }]}
                onClose={props.handleCloseMenu}
                onClick={props.handleSelectMenu}
              />
            </div>
            <div className={styles.listContents}>
              {props.chatLoading ? (
                <Loading styles={{ padding: "initial" }} />
              ) : props.chatList.length > 0 ? (
                props.chatList.map((chatInfo, index) => (
                  <div
                    key={chatInfo.id}
                    className={cx("item", { active: props.selected.id === chatInfo.id })}
                    onClick={() => props.handleClickChat(chatInfo)}
                  >
                    <span className={styles.imageContainer}>
                      <img
                        src={
                          chatInfo.image
                            ? chatInfo.image
                            : require("images/noimage.png")
                        }
                        alt="profile"
                        onError={(e) =>
                          (e.target.src = require("images/noimage.png"))
                        }
                      />
                    </span>
                    <div className={styles.infoContainer}>
                      <div className={styles.title}>
                        {trimText(chatInfo.name, 32)}
                      </div>
                      <div className={styles.lastMessage}>
                        {convertChatMessage(chatInfo.lastMessage, context)}
                      </div>
                    </div>
                    <span className={styles.lastUpdated}>
                      <span>
                        {chatInfo.lastMessageUpdated
                          ? convertComfortableDateTime(
                              chatInfo.lastMessageUpdated.toDate()
                            )
                          : ""}
                      </span>
                      {chatInfo.unread ? (
                        <div className={styles.badge}>
                          <span>{chatInfo.unread}</span>
                        </div>
                      ) : null}
                    </span>
                  </div>
                ))
              ) : (
                <div className={styles.empty}>{context.t("emptyData")}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={styles.chatContents}>
      <ThemeProvider>
        <div className={styles.contents}>
          <div className={styles.chatLayout}>
            <div className={styles.messageListContainer}>
              <MessageList
                active
                id="messageList"
                className={styles.listContents}
              >
                <InfiniteScroll
                  pageStart={0}
                  loadMore={props.getMatchChat}
                  hasMore={props.hasMore}
                  loader={<Loading key={0} />}
                  isReverse={true}
                  useWindow={false}
                  initialLoad={false}
                >
                  {props.messageList?.map((item, index) =>
                    item.isSystem ? (
                      <div key={index} className={styles.systemLog}>
                        <span>
                          {convertSystemLogText(context, item.type, item.payload)}
                        </span>
                        <span className={styles.time}>{item.displayTime}</span>
                      </div>
                    ) : (
                      <Row
                        key={index}
                        reverse={item.from?.id === props.auth.id ? true : false}
                      >
                        {item.from?.id !== props.auth.id && (
                          <Avatar
                            className={styles.imageContainer}
                            imgUrl={
                              item.from?.image
                                ? item.from?.image
                                : require("images/noimage.png")
                            }
                          />
                        )}
                        <Message
                          authorName={item.from?.name}
                          date={item.displayTime}
                          isOwn={item.from?.id === props.auth.id ? true : false}
                        >
                          {props.chatInfo.admin?.includes(item.from?.id) ? (
                            <div className={styles.admin}>
                              {context.t("operator")}
                            </div>
                          ) : null}
                          <Bubble
                            isOwn={item.from?.id === props.auth.id ? true : false}
                            className={
                              item.from?.id === props.auth.id
                                ? styles.bubbleUser
                                : styles.bubbleFriend
                            }
                          >
                            {item.text ? (
                              <MessageText
                                authorName={item.from?.name}
                                className={styles.chatMessage}
                              >
                                {item.text}
                              </MessageText>
                            ) : null}
                            {item.image ? (
                              <MessageMedia>
                                <img
                                  className={styles.chatImage}
                                  src={convertFileUrl(item.image)}
                                  alt="profile"
                                  onError={(e) =>
                                    (e.target.src = require("images/noimage.png"))
                                  }
                                />
                              </MessageMedia>
                            ) : null}
                          </Bubble>
                        </Message>
                      </Row>
                    )
                  )}
                </InfiniteScroll>
                {props.messageList.length ? (
                  <div
                    className={cx(styles.newMessages, {
                      active: props.isNewMessage
                    })}
                  >
                    <span
                      className={styles.text}
                      onClick={props.handleNewMessage}
                    >
                      <span className={styles.image}>
                        <img
                          src={
                            props.messageList[props.messageList.length - 1].from
                              ?.image || require("images/noimage.png")
                          }
                          alt="profile"
                        />
                      </span>
                      <span className={styles.name}>
                        {props.messageList[props.messageList.length - 1].from
                          ?.name || "-"}
                      </span>
                      <span className={styles.message}>
                        {props.messageList[props.messageList.length - 1].text ||
                          "New message..."}
                      </span>
                    </span>
                  </div>
                ) : null}
              </MessageList>
            </div>
          </div>
        </div>
        <div
          className={cx(styles.inputContainer, {
            readOnly: props.isReadOnly
          })}
        >
          <div
            className={cx(styles.inputContents, {
              readOnly: props.isReadOnly
            })}
          >
            <TextComposer
              onSend={(text) => props.handleSendMessage({ text })}
              className={cx(styles.textComposer, {
                readOnly: props.isReadOnly
              })}
            >
              <Row align="center" className={styles.textInputContainer}>
                <TextInput
                  type="text"
                  className={cx(styles.inputMessage, {
                    readOnly: props.isReadOnly
                  })}
                  name="message"
                  placeholder={
                    !props.isReadOnly
                      ? context.t("inputMessage")
                      : context.t("readOnlyRoom")
                  }
                  onChange={props.handleInputChange}
                  value={props.text}
                  maxRows={10}
                  minRows={2}
                />
                <IconButton2 className={styles.attachmentImageButton}>
                  <label
                    htmlFor={"uploadImage"}
                    className={styles.imageInputLabel}
                  >
                    <BsImage />
                  </label>
                  <input
                    type="file"
                    name="image"
                    id="uploadImage"
                    className={styles.imageInput}
                    accept="image/png, image/jpeg, image/gif"
                    onChange={props.handleChangeInputFile}
                    onClick={() => null}
                  />
                </IconButton2>
                <SendButton className={styles.sendButton} />
              </Row>
            </TextComposer>
          </div>
        </div>
      </ThemeProvider>
    </div>
  </div>
);

ContestChat.contextTypes = {
  t: PropTypes.func.isRequired
};

ContestChat.propTypes = {
  togglePresence: PropTypes.func.isRequired,
  isHide: PropTypes.bool.isRequired,
  chatList: PropTypes.array.isRequired,
  chatLoading: PropTypes.bool.isRequired
};

export default ContestChat;
