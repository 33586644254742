import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getContestMatchChatInfo: (contestId, matchId) => {
      return dispatch(contestActions.getContestMatchChatInfo(contestId, matchId));
    }
  };
};

export default connect(null, mapDispatchToProps)(Container);

