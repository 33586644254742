import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import ContestEntryEdit from "components/ContestEntryEdit";

const ContestEntryConfirm = (props, context) => (
  <div className={styles.contents}>
    <ContestEntryEdit
      {...props}
      mode="confirm"
      nextStep={props.handleEntryConfirm}
    />
  </div>
);

ContestEntryConfirm.propTypes = {
  handleEntryConfirm: PropTypes.func.isRequired
};

ContestEntryConfirm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestEntryConfirm;
