import React, { Component } from "react";
import { connect } from "react-redux";
import { actionCreators as userActions } from "redux/user";
import SignInWithMagicLink from "./presenter";

class Container extends Component {
  state = {
    email: "",
    loading: false,
    isAuthSuccess: false
  };

  componentDidMount = () => {
    this.setState({
      loading: true
    });

    this._onSignIn();
  };

  render() {
    return <SignInWithMagicLink {...this.props} {...this.state} />;
  }

  _onSignIn = async () => {
    // メールを送信したアドレスを取得する
    let email = window.localStorage.getItem("emailForSignIn");
    if (!email) {
      // 異なるデバイスで開いた場合は入力してもらう
      email = window.prompt(
        "本人確認のため、URLを受け取ったメールアドレスを入力してください"
      );
    }

    const { signInWithMagicLink, history, location } = this.props;

    const { result: user = {}, error } = await signInWithMagicLink(
      email,
      location.href
    );

    if (error) {
      console.log(error);
    } else {
      this.setState({
        isAuthSuccess: true
      });
      history.push(`users/${user.id}`);
    }

    this.setState({
      loading: false
    });
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signInWithMagicLink: (email, magicLink) => {
      return dispatch(userActions.signInWithMagicLink(email, magicLink));
    }
  };
};

export default connect(null, mapDispatchToProps)(Container);
