import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./styles.scss";

class Container extends Component {
  render() {
    return <TermsOfService />;
  }
}

const TermsOfService = (prosp, context) => (
  <div className={styles.contents}>
    <div className={styles.introduction}>
      {
        "利用規約（以下「本規約」といいます）は、AsobiClub運営（以下「当団体」といいます）が「AsobiClub」の名称で提供するサービス（以下「本サービス」といいます）の利用に関する条件を定めるものです。"
      }
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第1条（はじめに）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "利用者は、本規約に同意することを前提として、本サービスを利用することができます。利用者は、年齢や利用環境等、当団体の定める条件に応じて、当団体の定める範囲内で、本サービスを利用するものとします。"
          }
        </li>
        <li>
          {
            "当団体は、本サービス及び本サービスを利用して提供される一切のサービスについて、いつでも任意にその内容を変更し、中断し又は終了することができるものとします。"
          }
        </li>
        <li>
          {
            "本規約に同意しない利用者は、本サービスを利用することはできないものとし、直ちに本サービスのダウンロード、インストールもしくは利用を中止するものとします。利用者が本サービスを既にインストールしている場合、そのアンインストールを行うものとします。"
          }
        </li>
        <li>
          {
            "利用者は、本サービスの利用を、自身の所有するスマートフォン・タブレット・PC端末（以下、単に「端末」といいます）の操作によって行うものとします。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第2条（本規約の変更）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "当団体は、利用者に対し事前に通知し又は利用者から承諾を得ることなく、いつでも任意に、本規約を改訂することができるものとします。変更後の規約は、本サービス又は当団体の別途指定するウェブサイト上に掲示するものとし、当該掲示がなされた時点で効力を生じるものとします。"
          }
        </li>
        <li>
          {
            "利用者は、本規約の変更後に本サービスを利用した場合、変更後の本規約に同意したものとみなされます。仮に変更後の本規約に同意できない場合、直ちに本サービスの利用を中止するものとします。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第3条（著作権等の権利の帰属）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "本サービスを構成する文章、画像、プログラムその他一切の情報について発生している著作権その他の知的財産権、肖像権及びパブリシティ権その他の人格権ならびに所有権その他の財産権は、利用者が自ら作成したもの（文章やコメント等）に関する権利を除き、当団体又は当該権利を有する第三者に帰属します。"
          }
        </li>
        <li>
          {
            "当団体は、利用者が本サービスを通じて投稿、アップロード又は保存した全ての情報（文字情報、画像情報等を含みますがこれらに限られません。以下、「利用者コンテンツ」といいます）について、これらを保存・蓄積した上、本サービスの円滑な運営及び改善を目的として、本サービス及び本サービスの宣伝広告においてあらゆる態様で利用でき、利用者はこれに同意するものとします。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第4条（利用者情報の取扱い）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "当団体は、本サービスの提供により、次に掲げる情報（以下「利用者情報」といいます）を取得します。"
          }
          <ol>
            <li>
              {
                "利用者個人に関する情報：利用者が本サービスに登録し、当団体に送信したアカウント名（利用者の実名である場合を含みます）、メールアドレス、生年月日及び性別 "
              }
            </li>
            <li>
              {
                "利用端末に関する情報：ユーザーエージェント、使用デバイス、OS、Cookie情報、広告識別番号「IDFA/AndroidAdID」、端末言語設定、アクセス国 "
              }
            </li>
            <li>
              {"通信環境に関する情報：通信キャリア、通信環境、アクセス地域等 "}
            </li>
            <li>
              {
                "利用状況に関する情報：本サービスのバージョン、本サービスの利用履歴等 会員から個人情報の取扱いに関する同意を求めるための連絡"
              }
            </li>
          </ol>
        </li>
        <li>
          {"当団体は、利用者情報を次の方法で取得します。"}
          <ol>
            <li>
              {
                "利用者自らが問い合わせフォームなどを通じて提供することにより当団体が取得する方法： 電話番号、メールアドレス、生年月日及び性別"
              }
            </li>
            <li>
              {
                "利用者が本サービスを利用することにより自動的に当団体が取得する方法： Cookie情報、広告識別番号、端末の利用履歴"
              }
            </li>
          </ol>
        </li>

        <li>
          {"当団体は、利用者情報を次の目的で利用します。"}
          <ol>
            <li>
              {
                "（電話番号） 利用者からの問い合わせ対応のため（当団体が本サービス上で電話番号を入力させることはありません）"
              }
            </li>
            <li>
              {"（メールアドレス） 利用者への連絡・お知らせ・広告配信のため"}
            </li>
            <li>
              {
                "（生年月日・性別） 利用者の年齢確認のため・本サービスにおける特別なサービス（イベントの発生・アイテムのプレゼント）を提供するため"
              }
            </li>
            <li>
              {
                "（Cookie情報、広告識別番号、端末の利用履歴） ログイン認証の簡易化、利用者のステータスなどの情報保持、広告効果の測定、利用者情報の分析、利用者への最適な広告配信"
              }
            </li>
          </ol>
        </li>
        <li>
          {
            "保存・蓄積した利用者情報のうち、個人の特定が容易に可能な個人情報については、当団体のプライバシーポリシーに基づき、善良な管理者の注意義務に従い取り扱うとともに、上記の目的の範囲内で利用します。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>
        {"第5条（利用者情報及び利用者コンテンツの保存義務）"}
      </div>
      <ol className={styles.content}>
        <li>
          {
            "当団体は、利用者情報及び利用者コンテンツの保存義務を負わず、これらの完全性、可用性について保証しません。利用者は、利用者情報及び利用者コンテンツを保全したい場合、自らの責任によりバックアップをとるものとします。"
          }
        </li>
        <li>
          {
            "当団体は、利用者が本サービスを退会し、又は当団体が本サービスの運営を終了したときは、当団体の判断により、利用者情報及び利用者コンテンツを全て消去できるものとし、その返却等の要望には応じません。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第6条（料金及び費用）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "利用者は、原則として、本サービスを無料で利用できるものとします。ただし当団体は、利用者の選択によって追加又は拡張できる機能の全部又は一部を有料とし、その他有料のサービス（本サービス内で利用できるポイント、アイテム等の販売等を含みますが、これらに限定されません）を提供する場合があります。なお、未成年者が有料サービスを利用する場合、未成年者は、事前に親権者の同意を得なければならないものとします。"
          }
        </li>
        <li>
          {
            "当団体は、本サービスにおいて無料又は有料とされている機能について、当団体の判断により、その価格を変更する場合があります。利用者は、当該変更に承諾しない場合、本サービスの全部又は一部を利用できなくなる場合があることについて、予め承諾するものとします。"
          }
        </li>
        <li>
          {
            "本サービスの利用にあたって必要な端末の購入、導入又は維持にかかる費用、パケット料、データ利用料などの通信料その他の一切の費用は、利用者の負担とします。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第7条（禁止事項）"}</div>
      <p>
        {
          "当団体は、利用者に対し、本サービスの利用に関し、次の各号のいずれかに該当する行為を禁止します。"
        }
      </p>
      <ol className={styles.content}>
        <li>
          {"法令、公序良俗又は本規約に違反する行為、又はそのおそれのある行為。"}
        </li>
        <li>
          {
            "当団体又は第三者の著作権その他の知的財産権、肖像権及びパブリシティ権その他の人格権ならびに所有権その他の財産権を侵害する行為。"
          }
        </li>
        <li>
          {
            "当団体又は第三者を不当に差別し又は誹謗中傷する行為、当団体又は本サービスの名誉・信用を毀損する行為。"
          }
        </li>
        <li>
          {
            "第三者へのいやがらせ、わいせつ、悪口、嫌悪感を与える表現や内容を含む一切の利用者コンテンツを投稿し、書き込みし、又はアップロードする行為。"
          }
        </li>
        <li>
          {
            "アダルトサイト、出会い系サイト等へのリンクを掲載する行為、異性との出会いを希望又は誘導することを目的とする行為。"
          }
        </li>
        <li>
          {
            "氏名、名称、電話番号、メールアドレス、住所など特定の個人を特定できる情報、又は企業、団体などの非公開情報、営業秘密、秘密情報を、本人又は権利者の許可なく取得、開示、改ざんもしくは漏洩させる行為、又はそのおそれのある行為。"
          }
        </li>
        <li>
          {"第三者になりすまし、その他虚偽の情報を登録及び掲載する行為。"}
        </li>
        <li>
          {
            "他の利用者につきまとい、執拗に追いかける行為など、他の利用者の意思に反する一切の行為。"
          }
        </li>
        <li>
          {
            "不正アクセス、又は他人であるように振る舞うなどの誤解を与える行為、又はそのおそれのある行為。"
          }
        </li>
        <li>
          {
            "ねずみ講、マルチ商法、ネットワークビジネスその他連鎖講に類する取引の宣伝・告知・勧誘及びその取引行為。"
          }
        </li>
        <li>{"当団体の承認を得ずに行われる、営利を目的とする行為。"}</li>
        <li>
          {
            "当団体の承認を得ずに行われる、複数の利用者アカウントを保有し、又は利用者アカウントを複数人で共有する行為。"
          }
        </li>
        <li>
          {
            "本サービスを運営するためのサーバー又はネットワークに著しく負荷をかける行為。"
          }
        </li>
        <li>
          {
            "本サービスを改変、毀損し、又は逆アセンブル、逆コンパイル、リバースエンジニアリングする行為。"
          }
        </li>
        <li>
          {
            "当団体が本サービスを通じて提供する各種コンテンツ（本サービス内で利用できるポイント、アイテム等を含みますが、これらに限定されません。以下「サービス内コンテンツ」といいます）を不正な方法で取得する行為、又はこれを助長する行為。"
          }
        </li>
        <li>
          {
            "利用者アカウント、サービス内コンテンツその他本サービスにおいて利用者が利用可能な権利又は利益の全部又は一部を、現金その他の財産上の利益を対価として第三者に譲渡し又は利用させる行為、ならびにその宣伝・告知・勧誘。"
          }
        </li>
        <li>
          {
            "当団体又は第三者に対して迷惑、損害を与える行為、又はそのおそれのある行為。"
          }
        </li>
        <li>{"本サービスの運営を妨げる行為、又はそのおそれのある行為。"}</li>
        <li>{"その他当団体が不適切と判断する行為。"}</li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第8条（免責）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "当団体は、本サービスが第三者の権利を侵害していないこと、及び本サービスを実行することが第三者の権利を侵害しないことを保証しません。"
          }
        </li>
        <li>
          {
            "利用者は、自己の責任と判断において本サービスを利用するものとします。当団体は、本サービスの精度、完成度、正確性、妥当性、適用性、有用性及び可用性について保証せず、ならびに利用者の使用目的及び使用する端末への適合性について保証しません。"
          }
        </li>
        <li>
          {
            "当団体は、利用者が本サービスを通じて行う取引には一切関知しないものとし、本サービスを通じたいかなる情報の正確性も保証しません。利用者は、他の利用者との間で生じた一切の紛争については、自己の責任と判断において解決を図るものとします。"
          }
        </li>
        <li>
          {
            "地震、津波、台風、雷、大雨、洪水などの天災、戦争、争議、革命、労働争議などの不可抗力、本サービス運営において必要なシステム等の一時的又は大規模なメンテナンス、ネットワーク回線の混雑、プロバイダー等の障害、その他本サービスの運営にかかる障害等により、一時的又は長期にわたって本サービスの利用ができなくなる場合、又は当団体が本サービスそのものの利用を停止しもしくは終了させる場合があります。当団体は、利用者に対し、これらの事象に関して責任を負いません。"
          }
        </li>
        <li>
          {
            "当団体は、利用者に対し、本サービスに対する不正アクセス、コンピューターウイルスの侵入など、第三者の行為を原因として利用者に生じる一切の損害について責任を負いません。"
          }
        </li>
        <li>
          {
            "当団体は、本サービスに関連して発生した利用者又は第三者の損害について責任を負いません。また、当団体は、利用者が一度支払った金銭等について、その理由を問わず返金しません。"
          }
        </li>
        <li>
          {
            "当団体は、本サービス上で実施される広告の広告主等と利用者との間におけるあらゆるトラブル、約束、保証等及びその結果として利用者に生じる一切の損失・損害について責任を負いません。"
          }
        </li>
        <li>
          {
            "当団体は、本サービスからリンクにより遷移可能な一切の第三者ウェブサイトについて、その適法性、整合性、安全性、正確性、公序良俗に反しないものであることなどを保証しません。"
          }
        </li>
        <li>
          {
            "当団体は、本サービスに関する利用者からの問い合わせや改善等の意見その他の提案、フィードバック等について、これらの全てに回答をし、又は何らかの対応を実施する義務を負わず、自らの裁量及び任意の判断でこれらを実施するものとします。当団体は、利用者からの問い合わせ、意見、提案、フィードバック等の内容に対して何らかの対応を実施することがありますが、当団体による当該行為は、当該利用者に対し、個別に回答や報告、金銭的な補償などを行う義務を負うものとは解釈されません。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第9条（端末及びアカウントの管理）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "利用者は、本サービスの利用にあたり、その利用に用いる自身の端末及び利用者登録によって取得した自らのアカウント（以下「利用者アカウント」といいます）を、自らの責任において管理するものとします。"
          }
        </li>
        <li>
          {
            "利用者は、自身の端末又は利用者アカウント上を利用してなされた行為について、当該端末又はアカウントを現実に利用する者が誰であるかを問わず一切の責任を負うものとし、当該行為により当団体又は第三者が損害を被った場合、その損害を賠償するものとします。"
          }
        </li>
        <li>
          {
            "当団体は、利用者の使用する端末の故障、紛失、第三者からのマルウェア、スパイウェア、ウイルス、ハッキング等による攻撃、利用者アカウントの管理不備、使用上の過誤又は第三者の使用等により、利用者が損害を被った場合でも、責任を負いません。"
          }
        </li>
        <li>
          {
            "利用者は、自身の利用者アカウントが不正に取得され、又は端末もしくは利用者アカウントが第三者により利用されるおそれがある場合は、速やかに退会処理をし、又は当団体に報告をするなど、当団体又は第三者に損害が発生することを避けるために合理的に必要と考えられるあらゆる措置をとるものとします。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第10条（アカウントの停止等）"}</div>
      <p>
        {
          "当団体は、利用者が次の各号のいずれかに該当する場合、本サービスの一部又は全部の提供を停止し、利用者アカウントの利用を停止及び削除し、又は退会させ、その他当団体が適当と考える措置をとることができるものとします。当団体は、当該措置についてその理由を開示する義務を負わないものとし、利用者は、当団体に対し、当該措置について異議を申し立てることはできないものとします。"
        }
      </p>
      <ol className={styles.content}>
        <li>
          {
            "本規約の禁止事項を行うなど、本規約に違反した場合、又はそのおそれがあると当団体が判断した場合。"
          }
        </li>
        <li>
          {
            "理由のいかんを問わず、利用者の行為（本サービス外の行為及び不作為を含みます。）によって、本サービスの運営に支障が生じ、又は妨害がされたと当団体が判断した場合、又はそのおそれのあると当団体が判断した場合。"
          }
        </li>
        <li>
          {"その他本サービスの利用者として不適当と当団体が判断した場合。"}
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第11条（権利義務等の譲渡禁止）"}</div>
      <p>
        {
          "利用者は、本規約に基づく一切の権利又は義務について、第三者への譲渡、承継、質入その他一切の処分をしてはならないものとします。"
        }
      </p>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第12条（退会）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "利用者は、本サービスを退会するときは、当該時点において当団体に対して有している一切の債務を、直ちに履行するものとします。"
          }
        </li>
        <li>
          {
            "利用者が、本サービスの利用に際して用いる端末について、修理又は機種変更等によりこれを交換したときは、当団体が特に定める場合を除き、当該新たな端末において、端末を交換する以前の登録情報等の引継ぎ等はできないものとします。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第13条（損害賠償）"}</div>
      <ol className={styles.content}>
        <li>
          {
            "利用者は、本規約に違反したことにより、当団体又は第三者に損害（合理的な弁護士費用を含みますがこれに限られません）を与えた場合、当団体又は第三者に対し、その損害を賠償するものとします。"
          }
        </li>
        <li>
          {
            "当団体が、第8条（免責）の規定にも関わらず、利用者に対して損害賠償責任を負担する場合であっても、その損害賠償額は、当団体に故意又は重過失ある場合を除き、当該利用者の当団体に対する直近１ヶ月の支払額を上限とするものとします。"
          }
        </li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"第14条（準拠法及び裁判管轄）"}</div>
      <ol className={styles.content}>
        <li>
          {"本規約は、日本法に準拠し、日本法に従って解釈されるものとします。"}
        </li>
        <li>
          {
            "本サービス、本規約に関する一切の訴訟その他の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。"
          }
        </li>
      </ol>
    </div>
    <p>{"改定日: 2022年12月24日"}</p>
  </div>
);

export default connect(null, null)(Container);
