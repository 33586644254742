import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as socialActions } from "redux/social";

const mapStateToProps = (state, ownProps) => {
  const { user: { isSignedIn } = {} } = state;
  return {
    isSignedIn
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    socialLogin: (token, verifier) => {
      return dispatch(socialActions.socialLogin(token, verifier));
    }
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Container);
