import React from "react";
import styles from "./styles.scss";
import { Link } from "react-router-dom";

const Logo = (props) => (
  <div className={styles.logo}>
    <Link to="/">
      <div className={styles.title}>
        <img
          src={require("images/logo.png")}
          alt="logo"
          className={styles.logoImage}
        />
        <span className={styles.focused}>あそび</span>
        <span>クラブ</span>
      </div>
    </Link>
  </div>
);

export default Logo;
