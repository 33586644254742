import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import SocialLogin from "components/SocialLogin";
// import SignUpWithEmail from "components/SignUpWithEmail";
// import SignInWithEmail from "components/SignInWithEmail";
import SendMagicLink from "components/SendMagicLink";

const Auth = (props, context) => (
  <div className={styles.container}>
    <div className={styles.loginTitle}>{context.t(props.action)}</div>
    <SocialLogin {...props} />
    <SendMagicLink {...props} />
    {!props.isSignedIn && props.action === "login" ? (
      <div className={styles.loginContainer}>
        {/* <Login {...props} /> */}
        {/* <SignInWithEmail {...props} /> */}
        <div className={styles.switchAction}>
          <span onClick={props.changeAction} className={styles.link}>
            {context.t("goToSignUp")}
          </span>
        </div>
      </div>
    ) : (
      props.action === "signup" && (
        <div className={styles.signupContainer}>
          {/* <Signup {...props} /> */}
          {/* <SignUpWithEmail {...props} /> */}
          <div className={styles.switchAction}>
            <span onClick={props.changeAction} className={styles.link}>
              {context.t("goToLogin")}
            </span>
          </div>
        </div>
      )
    )}
  </div>
);

Auth.propTypes = {
  action: PropTypes.string.isRequired,
  changeAction: PropTypes.func.isRequired
};

Auth.contextTypes = {
  t: PropTypes.func.isRequired
};

export default Auth;
