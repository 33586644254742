import React from "react";
import CommonUploadMatchResult from "components/CommonUploadMatchResult";

const TournamentUploadMatchResult = (props, context) => (
  <CommonUploadMatchResult
    {...props}
    resourceInfo={props.tournamentInfo}
    matchInfo={props.tournamentMatchInfo}
    updateResource={props.updateTournament}
  />
);

export default TournamentUploadMatchResult;
