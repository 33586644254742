import React from "react";
import styles from "./styles.scss";
import classNames from "classnames/bind";
import PropTypes from "prop-types";

const cx = classNames.bind(styles);

const Loading = (props) => {
  const { size = "medium" } = props;
  return (
    <div className={cx("loadingContainer", size)}>
      <img
        src={require("images/loading.png")}
        alt="loading"
        className={styles.image}
      />
    </div>
  );
};

Loading.propTypes = {
  size: PropTypes.string
};

export default Loading;
