import Axios from "axios";

const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_HOST
});

// request interceptor
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("jwt");
  if (token) {
    config.headers.common["Authorization"] = "JWT " + token;
  }
  return config;
});

// response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  (err) => {
    if (err.response?.status === 401) {
      // TODO: 一旦alert直書き
      alert(this.context.t("authenticationError"));
    }
    return Promise.reject(err);
  }
);

export { axios };
