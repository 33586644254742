import React, { Component } from "react";
import TournamentMatchResult from "./presenter";

class Container extends Component {
  render() {
    return <TournamentMatchResult {...this.state} {...this.props} />;
  }
}

export default Container;
