import { firestore, FieldValue } from "lib/firebase";
import { convertFirestoreTime } from "lib/convert";

// actions
const SET_USER_CHAT_LIST = "SET_USER_CHAT_LIST";
const OPEN_CHAT = "OPEN_CHAT";
const CLOSE_CHAT = "CLOSE_CHAT";
const SET_CHAT_ROOM_INFO = "SET_CHAT_ROOM_INFO";
const SET_CHAT_MESSAGES = "SET_CHAT_MESSAGES";
const CLEAR_CHAT_MESSAGES = "CLEAR_CHAT_MESSAGES";

let chatListener = null;

// action creator
function openChat() {
  return {
    type: OPEN_CHAT
  };
}

function closeChat() {
  return {
    type: CLOSE_CHAT
  };
}

function setUserChatList(userChatList) {
  return {
    type: SET_USER_CHAT_LIST,
    userChatList
  };
}

function setChatRoomInfo(chatRoomInfo) {
  return {
    type: SET_CHAT_ROOM_INFO,
    chatRoomInfo
  };
}

function setChatMessages(chatMessages) {
  return {
    type: SET_CHAT_MESSAGES,
    chatMessages
  };
}

function clearChatMessages() {
  return {
    type: CLEAR_CHAT_MESSAGES
  };
}

// chatroom 作成
function createChatRoom(param) {
  return (dispatch, getState) => {
    return firestore
      .collection("rooms")
      .add({
        ...param,
        created: FieldValue.serverTimestamp(),
        updated: FieldValue.serverTimestamp()
      })
      .then((docRef) => {
        const chatRoomInfo = {
          id: docRef.id,
          ...param
        };
        dispatch(setChatRoomInfo(chatRoomInfo));
        return docRef;
      })
      .catch((err) => console.log(err));
  };
}

// チャットを送信
function sendMessage(roomId, param) {
  return (dispatch, getState) => {
    // add subcollection
    return firestore
      .collection("rooms")
      .doc(roomId)
      .collection("chat")
      .add({
        ...param,
        created: FieldValue.serverTimestamp(),
        updated: FieldValue.serverTimestamp()
      })
      .catch((err) => console.log(err));
  };
}

// ユーザーのチャット参加情報取得
function getUserChatList(userId) {
  return async (dispatch, getState) => {
    try {
      if (!userId) {
        throw new Error("userId is required");
      }

      firestore
        .collection("rooms")
        .where("join", "array-contains", userId)
        .orderBy("created", "asc")
        .onSnapshot((querySnapshot) => {
          const userChatList = [];

          if (querySnapshot.size === 0) {
            dispatch(setUserChatList(userChatList));
            return;
          }

          querySnapshot.forEach(
            (doc) => {
              const data = doc.data();

              userChatList.push({
                id: doc.id,
                ...data
              });

              dispatch(setUserChatList(userChatList));
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            }
          );
        });
    } catch (err) {
      console.log(err);
    }

    return;
  };
}

// ユーザーのリアルタイムチャットメッセージ取得
// 最新1件のみ監視するlistener
function getNewChatMessages(query) {
  return (dispatch, getState) => {
    try {
      if (!query.roomId) {
        throw new Error("roomId is required");
      }

      const chat = firestore
        .collection("rooms")
        .doc(query.roomId)
        .collection("chat");

      if (chatListener) {
        chatListener();
      }

      chatListener = chat
        .orderBy("created", "desc")
        .limit(1)
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              if (querySnapshot.size) {
                const data = querySnapshot.docs[change.newIndex].data();
                const displayTime = convertFirestoreTime(data.created);
                const id = querySnapshot.docs[change.newIndex].id;
                const {
                  chat: { chatMessages = [] }
                } = getState();

                let isExist = false;
                for (const val of chatMessages) {
                  if (val.id === id) {
                    isExist = true;
                  }
                }

                if (!isExist) {
                  chatMessages.push({
                    id,
                    ...data,
                    displayTime
                  });
                }
                dispatch(setChatMessages(chatMessages));
              }
            }
          });
        });
    } catch (err) {
      console.log(err);
    }
  };
}

// ユーザーのチャットメッセージ情報取得
function getChatMessages(query) {
  return (dispatch, getState) => {
    try {
      if (!query.roomId) {
        throw new Error("roomId is required");
      }

      let chat = firestore
        .collection("rooms")
        .doc(query.roomId)
        .collection("chat")
        .orderBy("created", "desc")
        .limit(query.limit);
      // 古いメッセージを読み込む用の開始点設定
      if (query.lastData) {
        chat = chat.startAfter(query.lastData);
      }

      return chat.get().then((snapShots) => {
        if (snapShots.size === 0) {
          const result = {
            length: 0,
            lastData: null
          };
          return result;
        } else {
          const oldChatMessages = [];
          snapShots.forEach(
            (doc) => {
              const data = doc.data();
              const displayTime = convertFirestoreTime(data.created);

              // 書き込み日時が新しい順で取得しているので、表示用にunshiftで古い順で追加
              oldChatMessages.unshift({
                id: doc.id,
                ...data,
                displayTime
              });
            },
            (err) => {
              console.log(`Encountered error: ${err}`);
            }
          );
          const {
            chat: { chatMessages = [] }
          } = getState();
          const newChatMessages = oldChatMessages.concat(chatMessages);
          const result = {
            length: snapShots.size,
            lastData: snapShots.docs[snapShots.docs.length - 1]
          };
          dispatch(setChatMessages(newChatMessages));
          return result;
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
}

const initialState = {
  type: "",
  chatIsOpen: false
};

// reducer
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER_CHAT_LIST:
      return applySetUserChat(state, action);
    case OPEN_CHAT:
      return applyOpenChat(state, action);
    case CLOSE_CHAT:
      return applyCloseChat(state, action);
    case SET_CHAT_ROOM_INFO:
      return applySetChatRoomInfo(state, action);
    case SET_CHAT_MESSAGES:
      return applySetChatMessages(state, action);
    case CLEAR_CHAT_MESSAGES:
      return applyClearChatMessages(state, action);
    default:
      return state;
  }
}

function applySetUserChat(state, action) {
  const { userChatList } = action;
  return {
    ...state,
    userChatList
  };
}

function applyOpenChat(state, action) {
  return {
    ...state,
    chatIsOpen: true
  };
}

function applyCloseChat(state, action) {
  return {
    ...state,
    chatIsOpen: false
  };
}

function applySetChatRoomInfo(state, action) {
  const { chatRoomInfo } = action;
  return {
    ...state,
    chatRoomInfo
  };
}

function applySetChatMessages(state, action) {
  const { chatMessages } = action;
  return {
    ...state,
    chatMessages
  };
}

function applyClearChatMessages(state, action) {
  return {
    ...state,
    chatMessages: []
  };
}

// exports
const actionCreators = {
  sendMessage,
  getUserChatList,
  openChat,
  closeChat,
  createChatRoom,
  getChatMessages,
  getNewChatMessages,
  clearChatMessages
};

export { actionCreators };

export default reducer;
