import React, { Component } from "react";
import ContestDetail from "./presenter";
import PropTypes from "prop-types";
import copy from "copy-to-clipboard";
import html2canvas from "html2canvas";
import styles from "./styles.scss";

class Container extends Component {
  state = {
    loading: true,
    createLoading: false,
    isShareModal: false,
    isEntryModal: false
  };

  async componentDidMount() {
    const {
      getContestDetail,
      getBattleRoyalInfo,
      getTournamentInfo,
      getSwissDrawInfo,
      getContestOperators,
      match: {
        params: { id }
      }
    } = this.props;

    this.setState({
      loading: true
    });

    // reduxで返した大会情報
    const contestInfo = await getContestDetail(id);
    // contestType === 'LEAGUE'もあるためelseは利用しない
    if (contestInfo?.contestType === "BATTLEROYAL") {
      await getBattleRoyalInfo(id);
    } else if (contestInfo?.contestType === "TOURNAMENT") {
      await getTournamentInfo(id);
    } else if (contestInfo?.contestType === "SWISSDRAW") {
      await getSwissDrawInfo(id);
    }
    // 運営ユーザ一覧取得
    await getContestOperators(id);
    this.setState({
      loading: false
    });
  }

  componentWillUnmount() {
    const { clearContestInfo } = this.props;
    clearContestInfo();
  }

  render() {
    return (
      <ContestDetail
        {...this.props}
        {...this.state}
        handleOpenEntryModal={this._handleOpenEntryModal}
        handleCloseEntryModal={this._handleCloseEntryModal}
        getRankPointTableHeight={this._getRankPointTableHeight}
        handleQuestion={this._handleQuestion}
        handleNotice={this._handleNotice}
        handleCheckin={this._handleCheckin}
        handleOpenShareModal={this._handleOpenShareModal}
        handleCloseShareModal={this._handleCloseShareModal}
        handleShareTwitter={this._handleShareTwitter}
        handleShareFacebook={this._handleShareFacebook}
        handleShareLink={this._handleShareLink}
        handleScreenShot={this._handleScreenShot}
      />
    );
  }

  // エントリーモーダルを開く
  _handleOpenEntryModal = () => {
    this.setState({
      isEntryModal: true
    });
  };

  // エントリーモーダルを閉じる
  _handleCloseEntryModal = () => {
    this.setState({
      isEntryModal: false
    });
  }

  // シェアモーダルを開く
  _handleOpenShareModal = () => {
    this.setState({
      isShareModal: true
    });
  };

  // シェアモーダルを閉じる
  _handleCloseShareModal = () => {
    this.setState({
      isShareModal: false
    });
  };

  // 画面キャプチャー
  _handleScreenShot = (event) => {
    const {
      contestInfo: { name }
    } = this.props;
    const tournamentDOM = document.getElementById("tournamentInfo");
    const width = tournamentDOM.clientWidth + 30;
    const height = tournamentDOM.clientHeight;
    const x = window.pageXOffset;
    const y = window.pageYOffset;

    // キャプチャーのため一瞬Topに変更
    window.scrollTo(0, 0);
    html2canvas(tournamentDOM, {
      backgroundColor: "#1c1c1c",
      scale: 1,
      width,
      height,
      useCORS: true,
      onclone: (doc) => {
        // logo 表示
        doc.getElementById("logo").style.visibility = "visible";
        // tournamentInfo に paddingLeft を設定
        doc.getElementById("tournamentInfo").style.paddingLeft = "30px";
        // 対戦するボタン非表示
        const elements = doc.getElementsByClassName(styles.matchButton);
        for (const element of elements) {
          element.style.visibility = "hidden";
        }
        return;
      }
    }).then((canvas) => {
      const canvasImg = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = canvasImg;
      link.download = name;
      // download
      link.click();
    });
    // 元スクロールに戻す
    window.scrollTo(x, y);
  };

  // Twitter 共有
  _handleShareTwitter = () => {
    const { location : { pathname = "" } } = this.props;
    const contestUrl = window.location.host + pathname;
    const twitterShareUrl = "https://twitter.com/intent/tweet";
    let params = {
      text: contestUrl,
      hashtags: "アソビクラブ,アソクラ,アソクラ大会"
    };
    const url =  twitterShareUrl + "?" + new URLSearchParams(params);
    // 別タブで URL開く
    window.open(url);
  };

  // Facebook 共有
  _handleShareFacebook = () => {
    const { location : { pathname = "" } } = this.props;
    const contestUrl = window.location.host + pathname;
    const facebookShareUrl = "https://www.facebook.com/sharer/sharer.php";
    const url = facebookShareUrl + `?u=${contestUrl}`;
    // 別タブで URL開く
    window.open(url);
  };

  // リンクコピー
  _handleShareLink = () => {
    const { location : { pathname = "" } } = this.props;
    const contestUrl = window.location.host + pathname;
    // clipboard にコピー
    copy(contestUrl);
    return alert("大会 URLをコピーしました");
  };

  _handleOpenMatch = (event) => {
    const { openModal, isSignedIn } = this.props;

    if (!isSignedIn) {
      event.preventDefault();
      openModal("login");
    }
  };

  // テーブルの height 取得
  // TODO: style定義だけてできるように修正
  _getRankPointTableHeight = () => {
    const {
      battleRoyalInfo: { rankPoint = [] }
    } = this.props;
    // point があるデータのみカウント
    const itemLength = rankPoint.filter((el) => el.point).length;
    const height = Math.round(itemLength / 2) * 30;
    return height;
  };

  // 質問チャットルームに遷移
  _handleQuestion = async () => {
    const {
      contestInfo = {},
      history = {},
      getContestQuestionChatInfo
    } = this.props;
    const chatInfo = await getContestQuestionChatInfo(contestInfo.id);
    if (contestInfo.id && chatInfo.id) {
      history.push({
        pathname: `/contests/${contestInfo.id}/chats/${chatInfo.id}`,
        state: { chatInfo: chatInfo }
      });
    } else {
      alert(this.context.t("emptyContestQuestionRoom"));
    }
  };

  // 告知チャットルームに遷移
  _handleNotice = async () => {
    const {
      contestInfo = {},
      history = {},
      getContestNoticeChatInfo
    } = this.props;
    const chatInfo = await getContestNoticeChatInfo(contestInfo.id);
    if (contestInfo.id && chatInfo.id) {
      history.push({
        pathname: `/contests/${contestInfo.id}/chats/${chatInfo.id}`,
        state: { chatInfo: chatInfo }
      });
    } else {
      alert(this.context.t("emptyContestNotice"));
    }
  };

  // チェックイン
  _handleCheckin = async () => {
    const { t } = this.context;
    try {
      const {
        contestInfo: {
          id: contestId,
          participantType,
          isCheckin,
          entryInfo = {}
        },
        contestUserCheckin,
        contestTeamCheckin
      } = this.props;
      const params = {
        checkin: !isCheckin
      };
      if (isCheckin) {
        if (window.confirm(t("alreadyCheckedinConfirm"))) {
          if (participantType === "MULTI") {
            const teamId = entryInfo.team?.id;
            await contestTeamCheckin(contestId, teamId, params);
          } else if (participantType === "SOLO") {
            await contestUserCheckin(contestId, entryInfo.id, params);
          }
          alert(t("completeCancelCheckin"));
          // TODO: 必要な状態のみ redux 更新
          // 画面リロード
          window.location.reload();
        }
      } else {
        if (participantType === "MULTI") {
          const teamId = entryInfo.team?.id;
          await contestTeamCheckin(contestId, teamId, params);
        } else if (participantType === "SOLO") {
          await contestUserCheckin(contestId, entryInfo.id, params);
        }
        alert(t("completeCheckin"));
        // TODO: 必要な状態のみ redux 更新
        // 画面リロード
        window.location.reload();
      }
    } catch (err) {
      // エラーハンドル
      const { data } = err?.response;
      switch (data) {
        case -2:
          // チェックインフラグが OFF の場合
          alert(t("disabledCheckin"));
          break;
        case -3:
          // チェックイン受付期間外の場合
          alert(t("notWithinCheckin"));
          break;
        default:
          // 例外エラー
          alert(t("exceptionError"));
      }
    }
  };

  static propTypes = {
    contestInfo: PropTypes.object.isRequired,
    getContestDetail: PropTypes.func.isRequired,
    getTournamentInfo: PropTypes.func.isRequired,
    getSwissDrawInfo: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
