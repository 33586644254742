import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: { contestInfo = {}, tournamentInfo = {}, tournamentMatchInfo = {} }
  } = state;

  return {
    contestInfo,
    tournamentInfo,
    tournamentMatchInfo
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentInfo: (contestId) => {
      return dispatch(contestActions.getTournamentInfo(contestId));
    },
    getTournamentMatchInfo: (tournamentId, matchId) => {
      return dispatch(
        contestActions.getTournamentMatchInfo(tournamentId, matchId)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
