import React, { Component } from "react";
import SelectWinner from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  render() {
    return <SelectWinner {...this.props} {...this.state} />;
  }

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
