import React, { Component } from "react";
import CommonMatchResult from "./presenter";

class Container extends Component {
  state = {
    winnerInfo: {},
    matchList: [],
    isLoading: false
  };

  async componentDidMount() {
    const {
      matchInfo: { winner, participantList = [], reports = [], matchCount = 1 }
    } = this.props;

    // winner がある場合、詳細取得
    if (winner) {
      const winnerInfo = participantList.find((el) => el.id === winner) || {};

      const matchList = [];
      for (const val of reports) {
        const winnerInfo =
          participantList.find((el) => el.id === val.winner) || {};
        matchList.push({
          ...val,
          winnerInfo
        });
      }
      if (!matchList.length) {
        // 結果がない場合は、matchCountで枠作成
        for (let i = 0; i < matchCount; i++) {
          matchList.push({
            index: i
          });
        }
      }

      this.setState({
        winnerInfo,
        matchList
      });
    }
  }

  render() {
    return <CommonMatchResult {...this.state} {...this.props} />;
  }
}

export default Container;
