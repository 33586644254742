import { firestore } from "lib/firebase";
import {
  getDocs,
  updateDoc,
  doc,
  collection,
  query,
  where,
  limit,
  startAfter,
  orderBy,
  onSnapshot,
  serverTimestamp,
  writeBatch,
} from "firebase/firestore";
import { downloadFile } from "lib/convert";


let unsubscribeNotification;

// actions
const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
const SET_NOTIFICATION_LASTDATA = "SET_NOTIFICATION_LASTDATA";
const SET_NOTIFICATION_HASMORE = "SET_NOTIFICATION_HASMORE";
const SET_NOTIFICATION_BADGE = "SET_NOTIFICATION_BADGE";
const CLEAR_NOTIFICATION_INFO = "CLEAR_NOTIFICATION_INFO";

// action creator
function setNotificationList(notificationList) {
  return {
    type: SET_NOTIFICATION_LIST,
    notificationList
  };
}

function setNotificationLastData(lastData) {
  return {
    type: SET_NOTIFICATION_LASTDATA,
    lastData
  };
}

function setNotificationHasMore(hasMore) {
  return {
    type: SET_NOTIFICATION_HASMORE,
    hasMore
  };
}

function setNotificationBadge(badge) {
  return {
    type: SET_NOTIFICATION_BADGE,
    badge
  };
}

function clearNotificationInfo() {
  return {
    type: CLEAR_NOTIFICATION_INFO
  };
}

// 通知一覧取得
function getNotificationList(userId) {
  return async (dispatch, getState) => {
    try {
      if (!userId) return;
      const {
        notification: { notificationList = [], limit: dataLimit, lastData = {} }
      } = getState();

      const notificationRef = collection(firestore, "notifications");
      let docRef = query(notificationRef, where("to", "==", userId));
      docRef = query(docRef, orderBy("created", "desc"), limit(dataLimit));

      // 古いメッセージを読み込む用の開始点設定
      if (lastData.id) {
        docRef = query(docRef, startAfter(lastData));
      }
      const querySnapshot = await getDocs(docRef);

      // redux store 更新
      const list = [];
      querySnapshot.forEach((doc) => {
        // 重複防止
        if (!notificationList.some((el) => el.id === doc.id)) {
          list.push({
            id: doc.id,
            ...doc.data()
          });
        }
      });

      for (const val of list) {
        const { notificationType, payload = {} } = val;
        let image;
        switch (notificationType) {
          case "tournament":
          case "contest":
            image = payload.contestImage;
            break;
          case "admin":
            image = payload.image;
            break;
          default:
            break;
        }
        val.imageUrl = await downloadFile(image);
      }

      if (list.length) {
        dispatch(setNotificationList(notificationList.concat(list)));
        const lastData = querySnapshot.docs[querySnapshot.docs.length - 1];
        dispatch(setNotificationLastData(lastData));
      }
      const hasMore = dataLimit > list.length ? false : true;
      dispatch(setNotificationHasMore(hasMore));

      const result = {
        length: list.length
      };
      return result;
    } catch (err) {
      console.log(err);
    }
  };
}

// API actions
// 新しい通知を受け取る
function subscribeNewNotification(userId) {
  return async (dispatch, getState) => {
    try {
      if (!userId) {
        throw new Error("userId is required");
      }
      if (unsubscribeNotification) {
        unsubscribeNotification();
      }
      const current = new Date();

      let docRef = collection(firestore, "notifications");
      docRef = query(docRef, where("to", "==", userId));
      docRef = query(docRef, where("created", ">", current));
      docRef = query(docRef, orderBy("created", "desc"), limit(1));

      unsubscribeNotification = onSnapshot(docRef, (querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          // redux store 更新
          const {
            notification: { notificationList = [], badge = 0 }
          } = getState();

          const changedNotificationList = [...notificationList];

          querySnapshot.docChanges().forEach((change) => {
            if (change.type === "added") {
              const doc = change.doc;
              // 重複防止
              if (!changedNotificationList.some((el) => el.id === doc.id)) {
                const data = doc.data();
                // 先頭に追加
                changedNotificationList.unshift({
                  id: doc.id,
                  ...data
                });
                dispatch(setNotificationList(changedNotificationList));
                // バッジー増加
                dispatch(setNotificationBadge(badge + 1));
              }
            }
          });
        });
      });
    } catch (err) {
      console.log(err);
    }
  };
}

// 通知バッジーを取得
function getNotificationBadge(userId) {
  return async (dispatch, getState) => {
    try {
      const notificationRef = collection(firestore, "notifications");
      let docRef = query(notificationRef, where("to", "==", userId));
      docRef = query(docRef, where("isView", "==", false));

      const querySnapshot = await getDocs(docRef);
      if (querySnapshot.empty) return;
      dispatch(setNotificationBadge(querySnapshot.size));
    } catch (err) {
      console.log(err);
    }
  };
}

// 通知の観覧記録を付ける
function viewNotification(userId) {
  return async (dispatch, getState) => {
    try {
      const {
        notification: { badge }
      } = getState();
      if (!badge) return;
      // バッジーを 0にする
      dispatch(setNotificationBadge(0));
      // firestore 更新
      const notificationRef = collection(firestore, "notifications");
      let docRef = query(notificationRef, where("to", "==", userId));
      docRef = query(docRef, where("isView", "==", false));
      const querySnapshot = await getDocs(docRef);
      if (querySnapshot.empty) return;

      let batch = writeBatch(firestore);
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        if (!data.isView) {
          batch.update(doc.ref, { isView: true });
        }
      });
      // 未読データを既読に一括更新
      await batch.commit();
    } catch (err) {
      console.log(err);
    }
  };
}

// notificationを既読
function readNotification(id) {
  return async (dispatch, getState) => {
    // firestore 更新
    let docRef = doc(firestore, "notifications", id);
    await updateDoc(docRef, {
      readed: serverTimestamp()
    });

    // redux store 更新
    const {
      notification: { notificationList = [] }
    } = getState();
    notificationList.map((el) => {
      if (el.id === id) {
        el.readed = serverTimestamp();
      }
      return el;
    });
    dispatch(setNotificationList(notificationList));
  };
}

// notificationを初期化
function clearNotification() {
  return (dispatch, getState) => {
    if (unsubscribeNotification) {
      unsubscribeNotification();
    }
    dispatch(clearNotificationInfo());
  };
}

const initialState = {
  notificationList: [],
  lastData: {},
  limit: 10,
  hasMore: false,
  badge: 0
};

// reducer
function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_NOTIFICATION_LIST:
      return applySetNotificationList(state, action);
    case SET_NOTIFICATION_LASTDATA:
      return applySetNotificationLastData(state, action);
    case SET_NOTIFICATION_HASMORE:
      return applySetNotificationHasMore(state, action);
    case SET_NOTIFICATION_BADGE:
      return applySetNotificationBadge(state, action);
    case CLEAR_NOTIFICATION_INFO:
      return applyClearNotificationInfo(state, action);
    default:
      return state;
  }
}

// reducer functions
function applySetNotificationList(state, action) {
  const { notificationList } = action;
  return {
    ...state,
    notificationList
  };
}

function applySetNotificationLastData(state, action) {
  const { lastData } = action;
  return {
    ...state,
    lastData
  };
}

function applySetNotificationHasMore(state, action) {
  const { hasMore } = action;
  return {
    ...state,
    hasMore
  };
}

function applySetNotificationBadge(state, action) {
  const { badge } = action;
  return {
    ...state,
    badge
  };
}

function applyClearNotificationInfo(state, action) {
  return initialState;
}

// exports
const actionCreators = {
  subscribeNewNotification,
  getNotificationList,
  getNotificationBadge,
  viewNotification,
  readNotification,
  clearNotification
};

export { actionCreators };

export default reducer;
