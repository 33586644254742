import React from "react";
import styles from "./styles.scss";
import validator from "validator";
import classNames from "classnames/bind";
import PropTypes from "prop-types";

const Message = (props, context) => (
  <div>
    {context.t(props.type, {
      value: context.t(props.value),
      length: props.length
    })}
  </div>
);

const required = (value, props, component) => {
  if (!value) {
    return props.addstyles ? (
      <div className={classNames(styles.formError, props.addstyles.formError)}>
        <Message type="required" value={props.name} />
      </div>
    ) : (
      <div className={styles.formError}>
        <Message type="required" value={props.name} />
      </div>
    );
  }
};

const email = (value, props, component) => {
  if (value && !validator.isEmail(value)) {
    return props.addstyles ? (
      <div className={classNames(styles.formError, props.addstyles.formError)}>
        <Message type="invalid" value={props.name} />
      </div>
    ) : (
      <div className={styles.formError}>
        <Message type="invalid" value={props.name} />
      </div>
    );
  }
};

const length = (value, props, component) => {
  if (
    props.minLength &&
    props.maxLength &&
    !validator.isLength(value, { min: props.minLength, max: props.maxLength })
  ) {
    return props.addstyles ? (
      <div className={classNames(styles.formError, props.addstyles.formError)}>
        <Message type="invalidLength" value={props.name} />
      </div>
    ) : (
      <div className={styles.formError}>
        <Message type="invalidLength" value={props.name} />
      </div>
    );
  } else if (
    !props.minLength &&
    props.maxLength &&
    !validator.isLength(value, { max: props.maxLength })
  ) {
    return props.addstyles ? (
      <div className={classNames(styles.formError, props.addstyles.formError)}>
        <Message
          type="invalidMaxLength"
          value={props.name}
          length={props.maxLength}
        />
      </div>
    ) : (
      <div className={styles.formError}>
        <Message
          type="invalidMaxLength"
          value={props.name}
          length={props.maxLength}
        />
      </div>
    );
  } else if (
    props.minLength &&
    !props.maxLength &&
    !validator.isLength(value, { min: props.minLength })
  ) {
    return props.addstyles ? (
      <div className={classNames(styles.formError, props.addstyles.formError)}>
        <Message
          type="invalidMinLength"
          value={props.name}
          length={props.minLength}
        />
      </div>
    ) : (
      <div className={styles.formError}>
        <Message
          type="invalidMinLength"
          value={props.name}
          length={props.minLength}
        />
      </div>
    );
  }
};

Message.contextTypes = {
  t: PropTypes.func.isRequired
};

export { required, email, length };
