import { connect } from "react-redux";
import Container from "./container";

const mapStateToProps = (state, ownProps) => {
  const { user = {} } = state;
  return {
    isSignedIn: user.isSignedIn
  };
};

export default connect(mapStateToProps)(Container);
