import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: { contestUsers = [], myRole = {}, },
    user: { auth = {}, isSignedIn }
  } = state;
  return {
    contestUsers,
    myRole,
    auth,
    isSignedIn
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getContestUsers: (contestId, query) => {
      return dispatch(contestActions.getContestUsers(contestId, query));
    },
    deleteContestUser: (contestId, userId) => {
      return dispatch(contestActions.deleteContestUser(contestId, userId));
    },
    removeChatUser: (contestId, userIdList) => {
      return dispatch(contestActions.removeChatUser(contestId, userIdList));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
