import React from "react";
import styles from "./styles.scss";
import classNames from "classnames/bind";
import PropTypes from "prop-types";

const cx = classNames.bind(styles);

const SelectWinner = (props, context) => {
  return (
    <div className={styles.selectWinner}>
      {props.matchInfo.id &&
        props.matchInfo.participantList.map((participant, index) =>
          participant.id ? (
            <span
              key={index}
              className={cx(styles.participant, {
                selected:
                  props.winType === "round"
                    ? props.winner === participant.id
                    : props.matchList[props.matchIndex].winner ===
                      participant.id
              })}
              onClick={() =>
                props.handleSelectWinner(
                  participant.id,
                  props.matchIndex,
                  props.winType
                )
              }
            >
              <div className={styles.participantInfoContainer}>
                <img
                  className={styles.participantImage}
                  src={
                    participant.imageUrl
                      ? participant.imageUrl
                      : require("images/noimage.png")
                  }
                  alt="Participant Logo"
                />
                <div>
                  <p className={styles.participantName}>{participant.name}</p>
                </div>
              </div>
              <div className={styles.participantBackgroundImageContainer}>
                <img
                  className={styles.participantBackgroundImage}
                  src={
                    participant.backgroundImageUrl
                      ? participant.backgroundImageUrl
                      : require("images/background_noimage.png")
                  }
                  alt="Participant Background"
                />
              </div>
            </span>
          ) : null
        )}
      {props.matchInfo.participantList.filter(
        (el) => Object.keys(el).length !== 0
      ).length === 1 ? (
        <div className={styles.noParticipant}>
          <div className={styles.noParticipantContainer}></div>
        </div>
      ) : null}
    </div>
  );
};

SelectWinner.propTypes = {
  handleSelectWinner: PropTypes.func.isRequired
};

SelectWinner.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SelectWinner;
