import React, { Component } from "react";
import Contest from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  render() {
    return <Contest {...this.props} />;
  }

  static propTypes = {
    auth: PropTypes.object.isRequired
  };
}

export default Container;
