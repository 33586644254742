import React from "react";
import styles from "./styles.scss";
import Loading from "components/lib/Loading";
import Logo from "components/Logo";
//import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import moment from "moment";
import { MdOndemandVideo } from "react-icons/md";
import { AiOutlinePicture } from "react-icons/ai";
import { RiSwordFill } from "react-icons/ri";
import Modal from "components/lib/Modal";
import TournamentMatchDetail from "components/TournamentMatchDetail";


const cx = classNames.bind(styles);

const LineContainer = ({ order, orderIndex }) => (
  <div
    className={cx(
      styles.lineContainer,
      { aboveItem: orderIndex % 2 === 0 },
      { winner: order.winner }
    )}
  >
    <div className={styles.horizontalLine} />
    <div className={styles.verticalLine} />
    <div className={styles.centerLine} />
  </div>
);

const MatchContainer = ({
  round,
  order,
  contestInfo,
  tournamentInfo,
  handleMatchInfo,
  context
}) => (
  <div className={styles.matchContainer}>
    <div className={styles.matchHeader}>
      <div className={styles.roundId}>
        {round.round + 1} - {order.order + 1}
      </div>
      <div className={styles.iconList}>
        {order.videoList ? <MdOndemandVideo className={styles.icon} /> : null}
        {order.reports &&
        order.reports.length &&
        order.reports.some((el) => el.images && el.images.length) ? (
          <AiOutlinePicture className={styles.icon} />
        ) : null}
      </div>
    </div>
    <div
      className={styles.tournamentMatch}
      onClick={handleMatchInfo.bind(this, order)}
    >
      {order.participantList.map((user, index) => (
        <div
          key={index}
          className={cx(
            user.index === 1 ? styles.below : styles.above,
            { myItem: user.myItem },
            {
              absence: order.unearned && order.winner !== user.id
            }
          )}
        >
          {user.id ? (
            <span className={styles.userInfo}>
              <img
                src={
                  user.imageUrl ? user.imageUrl : require("images/noimage.png")
                }
                alt="proflie"
                className={styles.image}
                onError={(e) => (e.target.src = require("images/noimage.png"))}
              />
              <div className={styles.name}>{user.name}</div>
            </span>
          ) : null}
          <div
            className={cx(styles.result, {
              winner: order.winner && order.winner === user.id
            })}
          >
            {order.reports && order.reports.some((el) => el.winner)
              ? order.reports.filter((el) => el.winner && el.winner === user.id)
                  .length
              : "-"}
          </div>
        </div>
      ))}
    </div>
    {!order.winner && order.participantList.some((el) => el.myItem) ? (
      <div className={styles.buttonLayout}>
        {/*
          <Link
            to={`/contests/${contestInfo.id}/tournament/${tournamentInfo.id}/match/${order.id}`}
          >*/
            <div className={styles.matchButton}>
              <RiSwordFill className={styles.icon} />
              <span>{context.t("match")}</span>
            </div>
          /*</Link>*/
        }
      </div>
    ) : null}
  </div>
);

const ContestTournament = (props, context) => (
  <div className={styles.contents}>
    {props.loading ? (
      <Loading />
    ) : props.tournamentMatchList ? (
      <div className={styles.tournamentContainer}>
        <div id="tournament" className={styles.tournament}>
          <div id="tournamentInfo" className={styles.tournamentInfo}>
            <div id="logo" className={styles.logo}>
              <Logo />
            </div>
            <div className={styles.tournamentLayout}>
              {props.tournamentMatchList.map((round, roundIndex) => (
                <div
                  className={cx(styles.round, {
                    finalRound:
                      roundIndex === props.tournamentMatchList.length - 1
                  })}
                  key={roundIndex}
                >
                  <div className={styles.roundTitle}>
                    <div>
                      {roundIndex === props.tournamentMatchList.length - 1
                        ? context.t("final")
                        : context.t("roundValue", { value: roundIndex + 1 })}
                    </div>
                    <div className={styles.startTime}>
                      {round.startTimeText + "~"}
                    </div>
                  </div>
                  <div
                    className={styles.roundView}
                    style={{
                      height:
                        props.tournamentMatchList[0].orderList.length * 100
                    }}
                  >
                    {round.orderList.map((order, orderIndex) => (
                      <React.Fragment key={orderIndex}>
                        {roundIndex !== props.tournamentMatchList.length - 1 ? (
                          <div className={styles.order}>
                            <LineContainer
                              order={order}
                              orderIndex={orderIndex}
                            />
                            <MatchContainer
                              round={round}
                              order={order}
                              contestInfo={props.contestInfo}
                              tournamentInfo={props.tournamentInfo}
                              handleMatchInfo={props.handleMatchInfo}
                              context={context}
                            />
                          </div>
                        ) : order.type !== "thirdPlaceMatch" ? (
                          <div className={cx(styles.order, styles.finalRound)}>
                            <MatchContainer
                              round={round}
                              order={order}
                              contestInfo={props.contestInfo}
                              tournamentInfo={props.tournamentInfo}
                              handleMatchInfo={props.handleMatchInfo}
                              context={context}
                            />
                            {round.orderList[orderIndex + 1].type ===
                            "thirdPlaceMatch" ? (
                              <div className={styles.thirdPlaceMatch}>
                                <div className={styles.roundTitle}>
                                  <div>{context.t("thirdPlaceMatch")}</div>
                                  <div>
                                    <div className={styles.startTime}>
                                      {moment(
                                        round.thirdPlaceMatchStartTime
                                      ).format("MM-DD HH:mm") + "~"}
                                    </div>
                                  </div>
                                </div>
                                <MatchContainer
                                  round={round}
                                  order={round.orderList[orderIndex + 1]}
                                  contestInfo={props.contestInfo}
                                  tournamentInfo={props.tournamentInfo}
                                  handleMatchInfo={props.handleMatchInfo}
                                  context={context}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : null}
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <Modal isOpen={props.isOpen} onClose={props.handleClose}>
          <TournamentMatchDetail {...props} />
        </Modal>
      </div>
    ) : (
      <div className={styles.empty}></div>
    )}
  </div>
);

ContestTournament.propTypes = {
  tournamentMatchList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

ContestTournament.contextTypes = {
  t: PropTypes.func.isRequired
};

export default ContestTournament;
