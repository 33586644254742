import { connect } from "react-redux";
import Container from "./container";
// import { actionCreators as userActions } from "redux/user";

// const mapStateToProps = (state, ownProps) => {
//   const {
//     user: { teamList }
//   } = state;
//   return {
//     teamList
//   };
// };

// const mapDispatchToProps = (dispatch, ownProps) => {
//   return {
//     getRelatedContestCount: (userId) => {
//       return dispatch(userActions.getRelatedContestCount(userId));
//     }
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Container);
export default connect(null, null)(Container);
