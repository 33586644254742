import React from "react";
import styles from "./styles.scss";
import Loading from "components/lib/Loading";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import Button from "components/lib/Button";

const cx = classNames.bind(styles);

const CreateTournamentMatch = (props, context) => (
  <div>
    {props.loading ? (
      <Loading />
    ) : (
      <div className={styles.contents}>
        <div className={styles.title}>{context.t("createtournament")}</div>
        <div className={styles.form}>
          <div className={styles.input}>
            <p>{context.t("slot")}</p>
            <select
              name="slotCount"
              value={props.slotCount || props.slotCountList[0]}
              onChange={props.handleSlotCountChange}
            >
              {props.slotCountList &&
                props.slotCountList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.input}>
            <p>{context.t("countOfMatch")}</p>
            <select
              name="matchCount"
              value={props.matchCount || props.matchCountList[0]}
              onChange={props.handleMatchCountChange}
            >
              {props.matchCountList &&
                props.matchCountList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.input}>
            <p>{context.t("timeOfMatch")}</p>
            <select
              name="matchTime"
              value={props.matchTime || props.matchTimeList[0]}
              onChange={props.handleMatchTimeChange}
            >
              {props.matchTimeList &&
                props.matchTimeList.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
            </select>
          </div>
          <div className={styles.tournament}>
            {props.tournament.map((val, i) => (
              <div
                className={cx(styles.round, {
                  finalRound: i === props.tournament.length - 1
                })}
                key={i}
              >
                <div className={styles.roundInfo}>
                  <div className={styles.roundTitle}>
                    {i === props.tournament.length - 1
                      ? context.t("final")
                      : context.t("roundValue", { value: i + 1 })}
                  </div>
                  <div>
                    <input
                      type="datetime-local"
                      className={styles.startTime}
                      placeholder={context.t("startTime")}
                      onChange={(e) => props.handleTimeChange(i, e)}
                      value={val.startTime}
                    />
                  </div>
                </div>
                {val.match.map((item, order) => (
                  <React.Fragment key={order}>
                    {item[0].type === "thirdPlaceMatch" ? (
                      <div
                        className={cx(styles.roundInfo, {
                          thirdPlaceMatch: item[0].type === "thirdPlaceMatch"
                        })}
                      >
                        <div className={styles.roundTitle}>
                          {context.t("thirdPlaceMatch")}
                        </div>
                        <div>
                          <input
                            type="datetime-local"
                            className={styles.startTime}
                            placeholder={context.t("startTime")}
                            onChange={(e) => props.handleTimeChange(i, e)}
                            id="thirdPlaceMatch"
                            value={val.thirdPlaceMatchStartTime}
                          />
                        </div>
                      </div>
                    ) : null}
                    <div
                      className={cx(styles.match, {
                        thirdPlaceMatch: item[0].type === "thirdPlaceMatch"
                      })}
                      key={order}
                    >
                      {item.map((info, index) =>
                        i === 0 ? (
                          <select
                            key={index}
                            className={styles.slot}
                            value={info[props.resource].id || ""}
                            onChange={(e) =>
                              props.handleSlotChange({ index, ...info }, e)
                            }
                          >
                            <option key="" value="" />
                            {props.activeList.map((value, key) => (
                              <option
                                key={key}
                                value={value.id}
                              >
                                {props.resource === "team"
                                  ? value.name
                                  : value.nickName}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <div key={index} className={styles.slot} />
                        )
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.buttonList}>
            <Button
              style={{ margin: 5 }}
              className={styles.cancelButton}
              text={context.t("back")}
              onClick={props.handleCancel}
              disabled={props.creating}
            />
            <Button
              style={{ margin: 5 }}
              className={styles.ShuffleButton}
              text={context.t("shuffle")}
              onClick={props.handleShuffle}
              disabled={props.creating}
            />
            <Button
              style={{ margin: 5 }}
              className={styles.addButton}
              text={context.t("add")}
              onClick={props.handleCreateTournament}
              disabled={props.creating}
            />
          </div>
          {props.creating ? (
            <div>
              <div className={styles.creating}>
                {context.t("creatingTournament")}
              </div>
              <Loading />
            </div>
          ) : null}
        </div>
      </div>
    )}
  </div>
);

CreateTournamentMatch.propTypes = {
  slotCountList: PropTypes.array.isRequired,
  slotCount: PropTypes.number.isRequired,
  tournament: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired
};

CreateTournamentMatch.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CreateTournamentMatch;
