import React from "react";
import { Route, Switch } from "react-router-dom";
import UserProfileDetail from "components/UserProfileDetail";
import UserProfileEdit from "components/UserProfileEdit";

const UserProfile = (props, context) => (
  <React.Fragment>
    {props.userInfo.id ? <Routes {...props} /> : null}
  </React.Fragment>
);

const Routes = (props) => (
  <Switch>
    <Route
      exact
      path="/users/:id"
      render={() => <UserProfileDetail {...props} />}
    />
    <Route
      exact
      path="/users/:id/edit"
      render={() => <UserProfileEdit {...props} />}
    />
  </Switch>
);

export default UserProfile;
