import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import Loading from "components/lib/Loading";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";

// 通知文言取得
const getNotificationText = (context, notificationType, status, payload) => {
  let text;
  switch (notificationType) {
    case "tournament":
      if (status === "created") {
        // トーナメント表作成の通知
        text = context.t("notificationCreatedTournament", {
          contestName: payload.contestName
        });
      } else if (status === "emptyResult") {
        // トーナメント結果未入力の通知
        const { round, order } = payload;
        text = context.t("notificationEmptyMatchResult", {
          matchName: `${payload.contestName} # ${round + 1} - ${order + 1}`
        });
      }
      break;
    case "contest":
      if (status === "start") {
        // 大会開始の通知
        text = context.t("notificationStartContest", {
          contestName: payload.contestName
        });
      } else if (status === "recommend") {
        // 大会レコメンドの通知
        text = context.t("notificationRecommendContest", {
          gameName: payload.gameName,
          contestName: payload.contestName
        });
      }
      break;
    case "admin":
      if (status === "custom") {
        text = payload.text;
      }
      break;
    default:
      break;
  }
  return text;
};

// 通知時間取得
const getNotificationTime = (updated) => {
  if (!updated) return;
  return moment(updated.toDate()).format("MM/DD HH:mm");
};

const Notification = (props, context) => (
  <div className={styles.contents} id="scrollable">
    {props.loading ? (
      <Loading />
    ) : (
      <div className={styles.list}>
        <InfiniteScroll
          dataLength={props.notificationList.length}
          next={props.loadMore}
          hasMore={props.hasMore}
          loader={<Loading />}
          scrollableTarget="scrollable"
        >
          {props.notificationList.length ? (
            props.notificationList.map((notification, index) => (
              <div
                key={index}
                onClick={() => {
                  props.readNotification(notification);
                }}
                className={styles.item}
              >
                <div className={styles.itemContents}>
                  <span className={styles.imageContainer}>
                    <img
                      src={notification.imageUrl || require("images/noimage.png")}
                      alt="thumbnail"
                      className={styles.image}
                      onError={(e) =>
                        (e.target.src = require("images/noimage.png"))
                      }
                    />
                  </span>
                  <span className={styles.rightAlign}>
                    <span className={styles.text}>
                      {getNotificationText(
                        context,
                        notification.notificationType,
                        notification.status,
                        notification.payload
                      )}
                    </span>
                    <span className={styles.time}>
                      {getNotificationTime(notification.created)}
                    </span>
                    {!notification.readed ? (
                      <span className={styles.unreadedFlag} />
                    ) : null}
                  </span>
                </div>
              </div>
            ))
          ) : (
            <div className={styles.empty}>{context.t("emptyData")}</div>
          )}
        </InfiniteScroll>
      </div>
    )}
  </div>
);

Notification.contextTypes = {
  t: PropTypes.func.isRequired
};

Notification.propTypes = {
  notificationList: PropTypes.array.isRequired
};

export default Notification;
