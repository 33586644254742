import React, { Component } from "react";
import ContestEdit from "./presenter";
import PropTypes from "prop-types";
import moment from "moment";
import { generateRandomCode, uploadFile } from "lib/convert";

class Container extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  state = {
    isLoading: false,
    image: "",
    imageUrl: "",
    uploadedFile: null,
    isChangedImage: false,
    name: "",
    entryStart: "",
    entryEnd: "",
    contestStart: "",
    contestEnd: "",
    isCheckin: false,
    isEntryPassword: false,
    entryPassword: "",
    checkinStart: "",
    checkinEnd: "",
    teamSize: 0,
    entryMaxCount: 0,
    discordUrl: "",
    iframeSrc: "",
    contestType: "",
    participantType: "",
    award: "",
    rule: "",
    battleRoyalInfo: {
      rankPoint: [
        { rank: 1, point: 0 },
        { rank: 2, point: 0 }
      ],
      killPoint: { point: 0, max: null },
      matchCount: 1
    },
    gameId: "",
    gameName: "",
    matchCountList: [
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
      20
    ],
    placeholder:
      '<iframe width="560" height="315" src="https://www.youtube.com/embed/******" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'
  };

  async componentDidMount() {
    const { getBattleRoyalInfo, contestInfo } = this.props;

    // 直パスでアクセスした時にエラーが起きるため追記
    const battleRoyalInfo = await getBattleRoyalInfo(contestInfo.id);

    if (contestInfo.contestType === "BATTLEROYAL") {
      // Firestoreで登録してない分のチーム数追加
      const teamCount =
        contestInfo.entryMaxCount - battleRoyalInfo.rankPoint.length;
      for (let i = 0; i < teamCount; i++) {
        battleRoyalInfo.rankPoint.push({
          rank: battleRoyalInfo.rankPoint.length + 1,
          point: "0"
        });
      }
    }
    this.setState({
      image: contestInfo.image,
      imageUrl: contestInfo.imageUrl,
      gameId: contestInfo.gameId,
      name: contestInfo.name,
      entryStart: moment(contestInfo.entryStart).format("YYYY-MM-DDTHH:mm"),
      entryEnd: moment(contestInfo.entryEnd).format("YYYY-MM-DDTHH:mm"),
      contestStart: moment(contestInfo.contestStart).format("YYYY-MM-DDTHH:mm"),
      contestEnd: moment(contestInfo.contestEnd).format("YYYY-MM-DDTHH:mm"),
      isCheckin: contestInfo.checkin,
      checkinStart: contestInfo.checkinStart
        ? moment(contestInfo.checkinStart).format("YYYY-MM-DDTHH:mm")
        : "",
      checkinEnd: contestInfo.checkinEnd
        ? moment(contestInfo.checkinEnd).format("YYYY-MM-DDTHH:mm")
        : "",
      isEntryPassword: contestInfo.isEntryPassword,
      entryPassword: contestInfo.entryPassword,
      teamSize: contestInfo.teamSize,
      entryMaxCount: contestInfo.entryMaxCount,
      contestType: contestInfo.contestType,
      participantType: contestInfo.participantType,
      discordUrl: contestInfo.discordUrl || "",
      iframeSrc: contestInfo.iframeSrc || "",
      award: contestInfo.award || "",
      rule: contestInfo.rule || "",
      killPoint: battleRoyalInfo.killPoint,
      rankPoint: battleRoyalInfo.rankPoint,
      matchCount: battleRoyalInfo.matchCount,
      created: battleRoyalInfo.created
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevState.isEntryPassword && this.state.isEntryPassword) {
      // パスワードが有効になった場合
      if (!this.state.entryPassword) {
        // パスワードが設定されてない場合新規発行
        const entryPassword = generateRandomCode(6);
        this.setState({
          entryPassword
        });
      }
    }
  }

  render() {
    return (
      <ContestEdit
        {...this.props}
        {...this.state}
        myRef={this.myRef}
        handleInputChange={this._handleInputChange}
        handleInputentryMaxCountChange={this._handleInputentryMaxCountChange}
        handleInputRankPointChange={this._handleInputRankPointChange}
        handleInputKillPointChange={this._handleInputKillPointChange}
        handleFileChange={this._handleFileChange}
        handleImageReset={this._handleImageReset}
        handleSubmit={this._handleSubmit}
        getRankPointTableHeight={this._getRankPointTableHeight}
        handleClickInputFile={this._handleClickInputFile}
        handleChangeInputFile={this._handleChangeInputFile}
        handleDragDrop={this._handleDragDrop}
        handleContestDelete={this._handleContestDelete}
        handleCheck={this._handleCheck}
      />
    );
  }

  _handleImageReset = () => {
    this.setState({
      imageUrl: "",
      uploadedFile: null,
      isChangedImage: true
    });
  };

  _handleInputChange = (event) => {
    const {
      target,
      target: { value }
    } = event;
    const name = target.name === "contestName" ? "name" : target.name;

    this.setState({
      [name]: value
    });
  };

  _handleInputentryMaxCountChange = (event) => {
    const {
      target: { name, value }
    } = event;

    // チームの上限数が変わる度に配列の初期化(延々配列にPushされるのを避けるため)
    const rankPoint = [];
    // チームの上限数分の要素を追加
    for (let i = 0; i < value; i++) {
      rankPoint.push({ rank: i + 1, point: "0" });
      // 400-500チームをいっぺんにチーム数を増やすとフリーズするため入力制限
      // AsobiClub側でポイントのテンプレルールを決めるようなので一時的に最低限動作する実装にとどめる
      // 現実的に256チームを耐えられるようにしておけば当面運用可能と思っていて256チームで処理を止める
      if (i > 256) {
        alert(this.context.t("entryMaxCountIsTooLarge"));
        return;
      }
    }
    this.setState({
      [name]: value,
      rankPoint
    });
  };

  _handleInputRankPointChange = (index, event) => {
    const {
      target: { value }
    } = event;
    const { rankPoint } = this.state;

    rankPoint[index].point = value;
    this.setState({
      rankPoint
    });
  };

  _handleInputKillPointChange = (event) => {
    const {
      target: { name, value }
    } = event;
    const { killPoint } = this.state;

    if (name === "killPoint") {
      killPoint.point = value;
    } else if (name === "killPointMax") {
      killPoint.max = value;
    }

    this.setState({
      killPoint
    });
  };

  // デフォルトチェックイン期間設定
  _setCheckinDuration = () => {
    // チェックイン期間が設定されてない場合
    if (!this.state.checkinStart && !this.state.checkinEnd) {
      const { contestStart } = this.state;
      // 期間のデフォルト設定
      const checkinEnd = moment(new Date(contestStart)).format(
        "YYYY-MM-DDTHH:mm"
      );
      const checkinStart = moment(checkinEnd)
        .subtract(1, "hours")
        .format("YYYY-MM-DDTHH:mm");
      this.setState({
        checkinStart,
        checkinEnd
      });
    }
  };

  // エントリー確定 Item 選択
  _handleCheck = (event) => {
    const {
      target: { name, checked }
    } = event;
    this.setState({
      [name]: checked
    });
    if (name === "isCheckin" && checked) {
      // チェックインが有効になった場合
      this._setCheckinDuration();
    }
  };

  _handleSubmit = (event) => {
    event.preventDefault();
    this._contestEditor();
  };

  _handleFileChange = (event) => {
    const {
      target: { files }
    } = event;
    // キャンセルボタンのとき、Stateを更新しない
    if (files.length === 0) {
      return;
    }

    const createObjectURL =
      (window.URL || window.webkitURL).createObjectURL ||
      window.createObjectURL;
    const imageUrl = files.length ? createObjectURL(files[0]) : null;

    this.setState({
      imageUrl,
      uploadedFile: files.length ? files[0] : null,
      isChangedImage: true
    });
  };

  // ルールのTextAreaにドラッグ&ドロップ
  _handleDragDrop = async (files) => {
    // TextAreaに focus
    this.myRef.current.focus();

    // キャンセルボタンのとき、Stateを更新しない
    if (!files || files.length === 0) {
      return;
    }

    const [file] = files;
    const supportType = ["image/png", "image/jpeg", "image/gif"];
    // ファイル形式チェック
    if (!supportType.includes(file.type)) {
      alert(this.context.t("unsupportedFileType"));
      return;
    }

    this._handleAttachImage(files);
  };

  // 添付ボタンクリック
  _handleClickInputFile = (event) => {
    event.target.value = "";
    // TextAreaに focus
    this.myRef.current.focus();
  };

  // 添付ボタンで画像選択
  _handleChangeInputFile = (event) => {
    // 画像選択
    const {
      target: { files }
    } = event;

    // キャンセルボタンのとき、Stateを更新しない
    if (!files || files.length === 0) {
      return;
    }

    this._handleAttachImage(files);
  };

  // 画像添付処理
  _handleAttachImage = async (files) => {
    const { rule = "" } = this.state;

    // キャンセルボタンのとき、Stateを更新しない
    if (!files || files.length === 0) {
      return;
    }
    this.setState({
      isLoading: true
    });

    // ファイルアップロード
    const imageUrl = await uploadFile(files[0], "images");

    if (imageUrl) {
      // 現在時刻
      const currentTime = moment().format("YYMMDDHHmmss");
      // イメージ名
      const image = `\n\n![image_${currentTime}](${imageUrl})\n\n`;
      // TextAreaの cursor位置取得
      const cursorIndex = this.myRef.current.selectionEnd;
      // cursor位置にイメージ挿入
      const changedRule =
        rule.slice(0, cursorIndex) + image + rule.slice(cursorIndex);
      this.setState({
        rule: changedRule,
        isLoading: false
      });
      // cursorの位置設定
      this.myRef.current.selectionEnd = cursorIndex + image.length;
    }
  };

  _contestEditor = async (target) => {
    const {
      uploadedFile,
      isChangedImage,
      image : contestImage,
      contestType,
      name,
      entryStart,
      entryEnd,
      contestStart,
      contestEnd,
      isCheckin,
      checkinStart,
      checkinEnd,
      isEntryPassword,
      entryPassword,
      participantType,
      teamSize,
      entryMaxCount,
      discordUrl,
      iframeSrc,
      award,
      rule,
      matchCount,
      rankPoint,
      killPoint,
    } = this.state;
    const {
      contestInfo,
      battleRoyalInfo,
      updateBattleRoyal,
      contestEdit,
      history
    } = this.props;

    // 画像アップロード
    const image = isChangedImage ? await uploadFile(uploadedFile, "contests") : contestImage;

    const { t } = this.context;
    this.setState({
      isLoading: true
    });

    if (name.length >= 256) {
      alert(t("toLongContestName"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if ((participantType !== "SOLO" && teamSize < 2) || entryMaxCount < 2) {
      alert(t("SetAValueOfAtLeast2"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (participantType !== "SOLO" && teamSize >= 100) {
      alert(t("TeamSizeIsTooLarge"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (entryMaxCount > 8192) {
      alert(t("entryMaxCountIsTooLarge"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (
      entryStart >= entryEnd ||
      contestStart >= contestEnd ||
      entryEnd > contestStart
    ) {
      alert(t("reversedDate"));
      this.setState({
        isLoading: false
      });
      return;
    }

    // エントリー用パスワードバリデーション
    if (isEntryPassword) {
      const pattern = new RegExp("^[a-zA-Z0-9]{4,16}$");
      if (!entryPassword) {
        alert(t("inputEntryPassword"));
        return this.setState({
          isLoading: false
        });
      } else if (!pattern.test(entryPassword)) {
        alert(t("invalidEntryPasswordFormat"));
        return this.setState({
          isLoading: false
        });
      }
    }

    // 必須ではないが文字が入力されている場合はURLを正規表現でチェック
    if (
      typeof discordUrl === "string" &&
      discordUrl.length &&
      !discordUrl.match(
        /(http(s)?:\/\/[a-zA-Z0-9-.!'()*;/?:@&=+$,%#]+\.[a-z]+)/
      )
    ) {
      alert(t("inputDiscordUrl"));
      this.setState({
        isLoading: false
      });
      return;
    }

    let iframeTagSrc;

    if (iframeSrc.match(/^<iframe/)) {
      iframeTagSrc = iframeSrc.match(/src="(.*?)"/)[1];
    } else {
      iframeTagSrc = iframeSrc;
    }
    // 必須ではないが文字が入力されている場合はURLを正規表現でチェック
    if (
      iframeTagSrc.length &&
      !iframeTagSrc.match(
        /(http(s)?:\/\/[a-zA-Z0-9-.!'()*;/?:@&=+$,%#]+\.[a-z]+)/
      )
    ) {
      alert(t("inputIframeTag"));
      this.setState({
        isLoading: false
      });
      return;
    }

    if (contestType === "BATTLEROYAL") {
      // 1回あたりのkillのpointがmaxを上回っている場合
      if (killPoint.max && Number(killPoint.point) > Number(killPoint.max)) {
        alert(t("overMaxKillPoint"));
        this.setState({
          isLoading: false
        });
        return;
      }

      // 試合数が0以下で登録された場合
      if (matchCount < 1) {
        alert(t("setMatchCount"));
        this.setState({
          isLoading: false
        });
        return;
      }
    }

    const params = {
      contestType,
      participantType,
      name,
      image,
      entryStart:  entryStart ? new Date(entryStart) : null,
      entryEnd: entryEnd ? new Date(entryEnd) : null,
      contestStart:contestStart ? new Date(contestStart) : null,
      contestEnd: contestEnd ? new Date(contestEnd) : null,
      checkinStart: checkinStart ? new Date(checkinStart) : null,
      checkinEnd: checkinEnd ? new Date(checkinEnd) : null,
      checkin: isCheckin,
      entryMaxCount,
      teamSize,
      iframeSrc,
      award,
      rule,
      discordUrl,
      isEntryPassword,
      entryPassword,
    };
    const result = await contestEdit(contestInfo.id, params);

    if (result.status !== 200) {
      switch (result.data) {
        case -1:
          alert(t("thisGameDoesNotExists"));
          return;
        case -2:
          alert(t("toLongContestName"));
          return;
        case -3:
          alert(t("reversedDate"));
          return;
        case -5:
          alert(t("SetAValueOfAtLeast2"));
          return;
        case -6:
          alert(t("TeamSizeIsTooLarge"));
          return;
        case -7:
          alert(t("entryMaxCountIsTooLarge"));
          return;
        case -8:
          alert(t("inputDiscordUrl"));
          return;
        default:
          alert(t("errorMsg"));
          break;
      }
      this.setState({
        isLoading: false
      });
    } else {
      const { image } = result.data;
      const contest = {
        id: contestInfo.id,
        name,
        image,
        participantType,
        contestStart,
        contestEnd
      };
      if (contestType === "BATTLEROYAL") {
        const rankPointList = rankPoint.map((item) => {
          return { rank: item.rank, point: Number(item.point) };
        });

        killPoint.point = Number(killPoint.point);
        killPoint.max = Number(killPoint.max);
        if (!killPoint.max) {
          delete killPoint.max;
        }

        const param = {
          contest: contest,
          matchCount: Number(matchCount),
          rankPoint: rankPointList,
          killPoint: killPoint
        };

        // reduxのstateが残るので書き換えておく
        battleRoyalInfo.rankPoint = rankPointList;

        updateBattleRoyal(battleRoyalInfo.id, param);
      }

      this.setState({
        isLoading: false
      });
      alert(t("contestHasBeenChanged"));
      history.push(`/contests/${contestInfo.id}/`);
    }
  };
  // テーブルの height 取得
  // TODO: style定義だけてできるように修正
  _getRankPointTableHeight = () => {
    const { rankPoint = [] } = this.state;
    const height = Math.round(rankPoint.length / 2) * 37;
    return height;
  };

  // 大会削除
  _handleContestDelete = async () => {
    const { deleteContest, contestInfo = {}, history } = this.props;
    const { t } = this.context;

    if (
      !window.confirm(t("deleteContestConfirm", { name: contestInfo.name }))
    ) {
      return;
    }
    // 削除処理
    const result = await deleteContest(contestInfo.id);

    if (result) {
      // エラーハンドル
      return alert(t("failedDeleteContest"));
    }
    alert(t("completeDeleteContest"));
    // 大会一覧に遷移
    history.push("/");
  };

  static propTypes = {
    contestEdit: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
