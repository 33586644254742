import React, { Component } from "react";
import CreateTournamentMatch from "./presenter";
import PropTypes from "prop-types";
import moment from "moment";

class Container extends Component {
  state = {
    resource: "team",
    slotCountList: [2, 4, 8, 16, 32, 64, 128, 256],
    matchCountList: [1, 3, 5],
    matchTimeList: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180],
    slotCount: 2,
    matchCount: 1,
    matchTime: 60,
    tournament: [],
    creating: false,
    loading: true
  };

  async componentDidMount() {
    const {
      contestInfo: { id, participantType },
      activeList = [],
      getContestTeams,
      getContestUsers
    } = this.props;

    const { slotCountList } = this.state;

    const resource = participantType === "MULTI" ? "team" : "user";

    if (!activeList.length) {
      if (resource === "team") {
        // 大会参加チーム一覧取得
        await getContestTeams(id, { status: "ALLOW" });
      } else if (resource === "user") {
        // 大会参加者一覧取得
        await getContestUsers(id, "ALLOW");
      }
    }

    await this.setState({
      resource
    });

    let slotCount;
    for (const val of slotCountList) {
      if (activeList.length <= val) {
        slotCount = val;
        break;
      }
    }
    this._setSlot(slotCount);

    this.setState({
      slotCount,
      loading: false
    });
  }

  render() {
    return (
      <CreateTournamentMatch
        {...this.props}
        {...this.state}
        handleSlotCountChange={this._handleSlotCountChange}
        handleSlotChange={this._handleSlotChange}
        handleMatchCountChange={this._handleMatchCountChange}
        handleMatchTimeChange={this._handleMatchTimeChange}
        handleTimeChange={this._handleTimeChange}
        handleTeamChange={this._handleTeamChange}
        handleCreateTournament={this._handleCreateTournament}
        handleShuffle={this._handleShuffle}
        handleCancel={this._handleCancel}
        handleNextStep={this._handleNextStep}
      />
    );
  }

  // slot設定
  _setSlot = (slotCount) => {
    const {
      contestInfo: { contestStart }
    } = this.props;
    const { matchCount, matchTime, resource } = this.state;
    const tournament = [];

    // round を決める
    for (let i = 0; i < 8; i++) {
      // 開始時刻の目安設定
      const startTime = moment(contestStart)
        .add(matchTime * i, "minute")
        .format("YYYY-MM-DDTHH:mm");

      // 2 の i乗が slotCountと一致する場合、round数は i
      if (Math.pow(2, i) === slotCount) break;

      tournament.push({
        round: i,
        startTime,
        matchCount,
        match: []
      });
      // round別 slot数
      const roundSlotCount = slotCount / Math.pow(2, i);

      const slotList = [];
      for (let index = 0; index < roundSlotCount; index++) {
        const slotInfo = {
          [resource]: {},
          order: Math.round((index + 1) / 2) - 1
        };
        // semi finalの場合
        if (Math.pow(2, i + 2) === slotCount) {
          slotInfo.type = "semiFinal";
        }
        // finalの場合
        if (Math.pow(2, i + 1) === slotCount) {
          slotInfo.type = "final";
        }

        slotList.push(slotInfo);
      }

      // 3位決定戦
      if (Math.pow(2, i + 1) === slotCount) {
        tournament[i].thirdPlaceMatchStartTime = startTime;
        for (let x = 1; x <= 2; x++) {
          slotList.push({
            [resource]: {},
            order: 1,
            type: "thirdPlaceMatch"
          });
        }
      }

      const grouped = this._groupBy(slotList, "order");
      for (const key in grouped) {
        tournament[i].match.push(grouped[key]);
      }
    }
    const shuffled = this._shuffleSlot(tournament);
    this.setState({
      slotCount,
      tournament: shuffled
    });
  };

  // ランダム配置
  _shuffleSlot = (tournament) => {
    const { resource } = this.state;
    const { activeList } = this.props;

    const shuffle = activeList.sort(() => Math.random() - 0.5);
    const roundOneMatch = tournament[0].match;
    // solt 初期化
    for (const order of roundOneMatch) {
      for (const val of order) {
        val[resource] = {};
      }
    }
    for (const index in shuffle) {
      // ユーザー情報加工
      const info = shuffle[index];
      if (resource === "team") {
        info.contact = shuffle[index].twitter;
      } else if (resource === "user") {
        info.twitter = shuffle[index].twitterId;
      }
      info.friendCode = shuffle[index].friendCode;
      info.gameUsername = shuffle[index].gameUsername;
      if (index < roundOneMatch.length) {
        // slot Aを埋める
        roundOneMatch[index][0][resource] = info;
      } else {
        // slot Bを埋める
        const i = Number(index) - roundOneMatch.length;
        roundOneMatch[i][1][resource] = info;
      }
    }
    return tournament;
  };

  // slot数変更
  _handleSlotCountChange = (event) => {
    const {
      target: { value }
    } = event;
    // slot 設定
    this._setSlot(Number(value));
  };

  // 試合数変更
  _handleMatchCountChange = (event) => {
    const {
      target: { value }
    } = event;
    const { tournament } = this.state;
    const matchCount = Number(value);

    for (const val of tournament) {
      val.matchCount = matchCount;
    }

    this.setState({
      matchCount,
      tournament
    });
  };

  _handleMatchTimeChange = (event) => {
    const {
      target: { value }
    } = event;
    const { tournament } = this.state;
    const matchTime = Number(value);

    for (let i = 0; i < tournament.length; i++) {
      const startTime = moment(tournament[0].startTime)
        .add(matchTime * i, "minute")
        .format("YYYY-MM-DDTHH:mm");
      tournament[i].startTime = startTime;
      if (i === tournament.length - 1) {
        tournament[i].thirdPlaceMatchStartTime = startTime;
      }
    }

    this.setState({
      matchTime,
      tournament
    });
  };

  _handleTimeChange = (index, event) => {
    const { tournament } = this.state;
    const {
      target: { value, id }
    } = event;

    const startTime = moment(value).format("YYYY-MM-DDTHH:mm");

    if (id === "thirdPlaceMatch") {
      tournament[index].thirdPlaceMatchStartTime = startTime;
    } else {
      tournament[index].startTime = startTime;
    }

    this.setState({
      tournament
    });
  };

  // スロット配置変更
  _handleSlotChange = (before, event) => {
    const { activeList } = this.props;
    const { tournament, resource } = this.state;
    const {
      target: { value }
    } = event;
    // 選択されたチーム情報取得
    const selected = activeList.find((el) => el.id === value);
    // 空選択は無効にする
    if (!selected) return;

    let selectedOrder, selectedIndex;
    const matches = tournament[0].match;
    for (const order in matches) {
      for (const index in matches[order]) {
        // 選択Itemの order, indexを取得
        if (matches[order][index][resource].id === value) {
          selectedOrder = order;
          selectedIndex = index;
        }
      }
    }
    // 入れ替え
    matches[selectedOrder][selectedIndex][resource] = before;
    matches[before.order][before.index][resource] = selected;

    this.setState({
      tournament
    });
  };

  // グルーピング
  _groupBy = (array, property) => {
    return array.reduce((group, item) => {
      return Object.assign(group, {
        [item[property]]: (group[item[property]] || []).concat(item)
      });
    }, {});
  };

  // トーナメント作成
  _handleCreateTournament = async (event) => {
    const {
      contestInfo: {
        id,
        name,
        gameId,
        image,
        participantType,
        contestStart,
        contestEnd
      },
      createTeamTournament,
      createUserTournament,
      activeList
      //history
    } = this.props;
    const { tournament, resource, matchTime } = this.state;
    this.setState({
      creating: true
    });
    if (!activeList.length) {
      alert(this.context.t("notEnoughActiveTeam"));
      return;
    }
    const contest = {
      id,
      name,
      image,
      participantType,
      contestStart,
      contestEnd
    };

    for (const val of tournament) {
      val.matchTime = matchTime;
    }
    // トーナメント登録
    if (resource === "team") {
      await createTeamTournament(contest, gameId, tournament);
    } else if (resource === "user") {
      await createUserTournament(contest, gameId, tournament);
    }
    this.setState({
      creating: false
    });
    alert(this.context.t("completeCreateTournament"));
    // 大会詳細ページに遷移
    //history.push(`/contests/${id}`);
    window.location.href = `/contests/${id}/tournament`;
  };

  // シャッフル
  _handleShuffle = (event) => {
    const { tournament } = this.state;
    const shuffled = this._shuffleSlot(tournament);
    this.setState({
      tournament: shuffled
    });
  };

  // 戻る
  _handleCancel = (event) => {
    const { history, prevStep } = this.props;
    if (prevStep) {
      prevStep();
    } else {
      history.goBack();
    }
  };

  static propTypes = {
    contestInfo: PropTypes.object.isRequired,
    getContestTeams: PropTypes.func.isRequired,
    createTeamTournament: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
