import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";
import { actionCreators as utilActions } from "redux/util";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {} },
    contest: { contestInfo = {}, tournamentInfo = {}, tournamentMatchInfo = {} }
  } = state;

  return {
    auth,
    contestInfo,
    tournamentInfo,
    tournamentMatchInfo
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTournamentInfo: (contestId) => {
      return dispatch(contestActions.getTournamentInfo(contestId));
    },
    getTournamentMatchInfo: (tournamentId, matchId) => {
      return dispatch(
        contestActions.getTournamentMatchInfo(tournamentId, matchId)
      );
    },
    uploadFile: (formData) => {
      return dispatch(utilActions.uploadFile(formData));
    },
    updateTournament: (tournamentId, matchId, param) => {
      return dispatch(
        contestActions.updateTournament(tournamentId, matchId, param)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
