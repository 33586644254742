import React, { Component } from "react";
import SocialCallback from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    loading: false
  };

  componentDidMount = () => {
    this.setState({
      loading: true
    });

    const {
      location: { search = "" }
    } = this.props;

    const params = new URLSearchParams(search);

    const token = params.get("oauth_token");
    const verifier = params.get("oauth_verifier");

    this._socialLogin(token, verifier);
  };

  _socialLogin = async (token, verifier) => {
    const { socialLogin, history } = this.props;

    const result = await socialLogin(token, verifier);

    // ログインもしくは登録成功時以外の場合
    if (result.status !== 200 && result.status !== 201) {
      let errorMessage = "exceptionError";
      switch (result.data) {
        case -1:
          errorMessage = "dbWriteError";
          break;
        case -2:
          errorMessage = "twitterSomethingError";
          break;
        case -3:
          errorMessage = "twitterAccountAlreadyIntegrated";
          break;
        case -4:
          errorMessage = "userDoesNotExist";
          break;
        case -5:
          errorMessage = "duplicateEmail";
          break;
        default:
          break;
      }
      await this._alertMsg(errorMessage);
    }
    history.goBack();
  };

  _alertMsg = (msg) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const { t } = this.context;
        alert(t(msg));
        resolve();
      }, 1000);
    });
  };

  render() {
    return <SocialCallback {...this.state} {...this.props} />;
  }
  static contextTypes = {
    t: PropTypes.func.isRequired
  };

  static propTypes = {
    socialLogin: PropTypes.func.isRequired
  };
}

export default Container;
