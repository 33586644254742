import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./styles.scss";

class Container extends Component {
  render() {
    return <PrivacyPolicy />;
  }
}

const PrivacyPolicy = (prosp, context) => (
  <div className={styles.contents}>
    <div className={styles.introduction}>
      {
        "AsobiClub運営（以下「当団体」といいます）は、個人情報及びプライバシーに関する情報（以下「個人情報等」といいます）の保護に努めることを社会的責務として、個人情報等の取扱いに関する方針を定め、適正な管理と利用、保護に努めております。当団体が「AsobiClub」の名称で提供するサービス（以下「本サービス」といいます）における、利用者の個人情報等については、当団体の個人情報保護方針のほか、以下の定めに従って取り扱います。"
      }
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"個人情報等の取得"}</div>
      <p>
        {
          "当団体は以下の個人情報等を取得します。これらは利用者本人からご提供いただく他、GoogleやTwitter等の外部サービス連携や Cookie, Web beacon 等の技術を利用し取得します。"
        }
      </p>
      <div className={styles.section}>
        <p className={styles.title}>{"個人情報"}</p>
        <p className={styles.content}>
          {
            "利用者の生年月日、性別、及びメールアドレスなど、生存する個人に関する情報であって、当該情報に含まれる生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）又は個人識別符号が含まれるもの"
          }
        </p>
      </div>
      <div className={styles.section}>
        <p className={styles.title}>{"端末情報"}</p>
        <p className={styles.content}>
          {"利用者が使用する端末固有の情報（端末固有のID等の個体識別情報等）"}
        </p>
      </div>
      <div className={styles.section}>
        <p className={styles.title}>{"ログ"}</p>
        <p className={styles.content}>
          {"IPアドレス、利用日時、利用履歴、ブラウザ情報等"}
        </p>
      </div>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"利用目的"}</div>
      <p>
        {
          "本サービスに関して当社が取得する個人情報等の利用目的は、前述したもののほか、以下の通りです。なお、当社は、個人情報等のうち、個人情報に該当しない情報に関して、これらの目的に必要な範囲で、第三者に提供する場合があります。個人情報の第三者への提供については、当社の個人情報保護方針に従います。"
        }
      </p>
      <ol className={styles.content}>
        <li>{"利用者が本サービスを円滑に利用できるようにするため"}</li>
        <li>{"本サービス利用に関する統計データを作成するため"}</li>
        <li>
          {
            "本サービスの企画立案検討、サービス改善にあたってのアンケート実施及び分析のため"
          }
        </li>
        <li>{"利用者からのお問い合わせに対する対応のため"}</li>
        <li>{"今後の本サービスに関する新企画立案を行い提供するため"}</li>
        <li>{"キャンペーン等の抽選及び賞品発送のため"}</li>
        <li>{"利用者からのお問い合わせ時など、本人確認を行うため"}</li>
        <li>
          {
            "その他本サービスに関する重要なお知らせなど、必要に応じた連絡を行うため"
          }
        </li>
        <li>{"利用者の年齢確認のため"}</li>
        <li>{"マーケティングデータの調査、統計、分析のため"}</li>
        <li>{"イベント等の運営管理のため"}</li>
      </ol>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"利用目的の変更"}</div>
      <p>
        {
          "当社は、個人情報等の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合には利用者に通知または公表します。また、当初の目的以外の目的で使用する場合や、第三者に提供する場合は、事前に同意を取得の上行います。"
        }
      </p>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"リンク"}</div>
      <p>
        {
          "本サービスにおいて、外部のサイトへのリンクが貼られることがあります。当社はリンク先のウェブサイトにおける個人情報等の取扱いについては責任を負いかねますので、各リンク先のサイトのご利用に際してはそれぞれのプライバシーポリシーを一読される事を推奨します。海外からのアクセスの場合、日本国外から本サービスを利用する場合、個人情報等は、当社が使用するサーバーに送信、蓄積されます。利用者は、これらの個人情報等に関して、日本の法律が適用されることに同意したものとみなされます。"
        }
      </p>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"内容の改定"}</div>
      <p>
        {
          "当社は、取得する個人情報等の変更、利用目的の変更その他の変更を行う際は、本サイトへの変更をもって公表とさせていただきます。変更後の内容は本サイトに掲載した日より効力を生じます。"
        }
      </p>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"個人情報の開示"}</div>
      <p>
        {
          "個人情報の開示につきましては、手数料（1件あたり1000円）を頂戴しておりますので、あらかじめ御了承ください。"
        }
      </p>
    </div>
    <div className={styles.chapter}>
      <div className={styles.title}>{"お問い合わせ窓口"}</div>
      <p>
        {
          "開示等のお申し出、ご意見、ご質問等のお問い合わせは、下記フォームよりお願いいたします。"
        }
      </p>
      <a
        href="https://forms.gle/nMAS9hHoaH84KdyH7"
        rel="noopener noreferrer"
        target="_blank"
      >
        {"お問い合わせフォーム"}
      </a>
    </div>

    <p>{"改定日: 2022年12月24日"}</p>
  </div>
);

export default connect(null, null)(Container);
