import { connect } from "react-redux";
import Container from "./container";
import { setLanguage } from "redux-i18n";

const mapStateToProps = (state, ownProps) => {
  const {
    i18nState: { lang = "" }
  } = state;
  return { lang };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setLanguage: (lang) => {
      dispatch(setLanguage(lang));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
