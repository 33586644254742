import React, { Component } from "react";
import CreateTournament from "./presenter";

class Container extends Component {
  state = {
    isConfirmed: false
  };

  render() {
    return (
      <CreateTournament
        {...this.props}
        {...this.state}
        handleNextStep={this._handleNextStep}
        handlePrevStep={this._handlePrevStep}
      />
    );
  }

  // 次のステップ
  _handleNextStep = () => {
    this.setState({
      isConfirmed: true
    });
  };

  // 前のステップ
  _handlePrevStep = () => {
    this.setState({
      isConfirmed: false
    });
  };
}

export default Container;
