import React, { Component } from "react";
import EntryContestUser from "./presenter";
import PropTypes from "prop-types";
import { convertGameUserNameKey, removeAtSign } from "lib/convert";

class Container extends Component {
  state = {
    twitterId: "",
    gameUsername: "",
    friendCode: "",
    entryPassword: "",
    answers: [],
    isChecked: true,
    gameUsernameKey: ""
  };

  async componentDidMount() {
    const {
      contestInfo: { gameId },
      auth: { twitter, social_users = [], gameUsers = [] }
    } = this.props;

    // ログインユーザーの twitter ID 取得
    // TODO: 先頭に @は外す
    const twitterAccount = social_users.find((el) => el.provider === "TWITTER");

    // twitter ID 設定
    let twitterId, gameUsername, friendCode;
    if (twitter) {
      // ログインユーザの twitterがある場合
      twitterId = twitter;
    } else if (twitterAccount) {
      // socialUser twitter情報がある場合
      twitterId = twitterAccount.socialUserName;
    }
    // gameUser ID, フレンドコード設定
    if (gameUsers.length) {
      const gameUserInfo = gameUsers.find((el) => el.game.id === gameId);
      if (gameUserInfo) {
        gameUsername = gameUserInfo.username;
        friendCode = gameUserInfo.friendCode;
      }
    }
    // タイトル別ゲームアカウントのi18nキー取得
    const gameUsernameKey = convertGameUserNameKey(gameId);

    this.setState({
      twitterId: twitterId || "",
      gameUsername: gameUsername || "",
      friendCode: friendCode || "",
      gameUsernameKey
    });
  }

  render() {
    return (
      <EntryContestUser
        {...this.props}
        {...this.state}
        handleInputChange={this._handleInputChange}
        handleInputAnswer={this._handleInputAnswer}
        handleSubmit={this._handleSubmit}
      />
    );
  }

  _handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    this.setState({
      [name]: value,
      isChecked: true
    });
  };

  _handleInputAnswer = (questionId, event) => {
    const {
      target: { value }
    } = event;
    const { answers } = this.state;

    const answer = answers.find((el) => el.questionId === questionId);
    if (answer) {
      answer.value = value;
    } else {
      answers.push({
        questionId,
        value
      });
    }

    this.setState({
      answers: answers,
      isChecked: true
    });
  };

  // 申請ボタンクリック
  _handleSubmit = (event) => {
    event.preventDefault();
    this._entryContest();
  };

  // 大会参加申請
  _entryContest = async () => {
    const {
      auth: { id: userId },
      contestInfo,
      entryContestUser,
      addChatUser,
      handleCloseEntryModal
    } = this.props;
    const {
      answers,
      twitterId,
      gameUsername,
      friendCode,
      entryPassword
    } = this.state;

    const { t } = this.context;

    if (answers.length) {
      this.setState({
        isChecked: !this.state.isChecked
      });
    } else {
      this.setState({
        isChecked: true
      });
    }

    // Twitter IDのチェック
    if (!twitterId.match(/^([@＠]?[a-zA-Z0-9_]{1,15})$/g)) {
      alert(t("correctTwitterID"));
      this.setState({
        isChecked: false
      });
      return;
    }

    // TODO: userId を auth.id 設定
    const param = {
      userId,
      twitterId: removeAtSign(twitterId),
      gameId: contestInfo.gameId,
      gameUsername,
      friendCode,
      entryPassword,
      createdAt: new Date(),
      updatedAt: new Date()
    };

    // 大会に個人エントリー
    const entryResult = await entryContestUser(contestInfo.id, param);
    switch (entryResult) {
      case 1:
        alert(t("contestEntrySuccess"));
        // 公開チャットに参加
        await addChatUser(contestInfo.id, [userId]);
        // モーダルを閉じる
        handleCloseEntryModal();
        window.location.href = `/contests/${contestInfo.id}`;
        break;
      case -1:
        alert(t("contestEntryDuplicate"));
        break;
      case -2:
        // 運営ユーザーまでお問い合わせください
        alert(t("contestEntryPasswordFailed"));
        break;
      default:
        alert(t("contestEntryFailed"));
        break;
    }

    this.setState({
      isChecked: false
    });
  };

  static propTypes = {
    contestInfo: PropTypes.object.isRequired,
    entryContestUser: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
