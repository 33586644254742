import React, { Component } from "react";
import ContestUserList from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    loading: false,
    deleteMode: false,
    deleteList: [],
    userCount: 0,
    loginUserId: 0,
    query: {
      skip: 0,
      limit: 10
    },
    hasMore: true
  };

  async componentDidMount() {
    const { query } = this.state;
    const {
      getContestUsers,
      auth: { id: userId },
      match: {
        params: { id }
      }
    } = this.props;

    this.setState({
      loading: true
    });

    // contestUser 取得
    const results = await getContestUsers(id);
    const hasMore = false;

    this.setState({
      contestUsers: results,
      query,
      hasMore,
      userCount: results.length,
      loginUserId: userId,
      loading: false
    });
  }

  render() {
    return (
      <ContestUserList
        {...this.props}
        {...this.state}
        handleUserDelete={this._handleUserDelete}
        handleEntryConfirm={this._handleEntryConfirm}
        handleDeleteMe={this._handleDeleteMe}
        handleCheck={this._handleCheck}
        handleSwitchDelete={this._handleSwitchDelete}
        loadMore={this._loadMore}
      />
    );
  }

  // 自分のエントリー削除
  _handleDeleteMe = async () => {
    const {
      contestInfo: { id: contestId },
      auth: { id: userId, nickname },
      deleteContestUser,
      removeChatUser
    } = this.props;
    const { t } = this.context;

    if (userId) {
      if (window.confirm(t("myEntryDeleteConfirm", { username: nickname }))) {
        await deleteContestUser(contestId, userId);
        // 公開チャットルームから除外
        await removeChatUser(contestId, [userId]);
        alert(t("completeDeleteEntryUser"));
        // TODO: redux stateの整合取る
        // 一時的にリロードで対応
        window.location.reload();
      }
    }
  };

  // エントリー確定
  _handleEntryConfirm = () => {
    const {
      history,
      contestInfo: { id }
    } = this.props;
    // エントリー確定ページに遷移
    history.push(`/contests/${id}/participant/entryconfirm`);
  };

  // 削除
  _handleUserDelete = async () => {
    const { t } = this.context;
    const {
      contestInfo: { id },
      deleteContestUser,
      removeChatUser
    } = this.props;
    const { deleteList, contestUsers } = this.state;
    // 選択Itemがない場合
    if (!deleteList.length) {
      alert(t("deleteSelectUser"));
      return;
    }

    if (window.confirm(t("userDeleteConfirm"))) {
      for (const val of deleteList) {
        // 削除フラグ ONのみ実行
        if (val.isDelete) {
          await deleteContestUser(id, val.id);
        }
      }
      const deleteIds = deleteList.map((item) => item.id);
      // 公開チャットルームから除外
      await removeChatUser(id, deleteIds);
      alert(t("completeDeleteEntryUser"));
      // ContestUser から削除対象を除外
      const filterUsers = contestUsers.filter(
        (el) => !deleteIds.includes(el.userId)
      );
      this.setState({
        contestUsers: filterUsers,
        userCount: this.state.userCount - deleteIds.length
      });
      // 削除モード解除
      this._handleSwitchDelete();
    }
  };

  // 削除 Item 選択
  _handleCheck = (event) => {
    const {
      target: { value, checked }
    } = event;
    const { deleteList } = this.state;
    // deleteList 検索
    const item = deleteList.find((el) => el.id === value);
    if (item) {
      item.isDelete = checked;
    } else {
      deleteList.push({
        id: value,
        isDelete: checked
      });
    }
    this.setState({ deleteList });
  };

  // 削除モード Switch
  _handleSwitchDelete = () => {
    const { deleteMode } = this.state;
    this.setState({
      deleteMode: !deleteMode,
      deleteList: []
    });
  };

  // Loadmore
  _loadMore = async () => {
    const { contestUsers = [], query = {} } = this.state;
    const {
      getContestUsers,
      match: {
        params: { id }
      }
    } = this.props;

    // contestUser 取得
    const results = await getContestUsers(id, query);

    query.skip += results.length;
    const hasMore = query.limit > results.length ? false : true;

    this.setState({
      contestUsers: contestUsers.concat(results),
      query,
      hasMore
    });
  };

  static propTypes = {
    contestUsers: PropTypes.array.isRequired,
    deleteContestUser: PropTypes.func.isRequired,
    getContestUsers: PropTypes.func.isRequired,
    //getContestUserCount: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
