import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import Select from "react-validation/build/select";
import { required, length } from "components/lib/Validator";
import Button from "components/lib/Button";

const getSelectAnswer = (answers = [], questionId = "") => {
  const answer = answers.find((el) => el.questionId === questionId);
  return typeof answer === "object" ? answer.value : "";
};

const EntryContestUser = (props, context) => (
  <div className={styles.contents}>
    <Form className={styles.form} method="post" onSubmit={props.handleSubmit}>
      <div className={styles.title}>
        <div>{props.contestInfo.name}</div>
        <div>
          {context.t("contest")}
          {context.t("entryContest")}
        </div>
      </div>
      <div className={styles.inputContents}>
        <div className={styles.inputContents}>
          <div className={styles.inputItem}>
            <span className={styles.label}>{context.t("nickname")}</span>
            <div className={styles.value}>{props.auth.nickname}</div>
          </div>
          <div className={styles.inputItem}>
            <span className={styles.label}>{context.t("inputTwitterID")}</span>
            <span className={styles.subLabel}>
              {context.t("tipsTwitterID")}
            </span>
            <Input
              type="text"
              name="twitterId"
              value={props.twitterId}
              placeholder={context.t("inputAnswer")}
              className={styles.textInput}
              onChange={props.handleInputChange}
              autoComplete="off"
              maxLength="256"
              required
              validations={[required, length]}
            />
          </div>
          <div className={styles.inputItem}>
            <span className={styles.label}>
              {context.t("inputGameUserNameKey", {
                value: context.t(props.gameUsernameKey)
              })}
            </span>
            <span className={styles.subLabel}>
              {context.t("tipsGameUsername")}
            </span>
            <Input
              type="text"
              name="gameUsername"
              value={props.gameUsername}
              placeholder={context.t("inputAnswer")}
              className={styles.textInput}
              onChange={props.handleInputChange}
              autoComplete="off"
              maxLength="256"
              validations={[length]}
            />
          </div>
          {["dbGameMasterValue00009", "dbGameMasterValue00016"].includes(
            props.contestInfo.gameId
          ) ? (
            <div className={styles.inputItem}>
              <span className={styles.label}>
                {context.t("inputFriendCode")}
              </span>
              <span className={styles.subLabel}>
                {context.t("tipsFriendCode")}
              </span>
              <Input
                type="text"
                name="friendCode"
                value={props.friendCode}
                placeholder={context.t("inputAnswer")}
                className={styles.textInput}
                onChange={props.handleInputChange}
                autoComplete="off"
                maxLength="256"
                validations={[length]}
              />
            </div>
          ) : null}
          {props.contestInfo.isEntryPassword ? (
            <div className={styles.inputItem}>
              <span className={styles.label}>
                {context.t("inputEntryPassword")}
              </span>
              <span className={styles.subLabel}>
                {context.t("tipsEntryPassword")}
              </span>
              <Input
                type="text"
                name="entryPassword"
                value={props.entryPassword}
                placeholder={context.t("inputAnswer")}
                className={styles.textInput}
                onChange={props.handleInputChange}
                autoComplete="off"
                maxLength="16"
                validations={[length]}
              />
            </div>
          ) : null}
          {props.contestQuestions.length ? (
            <div className={styles.survey}>
              <div className={styles.surveyTitle}>
                {context.t("sponsoredSurvey")}
              </div>
              <div className={styles.surveyTips}>
                {context.t("sponsoredSurveyTips")}
              </div>

              {props.contestQuestions.map((item, index) => (
                <div key={index}>
                  <div className={styles.question}>{item.question_detail}</div>
                  {item.input_type === "SELECT" ? (
                    <Select
                      name={context.t("quesitonRequiredValue")}
                      value={getSelectAnswer(props.answers, item.id)}
                      onChange={props.handleInputAnswer.bind(this, item.id)}
                      className={styles.textInput}
                      validations={[required]}
                    >
                      <option key="" value="">
                        {context.t("selectItem")}
                      </option>
                      {props.contestQuestionSelections &&
                        props.contestQuestionSelections.map(
                          (value, selectionIndex) =>
                            value.question === item.id ? (
                              <option
                                key={selectionIndex}
                                value={value.selection_detail}
                              >
                                {value.selection_detail}
                              </option>
                            ) : null
                        )}
                    </Select>
                  ) : (
                    <Input
                      type={item.input_type}
                      name={context.t("quesitonRequiredValue")}
                      placeholder={context.t("inputAnswer")}
                      required={item.required}
                      className={styles.textInput}
                      onChange={props.handleInputAnswer.bind(this, item.id)}
                      autoComplete="off"
                      minLength={item.minLength || 0}
                      maxLength={item.maxLength || 256}
                      validations={[length]}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.inputContents}>
        <Button
          style={{ width: "100%", marginTop: 10 }}
          className={styles.entryButton}
          text={context.t("entryContest")}
          onClick={props.handleSubmit}
          disabled={!(props.twitterId && props.isChecked)}
        />
      </div>
    </Form>
  </div>
);

EntryContestUser.propTypes = {
  contestInfo: PropTypes.object.isRequired,
  handleRadioChange: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  handleInputAnswer: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

EntryContestUser.contextTypes = {
  t: PropTypes.func.isRequired
};

export default EntryContestUser;
