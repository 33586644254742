import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Notification from "components/Notification";
import {
  MdPerson,
  MdArrowDropDown,
  MdNotifications,
  MdSearch
} from "react-icons/md";
import { RiSwordFill } from "react-icons/ri";
import Auth from "components/Auth";
import Modal from "components/lib/Modal";

const HeaderMenu = (props, context) => (
  <span className={styles.headerMenu}>
    <div className={styles.menuColumn}>
      <Link to="/search-user" className={styles.menuLink}>
        <MdSearch className={styles.icon} />
        <span className={styles.text}>{context.t("searchUser")}</span>
      </Link>
    </div>
    {props.isSignedIn ? (
      <div className={styles.menuColumn}>
        <Link to="/contests/registration" className={styles.menuLink}>
          <RiSwordFill className={styles.icon} />
          <span className={styles.text}>{context.t("createContest")}</span>
        </Link>
      </div>
    ) : (
      <div></div>
    )}
    {props.isSignedIn && (
      <div className={styles.menuColumn}>
        <span onClick={props.toggleNotification} className={styles.menuLink}>
          <MdNotifications className={styles.icon} />
          <span className={styles.text}>{context.t("notification")}</span>
          {props.notification && (
            <div className={styles.popup}>
              <Notification {...props} />
            </div>
          )}
          {props.badge ? (
            <div className={styles.badge}>
              <span>{props.badge}</span>
            </div>
          ) : null}
        </span>
      </div>
    )}
    <div className={styles.menuColumn}>
      {props.isSignedIn ? (
        <span className={styles.menuLink} onClick={props.toggleUserNavigation}>
          <img
            src={
              props.auth.profileImage
                ? props.auth.profileImage
                : require("images/noimage.png")
            }
            alt="profile"
            className={styles.profileImage}
            onError={(e) => (e.target.src = require("images/noimage.png"))}
          />
          <MdArrowDropDown fontSize="20px" />
        </span>
      ) : (
        <div onClick={props.handleLogin} className={styles.menuLink}>
          <MdPerson fontSize="20px" className={styles.icon} />
          {context.t("login")}
        </div>
      )}
      {props.userNavigation && (
        <div className={styles.popup}>
          <UserNavigationPopup {...props} />
        </div>
      )}
    </div>
    <Modal isOpen={props.isAuth} onClose={props.handleClose}>
      <Auth {...props} />
    </Modal>
  </span>
);

const UserNavigationPopup = (props, context) => (
  <div className={styles.userNavigationPopup}>
    {props.isSignedIn ? (
      <div>
        <Link to={`/users/${props.auth.id}`}>
          <div className={styles.item} onClick={props.toggleUserNavigation}>
            {props.auth.nickname}
          </div>
        </Link>
        <div className={styles.item} onClick={props.handleLogout}>
          {context.t("logout")}
        </div>
      </div>
    ) : (
      <Link to="/login">
        <div className={styles.item}>{context.t("login")}</div>
      </Link>
    )}
  </div>
);

HeaderMenu.propTypes = {
  userNavigation: PropTypes.bool.isRequired,
  notification: PropTypes.bool.isRequired,
  toggleUserNavigation: PropTypes.func.isRequired,
  toggleNotification: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    id: PropTypes.string
  }),
  isSignedIn: PropTypes.bool.isRequired
};

HeaderMenu.contextTypes = {
  t: PropTypes.func.isRequired
};

UserNavigationPopup.contextTypes = {
  t: PropTypes.func.isRequired
};

export default HeaderMenu;
