import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";
import { actionCreators as userActions } from "redux/user";

const mapStateToProps = (state, ownProps) => {
  const {
    user: { auth = {} },
    game: { gameList = [] }
  } = state;

  return {
    auth,
    gameList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    contestRegistration: (formData) => {
      return dispatch(contestActions.contestRegistration(formData));
    },
    createBattleRoyal: (param) => {
      return dispatch(contestActions.createBattleRoyal(param));
    },
    createPublicChat: (name, option) => {
      return dispatch(contestActions.createPublicChat(name, option));
    },
    getContestTemplateList: (userId) => {
      return dispatch(userActions.getContestTemplateList(userId));
    },
    getBattleRoyalInfo: (contestId) => {
      return dispatch(contestActions.getBattleRoyalInfo(contestId));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
