import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import Loading from "components/lib/Loading";

const SocialCallback = (props, context) => (
  <div className={styles.contents}>{props.loading ? <Loading /> : null}</div>
);

SocialCallback.propTypes = {
  loading: PropTypes.bool.isRequired
};

export default SocialCallback;
