import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    contest: { contestInfo = {}, battleRoyalInfo = {} },
    game: { gameList = [] }
  } = state;

  return {
    contestInfo,
    battleRoyalInfo,
    gameList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getContestDetail: (id) => {
      return dispatch(contestActions.getContestDetail(id));
    },
    getBattleRoyalInfo: (id) => {
      return dispatch(contestActions.getBattleRoyalInfo(id));
    },
    contestEdit: (id, param) => {
      return dispatch(contestActions.contestEdit(id, param));
    },
    updateBattleRoyal: (id, param) => {
      return dispatch(contestActions.updateBattleRoyal(id, param));
    },
    deleteContest: (id) => {
      return dispatch(contestActions.deleteContest(id));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
