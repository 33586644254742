import React, { Component } from "react";
import UserProfileEdit from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    userInfo: {},
    isRestoreBackground: false,
    crop: {
      unit: "%",
      width: 30,
      aspect: 3 / 1
    },
    modal: {
      isOpen: false
    },
    modalStyle: {
      overlay: {
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: "100"
      }
    },
    cropCompleted: false,
    croppedImageUrl: null,
    imageUrl: "",
    uploadedimageUrl: new Blob(),
    isRestoreimageUrl: false,
    loading: false
  };

  componentDidMount() {
    const {
      auth: { nickname, bio, imageUrl, backgroundImageUrl }
    } = this.props;

    const {
      crop,
      croppedImageUrl,
      backgroundImageSrc,
      cropCompleted
    } = this.state;

    this.setState({
      userInfo: {
        nickname,
        bio,
        imageUrl,
        backgroundImageUrl
      },
      crop,
      croppedImageUrl,
      backgroundImageSrc,
      cropCompleted
    });
  }

  render() {
    return (
      <div>
        <UserProfileEdit
          {...this.props}
          {...this.state}
          handleCloseButton={this._handleCloseButton}
          nextUserInfo={this.state.userInfo}
          onModalClose={this._onModalClose}
          onSelectBackgroundImage={this._onSelectBackgroundImage}
          onImageLoaded={this._onImageLoaded}
          onCropChange={this._onCropChange}
          onbackgroundImageUrlDelete={this._onbackgroundImageUrlDelete}
          onCropComplete={this._onCropComplete}
          onCropCompleted={this._onCropCompleted}
          handleInputChange={this._handleInputChange}
          handleFileChange={this._handleFileChange}
          handleSubmit={this._handleSubmit}
        />
      </div>
    );
  }

  // 編集画面の非表示
  _handleCloseButton = (event) => {
    const {
      history,
      auth: { id }
    } = this.props;

    event.preventDefault();
    history.push(`/users/${id}`);
  };

  _onModalClose = (event) => {
    this.setState({
      modal: {
        isOpen: false
      },
      croppedImageUrl: "",
      backgroundImageSrc: ""
    });
    document.getElementById("backgroundSelect").value = "";
  };

  _handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;
    const { userInfo } = this.state;

    this.setState({
      userInfo: {
        ...userInfo,
        [name]: value === "" ? null : value
      }
    });
  };

  _handleFileChange = (event) => {
    const {
      target: { files }
    } = event;
    const { userInfo } = this.state;

    // キャンセルボタンのとき、Stateを更新しない
    if (files.length === 0) {
      return;
    }

    const createObjectURL =
      (window.URL || window.webkitURL).createObjectURL ||
      window.createObjectURL;
    const imageUrl = files.length ? createObjectURL(files[0]) : null;

    this.setState({
      userInfo: {
        ...userInfo,
        imageUrl: imageUrl
      },
      uploadedProfileImage: files.length ? files[0] : null,
      isRestoreProfileImage: false
    });
  };

  _handleSubmit = async (event) => {
    const {
      auth: { id },
      history
    } = this.props;

    this.setState({
      loading: true
    });

    event.preventDefault();

    // バリデーションチェック
    const isValid = true;

    if (isValid) {
      await this._updateUserInfo();

      history.push(`/users/${id}`);
    }
  };

  _onImageLoaded = (image) => {
    this.imageRef = image;
  };

  _onCropComplete = (crop) => {
    this._makeClientCrop(crop);
  };

  _onSelectBackgroundImage = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({
          backgroundImageSrc: reader.result,
          cropCompleted: false,
          modal: { isOpen: true }
        })
      );
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  _onCropChange = (crop) => {
    this.setState({ crop });
  };

  _onbackgroundImageUrlDelete = () => {
    const { userInfo } = this.state;
    this.setState({
      cropCompleted: null,
      isRestoreBackground: true,
      userInfo: {
        ...userInfo,
        backgroundImageUrl: null
      }
    });
  };

  _onCropCompleted = () => {
    this.setState({
      isRestoreBackground: false,
      cropCompleted: true,
      modal: { isOpen: false }
    });
  };

  async _makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const { croppedBlob, croppedImageUrl } = await this._getCroppedImg(
        this.imageRef,
        crop,
        "backgroundImage.jpeg"
      );
      this.setState({ croppedBlob, croppedImageUrl });
    }
  }

  _getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve({ croppedBlob: blob, croppedImageUrl: this.fileUrl });
      }, "image/jpeg");
    });
  }

  // ユーザ詳細情報更新
  _updateUserInfo = async () => {
    const {
      updateUserInfo,
      auth: { id }
    } = this.props;

    const {
      userInfo
      // isRestoreProfileImage,
      // uploadedProfileImage,
      // croppedBlob,
      // isRestoreBackground
    } = this.state;

    // const formData = new FormData(target);
    const { t } = this.context;

    // // 初期化
    // formData.delete("profileImage");
    // formData.delete("profileBackgroundImage");

    // isRestoreProfileImage
    //   ? formData.append("profileImage", "")
    //   : uploadedProfileImage.size &&
    //     formData.append(
    //       "profileImage",
    //       uploadedProfileImage,
    //       uploadedProfileImage.name
    //     );

    // isRestoreBackground
    //   ? formData.append("profileBackgroundImage", "")
    //   : croppedBlob &&
    //     formData.append(
    //       "profileBackgroundImage",
    //       new File([croppedBlob], "backgroundImage.jpeg", {
    //         type: "image/jpeg"
    //       })
    //     );

    const [result, error] = await updateUserInfo(id, userInfo);

    if (result) {
      alert(t("saveChangeMsg"));
    } else {
      alert(t("errorMsg"));
      console.log(error);
    }

    this.setState({
      loading: false
    });
  };

  // _addGameTitleImage = (gameUsers) => {
  //   gameUsers.map(
  //     (gameUser) =>
  //       (gameUser.imageUrl =
  //         process.env.REACT_APP_GCS_PATH +
  //         `/game/${gameUser.game.key}_thumbnail`)
  //   );
  // };

  // // チーム一覧取得
  // _getTeamList = async () => {
  //   const {
  //     getTeamList,
  //     match: {
  //       params: { id }
  //     }
  //   } = this.props;

  //   await getTeamList(id);
  //   this.setState({
  //     loading: false
  //   });
  // };

  // _checkGameUserTeam = () => {
  //   const {
  //     teamList,
  //     userInfo: { gameUsers }
  //   } = this.props;
  //   gameUsers.map((gameUser) =>
  //     teamList.map((team) =>
  //       gameUser.game.id === team.game.id ? (gameUser.team = team) : null
  //     )
  //   );
  // };

  // _getTeamCheck = async () => {
  //   await this._getTeamList();
  //   await this._checkGameUserTeam();
  // };

  static propTypes = {
    updateUserInfo: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
