import React from "react";
import styles from "./styles.scss";
import PropTypes from "prop-types";
import { MdContentCopy } from "react-icons/md";
import { TiSocialTwitter, TiDelete } from "react-icons/ti";
import { CopyToClipboard } from "react-copy-to-clipboard";
import TournamentMatchResult from "components/TournamentMatchResult";
import TournamentUploadMatchResult from "components/TournamentUploadMatchResult";
//import TournamentMatchChat from "components/TournamentMatchChat";
import Linkify from "react-linkify";
import { Link, /*Route, Switch*/ } from "react-router-dom";
import classNames from "classnames/bind";
import { convertClientDateTime } from "lib/convert";
import { IoCheckmarkCircle } from "react-icons/io5";
import Button from "components/lib/Button";

const cx = classNames.bind(styles);

const CommonMatchDetail = (props) => (
  <div className={styles.contents}>
    <Detail {...props} />
  </div>
);

const Detail = (props, context) => (
  <div>
    {props.matchInfo.id ? (
      <div className={styles.matchInfo}>
        <div className={styles.title}>
          # {props.matchInfo.round + 1} - {props.matchInfo.order + 1}
        </div>
        <div>
          {props.type === "detail" ? (
            <div>
              {props.contestInfo.contestType === "TOURNAMENT" ? (
                <TournamentMatchResult {...props} />
              ) : null}
              {props.matchInfo.winner &&
              (props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ||
                props.matchInfo.participantList.some((el) => el.myItem)) ? (
                <div className={styles.buttonLayout}>
                  <Button
                    style={{ width: "100%" }}
                    className={styles.editButton}
                    text={context.t("editMatchResult")}
                    onClick={props.handleEditResult}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
          {props.type === "edit" ? (
            <div>
              {props.contestInfo.contestType === "TOURNAMENT" ? (
                <TournamentUploadMatchResult {...props} />
              ) : null}
            </div>
          ) : null}
        </div>
        <div className={styles.participantInfo}>
          <div className={styles.label}>{context.t("participant")}</div>
          <div className={styles.value}>
            {props.matchInfo.participantList.length ? (
              props.matchInfo.participantList.map((item, index) => (
                <div key={index} className={styles.item}>
                  <div className={styles.info}>
                    <div className={styles.infoItem}>
                      <div className={styles.label}>
                        {context.t("user")}
                      </div>
                      <div className={styles.value}>
                        <Link
                          to={`/users/${item.userId}`}
                        >
                          <img
                            src={
                              item.imageUrl
                                ? item.imageUrl
                                : require("images/noimage.png")
                            }
                            alt="proflie"
                            className={styles.image}
                            onError={(e) =>
                              (e.target.src = require("images/noimage.png"))
                            }
                          />
                          <span>{item.name || "-"}</span>
                        </Link>
                      </div>
                    </div>
                    {["OWNER", "ADMIN"].includes(
                      props.contestInfo.operatorType
                    ) ||
                    (props.matchInfo.participantList &&
                      props.matchInfo.participantList.some(
                        (el) => el.myItem
                      )) ? (
                      <div>
                        <div className={styles.infoItem}>
                          <div className={styles.label}>
                            {props.contestInfo.participantType === "SOLO"
                              ? context.t("twitterID")
                              : context.t("representativeTwitterID")}
                          </div>
                          <div className={styles.value}>
                            {item.twitterId ? (
                              <a
                                href={`https://twitter.com/${item.twitter}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={styles.twitterURL}
                              >
                                <div className={styles.twitterId}>
                                  <TiSocialTwitter
                                    className={styles.twitterBird}
                                  />
                                  <span className={styles.twitterText}>
                                    {item.twitterId}
                                  </span>
                                </div>
                              </a>
                            ) : null}
                          </div>
                        </div>
                        <div className={styles.infoItem}>
                          <div className={styles.label}>
                            {props.contestInfo.participantType === "SOLO"
                              ? context.t(props.gameUsernameKey)
                              : context.t("representativeGameUsername", {
                                  value: context.t(props.gameUsernameKey)
                                })}
                          </div>
                          <div className={styles.value}>
                            {item.gameuser ? (
                              <span>
                                {item.gameuser}
                                <CopyToClipboard text={item.gameuser}>
                                  <MdContentCopy
                                    fontSize="18px"
                                    className={styles.copyIcon}
                                    onClick={props.handleCopyText}
                                  />
                                </CopyToClipboard>
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                        {item.friendCode ? (
                          <div className={styles.infoItem}>
                            <div className={styles.label}>
                              {props.contestInfo.participantType === "SOLO"
                                ? context.t("friendCode")
                                : context.t("representativeFriendCode")}
                            </div>
                            <div className={styles.value}>
                              {item.friendCode ? (
                                <span>
                                  {item.friendCode}
                                  <CopyToClipboard text={item.friendCode}>
                                    <MdContentCopy
                                      fontSize="18px"
                                      className={styles.copyIcon}
                                      onClick={props.handleCopyText}
                                    />
                                  </CopyToClipboard>
                                </span>
                              ) : (
                                "-"
                              )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                    {props.contestInfo.checkin
                      ? props.additionalParticipantInfo.map(
                          (participant, index) =>
                            participant.participantId === item.id ? (
                              <div key={index} className={styles.infoItem}>
                                <div className={styles.label}>
                                  {context.t("checkinContest")}
                                </div>
                                <div className={styles.checkInContainer}>
                                  <IoCheckmarkCircle
                                    className={cx(styles.checkinIcon, {
                                      active: participant.checkin
                                    })}
                                  />
                                  <div
                                    className={cx(styles.value, {
                                      active: participant.checkin
                                    })}
                                  >
                                    {participant.checkin
                                      ? convertClientDateTime(
                                          participant.checkUpdated,
                                          "MM-DD HH:mm"
                                        )
                                      : context.t("incomplete")}
                                  </div>
                                </div>
                              </div>
                            ) : null
                        )
                      : null}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.empty}>{context.t("emptyData")}</div>
            )}
          </div>
          {props.myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ||
          (props.matchInfo.participantList &&
            props.matchInfo.participantList.some((el) => el.myItem)) ? (
            <div>
              <div className={styles.buttonLayout}>
                <Button
                  style={{ width: "100%" }}
                  className={styles.chatButton}
                  text={context.t("chats")}
                  onClick={props.handleMatchChat}
                />
              </div>
              <div className={styles.infoItem}>
                <div className={styles.videoUrl}>
                  <div className={styles.link} onClick={props.handleVedioUrl}>
                    {context.t("addVideoUrl")}
                  </div>
                  {props.isVideoUrl ? (
                    <div className={styles.videoUrlForm}>
                      <div>{context.t("inputVideoUrl")}</div>
                      <input
                        type="text"
                        className={styles.inputVideoUrl}
                        value={props.videoUrl}
                        onChange={props.handleInputChange}
                      />
                      <div className={styles.buttonLayout}>
                        <Button
                          style={{ width: "100%" }}
                          className={styles.saveButton}
                          text={context.t("add")}
                          onClick={props.handleAddVideoUrl}
                        />
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          {props.matchInfo.videoList && props.matchInfo.videoList.length ? (
            <div className={styles.infoItem}>
              <div className={styles.label}>{context.t("videoUrl")}</div>
              {props.matchInfo.videoList.map((item, index) => (
                <div key={index} className={styles.value}>
                  <div className={styles.videoUrlInfo}>
                    <Linkify
                      className={styles.link}
                      properties={{
                        target: "_blank",
                        rel: "noopener noreferrer"
                      }}
                    >
                      <span className={styles.text}>{item.videoUrl}</span>
                    </Linkify>
                    {item.editBy === props.auth.id ? (
                      <span
                        onClick={props.handleDeleteVideoUrl.bind(this, item)}
                      >
                        <TiDelete className={styles.icon} fontSize="22px" />
                      </span>
                    ) : null}
                  </div>
                  {props.iframeUrlInfo[index] ? (
                    <div className={styles.iframeTagContents}>
                      <iframe
                        className={styles.iframe}
                        title={context.t("contestIframeVideo")}
                        src={props.iframeUrlInfo[index]}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    ) : null}
  </div>
);

/*
const Routes = (props) => (
  <Switch>
    <Route
      path="/contests/:id/tournament/:resourceId/match/:matchId/chats"
      render={({ match }) => <TournamentMatchChat {...props} match={match} />}
    />
    <Route
      path="/contests/:id/tournament/:resourceId/match/:matchId"
      render={({ match }) => <Detail {...props} match={match} />}
    />
    <Route
      path="/contests/:id/swissDraw/:resourceId/match/:matchId/chats"
      render={({ match }) => <SwissDrawMatchChat {...props} match={match} />}
    />
    <Route
      path="/contests/:id/swissdraw/:resourceId/match/:matchId"
      render={({ match }) => <Detail {...props} match={match} />}
    />
  </Switch>
);
*/

Detail.contextTypes = {
  t: PropTypes.func.isRequired
};

export default CommonMatchDetail;
