import React, { Component } from "react";
import CommonMatchDetail from "./presenter";
import PropTypes from "prop-types";
import URLSearchParams from "url-search-params";
import { isWithinDuration } from "lib/convert";
import { arrayUnion, arrayRemove } from "firebase/firestore";
import { convertGameUserNameKey } from "lib/convert";

class Container extends Component {
  state = {
    type: "detail",
    isVideoUrl: false,
    videoUrl: "",
    iframeUrlInfo: {},
    representativeUserList: [],
    additionalParticipantInfo: [],
    matchInfo: {}
  };

  async componentDidMount() {
    const {
      contestInfo: {
        id: contestId,
        participantType,
        gameId
      },
      resourceInfo: { id: resourceId },
      selected: {
        matchId,
      },
      myRole = {},
      getResourceInfo,
      getResourceMatchInfo,
      getContestTeamInfo
    } = this.props;
    const { iframeUrlInfo = {} } = this.state;

    // 対戦表からの遷移では resourceIdが取れる
    // 対戦画面に直接アクセスする場合は resourceIdから取る
    // (親の ContestTournamentに Router設置することで共有化できるが、他のComponentは表示したくないため)

    let resourceInfo = {};
    if (!resourceId) {
      // resourceInfo 取得
      resourceInfo = await getResourceInfo(contestId);
    }
    const resoId = resourceId ? resourceId : resourceInfo?.id;

    // match 取得
    const matchInfo = await getResourceMatchInfo(resoId, matchId) || {};
    const {
      winner,
      participantList = [],
      videoList = [{ videoUrl: "" }]
    } = matchInfo;

    // 結果表示、結果入力の表示制御
    let type;
    if (
      myRole?.abilityList?.includes("ABILITY_CONTEST_EDIT") ||
      participantList.some((el) => el.myItem)
    ) {
      // 運営者、または参加者の場合
      if (winner) {
        // winner がいる場合
        type = "detail";
      } else {
        // winner がいない場合
        type = "edit";
      }
    } else {
      // 参加してないユーザ
      type = "detail";
    }

    // タイトル別ゲームアカウントのi18nキー取得
    const gameUsernameKey = convertGameUserNameKey(gameId);
    this.setState({
      type,
      gameUsernameKey
    });

    //for(const val of videoList) {
    videoList.forEach((val, index) => {
      // iframe URL取得
      if (val.videoUrl) {
        // Youtube の動画URLから iframe URL取得
        if (val.videoUrl.match(/www.youtube.com\/watch/)) {
          // Query string を parsing
          const urlSplit = val.videoUrl.split("www.youtube.com/watch");
          if (urlSplit.length && urlSplit[1]) {
            const params = new URLSearchParams(urlSplit[1]);
            const vedioId = params.get("v");
            if (vedioId) {
              iframeUrlInfo[index] = `https://www.youtube.com/embed/${vedioId}`;
              this.setState({
                iframeUrlInfo
              });
            }
          }
        }
      }
    });

    if (participantType === "MULTI") {
      // 代表者 取得(contestTeam.createBy)
      // TODO: createByから取得するのは微妙なので、別のデータから取得に修正
      const representativeUserList = [];
      for (const val of participantList) {
        const teamId = val.id;
        const result = await getContestTeamInfo(contestId, teamId);
        if (result) {
          representativeUserList.push({
            ...result.createBy,
            teamId
          });
        }
      }
      this.setState({
        representativeUserList
      });
    }

    this._getAdditionalParticipantInfo(matchInfo, participantType);

    this.setState({ matchInfo: matchInfo });
  }

  // Component から抜ける時
  componentWillUnmount() {
    // 対戦詳細情報クリアー
    const { clearResourceMatchInfo } = this.props;
    clearResourceMatchInfo();
  }

  render() {
    return (
      <CommonMatchDetail
        {...this.state}
        {...this.props}
        handleEditResult={this._handleEditResult}
        handleCopyText={this._handleCopyText}
        handleVedioUrl={this._handleVedioUrl}
        handleAddVideoUrl={this._handleAddVideoUrl}
        handleDeleteVideoUrl={this._handleDeleteVideoUrl}
        handleInputChange={this._handleInputChange}
        handleMatchChat={this._handleMatchChat}
      />
    );
  }

  // ゲームID, フレンドコードをコピー
  _handleCopyText = () => {
    const { t } = this.context;
    alert(t("copiedText"));
  };

  // 編集ボタンクリック
  _handleEditResult = () => {
    const {
      contestInfo: { contestStart, contestEnd, contestType },
      resourceInfo: { currentRound, roundInfo, roundStatus }
    } = this.props;
    const {
      matchInfo: { round }
    } = this.state;

    if (!isWithinDuration(contestStart, contestEnd)) {
      // 大会期間外には編集不可
      const { t } = this.context;
      alert(t("notWithinContestDuration"));
      return;
    }

    // スイスドローはcurrentRoundかつ全てのラウンドが終了していない場合は編集可能
    if (
      contestType === "SWISSDRAW" &&
      (round !== currentRound ||
        (currentRound === roundInfo.length - 1 && roundStatus === "end"))
    ) {
      const { t } = this.context;
      alert(t("finishedRound"));
      return;
    }

    this.setState({
      type: "edit"
    });
    window.scrollTo(0, 0);
  };

  // 対戦チャット
  _handleMatchChat = async () => {
    const {
      contestInfo: { id: contestId },
      getContestMatchChatInfo,
      history
    } = this.props;
    const {
      matchInfo: { id: matchId } = {}
    } = this.state;
    // チャット情報取得
    const chatInfo = await getContestMatchChatInfo(contestId, matchId)
    if (contestId && chatInfo.id) {
      history.push(
        `/contests/${contestId}/chats/${chatInfo.id}`
      );
    }
  };

  // videoUrl toggle
  _handleVedioUrl = () => {
    const { isVideoUrl } = this.state;

    this.setState({
      isVideoUrl: !isVideoUrl
    });
  };

  // videoUrl 入力
  _handleInputChange = (event) => {
    const {
      target: { value }
    } = event;

    this.setState({
      videoUrl: value
    });
  };

  // videoUrl 追加
  _handleAddVideoUrl = async () => {
    const { videoUrl } = this.state;
    const {
      resourceInfo: { id: resourceId },
      updateResource,
      auth: { id: userId }
    } = this.props;
    const {
      matchInfo: { id: matchId, videoList = [] }
    } = this.state;

    const { t } = this.context;
    const maxLength = 5;
    if (videoList.length >= maxLength) {
      alert(t("overMaxVideoUrl", { val: maxLength }));
      return;
    }

    if (resourceId && matchId) {
      // video Url 更新
      const params = {
        videoList: arrayUnion({
          videoUrl,
          editBy: userId,
          updated: new Date()
        })
      };
      await updateResource(resourceId, matchId, params);
      alert(t("completeAddVideoUrl"));
      window.location.reload();
    }
  };

  // videoUrl 削除
  _handleDeleteVideoUrl = async (item) => {
    const {
      resourceInfo: { id: resourceId },
      updateResource
    } = this.props;
    const {
      matchInfo: { id: matchId }
    } = this.state;

    const { t } = this.context;

    if (resourceId && matchId) {
      if (window.confirm(t("deleteVideoUrlConfirm"))) {
        // video Url 更新
        const params = {
          videoList: arrayRemove(item)
        };
        await updateResource(resourceId, matchId, params);
        alert(t("completeDeleteVideoUrl"));
        window.location.reload();
      }
    }
  };

  // Firestore から取得できていない参加者情報を取得する (チェックイン、代表者情報)
  // TODO: チームの代表者情報をcreateByから取得するのは微妙なので、別のデータから取得に修正
  _getAdditionalParticipantInfo = async (matchInfo = {}, participantType) => {
    const { contestInfo, getContestUserInfo, getContestTeamInfo } = this.props;
    const additionalParticipantInfo = [];

    if(matchInfo?.participantList?.length) {
      for (const val of matchInfo.participantList) {
        const participantId = val.id;
        const result =
          participantType === "SOLO"
            ? await getContestUserInfo(contestInfo.id, participantId)
            : await getContestTeamInfo(contestInfo.id, participantId);

        if (result) {
          additionalParticipantInfo.push({
            ...result.createBy,
            checkin: result.checkin,
            checkUpdated: result.checkUpdated,
            participantId
          });
        } else {
          // エラーで値が代入されない場合はここで初期化
          additionalParticipantInfo.push({
            createBy: { nickname: "-" },
            checkin: false,
            checkUpdated: null,
            participantId
          });
        }
      }
    }

    this.setState({
      additionalParticipantInfo: additionalParticipantInfo
    });
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
