import React, { Component } from "react";
import EditTournamentMatch from "./presenter";
import PropTypes from "prop-types";
import moment from "moment";

class Container extends Component {
  state = {
    resource: "teams",
    slotCountList: [2, 4, 8, 16, 32, 64, 128, 256],
    matchCountList: [1, 3, 5],
    matchTimeList: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180],
    slotCount: 2,
    matchCount: 1,
    matchTime: 60,
    tournament: [],
    tournamentId: "",
    editing: false,
    loading: true
  };

  async componentDidMount() {
    const {
      contestInfo: { id, participantType },
      getTournamentMatchList,
      getContestTeams,
      getContestUsers,
      match: { params: { tournamentId } }
    } = this.props;
    const { slotCountList } = this.state;

    const resource = participantType === "MULTI" ? "teams" : "users";
    // store にデータセット
    resource === "teams" ? getContestTeams(id) : getContestUsers(id);

    // firestoreのトーナメントに紐づくマッチ情報取得
    const matchList = await getTournamentMatchList(tournamentId);
    // round, order でグルーピングを行ったマッチ取得
    const tournament = this._getGroupByTournament(matchList);

    // アクティブリスト取得
    this._getActiveList(tournament, resource);

    // スロットカウント設定
    let slotCount;
    for (const val of slotCountList) {
      if (matchList.length <= val) {
        slotCount = val;
        break;
      }
    }

    this.setState({
      slotCount,
      matchCount: matchList[0].matchCount,
      matchTime: matchList[0].matchTime,
      tournament: tournament,
      tournamentId: tournamentId,
      resource: resource,
      loading: false
    });
  }

  render() {
    return (
      <EditTournamentMatch
        {...this.props}
        {...this.state}
        handleTimeChange={this._handleTimeChange}
        handleEditTournament={this._handleEditTournament}
        handleDeleteTournament={this._handleDeleteTournament}
        handleSlotChange={this._handleSlotChange}
        handleCancel={this._handleCancel}
      />
    );
  }

  // スロット単位で画面表示するためマッチ情報を round と order でグルーピング
  _getGroupByTournament = (matchs) => {
    const groupedMatch = this._groupBy(matchs, "round");
    const matchList = [];
    for (const i in groupedMatch) {
      // 時刻を画面表示用にフォーマット
      const startTimetoDate = groupedMatch[i][0].startTime.toDate();
      const startTime = moment(startTimetoDate).format("YYYY-MM-DDTHH:mm");
      const params = {
        round: Number(i),
        startTime: startTime,
        match: []
      };
      matchList.push(params);
      const grouped = this._groupBy(groupedMatch[i], "order");
      for (const index in grouped) {
        // 3位決定戦の時刻を画面表示用にフォーマット
        if (grouped[index][0].type === "thirdPlaceMatch") {
          params.thirdPlaceMatchStartTime = moment(
            grouped[index][0].startTime.toDate()
          ).format("YYYY-MM-DDTHH:mm");
        }
        if (matchList[i]) {
          matchList[i].match.push(grouped[index][0]);
        }
      }
    }
    return matchList;
  };

  // 時刻変更
  _handleTimeChange = (index, event) => {
    const { tournament } = this.state;
    const {
      target: { value, id }
    } = event;

    const startTime = moment(value).format("YYYY-MM-DDTHH:mm");

    if (id === "thirdPlaceMatch") {
      tournament[index].thirdPlaceMatchStartTime = startTime;
    } else {
      tournament[index].startTime = startTime;
    }

    this.setState({
      tournament
    });
  };

  // トーナメント編集
  _handleEditTournament = async (event) => {
    const {
      contestInfo: { id },
      updateTournament
    } = this.props;

    const { tournament, tournamentId, resource } = this.state;

    this.setState({
      editing: true
    });

    const params = {
      startTime: ""
    };
    for (const t of tournament) {
      params.startTime = t.startTime;
      for (const match of t.match) {
        if (match.type === "thirdPlaceMatch") {
          params.startTime = t.thirdPlaceMatchStartTime;
        }
        // 表示用の空データ除外
        const resources = match[resource].filter(
          (r) => Object.keys(r).length !== 0
        );
        params[resource] = [];
        if ("reports" in match) {
          // 戦績がある場合はパラメーターからリソース削除
          delete params[resource];
        } else if (resources.length === 0) {
          // リソースが空の場合は空スロットなので初期化したままでいい
          // pass
        } else {
          for (const r of resources) {
            params[resource].push(r);
          }
        }
        // firestore のトーナメント情報更新
        await updateTournament(tournamentId, match.id, params);
      }
    }

    this.setState({
      editing: false
    });
    alert(this.context.t("completeEditTournament"));
    // 大会詳細ページに遷移
    window.location.href = `/contests/${id}/tournament`;
  };

  // トーナメント削除
  _handleDeleteTournament = async () => {
    if (!window.confirm(this.context.t("tournamentDeleteConfirm"))) {
      return;
    }

    const {
      contestInfo: { id },
      deleteTournament,
      updateContestTeamStatus,
      updateContestUserStatus
    } = this.props;

    const { tournamentId, tournament, resource } = this.state;

    this.setState({
      editing: true
    });

    // User or Team のステータスを null に更新
    const status = null;
    for (const match of tournament[0].match) {
      for (const resourceItem of match[resource]) {
        if(!resourceItem.id) continue;
        if (resource === "teams") {
          await updateContestTeamStatus(id, resourceItem.id, status);
        } else {
          await updateContestUserStatus(id, resourceItem.id, status);
        }
      }
    }

    // firestore トーナメント情報削除
    await deleteTournament(tournamentId);

    this.setState({
      editing: false
    });

    alert(this.context.t("completeDeleteTournament"));
    // 大会詳細ページに遷移
    window.location.href = `/contests/${id}/tournament`;
  };

  //アクティブリスト取得
  _getActiveList = (tournament, resource) => {
    // 戦績のないチーム/ユーザーを取得
    let noReports = [];
    tournament[0].match.forEach((match) => {
      if (!("reports" in match)) {
        match[resource].forEach((item) => {
          noReports.push(item);
        });
      }
    });
    tournament.forEach((t) => {
      t.match.forEach((match) => {
        // 一回戦目以外は他のスロットのデータを追加しない
        if ("reports" in match || t.round !== 0) {
          match.activeList = match[resource];
          // 空スロット表示用にアクティブリストデータ追加
          if (match[resource].length === 0) {
            match.activeList.push({});
            match.activeList.push({});
          } else if (match[resource].length === 1) {
            match.activeList.push({});
          }
        } else {
          // 一回戦目は戦績のないスロットのデータは全て追加
          match.activeList = noReports;
          // 空スロット表示用にリソースデータ追加
          if (match[resource].length === 0) {
            match[resource].push({});
            match[resource].push({});
          } else if (match[resource].length === 1) {
            match[resource].push({});
          }
        }
        // インデックス入れ替え
        if (match[resource].length > 1 && match[resource][0].index === 1) {
          const index1 = match[resource][0];
          const index0 = match[resource][1];
          match[resource][0] = index0;
          match[resource][1] = index1;
        }
      });
    });
  };

  // スロット配置変更
  _handleSlotChange = (before, event) => {
    const { tournament, resource } = this.state;
    const beforeOrder = Number(before.matchIndex);
    const beforeIndex = Number(before.index);
    delete before.matchIndex;
    delete before.index;
    const {
      target: { value }
    } = event;

    // 選択されたチーム情報取得
    let selected;
    tournament[0].match.forEach((match) => {
      match[resource].forEach((el) => {
        if (el.id === value) {
          selected = el;
        }
      });
    });
    // // // 空選択は無効にする
    if (!selected) return;
    let selectedOrder, selectedIndex;
    // TODO createは一回戦目しか変更されないので0固定だが
    const matches = tournament[0].match;
    for (const order in matches) {
      for (const index in matches[order][resource]) {
        // 選択Itemの order, indexを取得
        if (matches[order][resource][index].id === value) {
          selectedOrder = order;
          selectedIndex = index;
        }
      }
    }
    // 入れ替え
    // 選択元のスロットが空以外ならインデックスを入れる
    if ("id" in before) before.index = Number(selectedIndex);
    selected.index = beforeIndex;
    matches[selectedOrder][resource][selectedIndex] = before;
    matches[beforeOrder][resource][beforeIndex] = selected;

    this.setState({
      tournament
    });
  };

  // 戻る
  _handleCancel = (event) => {
    const { history, prevStep } = this.props;
    if (prevStep) {
      prevStep();
    } else {
      history.goBack();
    }
  };

  // グルーピング
  _groupBy = (array, property) => {
    return array.reduce((group, item) => {
      return Object.assign(group, {
        [item[property]]: (group[item[property]] || []).concat(item)
      });
    }, {});
  };

  static propTypes = {
    contestInfo: PropTypes.object.isRequired,
    getTournamentInfo: PropTypes.func.isRequired,
    getTournamentMatchList: PropTypes.func.isRequired,
    updateTournament: PropTypes.func.isRequired,
    deleteTournament: PropTypes.func.isRequired,
    updateContestTeamStatus: PropTypes.func.isRequired,
    updateContestUserStatus: PropTypes.func.isRequired
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
