import { axios } from "lib/axios";
import { actionCreators as userActions } from "redux/user";

// ユーザをプロバイダーの画面へリダイレクトさせ認証
function socialOauth() {
  return (dispatch) => {
    return axios
      .get("/api/v1/socials/twitter/oauth/url/")
      .then((result) => {
        return result.data;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
}

// ユーザ登録 or ログイン処理
function socialLogin(token, verifier) {
  const param = {
    token,
    verifier
  };
  return (dispatch) => {
    return axios
      .post("/api/v1/socials/twitter/oauth/", param)
      .then((result) => {
        if (result.data) {
          const { token, userInfo } = result.data;

          dispatch(userActions.saveToken(token));
          dispatch(userActions.setProfile(userInfo));
        }
        return result;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  };
}

// タイムラインの取得
function getTwitterTimeline(query) {
  return (dispatch) => {
    return axios
      .get("/api/v1/socials/twitter/timeline/", query)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  };
}

const initialState = {};

function reducer(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}

const actionCreators = {
  socialOauth,
  socialLogin,
  getTwitterTimeline
};

export { actionCreators };

export default reducer;
