import React from "react";
import styles from "./styles.scss";
import classNames from "classnames/bind";
import PropTypes from "prop-types";

const cx = classNames.bind(styles);

const UserContract = (props, context) => (
  <div className={styles.contents}>
    <div className={styles.title}>{context.t("userContractTitle")}</div>
    <div
      className={cx("text", {
        modalText: props.match != null ? props.match.path !== "/contract" : true
      })}
    >
      {context.t("userContractContents")}
    </div>
  </div>
);

UserContract.contextTypes = {
  t: PropTypes.func.isRequired
};

export default UserContract;
