import TagManager from "react-gtm-module";

const {
  REACT_APP_GTM_TRACKING_ID,
  REACT_APP_GTM_AUTH,
  REACT_APP_GTM_PREVIEW
} = process.env;

const tagManagerArgs = {
  gtmId: REACT_APP_GTM_TRACKING_ID,
  auth: REACT_APP_GTM_AUTH,
  preview: REACT_APP_GTM_PREVIEW
};

TagManager.initialize(tagManagerArgs);

/**
 * Google Tag Manager の DataLayer 設定
 * @param {String} key
 * @param {String} value
 */
function setGTMDataLayer(key, value) {
  const tagManagerArgs = {
    dataLayer: {
      [key]: value
    }
  };
  TagManager.dataLayer(tagManagerArgs);
}

export { setGTMDataLayer };

export default TagManager;
