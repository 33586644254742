import { initializeApp } from "firebase/app";
import {
  getFirestore,
  connectFirestoreEmulator,
  FieldValue,
  Timestamp
} from "firebase/firestore";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_IS_EMULATOR = false,
  REACT_APP_FIREBASE_EMULATOR_PORT = 5002
} = process.env;

// initialize firebase instance with config from console
const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID
};

// Initialize firebase instance
const FirebaseApp = initializeApp(firebaseConfig);

// Initialize Cloud Firestore through Firebase
const firestore = getFirestore(FirebaseApp);

if (REACT_APP_FIREBASE_IS_EMULATOR === "true") {
  // firebase emulator 接続
  connectFirestoreEmulator(
    firestore,
    "localhost",
    REACT_APP_FIREBASE_EMULATOR_PORT
  );
}

// https://firebase.google.com/docs/reference/js/firebase.firestore.FieldValue
// const FieldValue = firestore.FieldValue;
// https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp
// const Timestamp = firestore.Timestamp;

export { firestore, FieldValue, Timestamp };

export default FirebaseApp;
