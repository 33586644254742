import React from "react";
import PropTypes from "prop-types";
import ContestEntryEdit from "components/ContestEntryEdit";
import CreateTournamentMatch from "components/CreateTournamentMatch";

const CreateTournament = (props) => (
  <div>
    {!props.isConfirmed ? (
      <ContestEntryEdit
        {...props}
        nextStep={props.handleNextStep}
        mode="createTournament"
      />
    ) : (
      <CreateTournamentMatch {...props} prevStep={props.handlePrevStep} />
    )}
  </div>
);

CreateTournament.propTypes = {
  isConfirmed: PropTypes.bool.isRequired,
  handleNextStep: PropTypes.func.isRequired
};

export default CreateTournament;
