import React from "react";
import ReactDOM from "react-dom";
import App from "components/App";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "redux/configureStore";
import I18n from "redux-i18n";
import translations from "./translations";

// indexedDB 言語取得 -> browser 言語取得
const lang =
  localStorage.getItem("lang") ||
  (window.navigator.languages && window.navigator.languages[0]) ||
  window.navigator.language ||
  window.navigator.userLanguage ||
  window.navigator.browserLanguage;

ReactDOM.render(
  <Provider store={store}>
    <I18n translations={translations} initialLang={lang} fallbackLang="en">
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </I18n>
  </Provider>,
  document.getElementById("root")
);
