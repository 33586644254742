import React, { Component } from "react";
import ContestChat from "./presenter";
import PropTypes from "prop-types";

class Container extends Component {
  state = {
    isHide: true,
    chatLoading: true,
    anchorEl: null,
    selected: {},
    message: "",
    hasMore: false,
    query: {
      limit: 10,
      lastData: null
    },
    isNewMessage: false,
    isScrollBottom: false,
    chatInfo: {},
    isReadOnly: false,
    modalStyle: {
      overlay: {
        zIndex: "120"
      }
    },
    isChatMenu: false
  };
    
  async componentDidMount() {
    const {
      contestInfo: { id: contestId },
      subscribeRoomList
    } = this.props;
    // チャット一覧をリアルタイムリスナーで取得
    subscribeRoomList(contestId);

    // 画面幅が小さい端末はデフォルト非表示
    this.setState({
      isHide: this._isSmallDevice()
    });

    this.setState({
      chatLoading: false
    });    
  }


  // Component 更新
  componentDidUpdate(prevProps) {
    const { chatList = [], chatId } = this.props;
    const { chatId : prevChatId } = prevProps;
    // チャットID が更新された場合、もしくは選択されたチャットがない場合（初回表示）
    if(chatList.length && (!this.state.selected.id || prevChatId !== chatId)) {
      const item = chatList.find(el => el.chatId === chatId);
      // 指定チャットがある場合は対象チャット、なければ1個目のチャットを設定
      const initChat = item ? item : chatList[0];
      // チャットルームを開く
      this._handleOpenChat(initChat);
    }
  }

  render() {
    return (
      <ContestChat
        {...this.props}
        {...this.state}
        togglePresence={this._togglePresence}
        handleClickChat={this._handleClickChat}
        handleCreateChatRoom={this._handleCreateChatRoom}
        handleOpenMenu={this._handleOpenMenu}
        handleCloseMenu={this._handleCloseMenu}
        handleSelectMenu={this._handleSelectMenu}
        handleInputChange={this._handleInputChange}
        handleSendMessage={this._handleSendMessage}
        handleBackPage={this._handleBackPage}
        getMatchChat={this._getMatchChat}
        handleNewMessage={this._handleNewMessage}
        handleChangeInputFile={this._handleChangeInputFile}
        toggleChatMenu={this._toggleChatMenu}
      />
    );
  }

  // プレゼンスの開閉
  _togglePresence = () => {
    const { isHide } = this.state;

    this.setState({
      isHide: !isHide
    });
  };

  // チャットメニュー開く
  _handleOpenMenu = (event) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  // チャットメニュー閉じる
  _handleCloseMenu = () => {
    this.setState({
      anchorEl: null
    });
  };

  // チャットメニュー選択
  _handleSelectMenu = (selected) => {
    const {
      contestInfo: { id: contestId },
      clearChatBadge
    } = this.props;
    if (selected === 1) {
      // すべて既読にする処理
      clearChatBadge(contestId);
    }

    this.setState({
      anchorEl: null
    });
  };

  // チャットルームをクリック
  _handleClickChat = async (roomInfo = {}) => {
    const {
      contestInfo: { id: contestId },
      history
    } = this.props;
    if(roomInfo.chatId) {
      // チャットパスを記録
      history.push(`/contests/${contestId}/chats/${roomInfo.chatId}`);
    }
    // チャットを開く
    this._handleOpenChat(roomInfo);
  }

  // チャットを開く
  _handleOpenChat = async (roomInfo = {}) => {
    const {
      getChatDetail,
      clearMatchChat,
      subscribeMatchChat,
      changeRoomStatus,
    } = this.props;
    
    // 重複選択防止
    const { selected = {} } = this.state;
    if(selected.id === roomInfo.id) return;

    // 既存チャットクリア
    clearMatchChat();
    this.setState({
      selected: roomInfo,
      hasMore: false,
      query: {
        ...this.state.query,
        lastData: null,
      }
    });

    // チャット情報取得
    const chatInfo = await getChatDetail(roomInfo.chatId);
    await this._getMatchChat();
    if(chatInfo.id) {
      // 新しいチャットを受信
      subscribeMatchChat(chatInfo.id);
      changeRoomStatus(chatInfo.id);
    }
  };

  // チャットルーム作成
  _handleCreateChatRoom = () => {
    const {
      contestInfo: { id: contestId },
      history
    } = this.props;

    if (contestId) {
      history.push({
        pathname: `/contests/${contestId}/chats`
      });
    }
  };

  // 画面幅のチェック
  _isSmallDevice = () => {
    return (
      window.matchMedia &&
      window.matchMedia("(max-device-width: 640px)").matches
    );
  };


  _getMatchChat = async () => {
    const { getMatchChat } = this.props;
    const { query, selected = {} } = this.state;

    // チャットメッセージ取得
    const result = await getMatchChat(selected.chatId, query);
    query.lastData = result.lastData;
    const hasMore = query.limit > result.length ? false : true;

    this.setState({
      query,
      hasMore
    });
  };

  // メッセージ入力
  _handleInputChange = (event) => {
    const {
      target: { name, value }
    } = event;

    this.setState({
      [name]: value
    });
  };

  // 新しいメッセージクリック
  _handleNewMessage = () => {
    // スクロールを最下部に移動
    this._changeScrollBottom();
    this.setState({
      isNewMessage: false
    });
  };

  // メッセージ送信
  _handleSendMessage = (msg = {}) => {
    const {
      auth: { id, nickname, profileImage },
      sendMessage
    } = this.props;
    const { selected = {} } = this.state;

    if (typeof msg !== "object") return;
    const param = {
      from: {
        id,
        name: nickname,
        image: profileImage || null
      }
    };
    if (msg.text) {
      param.text = msg.text;
    }
    if (msg.image) {
      param.image = msg.image;
    }

    // メッセージ送信
    sendMessage(selected.chatId, param);
    // スクロールを最下部に移動
    this._changeScrollBottom();
  };

  // 前のページに戻る
  _handleBackPage = () => {
    const { history } = this.props;

    // 新規作成から遷移してきた場合は2画面分戻る
    if (this.props.location.state?.isCreated) {
      return history.go(-2);
    }

    history.goBack();
  };

  // スクロールを最下部に移動
  _changeScrollBottom = () => {
    const el = document.getElementById("messageList");
    const y = el.scrollHeight - el.clientHeight;
    el.scroll(0, y);
  };

  _handleChangeInputFile = (event) => {
    // 画像選択
    const {
      target: { files }
    } = event;

    // キャンセルボタンのとき、Stateを更新しない
    if (!files || files.length === 0) {
      return;
    }

    this._handleAttachImage(files);
  };

  // 画像添付処理
  _handleAttachImage = async (files) => {
    const { uploadFile } = this.props;

    // キャンセルボタンのとき、Stateを更新しない
    if (!files || files.length === 0) {
      return;
    }

    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("prefix", "chat");

    // ファイルアップロード
    const imageUrl = await uploadFile(formData);

    if (imageUrl) {
      this._handleSendMessage({ image: imageUrl });
    }
  };

  // チャットメニュー操作
  _toggleChatMenu = () => {
    const { isChatMenu } = this.state;

    this.setState({
      isChatMenu: !isChatMenu
    });
  };

  static contextTypes = {
    t: PropTypes.func.isRequired
  };
}

export default Container;
