import { connect } from "react-redux";
import Container from "./container";
import { actionCreators as userActions } from "redux/user";
import { actionCreators as contestActions } from "redux/contest";

const mapStateToProps = (state, ownProps) => {
  const {
    game: { gameList = [] },
    contest: { operatorList = [] }
  } = state;
  return {
    gameList,
    operatorList
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    searchUser: (query) => {
      return dispatch(userActions.searchUser(query));
    },
    getContestOperators: (contestId) => {
      return dispatch(contestActions.getContestOperators(contestId));
    },
    addContestOperators: (contestId, gameId, addList) => {
      return dispatch(contestActions.addContestOperators(contestId, gameId, addList));
    },
    deleteContestOperators: (contestId, deleteList) => {
      return dispatch(
        contestActions.deleteContestOperators(contestId, deleteList)
      );
    },
    addChatAdmin: (contestId, userIdList) => {
      return dispatch(contestActions.addChatAdmin(contestId, userIdList));
    },
    removeChatAdmin: (contestId, userIdList, isOnlyPublic) => {
      return dispatch(
        contestActions.removeChatAdmin(contestId, userIdList, isOnlyPublic)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
